import React, { FC, useEffect, useState, useMemo } from 'react';
import { styled } from '@cappex/theme';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { AutomationNameDefault } from '@util/automation';

type TextGradientProps = {
	text: string;
	lineNumbers: number;
	doesTextContainMarkup?: boolean;
	textColor?: TypographyProps['color'];
	variant?: TypographyProps['variant'];
	displayReadButtons?: boolean;
	readMoreLabel?: string;
	readLessLabel?: string;
};

export const FixedHeightDiv = styled.div<{ height: number; showFullText: boolean }>`
	${props => (props.showFullText ? `height: auto;` : `height: ${props.height / 16}rem;`)}
	overflow: hidden;
	position: relative;
`;

export const Gradient = styled.div<{ hideGradient: boolean }>`
	text-align: right;
	position: absolute;
	bottom: 0;
	${props =>
		props.hideGradient
			? ''
			: `background: linear-gradient(
    			to right,
    			rgba(255, 255, 255, 0) 50%,
    			${props.theme.palette.common.white},
    			${props.theme.palette.common.white});`} // Must use rgba because Safari doesn't render transparent gradients correctly
	width: 100%;
	height: 1.625rem;
`;

const StyledButton = styled(Button)`
	position: relative;
	padding-bottom: 2px;
	padding-top: 2px;
	height: 1.625rem;
`;

const RightAlignedWrapper = styled.div`
	text-align: right;
`;

const createMarkup = (html: string) => {
	return { __html: html };
};

let gradientRef: HTMLDivElement;

const TextGradient: FC<TextGradientProps> = ({
	text,
	lineNumbers,
	doesTextContainMarkup = false,
	textColor,
	variant = 'body2',
	displayReadButtons = false,
	readMoreLabel = 'Read More',
	readLessLabel = 'Read Less',
}) => {
	const [showFullText, setShowFullText] = useState(false);
	const [windowWidth, setWindowWidth] = useState<number>();
	const [gradientHideable, setGradientHideable] = useState(false);

	const fixedHeightDivsHeight = useMemo(() => {
		const doesStartWithParagraph = doesTextContainMarkup && text && text.trim().startsWith('<p>');

		return lineNumbers * 21 + 3 + (doesStartWithParagraph ? 14 : 0);
	}, [doesTextContainMarkup, lineNumbers, text]);

	// keep track of window size changes
	// to check if read more/less buttons are excessive
	useEffect(() => {
		const updateWindowsWidth = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', updateWindowsWidth);
		setGradientHideable(!(gradientRef && gradientRef.scrollHeight > fixedHeightDivsHeight));

		return () => window.removeEventListener('resize', updateWindowsWidth);
	}, [fixedHeightDivsHeight, windowWidth]);

	const getTextComponent = () => {
		return doesTextContainMarkup ? (
			<Typography
				variant={variant}
				// need to use dangerouslySetInnerHTML
				// since some data contains Admin Created Markup
				dangerouslySetInnerHTML={createMarkup(text)}
				color={textColor}
			/>
		) : (
			<Typography variant={variant} color={textColor}>
				{text}
			</Typography>
		);
	};

	const shouldShowReadMore = () => {
		return displayReadButtons && !showFullText && !gradientHideable;
	};

	const shouldShowReadLess = () => {
		return displayReadButtons && showFullText && !gradientHideable;
	};

	return (
		<>
			<FixedHeightDiv
				showFullText={showFullText}
				ref={el => {
					gradientRef = el;
				}}
				height={fixedHeightDivsHeight}
			>
				{getTextComponent()}

				<Gradient hideGradient={displayReadButtons && (gradientHideable || showFullText)}>
					{shouldShowReadMore() && (
						<StyledButton
							data-qa={AutomationNameDefault.readMore}
							onClick={() => setShowFullText(true)}
							color="primary"
							variant="text"
						>
							{readMoreLabel}
						</StyledButton>
					)}
				</Gradient>
			</FixedHeightDiv>

			{shouldShowReadLess() && (
				<RightAlignedWrapper>
					<StyledButton
						data-qa={AutomationNameDefault.readLess}
						onClick={() => setShowFullText(false)}
						color="primary"
						variant="text"
					>
						{readLessLabel}
					</StyledButton>
				</RightAlignedWrapper>
			)}
		</>
	);
};

export default TextGradient;
