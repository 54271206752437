import { useState, useEffect } from 'react';
import useUrlParam from './useUrlParam';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import { CompleteCollege } from '../college/collegeDataUtil';

const useCheckUrlCollegeId = (active: boolean) => {
	const [isCollegePending, setIsCollegePending] = useState(true);
	const [collegeId, setCollegeId] = useState<string>();
	const [collegeName, setCollegeName] = useState<string>();

	const urlCollegeId = useUrlParam('collegeId');

	useEffect(() => {
		if (!active || !isCollegePending || collegeName) return;
		if (urlCollegeId) {
			request<WebResponse<FormKeyedData, CompleteCollege[]>>({
				url: getEndpoint(`/colleges/v1/public/retrieve/${urlCollegeId}`),
				method: RequestMethod.GET,
				headers: [JsonAcceptHeader, JsonContentTypeHeader],
				withCredentials: true,
			})
				.then(res => {
					const { data } = res;

					if (data.meta.success && data.response[urlCollegeId]?.college.name && !collegeName) {
						setCollegeName(data.response[urlCollegeId]?.college.name);
						setCollegeId(urlCollegeId);
						setIsCollegePending(false);
					} else {
						setIsCollegePending(false);
					}
				})
				.catch(() => {
					setIsCollegePending(false);
				});
		} else {
			setIsCollegePending(false);
		}
	}, [urlCollegeId, isCollegePending, active, collegeName]);

	const isValidCollege = !!collegeName;

	return {
		isCollegePending,
		collegeId,
		collegeName,
		urlCollegeId,
		setCollegeName,
		setCollegeId,
		isValidCollege,
	};
};

export default useCheckUrlCollegeId;
