import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@cappex/theme';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { Button, Grid } from '@material-ui/core';
import DataFlowContainer from '../DataFlowContainer';
import DataFlowContext from '../../util/DataFlowContext';
import useUserData from '@src/common/util/hooks/useUserData';
import { Student, parseStudent } from '@src/common/util/student/studentDataUtil';
import request, { RequestMethod, JsonAcceptHeader, JsonContentTypeHeader } from '@cappex/request';
import getEndpoint from '@util/request';
import CboFormInput from '@src/common/components/CboFormInput';
import { SubForm } from '@src/common/components/BaseValidationForm';
import { FormNames, ListFormNames } from '@cappex/constants';

type OwnProps = {};
const EMPTY_FUNCTION = () => {};

const RegisterButton = withStyleOptions(Button);

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

const CboGeneralCboSearchPage: FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	OwnProps> = ({
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	active,
	customLogoUrl,
}) => {
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);

	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);

	const [userData, setUserData] = useState<Partial<Student>>({});
	const [isButtonDisabled, setButtonDisabled] = useState(false);

	useEffect(() => {
		if (R.isNil(userInfo.roleId)) {
			return;
		}

		request<any>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data.response)
			.then(data => {
				setUserData(parseStudent(data));
			});
	}, [userInfo]);

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
		});
		return true;
	};

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<SubForm name="student">
					<SubForm name={FormNames.studentCboForm}>
						<Grid item xs={12}>
							<SearchWrapper>
								<CboFormInput
									header="College Readiness Program"
									studentLat={userData.latitude}
									studentLng={userData.longitude}
									name={ListFormNames.studentCbos}
								/>
							</SearchWrapper>
						</Grid>
					</SubForm>
				</SubForm>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						Next
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default CboGeneralCboSearchPage;
