import BirthYearDataLayer from './birthYearDataLayer';
import DataLayerEventTypes from './dataLayerEventTypes';

interface StudentData {
	birthYear: string;
}

const updateApplicationDataLayer = (studentData: StudentData) => {
	BirthYearDataLayer(DataLayerEventTypes.APPLICATION_BIRTH_YEAR, studentData?.birthYear);
};

export default updateApplicationDataLayer;
