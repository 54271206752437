import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { styled } from '@cappex/theme';
import { Link } from '@material-ui/core';
import { AutomationNameDefault } from '@common/util/automation';

interface AnchorButtonProps {
	text: string;
	href?: string;
	target?: string;
	onClick?: (e?: any) => void;
	anchorBottom?: boolean;
	automationName?: string;
	center?: boolean;
}

const GridStyled = styled(Grid)`
	& a {
		text-transform: none;
	}

	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		&.anchor {
			position: fixed;
			bottom: 0;
			margin-bottom: 16px;
		}
	}
`;

const AnchorButton: FC<AnchorButtonProps> = ({
	text,
	href = '#',
	target = '',
	onClick,
	anchorBottom = false,
	automationName = AutomationNameDefault.anchorButton,
	center = true,
}) => (
	<GridStyled
		xs={12}
		className={anchorBottom ? 'anchor' : ''}
		container
		item
		justifyContent={center ? 'center' : 'flex-start'}
		direction="row"
	>
		<Grid item>
			<Link
				target={target}
				href={href}
				onClick={onClick}
				color="textPrimary"
				variant="body2"
				data-qa={automationName}
			>
				{text}
			</Link>
		</Grid>
	</GridStyled>
);

export default AnchorButton;
