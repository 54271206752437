import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';

type StudyAbroadSelectProps = Omit<Partial<BaseFormSelectProps>, 'references'>;
const StudyAbroadSelect: FC<StudyAbroadSelectProps> = ({
	id = AutomationNameDefault.studyAbroadInput,
	name = FormNames.studyAbroadId,
	label = 'Study Abroad',
	initialValue,
	required,
	automationName = AutomationNameGeneric.formSelect,
	variant,
}) => {
	const [preferences] = useCloudReferenceData({
		dataType: ReferenceDataTypes.preference,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			references={preferences}
			initialValue={initialValue}
			required={required}
			automationName={automationName}
			addEmptyValue
			variant={variant}
		/>
	);
};

export default StudyAbroadSelect;
