import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import AnchorButton from '@src/common/components/AnchorButton';
import { SubForm } from '@src/common/components/BaseValidationForm';
import EmailPasswordForm from '@src/common/components/EmailPasswordForm';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames, ListFormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { RoleId } from '@src/common/util/roles/constants';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@src/common/util/validation/form';
import { createChancesConfig } from '../../../registration/constants/chancesConfig';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { StepContainerProps } from '@src/common/util/steps';

interface CreateAccountFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.password]?: string;
	[FormNames.uuid1]?: string;
	[FormNames.uuid2]?: string;
}

type OwnProps = {};
type StudentCollegesForm = {
	studentColleges: object[];
};

const RegisterButton = withStyleOptions(Button);

const handleCreateAccountFailure = (
	json: any,
	setCreateAccountDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) => {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setCreateAccountDisabled(false);
		const formErrors: CreateAccountFormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
};

const ChancesPersonalInfoPage: FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	OwnProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		hideLeftMediaImageMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		legalTextConfig,
	},
	complete,
	reverse,
	active,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { getValue, setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic, setValidAuth } = useContext(AuthContext);

	const [emailPasswordComplete, setEmailPasswordComplete] = useState(false);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isReverseCalled, setIsReverseCalled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				if (!R.isEmpty(responseData.response.accountId)) {
					setValidAuth(
						RoleId.STUDENT,
						responseData.response.userAccessPermissions,
						false,
						responseData.response.accountUuid
					);
				}

				complete();
				setButtonDisabled(false);
			} else {
				handleCreateAccountFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleCreateAccountFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	const studentCollegeFormValues = useMemo(
		() => getValue(ListFormNames.studentColleges) as StudentCollegesForm,
		[getValue]
	);

	useEffect(() => {
		if (
			!isReverseCalled &&
			active &&
			(R.isEmpty(studentCollegeFormValues) || R.isEmpty(studentCollegeFormValues.studentColleges))
		) {
			setIsReverseCalled(true);
			reverse({ newConfig: createChancesConfig(), newStep: 0 });
		}
	}, [reverse, studentCollegeFormValues, isReverseCalled, active]);

	if (
		(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
		active
	) {
		complete();
	}

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<Typography variant="h6">Create Account</Typography>
					<SubForm name="accountInfo">
						<EmailPasswordForm
							complete={() => setEmailPasswordComplete(true)}
							reverse={() => {}}
							required={active}
							active={active}
							passwordLabel="Create Password"
						/>
					</SubForm>
				</Grid>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled || !emailPasswordComplete}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my chances' : 'Next'}
					</RegisterButton>
				</Grid>
				<Grid item xs={12}>
					<LegalTextAndLinks configs={legalTextConfig} />
					<AnchorButton text="Already have an account?" href="/login" />
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default ChancesPersonalInfoPage;
