import React, { FC, useState, useContext } from 'react';
import { StepComponentProps } from '@src/common/util/steps';
import NameForm from '@src/common/components/NameForm';
import { styled } from '@cappex/theme';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import EmailInput from '@src/common/components/EmailInput';
import Grid from '@material-ui/core/Grid';
import getEndpoint from '@util/request';
import { FormNames, DataFlowStepName } from '@cappex/constants';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import FormContainer from '@common/components/FormContainer';
import RegisterButton from '@common/components/RegisterButton';
import { FormContext, withFormValidation, FormFields } from '@util/validation/form';
import request, {
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	ErrorMessages,
	getFormErrors,
	checkForFormError,
	FORM_NAME,
} from '@cappex/request';
import { DataFlowStep } from '../../constants/types';
import DataFlowContainer from '../DataFlowContainer';

const BoldTypography = styled(Typography)`
	font-weight: bold;
`;

interface ParentGuardianFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.firstName]?: string;
	[FormNames.lastName]?: string;
}

const ParentGuardianInvitePage: FC<StepComponentProps<any, any, DataFlowStep['data']>> = ({
	complete,
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getFormValues } = useContext(FormContext);

	const [allowSubmit, setAllowSubmit] = useState<boolean>(true);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const submitForm = () => {
		setAllowSubmit(false);

		request<any>({
			url: getEndpoint('/registration/v1/parent-invite'),
			method: RequestMethod.POST,
			data: getFormValues(),
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data)
			.then(data => {
				if (data.meta.success) {
					complete();
				} else {
					setAllowSubmit(true);

					const formErrors: ParentGuardianFormFields = getFormErrors(data);
					setFormErrors(formErrors);

					if (checkForFormError(formErrors)) {
						openErrorSnack(formErrors[FORM_NAME]);
					}
				}
			})
			.catch(() => {
				setAllowSubmit(true);
				openErrorSnack(ErrorMessages.unknown);
			});
	};

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<FormContainer
				id={DataFlowStepName.ParentGuardianInviteName}
				name={DataFlowStepName.ParentGuardianInviteName}
				onSubmit={submitForm}
			>
				<Grid item>
					<Grid item>
						<BoldTypography align="left" variant="h6" gutterBottom>
							Parent/Guardian Information{' '}
							<Typography display="inline" variant="button" color="textSecondary">
								<FontAwesomeIcon icon={faQuestionCircle} />
							</Typography>
						</BoldTypography>
					</Grid>
					<Grid item>
						<NameForm
							id="parent_guardian-"
							fullWidth
							firstNameLabel="Parent/Guardian's First name"
							lastNameLabel="Parent/Guardian's Last Name"
						/>
					</Grid>
					<Grid item>
						<EmailInput
							required
							fullWidth
							label="Parent/Guardian's Email Address"
							helperText="example@email.com"
						/>
					</Grid>
					<Grid item>
						<RegisterButton submitDisabled={!allowSubmit}>
							{totalSteps === currentStep ? 'Finish' : 'Continue'}
						</RegisterButton>
					</Grid>
				</Grid>
			</FormContainer>
		</DataFlowContainer>
	);
};

export default withFormValidation(ParentGuardianInvitePage);
