import React, { useState, useEffect, FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import { styled } from '@cappex/theme';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FloatingCloseButton } from '@cappex/components';
import * as R from 'ramda';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import request, { JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import useFailedAuth from '@util/auth/hooks/useFailedAuth';
import {
	buildStudentConsentRequestFormData,
	ConsentType,
	StudentConsentResponse,
	V1StudentCollegeConsentWebResponse,
} from '@src/features/consent/consentUtil';
import { StepComponentProps } from '@src/common/util/steps';
import { makeStyles } from '@material-ui/core';
import { CompleteCollege } from '@src/common/util/college/collegeDataUtil';

const StyledPaper = styled(Paper)`
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	width: 22.5rem;
	max-height: 90%;
`;

const ButtonWrapper = styled.div`
	padding: 2.5rem 1rem 1rem;
`;

const StyledSecondaryTextButton = styled(Button)`
	color: ${props => props.theme.palette.text.secondary};
`;

const DialogContentTextExtraPaddingTop = styled(DialogContentText)`
	padding-top: 2rem;
`;

const useStyles = makeStyles(theme => ({
	root: { width: '100vw' },
	whiteBackdrop: { backgroundColor: theme.palette.background.default },
}));

type GenericConsentModalProps = Omit<StepComponentProps, 'reverse'> & {
	collegesToDisplayConsent: CompleteCollege[];
	consentType: ConsentType;
	whiteBackdrop?: boolean;
	onClose?: () => void;
};

const GenericConsentModal: FC<GenericConsentModalProps> = ({
	complete,
	collegesToDisplayConsent,
	consentType,
	children,
	whiteBackdrop = false,
	onClose,
}) => {
	const useFailAuth = useFailedAuth();
	const classes = useStyles();
	const [disableButtons, setDisableButtons] = useState(false);
	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(!R.isEmpty(collegesToDisplayConsent));
		R.isEmpty(collegesToDisplayConsent) && complete();
	}, [collegesToDisplayConsent, setOpen, complete]);

	// saves  Yes, No, N/A response and closes modal
	const handleSaveResponse = (responseType: StudentConsentResponse, failAuth): void => {
		onClose?.();
		setDisableButtons(true);
		request<V1StudentCollegeConsentWebResponse>({
			url: getEndpoint('/student-college-consents/v1'),
			method: RequestMethod.POST,
			data: buildStudentConsentRequestFormData(responseType, collegesToDisplayConsent, [
				consentType,
			]),
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		})
			.then(() => {
				setDisableButtons(false);
				complete();
			})
			.catch(err => {
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					failAuth();
					return;
				}
				setDisableButtons(false);
				complete();
			});
	};

	return (
		<Dialog
			PaperComponent={StyledPaper}
			classes={{ root: classes.root }}
			BackdropProps={whiteBackdrop ? { classes: { root: classes.whiteBackdrop } } : undefined}
			open={open}
			onClose={() => handleSaveResponse(StudentConsentResponse.NO_RESPONSE, useFailAuth)}
			scroll="paper"
			data-qa={`${AutomationNameDefault.consent}-${AutomationNameGeneric.wrapper}`}
		>
			<DialogContent>
				<DialogContentTextExtraPaddingTop
					paragraph
					data-qa={`${AutomationNameGeneric.headerText}`}
					align="center"
					variant="h6"
					color="textPrimary"
				>
					Thanks for your interest
				</DialogContentTextExtraPaddingTop>
				{children}
				<ButtonWrapper>
					<DialogContentText>
						<Button
							fullWidth
							data-qa={`${AutomationNameGeneric.submitButton}-${AutomationNameDefault.consentYes}`}
							disabled={disableButtons}
							onClick={() => {
								handleSaveResponse(StudentConsentResponse.YES, useFailAuth);
							}}
							color="primary"
							variant="contained"
						>
							I agree
						</Button>
					</DialogContentText>
					<DialogContentText>
						<StyledSecondaryTextButton
							fullWidth
							data-qa={`${AutomationNameGeneric.submitButton}-${AutomationNameDefault.consentNo}`}
							disabled={disableButtons}
							onClick={() => {
								handleSaveResponse(StudentConsentResponse.NO, useFailAuth);
							}}
							variant="text"
						>
							I do not agree
						</StyledSecondaryTextButton>
					</DialogContentText>
				</ButtonWrapper>
			</DialogContent>
			<FloatingCloseButton
				onClick={() => handleSaveResponse(StudentConsentResponse.NO_RESPONSE, useFailAuth)}
				position={['top', 'right']}
				qa={`${AutomationNameGeneric.closeButton}-${AutomationNameDefault.consentNoResponse}`}
			/>
		</Dialog>
	);
};

export default GenericConsentModal;
