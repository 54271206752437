import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputBase } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { AutomationNameGeneric } from '@common/util/automation';

const SearchWrapper = styled.div`
	position: relative;
	background-color: ${props => props.theme.palette.grey[50]};
	width: 100%;
`;

const SearchIconRight = styled.div`
	width: 4.5rem;
	height: 100%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${props => props.theme.palette.text.primary};
	right: 0;
	&:hover {
		cursor: pointer;
	}
`;

const SearchInputBase = styled(InputBase)`
	color: ${props => props.theme.palette.text.primary};
	width: 100%;
	height: 100%;
	& input {
		height: 3.5rem;
		padding-top: 0;
		padding-bottom: 0;
		margin-right: 0;
		margin-left: 1rem;
		width: 100%;
	}
`;

type Props = {
	handleSearchClose: (e: any) => void;
	automationName?: string;
	automationClose?: string;
};

const Search: FC<Props> = ({
	handleSearchClose,
	automationName = AutomationNameGeneric.searchInput,
	automationClose = AutomationNameGeneric.closeButton,
}) => {
	return (
		<SearchWrapper>
			<SearchIconRight onClick={handleSearchClose} data-qa={automationClose}>
				<FontAwesomeIcon icon={faTimes} />
			</SearchIconRight>
			<SearchInputBase placeholder="Search" inputProps={{ 'data-qa': automationName }} />
		</SearchWrapper>
	);
};

export default Search;
