import { SupportedCustomQuizDataFields } from '@cappex/constants';
import customQuizQuestionMappingNew from '@src/features/dataflow/util/customQuizQuestionMapping';

export type QuizAnswerCustomMapping = {
	dataField: string;
	value: string[];
};

export const addToFormValue = (customMapping: QuizAnswerCustomMapping[], name: string, setError: (field: string, error: string[]) => void, multi: boolean, getValue: (fieldName: string) => object, setFormValue: (fieldName: string, fieldValue: any) => void) => {
	if(!customMapping?.length) return
	customMapping.forEach(mapping => {
		if (
			!Object.values(SupportedCustomQuizDataFields).includes(
				mapping.dataField as SupportedCustomQuizDataFields
			)
		) {
			setError(name, ['Attempting to set invalid data field']);
		}
		const paths = customQuizQuestionMappingNew(mapping.dataField)

		let existingValue = Object.values(getValue(paths[paths.length-1]))[0]
		if(!Array.isArray(existingValue)){
			existingValue = []
		}

		const valuesToAdd = multi? [...new Set(existingValue.concat(mapping.value))]: mapping.value
		setFormValue(paths[paths.length-1], valuesToAdd);
	});
};

export const removeFromFormValue = (customMapping: QuizAnswerCustomMapping[], getValue: (fieldName: string) => object, setFormValue: (fieldName: string, fieldValue: any) => void) => {
	if (customMapping != null && customMapping.length > 0) {
		customMapping.forEach(mapping => {
			const paths = customQuizQuestionMappingNew(mapping.dataField)
			const existingValue = Object.values(getValue(paths[paths.length-1]))[0]
			const remainingValues = existingValue.filter(value => !mapping.value.includes(value))

			setFormValue(paths[paths.length-1], remainingValues);
		});
	}
};