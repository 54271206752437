import * as R from 'ramda';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { OutreachData } from '..';

const getOutreachData = (outreachLimit: number): Promise<OutreachData[]> =>
	request<any>({
		url: getEndpoint(`/college-outreach/v2/matching/${outreachLimit}`),
		method: RequestMethod.GET,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
		withCredentials: true,
	})
		.then(res => {
			const { data } = res;
			const { response } = data;

			if (data.meta.success && !R.isNil(response)) {
				return data.response;
			}
			throw new Error();
		})
		.catch<OutreachData[]>(() => []);

export default getOutreachData;
