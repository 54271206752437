import * as R from 'ramda';
import React, { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import AuthContext, { Auth, AuthenticState, defaultAuthBase } from '@util/auth';
import { RoleId } from '../../roles/constants';
import { loadUserAccount, PermissionsActionsMap, UserAccount } from '../../user/userUtil';
import { isAllowedUnauthentic } from '../util/NoAuthAllowedRoutes';
import useAlgoliaInsights from '@src/common/components/search/util/useAlgoliaInsights';
import { NavigateFunction, useNavigate } from 'react-router-dom';

type updateProps = {
	setValidAuth: (
		role: RoleId,
		permissionsAndActions: PermissionsActionsMap,
		registrationIsComplete: boolean,
		accountUuid: string
	) => void;
	setInvalidAuth: () => void;
	setAttemptedLocation: (location: string) => void;
	navigate: NavigateFunction;
};

type UpdateFunction = (autoFail?: boolean, onAuthFail?: () => void) => void;
type UpdateFunctionCreator = (updateProps: updateProps) => UpdateFunction;

const update: UpdateFunctionCreator = ({
	setValidAuth,
	setInvalidAuth,
	setAttemptedLocation,
	navigate,
}) => {
	const redirectAndFail = () => {
		// Any Auth issues, invalidate and redirect to login.
		setInvalidAuth();
		// Avoid redirecting if we came straight to a non-authentic route.
		if (!isAllowedUnauthentic(window.location.pathname)) {
			setAttemptedLocation(window.location.pathname);
			navigate('/login');
		}
	};

	const userAccountToAuth = (userAccount: UserAccount) => {
		setValidAuth(
			userAccount.roleId,
			userAccount.permissionsAndActionsMap,
			userAccount.completedRegistration,
			userAccount.accountUuid
		);
	};

	/**
	 * Date: 12/15/2021
	 * Author: WL
	 *
	 * The call to auth/v2/check the first time loadUserAccount is called seems to not happen.
	 * This behavior can be seen in the logs for edge-api, with the first call not displaying anything in the log and only when user refresh will we see the check call being made.
	 * We are assuming this has something to do with the JSESSIONID not being available at the beginning when logging in via 'Remember Me'.
	 * Because of this, as a solution, we are recalling loadUserAccount, which seems to consistently work and log the user back in.
	 * If it were to fail again, to prevent endless attempts, the user will be redirected back to the login page.
	 * */
	const tryAgain = () => {
		setTimeout(() => loadUserAccount(userAccountToAuth, redirectAndFail, redirectAndFail), 1000);
	};

	return (autoFail = false, onAuthFail = redirectAndFail) => {
		if (!autoFail) {
			loadUserAccount(userAccountToAuth, tryAgain, onAuthFail);
		} else {
			redirectAndFail();
		}
	};
};

const INVALID = {
	...defaultAuthBase,
	isAuthentic: AuthenticState.NotAuthentic,
};

const Provider: FC = ({ children }) => {
	const [auth, setAuth] = useState(defaultAuthBase);
	const [attemptedLocation, setAttemptedLocation] = useState('');
	const algoliaInsightsClient = useAlgoliaInsights();
	const navigate = useNavigate();

	const setValidAuth = useCallback(
		(
			role: RoleId,
			permissionsAndActions: PermissionsActionsMap,
			registrationIsComplete: boolean,
			accountUuid: string
		) => {
			setAuth({
				isAuthentic: AuthenticState.Authentic,
				role,
				permissionsAndActions,
				registrationIsComplete: R.isNil(registrationIsComplete) || registrationIsComplete,
				accountUuid,
			});

			accountUuid && algoliaInsightsClient('setUserToken', accountUuid);
		},
		[algoliaInsightsClient]
	);

	const setInvalidAuth = useCallback(() => {
		setAuth(INVALID);
	}, []);

	const setUnknownAuth = useCallback(() => {
		setAuth(defaultAuthBase);
	}, []);

	useLayoutEffect(
		() => {
			update({ setValidAuth, setInvalidAuth, setAttemptedLocation, navigate })();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps -- passing navigate causes refreshes every route change
		[]
	);

	const finalAuth: Auth = useMemo(
		() => ({
			...auth,
			forceRefresh: update({
				setValidAuth,
				setInvalidAuth,
				setAttemptedLocation,
				navigate,
			}),
			setValidAuth,
			setInvalidAuth,
			setUnknownAuth,
			attemptedLocation,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps -- passing navigate causes refreshes every route change
		[auth, setInvalidAuth, setUnknownAuth, setValidAuth, attemptedLocation]
	);

	return <AuthContext.Provider value={finalAuth}>{children}</AuthContext.Provider>;
};

export default Provider;
