import { useCallback } from 'react';
import { QuizEvent, QuizEventType, QuizValue } from '..';
import * as R from 'ramda';

export type QuizDispatchableConfig = {
	id: string | number;
	values?: QuizValue[];
};

type MergedFormQuizDispatchType = {
	dispatch: (value: QuizEvent) => void;
	multi: boolean;
	selectConfig: QuizDispatchableConfig[];
	setValue: (value: any) => void;
	name?: string;
	selectedIds: (string | number)[];
};

const useMergedFormQuizDispatch = ({
	dispatch,
	multi,
	selectConfig,
	setValue,
	name,
	selectedIds,
}: MergedFormQuizDispatchType) =>
	useCallback(
		(id: string | number, event: QuizEvent) => {
			let newAnswers: (string | number)[];
			// Remove id from selected if it's already there, otherwise add the new id
			switch (event.type) {
				case QuizEventType.REVOKE:
					newAnswers = R.without([id], selectedIds);
					newAnswers = newAnswers.length === 0 ? [null] : newAnswers;
					break;
				case QuizEventType.SEND:
				default:
					if (multi) {
						newAnswers = [...selectedIds, id].filter(R.complement(R.isNil));
						// Add the current one to the list of selected stuff
					} else {
						// Otherwise, remove the possible current selection, and set the new selection
						if (selectedIds.length === 1 && selectedIds[0] !== null) {
							// Find by ID, if not exists find by index
							const oldConfig: QuizDispatchableConfig =
								R.find(R.propEq('id', selectedIds[0]))(selectConfig) ??
								selectConfig[selectedIds[0]];

							dispatch({
								type: QuizEventType.REVOKE,
								values: oldConfig?.values,
							});
						}
						newAnswers = [id];
					}
			}

			setValue({ [name]: newAnswers });
			dispatch(event);
		},
		[dispatch, multi, selectConfig, setValue, name, selectedIds]
	);

export default useMergedFormQuizDispatch;
