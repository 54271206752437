import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { BadgeProps } from '@material-ui/core/Badge';
import { styled } from '@cappex/theme';

type Props = {
	iconName: IconProp;
	color: string;
	badgeColor?: BadgeProps['color'];
};

const BadgeWrapper = styled(Badge)`
	font-size: 1.125rem;
`;

const NavIcon: FC<Props> = ({ iconName, color, badgeColor }) => (
	<BadgeWrapper color={badgeColor} variant="dot" overlap="circular">
		<FontAwesomeIcon icon={iconName} color={color} />
	</BadgeWrapper>
);

export default NavIcon;
