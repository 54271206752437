import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import BirthDateInput from '@common/components/BirthDateInput';
import NameForm from '@common/components/NameForm';
import checkLockout from '@util/lockout';
import getEndpoint from '@util/request';
import { Grid, Typography } from '@material-ui/core';
import { SubForm } from '@src/common/components/BaseValidationForm';
import GenderSelect from '@src/common/components/GenderSelect';
import HiddenInput from '@src/common/components/HiddenInput';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames } from '@cappex/constants';
import { AuthenticState } from '@src/common/util/auth';
import useAuthentication from '@src/common/util/auth/hooks/useAuthentication';
import useUserData from '@src/common/util/hooks/useUserData';
import request, {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	RequestSourceIdentifier,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import { getStudentType, parseStudent, Student, StudentType } from '@util/student/studentDataUtil';
import { FormContext, FormFields } from '@util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import RadioChipSelector from '@src/common/components/RadioChipSelector';
import FirstGenerationStudent from '@common/components/FirstGenerationStudent';
import EthnicityCheckboxList from '@common/components/EthnicityCheckboxList';
import { useNavigate } from 'react-router-dom';

const EMPTY_FUNCTION = () => {};

const STUDENT_TYPE_OPTIONS = [
	{ displayValue: 'High school student', value: StudentType.HIGH_SCHOOL },
	{ displayValue: 'College student', value: StudentType.COLLEGE },
];

const StudentPersonalInfoPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		isAllowedUnauthentic,
		targetWhenUnauthentic,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { getValue } = useContext(FormContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const [studentType, setStudentType] = useState(StudentType.HIGH_SCHOOL);
	const isAuthentic = useAuthentication();
	const navigate = useNavigate();
	const [userData, setUserData] = useState<Partial<Student>>({});
	const [userInfo] = useUserData(EMPTY_FUNCTION, active || R.isEmpty(userData), true);
	const [submitDisabled, setSubmitDisabled] = useState(false);

	// Get user info to pre-populate
	useEffect(() => {
		if (R.isNil(userInfo.roleId)) {
			return;
		}

		request<any>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data.response)
			.then(data => {
				setUserData(parseStudent(data));
			});
	}, [userInfo]);

	useEffect(() => {
		const newStudentType =
			getStudentType((getValue(FormNames.studentTypeId) as FormFields)?.studentTypeId) ||
			userData.studentTypeId;
		if (newStudentType && newStudentType !== studentType) {
			setStudentType(newStudentType);
		}
	}, [studentType, getValue, userData]);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	const birthDateInitial = useMemo(
		() => ({
			month: userData.birthMonth,
			day: userData.birthDay,
			year: userData.birthYear,
		}),
		[userData.birthMonth, userData.birthDay, userData.birthYear]
	);

	// The flow expects this page to only be hit
	if (
		!isAllowedUnauthentic &&
		isAuthentic !== AuthenticState.Authentic &&
		isAuthentic !== AuthenticState.Unknown &&
		active
	) {
		navigate(targetWhenUnauthentic);
	}

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<Grid item>
					<RadioChipSelector
						title="Join as a"
						defaultValue={userData.studentTypeId}
						options={STUDENT_TYPE_OPTIONS}
						radioName={FormNames.studentTypeId}
						complete={EMPTY_FUNCTION}
						reverse={EMPTY_FUNCTION}
						chipColumns={{ xs: 7 }}
					/>
				</Grid>
				<SubForm name="student">
					<HiddenInput name={FormNames.studentId} initialValue={userData.studentId} />
					<SubForm name="studentDataForm">
						<Grid item>
							<NameForm
								fullWidth
								required={active}
								label="Name"
								firstNameLabel="First Name"
								lastNameLabel="Last Name"
								defaultValue={{
									first: userData.firstName,
									last: userData.lastName,
								}}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h6">Gender</Typography>
							<GenderSelect initialValue={userData[FormNames.genderId]} label="Gender (Optional)" />
						</Grid>
						<Grid item>
							<BirthDateInput
								required={active}
								id="registration__birth_date"
								label="Date of Birth"
								defaultValue={birthDateInitial}
							/>
						</Grid>
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<>
							<SubForm name="studentCollegeDataForm">
								<Grid item xs={12}>
									<FirstGenerationStudent />
								</Grid>
							</SubForm>
							<SubForm name="studentEthnicityForm">
								<Grid item xs={12}>
									<EthnicityCheckboxList subText="Optional" />
								</Grid>
							</SubForm>
						</>
					)}
				</SubForm>
				<Grid item>
					<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
						{currentStep === totalSteps ? 'Finish' : 'Next'}
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default StudentPersonalInfoPage;
