import React, { FC } from 'react';
import { Dialog, Grid, Paper, Button, Typography, DialogActions } from '@material-ui/core';
import { RoleId } from '@src/common/util/roles/constants';
import { styled } from '@cappex/theme';
import { AutomationNameDefault } from '@src/common/util/automation';

type Props = {
	roleId: RoleId;
	show: boolean;
	hostEmail: string;
	hostFirstName: string;
	parentStudentRelationshipName?: string;
	action: (status: boolean) => void;
};

const getInviteTitleCopy = (str: string) => `Accept Connection with ${str}`;
const getInviteHeader = (
	roleId: RoleId,
	parentStudentRelationshipName: string,
	hostFirstName: string
) => {
	switch (roleId) {
		case RoleId.PARENT:
			return getInviteTitleCopy(hostFirstName);
		case RoleId.STUDENT:
			return getInviteTitleCopy(parentStudentRelationshipName);
		default:
			return '';
	}
};

const getInviteCopy = (
	roleId: RoleId,
	parentStudentRelationshipName: string,
	hostFirstName: string
) => {
	switch (roleId) {
		case RoleId.PARENT:
			return `${hostFirstName} has sent you a request to connect on Appily`;
		case RoleId.STUDENT:
			return `Your ${parentStudentRelationshipName.toLocaleLowerCase()} ${hostFirstName} has sent you a request to connect on Appily`;
		default:
			return '';
	}
};

const WiderPaper = styled(Paper)`
	margin: 1rem;
	padding: 1rem;
`;

const NoMarginDialogActions = styled(DialogActions)`
	margin: 0rem;
`;

const InviteDialog: FC<Props> = ({
	roleId,
	show,
	hostEmail,
	hostFirstName,
	parentStudentRelationshipName = '',
	action = () => {},
}) => (
	<Dialog
		open={show}
		PaperComponent={WiderPaper}
		maxWidth="xs"
		onClose={(_, reason) => {
			reason === 'backdropClick' && action(undefined);
			reason === 'escapeKeyDown' && action(undefined);
		}}
	>
		<Grid container spacing={1} direction="column">
			<Grid item>
				<Typography variant="h6" data-qa={`${AutomationNameDefault.inviteModal}-title`}>
					{getInviteHeader(roleId, parentStudentRelationshipName, hostFirstName)}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="caption" data-qa={`${AutomationNameDefault.inviteModal}-email`}>
					{hostEmail}
				</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body2" data-qa={`${AutomationNameDefault.inviteModal}-description`}>
					{getInviteCopy(roleId, parentStudentRelationshipName, hostFirstName)}
				</Typography>
			</Grid>
		</Grid>
		<NoMarginDialogActions>
			<Button
				color="primary"
				onClick={() => action(false)}
				data-qa={`${AutomationNameDefault.inviteModal}-decline`}
			>
				Decline
			</Button>
			<Button
				color="primary"
				onClick={() => action.bind(true)}
				data-qa={`${AutomationNameDefault.inviteModal}-accept`}
			>
				Accept
			</Button>
		</NoMarginDialogActions>
	</Dialog>
);

export default InviteDialog;
