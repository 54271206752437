import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { AutomationNameDefault, AutomationNameGeneric } from '@common/util/automation';

export interface TitleHeaderProps {
	header?: string;
	subHeader?: string;
	description?: string;
	imageTop?: string;
	imageBottom?: string;
	automationNameTopImg?: string;
	automationNameBotImg?: string;
}

const TitleHeader: FC<TitleHeaderProps> = ({
	header,
	subHeader,
	description,
	imageTop,
	imageBottom,
	automationNameBotImg = AutomationNameGeneric.image,
	automationNameTopImg = AutomationNameDefault.logo,
}) => {
	return (
		<div>
			{imageTop && <img src={imageTop} data-qa={automationNameTopImg} alt="" />}
			<Typography
				variant="h5"
				align="left"
				gutterBottom
				dangerouslySetInnerHTML={{ __html: header }}
			/>
			<Typography
				variant="h5"
				align="left"
				gutterBottom
				dangerouslySetInnerHTML={{ __html: subHeader }}
			/>
			<Typography
				variant="body1"
				align="left"
				paragraph
				dangerouslySetInnerHTML={{ __html: description }}
			/>
			{imageBottom && (
				<Hidden smDown>
					<img src={imageBottom} data-qa={automationNameBotImg} alt="" />
				</Hidden>
			)}
		</div>
	);
};

export default TitleHeader;
