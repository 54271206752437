import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';

type DesiredCompletionTimeFrameSelectProps = Omit<Partial<BaseFormSelectProps>, 'references'>;
const DesiredCompletionTimeFrameSelect: FC<DesiredCompletionTimeFrameSelectProps> = ({
	id = AutomationNameDefault.desiredCompletionTimeframeInput,
	name = FormNames.desiredCompletionTimeframeId,
	label = 'Desired Completion Timeframe',
	initialValue,
	required,
	automationName = AutomationNameGeneric.formSelect,
	variant,
}) => {
	const [desiredCompletionTimeframes] = useCloudReferenceData({
		dataType: ReferenceDataTypes.desiredCompletionTimeframe,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			references={desiredCompletionTimeframes}
			initialValue={initialValue}
			required={required}
			automationName={automationName}
			addEmptyValue
			variant={variant}
		/>
	);
};

export default DesiredCompletionTimeFrameSelect;
