import checkForJitModal from '@src/common/util/jit/checkForJitModal';
import { JitEventPayload } from '@src/common/util/jit/constants';
import { ModalContext } from '@src/common/util/steps/components/ModalStepFlow';
import { useContext, useEffect } from 'react';
import EventBus, { EventTypes } from '../util/EventBus';

const EventBusManager = () => {
	const { queueModal } = useContext(ModalContext);
	useEffect(() => {
		EventBus.on(EventTypes.JIT_CHECK, (payload: JitEventPayload) =>
			checkForJitModal(payload, queueModal)
		);
	}, [queueModal]);

	return null;
};

export default EventBusManager;
