import React, { useState, useContext, useRef, useMemo, FC, useCallback, useEffect } from 'react';
import useCloudReferenceData from '@src/common/util/hooks/useCloudReferenceData';
import { ReferenceDataTypes, ListFormNames } from '@cappex/constants';
import useFormValidation from '@src/common/util/hooks/useFormValidation';
import { SubFormContext } from '@src/common/components/BaseValidationForm';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { Typography, Grid } from '@material-ui/core';
import PreferenceChip from '@src/features/collegepreferences/components/PreferenceChip';
import { styled } from '@cappex/theme';
import { validateRequired } from './BaseFormSelect';

const OptionalGutterGrid = styled(({ gutter, ...props }) => <Grid {...props} />)`
	${({ gutter }) => (gutter ? 'margin-bottom: 1rem;' : '')}
`;
type ModalitySelectProps = {
	label: string;
	name: string;
	required?: boolean;
	defaultValue?: string[];
	gutter?: boolean;
};

const EMPTY_ARRAY = [];

const SizeFormComponent: FC<ModalitySelectProps> = ({
	label,
	name,
	required,
	defaultValue = EMPTY_ARRAY,
	gutter,
}) => {
	const [modalityData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.modality,
	});
	const automationNamePreferenceChipButton = `size-${AutomationNameGeneric.chipButton}`;
	const { path } = useContext(SubFormContext);
	const [selectedItems, setSelectedItems] = useState<string[]>(defaultValue);

	const headerRef = useRef(null);
	const initialValueObj = useMemo(
		() => ({
			[ListFormNames.modalityIds]: defaultValue,
		}),
		[defaultValue]
	);
	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	useEffect(() => {
		// keep the selected items in sync with the form value when the form value changes from outside
		if (value[ListFormNames.modalityIds] !== selectedItems) {
			setSelectedItems(value[ListFormNames.modalityIds] as string[]);
		}
	}, [value, selectedItems]);

	const handleMultipleSelect = useCallback(
		(id: string) => {
			const updatedState = [...selectedItems];
			let hasUpdated = false;

			const idIndex = selectedItems && selectedItems.findIndex(itemId => itemId === id);
			if (idIndex >= 0) {
				updatedState.splice(idIndex, 1);
				hasUpdated = true;
			} else if (selectedItems.length < modalityData.length) {
				updatedState.push(id);
				hasUpdated = true;
			}

			if (hasUpdated) {
				setSelectedItems(updatedState);
				setValue({
					...value,
					[ListFormNames.modalityIds]: updatedState,
				});
			}
		},
		[selectedItems, setValue, value, modalityData]
	);

	return (
		<OptionalGutterGrid gutter={gutter} container spacing={2} innerRef={headerRef}>
			{label && (
				<Grid item xs={12}>
					<Typography variant="h6">{label}</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{modalityData.map(modality => (
						<Grid item xs={12} key={modality.id}>
							<PreferenceChip
								clickable
								onClick={() => handleMultipleSelect(modality.id)}
								label={modality.value}
								data-qa={automationNamePreferenceChipButton}
								isSelected={selectedItems && selectedItems.includes(modality.id)}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</OptionalGutterGrid>
	);
};

export default SizeFormComponent;
