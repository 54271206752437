import * as R from 'ramda';
import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, theme } from '@cappex/theme';
import NavIcon from '@common/components/NavIcon';
import SidebarListItem, {
	ItemMenuItemDiv,
	ListIconWrapper,
	NavListDivider,
	primarySidebarTypographyProps,
	StyledListItemText,
	StyledListItemTextActive,
	StyledNoIconListItemText,
	StyledNoIconListItemTextActive,
} from '@common/components/SidebarListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { NavigationSection } from '@util/links/navigationLinks';

// @ts-ignore -- 10/29/21 BLM -- typescript sometimes gets confused when you style already-styled components
const SectionListItemIconWrapper = styled(ListIconWrapper)`
	visibility: visible;
`;

const SectionMenuItemWrapper = styled(MenuItem)`
	padding: 0;

	${props =>
		props.disableRipple
			? `
				&:hover {
					background: none;
					cursor: initial;
				}
			`
			: `
				border-radius: 4px;
				margin 0 0.5rem 0 0.5rem; 
				&:hover {
					background-color: ${props.theme.palette.common.white}
				}
			`}
`;

const BoldTypography = styled(Typography)`
	font-weight: bold;
`;

interface Props extends NavigationSection {
	handleDrawerClose: (e: any) => void;
}

const SidebarSection: FC<Props> = ({
	handleDrawerClose,
	name,
	navigationLinks,
	icon,
	link,
	divider,
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const isClickable = !R.isNil(link);

	const onSectionLinkClick = e => {
		if (isClickable) {
			handleDrawerClose(e);
			navigate(link);
		}
	};

	return (
		<div data-qa={`${AutomationNameDefault.sideNavList}-${AutomationNameGeneric.section}`}>
			{name && (
				<SectionMenuItemWrapper disableRipple={!isClickable} onClick={onSectionLinkClick}>
					<ItemMenuItemDiv active={isClickable ? pathname.includes(link) : false}>
						{icon && pathname.includes(link) && (
							<>
								<SectionListItemIconWrapper>
									<NavIcon
										data-qa={`${AutomationNameGeneric.section}-${AutomationNameGeneric.icon}`}
										iconName={icon}
										color={theme.palette.primary.main}
									/>
								</SectionListItemIconWrapper>
								<StyledListItemTextActive
									data-qa={`${AutomationNameGeneric.section}-${
										isClickable ? AutomationNameGeneric.link : AutomationNameGeneric.text
									}`}
									primary={
										<BoldTypography {...primarySidebarTypographyProps}>{name}</BoldTypography>
									}
								/>
							</>
						)}
						{icon && !pathname.includes(link) && (
							<>
								<SectionListItemIconWrapper>
									<NavIcon
										data-qa={`${AutomationNameGeneric.section}-${AutomationNameGeneric.icon}`}
										iconName={icon}
										color={theme.palette.ink.main}
									/>
								</SectionListItemIconWrapper>
								<StyledListItemText
									data-qa={`${AutomationNameGeneric.section}-${
										isClickable ? AutomationNameGeneric.link : AutomationNameGeneric.text
									}`}
									primary={name}
									primaryTypographyProps={primarySidebarTypographyProps}
								/>
							</>
						)}
						{!icon && pathname.includes(link) && (
							<StyledNoIconListItemTextActive
								data-qa={`${AutomationNameGeneric.section}-${
									isClickable ? AutomationNameGeneric.link : AutomationNameGeneric.text
								}`}
								primary={<BoldTypography {...primarySidebarTypographyProps}>{name}</BoldTypography>}
							/>
						)}
						{!icon && !pathname.includes(link) && (
							<StyledNoIconListItemText
								data-qa={`${AutomationNameGeneric.section}-${
									isClickable ? AutomationNameGeneric.link : AutomationNameGeneric.text
								}`}
								primary={<BoldTypography {...primarySidebarTypographyProps}>{name}</BoldTypography>}
							/>
						)}
					</ItemMenuItemDiv>
				</SectionMenuItemWrapper>
			)}
			{navigationLinks.map(listItem => (
				<SidebarListItem
					listItem={listItem}
					handleDrawerClose={handleDrawerClose}
					key={listItem.name}
				/>
			))}
			{divider && <NavListDivider />}
		</div>
	);
};

export default SidebarSection;
