import React, { FC } from 'react';
import { Box, Grid, LinearProgress, Typography } from '@material-ui/core';
import * as R from 'ramda';
import { AutomationNameDefault } from '@common/util/automation';
import { Step } from '..';
import { includedInStepCount } from '../constants';
import evaluatePredicate, { Predicate } from '../../form/evaluatePredicate';
import { ValidationState } from '../../validation/form';

export enum StepCounterVariant {
	STANDARD,
	LOADING,
}

export type CountableStep = Step<any, { [includedInStepCount]: boolean; predicate?: Predicate }>;
type StepCounterProps = {
	current: number;
	total: number;
	automationNameCurrent?: string;
	automationNameTotal?: string;
	variant?: StepCounterVariant;
};

const getStepCountFromStepArray = (
	steps: CountableStep[],
	index: number,
	formState?: ValidationState
): { current: number; total: number } => {
	const stepCounts: boolean[] = R.map(
		(step: CountableStep) =>
			step.data[includedInStepCount] &&
			(!step.data.predicate || evaluatePredicate(step.data.predicate)(formState))
	)(steps);
	if (stepCounts.length > index && stepCounts[index]) {
		return {
			current: stepCounts.slice(0, index + 1).filter(b => b).length,
			total: stepCounts.filter(b => b).length,
		};
	}
	return { current: null, total: null };
};

const StandardStepCounter: FC<Exclude<StepCounterProps, 'variant'>> = ({
	current,
	total,
	automationNameCurrent,
	automationNameTotal,
}) => (
	<Typography variant="overline" color="primary">
		Step{' '}
		<span data-qa={automationNameCurrent} id="current-step">
			{current}
		</span>{' '}
		of{' '}
		<span data-qa={automationNameTotal} id="total-steps">
			{total}
		</span>
	</Typography>
);

const LoadingStepCounter: FC<Exclude<StepCounterProps, 'variant'>> = ({
	current,
	total,
	automationNameCurrent,
	automationNameTotal,
}) => (
	<Grid container direction="column" spacing={1}>
		<Grid item xs={12}>
			<Box width="100%" height={2} overflow="hidden">
				<LinearProgress variant="determinate" value={(current / total) * 100} />
			</Box>
		</Grid>
		<Grid item>
			<Typography variant="caption" color="textSecondary" align="center" component="div">
				<span data-qa={automationNameCurrent} id="current-step">
					{current}
				</span>{' '}
				/{' '}
				<span data-qa={automationNameTotal} id="total-steps">
					{total}
				</span>
			</Typography>
		</Grid>
	</Grid>
);

const StepCounter: FC<StepCounterProps> = ({
	current,
	total,
	automationNameCurrent = AutomationNameDefault.stepCurrent,
	automationNameTotal = AutomationNameDefault.stepTotal,
	variant = StepCounterVariant.STANDARD,
}) => {
	if (R.isNil(current) || R.isNil(total)) {
		return null;
	}
	switch (variant) {
		case StepCounterVariant.LOADING:
			return (
				<LoadingStepCounter
					current={current}
					total={total}
					automationNameCurrent={automationNameCurrent}
					automationNameTotal={automationNameTotal}
				/>
			);
		case StepCounterVariant.STANDARD:
		default:
			return (
				<StandardStepCounter
					current={current}
					total={total}
					automationNameCurrent={automationNameCurrent}
					automationNameTotal={automationNameTotal}
				/>
			);
	}
};

export default StepCounter;
export { getStepCountFromStepArray };
