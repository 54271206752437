import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import SplitContainer from '@common/components/SplitContainer';
import LoginComponent from '@src/features/login/components/LoginComponent';
import TitleHeader from '@common/components/TitleHeader';
import useUrlParam from '@src/common/util/hooks/useUrlParam';
import { styled } from '@cappex/theme';
import { GOOGLE_BUTTON_FIXED_WIDTH } from '../../common/constants/signInWithGoogle';
import Footer from '@src/common/components/Footer';

const WidthGrid = styled(Grid)`
	max-width: ${GOOGLE_BUTTON_FIXED_WIDTH};
`;

const RelativeDiv = styled.div`
	position: relative;
	min-height: 100vh;
`;

const SpacingDiv = styled.div`
	padding-bottom: 10rem;
`;

const LoginContainer: FC = () => {
	const email = useUrlParam('email');
	const uuid1 = useUrlParam('uuid1');
	const uuid2 = useUrlParam('uuid2');
	const collegeIdToAdd = useUrlParam('collegeId');
	const externalLocation = useUrlParam('externalLocation');
	const header = 'Welcome back';
	const description =
		'Let’s face it, college is a big deal. From applications to stepping on campus, Appily has your back every step of the way.';

	return (
		<RelativeDiv>
			<SplitContainer
				leftTop={
					<Grid container justifyContent="center">
						<WidthGrid item xs={12}>
							<TitleHeader header={header} description={description} />
						</WidthGrid>
					</Grid>
				}
				rightBottom={
					<LoginComponent
						email={email === null ? undefined : email}
						emailLocked={email !== null}
						collegeIdToAdd={collegeIdToAdd}
						externalLocation={externalLocation}
						token={{ first: uuid1, second: uuid2 }}
					/>
				}
			/>
			<SpacingDiv />
			<Footer />
		</RelativeDiv>
	);
};

export default LoginContainer;
