import React, { FC } from 'react';
import {
	findContentValueText,
	findRequiredValidation,
	findValidationType,
} from '@src/features/offer/util/offerUtil';
import OfferBaseFormEmailInput from '@src/features/offer/components/inputs/OfferBaseFormEmailInput';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';
import { CampaignOfferValidationType } from '@src/features/offer/constants/constants';

const OfferCardEmailInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const contentId = `${content.id}`;
	const label = findContentValueText(content);
	const { campaignOfferContentValidations } = content;
	const required = findRequiredValidation(campaignOfferContentValidations);
	// only check if validation should be disabled for some reason
	// email validation is automatically applied otherwise
	const hasEmailValidation = findValidationType(
		CampaignOfferValidationType.EMAIL,
		campaignOfferContentValidations
	);
	return (
		<OfferBaseFormEmailInput
			id={contentId}
			name={contentId}
			automationName={automationName}
			label={label}
			required={required}
			noEmailValidation={!hasEmailValidation}
		/>
	);
};

export default OfferCardEmailInput;
