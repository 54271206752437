import React, { ChangeEvent, FC, useContext, useMemo, useRef, useCallback } from 'react';
import OutlinedSelect from '@common/components/OutlinedSelect';
import { FormNames } from '@cappex/constants';
import { AutomationNameGeneric } from '@common/util/automation';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	OutlinedInput,
	TextFieldProps,
} from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import useFormValidation from '@util/hooks/useFormValidation';
import { SubFormContext } from './BaseValidationForm';
import { FormFields } from '../util/validation/form';
import requiredFieldMessage from '../util/validation/constants';
import { styled } from '@cappex/theme';

export interface BaseFormSelectProps {
	id?: string;
	name: FormNames;
	label?: string;
	references: ReferenceData[];
	initialValue?: string;
	automationName?: string;
	onChange?: (value: string) => void;
	addEmptyValue?: boolean;
	selectMargin?: FormControlProps['margin'];
	required?: boolean;
	variant?: TextFieldProps['variant'];
}

const BgDiv = styled.div`
	background: white;
`;

export const validateRequired = (name: string, required: boolean) => (input: FormFields) => {
	const inputValue: string = input[name];

	if (required && (!inputValue || inputValue[0] === null)) {
		return requiredFieldMessage;
	}
	return '';
};

const BaseFormSelect: FC<BaseFormSelectProps> = ({
	id = AutomationNameGeneric.formSelect,
	name,
	label = '',
	references,
	initialValue = null,
	automationName = AutomationNameGeneric.formSelect,
	onChange = () => {},
	addEmptyValue = false,
	selectMargin = 'normal',
	required = false,
	variant = 'outlined',
}) => {
	const { path } = useContext(SubFormContext);
	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const labelRef = useRef(null);
	const controlRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: initialValue }), [initialValue, name]);
	const { value, setValue, error } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: controlRef,
	});

	const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;

	const onSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
		onChange(event.target.value);

		setValue({
			[name]: event.target.value,
		});
	};

	// InputLabel breaks when the Select is initialized with undefined as the value
	// Or when given a value and there is no matching option yet.
	const selectValue = value[name] && references && references.length > 0 ? value[name] : '';

	return (
		<FormControl margin={selectMargin} fullWidth variant={variant} error={!!error} ref={controlRef}>
			<InputLabel htmlFor={id}>
				<BgDiv ref={labelRef}>{label}</BgDiv>
			</InputLabel>
			<OutlinedSelect
				native
				input={
					<OutlinedInput
						labelWidth={labelWidth}
						name={name}
						id={id}
						inputProps={{
							'data-qa': automationName,
						}}
					/>
				}
				onChange={onSelectChange}
				value={selectValue}
				label={label}
			>
				{addEmptyValue && <option value={null} />}
				{references.map(reference => (
					<option key={reference.id} value={reference.id}>
						{reference.value}
					</option>
				))}
			</OutlinedSelect>
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
};

export default BaseFormSelect;
