import * as R from 'ramda';
import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import celebIcon from '@common/assets/CelebrationIcon-02.svg';
import BoldableTypography, {
	BoldableTypographyEnum,
	BoldableTypographyObject,
} from '@common/components/BoldableTypography';
import { Button, Grid, Typography } from '@material-ui/core';
import OfferCardList from '@src/features/offer/components/OfferCardList';
import { AutomationNameDefault } from '@util/automation';
import { CampaignOffer, Placement } from '@src/features/offer/constants/constants';
import withStyleOptions from '@util/style/styleOptions';
import HorizontalLogo from '@src/common/components/HorizontalLogo';

type GutterBottomGridProps = {
	$marginValue: string;
};

const ContinueButton = styled(withStyleOptions(Button))`
	font-size: 1rem;
`;

// @ts-ignore -- 10/29/21 BLM -- typescript sometimes gets confused when you style already-styled components
const EmptyStateContinueButton = styled(ContinueButton)`
	width: 19.5rem;
`;

const Wrapper = styled.div`
	padding-top: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
	padding-bottom: 6.5rem;
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		padding-top: 4rem;
		padding-left: 4rem;
		padding-right: 4rem;
	}
`;

const MarginBottom = styled.div`
	margin-bottom: 1.5rem;
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		margin-top: 1rem;
	}
`;

const EmptyStateGridContainer = styled(Grid)`
	margin-top: 9.625rem;
`;

const EmptyStateImage = styled.img`
	width: 4.125rem;
	height: 4.125rem;
`;

const StyledGrid = styled(Grid)`
	margin-left: 0.625rem;
`;

const GutterBottomGrid = styled(Grid)<GutterBottomGridProps>`
	margin-bottom: ${props => props.$marginValue};
`;

export const defaultInterstitialCopy = 'Add opportunities to help you plan and pay for college.';
export const logInInterstitialCopy = `Welcome back! ${defaultInterstitialCopy}`;

const getInterstitialTextConfig = (placement: Placement): BoldableTypographyObject[] => {
	switch (placement) {
		case Placement.LOG_IN_INTERSTITIAL:
			return [
				{
					type: BoldableTypographyEnum.BOLD_TEXT,
					boldText: logInInterstitialCopy,
				},
			];
		default:
			return [
				{
					type: BoldableTypographyEnum.BOLD_TEXT,
					boldText: defaultInterstitialCopy,
				},
			];
	}
};

type OfferInterstitialProps = {
	placement: Placement;
	campaignOffers: CampaignOffer[];
	onOfferAccepted: (campaignOfferId: number) => void;
	onContinueButtonClicked: () => void;
};

const OfferInterstitial: FC<OfferInterstitialProps> = ({
	placement,
	campaignOffers,
	onOfferAccepted,
	onContinueButtonClicked,
}) =>
	!R.isNil(campaignOffers) && (
		<Wrapper>
			<Grid container spacing={0} direction="column">
				<Grid item xs={12}>
					<MarginBottom>
						<HorizontalLogo data-qa={AutomationNameDefault.logo} />
					</MarginBottom>
				</Grid>
				{R.isEmpty(campaignOffers) ? (
					<EmptyStateGridContainer
						container
						spacing={0}
						direction="column"
						alignItems="center"
						justifyContent="center"
					>
						<GutterBottomGrid item xs $marginValue="1.75rem">
							<EmptyStateImage src={celebIcon} alt="" />
						</GutterBottomGrid>
						<GutterBottomGrid item xs $marginValue="1.75rem">
							<Typography variant="subtitle1" color="textSecondary">
								That&apos;s all for now!
							</Typography>
						</GutterBottomGrid>
						<Grid item xs>
							<EmptyStateContinueButton
								onClick={onContinueButtonClicked}
								type="button"
								$noneTextTransform
								variant="contained"
								color="primary"
								data-qa={AutomationNameDefault.paEmptyInterstitialContinueButton}
							>
								Continue to Home
							</EmptyStateContinueButton>
						</Grid>
					</EmptyStateGridContainer>
				) : (
					<>
						<GutterBottomGrid item xs $marginValue="2rem;">
							<Grid container spacing={0}>
								<Grid item>
									<img src={celebIcon} alt="" />
								</Grid>
								<StyledGrid item xs>
									<BoldableTypography
										config={getInterstitialTextConfig(placement)}
										variant="body1"
									/>
								</StyledGrid>
							</Grid>
						</GutterBottomGrid>
						<GutterBottomGrid item xs $marginValue="2.75rem">
							<OfferCardList
								campaignOffers={campaignOffers}
								onOfferAccepted={onOfferAccepted}
								placement={placement}
							/>
						</GutterBottomGrid>
						<Grid container item xs alignItems="center" justifyContent="center">
							<ContinueButton
								onClick={onContinueButtonClicked}
								$grey500Text
								type="button"
								variant="text"
								data-qa={AutomationNameDefault.paInterstitialContinueButton}
							>
								Continue
							</ContinueButton>
						</Grid>
					</>
				)}
			</Grid>
		</Wrapper>
	);

export default OfferInterstitial;
