import React, { FC } from 'react';
import BaseFormCheckboxList, {
	BaseFormCheckboxListProps,
} from '@common/components/BaseFormCheckboxList';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';
import { AutomationNameDefault } from '@util/automation';
import { ReferenceDataTypes, ListFormNames } from '@cappex/constants';

type EthnicityCheckboxListProps = Omit<Partial<BaseFormCheckboxListProps>, 'references'>;
const EthnicityCheckboxList: FC<EthnicityCheckboxListProps> = ({
	id = AutomationNameDefault.ethnicityCheckbox,
	name = ListFormNames.studentEthnicities,
	label = 'Ethnicity',
	subText,
	required,
	initialValue,
	automationName = AutomationNameDefault.ethnicityCheckbox,
}) => {
	const [ethnicities] = useCloudReferenceData({
		dataType: ReferenceDataTypes.ethnicity,
	});

	return (
		<BaseFormCheckboxList
			id={id}
			name={name}
			label={label}
			subText={subText}
			required={required}
			references={ethnicities}
			initialValue={initialValue}
			automationName={automationName}
		/>
	);
};

export default EthnicityCheckboxList;
