import React, { FC } from 'react';
import { EllipsisTypography } from '@cappex/components';
import { OfferCardBaseTextProps } from '@src/features/offer/components/OfferCardParagraph';

const OfferCardText: FC<OfferCardBaseTextProps> = ({ text, automationName }) =>
	text && (
		<EllipsisTypography
			lineheight="1.5"
			lines={15}
			paddingRight={false}
			variant="body1"
			automationName={automationName}
		>
			{text}
		</EllipsisTypography>
	);

export default OfferCardText;
