import { styled, theme } from '@cappex/theme';
import { faGhost } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Button, Typography, Container } from '@material-ui/core';
import ErrorBoundary from '@src/common/components/ErrorBoundary';
import React from 'react';

const StyledContainer = styled(Container)`
	padding-top: 11rem;
`;

const StyledTypography = styled(Typography)`
	color: ${theme.palette.ink.light};
`;

const StyledButton = styled(Button)`
	margin-top: 2rem;
`;

const ErrorBoundaryFallback = () => {
	const handleTakeMeHome = () => {
		window.location.href = '/dashboard';
	};
	return (
		<StyledContainer>
			<Grid container alignItems="center" direction="column" spacing={2}>
				<Grid item>
					<FontAwesomeIcon icon={faGhost} size="3x" color={theme.palette.ink.transparent} />
				</Grid>
				<Grid item>
					<StyledTypography>Well that was unexpected...</StyledTypography>
				</Grid>
				<Grid item>
					<StyledButton
						variant="contained"
						color="primary"
						onClick={handleTakeMeHome}
						data-qa="errorpage-button"
					>
						Take me home
					</StyledButton>
				</Grid>
			</Grid>
		</StyledContainer>
	);
};

const AppErrorBoundary: React.FC = ({ children }) => (
	<ErrorBoundary Fallback={ErrorBoundaryFallback}>{children}</ErrorBoundary>
);
export default AppErrorBoundary;
