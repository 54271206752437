import React, { FC } from 'react';
import { Box, BoxProps, Typography, TypographyProps } from '@material-ui/core';
import { styled } from '@cappex/theme';

type HeaderSubtextProps = {
	headerVariant?: TypographyProps['variant'];
	subVariant?: TypographyProps['variant'];
	headerColor?: TypographyProps['color'];
	subColor?: TypographyProps['color'];
	header?: string;
	subtext?: string;
	negativeGutter?: boolean;
};

const PropBox: FC<BoxProps & { gutter: boolean }> = ({ gutter, ...props }) => <Box {...props} />;
const NegativeGutterBox = styled(PropBox)`
	${({ gutter }) => (gutter ? 'margin-bottom: -1rem;' : '')}
`;

const HeaderSubtext: FC<HeaderSubtextProps> = ({
	headerVariant = 'h6',
	headerColor,
	header,
	subVariant = 'body2',
	subColor = 'textSecondary',
	subtext,
	negativeGutter = true,
}) => (
	<NegativeGutterBox gutter={negativeGutter}>
		{header && (
			<Typography variant={headerVariant} color={headerColor}>
				{header}
			</Typography>
		)}
		{subtext && (
			<Typography variant={subVariant} color={subColor}>
				{subtext}
			</Typography>
		)}
	</NegativeGutterBox>
);

export default HeaderSubtext;
