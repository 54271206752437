import { createContext } from 'react';
import { FitResultDetail } from '../fit/constants';
import { ReferenceData } from '../hooks/useCloudReferenceData';

export type OutreachData = {
	collegeId: number;
	collegeName: string;
	collegeHeroImagePath: string;
	collegeCity: ReferenceData;
	collegeState: ReferenceData;
	matchedCollegePreferences: FitResultDetail[];
	collegeStudentBodySize: string;
	collegeTownSize: ReferenceData;
};

type OutreachContext = {
	outreachList: OutreachData[];
	outreachDataRetrieved: boolean;
	noMatches: boolean;
	refresh: () => void;
	removeListItem: (collegeId: number) => void;
};

const DEFAULT_FUNCTION = () => {
	throw new Error('Using default context is disallowed for: Outreach Context');
};

export const OutreachContext = createContext<OutreachContext>({
	outreachList: [],
	outreachDataRetrieved: false,
	noMatches: false,
	refresh: DEFAULT_FUNCTION,
	removeListItem: DEFAULT_FUNCTION,
});
