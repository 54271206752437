import { useState, useEffect, Dispatch, useCallback } from 'react';
import * as R from 'ramda';

const useDerivedStateFromPropsWithMap = <T extends any = any, M extends any = T>(
	prop: T,
	// Map prop of type T to  type M; Defaults to identity with a cast
	map: (prop: T) => M = R.identity as (prop: T) => M
): [M, Dispatch<T>] => {
	const [value, setValue] = useState(map(prop));
	useEffect(() => {
		setValue(map(prop));
	}, [map, prop]);

	const setValueAndMap = useCallback(R.pipe(map, setValue), [map]);

	return [value, setValueAndMap];
};

export default useDerivedStateFromPropsWithMap;
