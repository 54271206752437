import { appUrl } from '@src/features/environment';
import axios from 'axios';
import { useEffect } from 'react';

const useReloadCheck = () => {
	const interval = +process.env.REACT_APP_RELOAD_CHECK_INTERVAL;
	useEffect(() => {
		if (!interval) return;
		setInterval(async () => {
			const localBuildId = process.env.REACT_APP_BUILD_ID;
			const serverBuildId = (await axios.get(`${appUrl}/get-build-id?localBuildId=${localBuildId}`))
				.data;
			if (serverBuildId !== localBuildId) window.location.reload();
		}, interval);
	}, [interval]);
};

export default useReloadCheck;
