import React, { FC } from 'react';
import { TypographyProps, Typography } from '@material-ui/core';

export enum BoldableTypographyEnum {
	TEXT,
	BOLD_TEXT,
}

export type BoldableTypographyObject = {
	type: BoldableTypographyEnum;
	text?: string;
	boldText?: string;
};

interface BoldableTypographyProps extends TypographyProps {
	config?: BoldableTypographyObject[];
}

const mapBoldableTypographyObjectToComponent = (
	{ type, text, boldText }: BoldableTypographyObject,
	i
) => {
	switch (type) {
		case BoldableTypographyEnum.TEXT:
			return text;
		case BoldableTypographyEnum.BOLD_TEXT:
			return <b key={i}>{boldText}</b>;
		default:
			return '';
	}
};

const BoldableTypography: FC<BoldableTypographyProps> = ({
	config = [],
	variant,
	gutterBottom = false,
}) => (
	<Typography variant={variant} gutterBottom={gutterBottom}>
		{config.map(mapBoldableTypographyObjectToComponent)}
	</Typography>
);

export default BoldableTypography;
