import { DataFlowStepName } from '@cappex/constants';
import { includedInStepCount, name } from '@src/common/util/steps/constants';
import { DataFlowConfig } from '../../dataflow/constants/types';

const coreConfiguration: DataFlowConfig = {
	canStartMidway: false,
	customLogoUrl: null,
	saveResults: false,
	steps: [
		{
			key: DataFlowStepName.FlexFetchName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: false,
				[name]: 'fetch',

				leftMedia: {},
				rightMedia: {},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
	],
};

export default coreConfiguration;
