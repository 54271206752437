import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import HiddenInput from '@src/common/components/HiddenInput';

type OfferBaseFormHiddenInputType = {
	id?: string;
	name: string;
	automationName: string;
	value?: string;
};

const OfferBaseFormHiddenInput: FC<OfferBaseFormHiddenInputType> = ({
	id,
	name,
	automationName,
	value,
}) => (
	<HiddenInput
		id={id}
		name={name as FormNames}
		automationName={automationName}
		initialValue={value}
	/>
);

export default OfferBaseFormHiddenInput;
