import * as R from 'ramda';
import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@cappex/theme';
import { FormHelperText, Grid } from '@material-ui/core';
import StudentCollegeSearch from '@src/features/collegelist/components/StudentCollegeSearch';
import { ListFormNames } from '@cappex/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import {
	StudentCollegeConnectionType,
	StudentCollegeListStatus,
	StudentCollegeListTrackingType,
	StudentCollegeListTypeTrackingValue,
	StudentCollegeListLocationTrackingValue,
	ADD_A_COLLEGE_MESSAGE,
} from '../util/studentcollege/constants';
import requiredFieldMessage from '../util/validation/constants';
import { FormFields } from '../util/validation/form';
import { SubFormContext } from './BaseValidationForm';

type SingleCollegeInputProps = {
	onSelect?: (collegeObject: object) => void;
	locationTrackingValue: StudentCollegeListLocationTrackingValue;
	typeTrackingValue: StudentCollegeListTypeTrackingValue;
	collegeId?: string;
	notifyOnCollegeIdAddedToForm?: (a?: string) => void;
	multiFormInit?: boolean;
	required?: boolean;
	defaultCollegeName?: string;
};

const EMPTY_ARRAY = [];

const ErrorText = styled(FormHelperText)`
	padding-left: 1rem;
`;

const createValidation = (name: string, required: boolean) => (input: FormFields) =>
	required && input[name].length === 0 ? requiredFieldMessage : '';

const SingleCollegeInput: FC<SingleCollegeInputProps> = ({
	onSelect,
	locationTrackingValue,
	typeTrackingValue,
	collegeId,
	notifyOnCollegeIdAddedToForm = R.identity,
	multiFormInit = false,
	required = false,
	defaultCollegeName,
}) => {
	const { path } = useContext(SubFormContext);
	const gridRef = useRef(null);
	const validate = useMemo(() => createValidation(ListFormNames.studentColleges, required), [
		required,
	]);

	const [placeholderText, setPlaceholderText] = useState(ADD_A_COLLEGE_MESSAGE);
	const [studentCollegeObject, setStudentCollegeObject] = useState({});
	const [isCollegeIdAdded, setIsCollegeIdAdded] = useState(false);

	const initialValueObj = useMemo(
		() => ({
			[ListFormNames.studentColleges]: EMPTY_ARRAY,
		}),
		[]
	);

	const { value, error, setValue } = useFormValidation({
		path,
		name: ListFormNames.studentColleges,
		initialValue: initialValueObj,
		validator: validate,
		fieldRef: gridRef,
		multiFormInit,
	});

	const updateStudentCollegeForm = useCallback(
		collegeObject => {
			const trackingData = {
				[StudentCollegeListTrackingType.LOCATION]: locationTrackingValue,
				[StudentCollegeListTrackingType.TYPE]: typeTrackingValue,
			};
			const studentCollege = {
				collegeId: collegeObject.value,
				collegeListConnectionType: StudentCollegeConnectionType.GENERAL,
				collegeListStatus: StudentCollegeListStatus.ACTIVE,
				levelOfInterest: null,
				collegeApplicationStatus: null,
				collegeListTrackingData: trackingData,
			};

			const current = value[ListFormNames.studentColleges] as string[];

			if (current !== undefined && current.length > 0) {
				if (studentCollegeObject !== undefined && !R.isEmpty(studentCollegeObject)) {
					const index = R.indexOf(studentCollegeObject, current);
					current[index] = (studentCollege as unknown) as string;

					setValue({
						...value,
						[ListFormNames.studentColleges]: current,
					});
				} else {
					setValue({
						...value,
						[ListFormNames.studentColleges]: [...current, JSON.stringify(studentCollege)],
					});
				}
			} else {
				setValue({
					...value,
					[ListFormNames.studentColleges]: [studentCollege as unknown] as string[],
				});
			}

			setStudentCollegeObject(studentCollege);
		},
		[locationTrackingValue, typeTrackingValue, studentCollegeObject, value, setValue]
	);

	const filterValues = useMemo(
		() =>
			R.pipe(
				R.without((studentCollegeObject as unknown[]) || []),
				R.pluck('collegeId')
			)((value[ListFormNames.studentColleges] as string[]) || []),
		[studentCollegeObject, value]
	);

	useEffect(() => {
		if (
			collegeId !== undefined &&
			!isCollegeIdAdded &&
			value[ListFormNames.studentColleges] !== undefined
		) {
			updateStudentCollegeForm({ value: collegeId });
			setIsCollegeIdAdded(true);
			notifyOnCollegeIdAddedToForm(collegeId);
		}
	}, [isCollegeIdAdded, collegeId, value, updateStudentCollegeForm, notifyOnCollegeIdAddedToForm]);

	useEffect(() => {
		collegeId && defaultCollegeName && setPlaceholderText(defaultCollegeName);
	}, [collegeId, defaultCollegeName]);

	return (
		<>
			<Grid item ref={gridRef}>
				<StudentCollegeSearch
					placeholderText={placeholderText}
					onItemSelect={collegeObject => {
						setPlaceholderText(collegeObject.label);
						updateStudentCollegeForm(collegeObject);
						onSelect(collegeObject);
					}}
					filterValues={filterValues}
				/>
			</Grid>
			<Grid item>
				<ErrorText error={!!error}>{error}</ErrorText>
			</Grid>
		</>
	);
};

export default SingleCollegeInput;
