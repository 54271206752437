import * as R from 'ramda';

const isValid = (value: string, min: number, max: number): boolean => {
	const number: number = parseFloat(value);
	if (!R.is(Number, number)) {
		return false;
	}

	return min <= number && number <= max;
};

const isEmpty = (value: string) => R.isNil(value) || R.isEmpty(value);

const numericRangeErrorMessage = (min: number, max: number) => `Must be between ${min} and ${max}`;

const validNumericRangeCriteria = (
	value: string,
	min: number,
	max: number,
	errorMessage?: string
): string => {
	return isEmpty(value) || isValid(value, min, max)
		? ''
		: errorMessage || numericRangeErrorMessage(min, max);
};

export {
	numericRangeErrorMessage,
	// validation criteria - to be used in components.
	validNumericRangeCriteria,
};
