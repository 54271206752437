import { Divider, DividerProps, Grid, GridProps } from '@material-ui/core';
import React from 'react';

interface Props extends GridProps {
	startDividerProps?: DividerProps;
	endDividerProps?: DividerProps;
}

const DividerWithChildren: React.FC<Props> = ({
	children,
	startDividerProps,
	endDividerProps,
	...rest
}) => (
	<Grid item xs={12} container direction="row" wrap="nowrap" alignItems="center" {...rest}>
		<Grid item xs={12}>
			<Divider {...startDividerProps} />
		</Grid>
		<Grid item xs={4}>
			{children}
		</Grid>

		<Grid item xs={12}>
			<Divider {...endDividerProps} />
		</Grid>
	</Grid>
);

export default DividerWithChildren;
