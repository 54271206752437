import React, {
	useReducer,
	ComponentType,
	useCallback,
	useState,
	useMemo,
	useContext,
	useEffect,
} from 'react';
import * as R from 'ramda';
import { StepComponentProps } from '..';

export enum QueueAction {
	APPEND,
	PREPEND,
	POP,
	PREPEND_AFTER_CURRENT,
}

type QueueStepComponent = {
	key: string;
	Component: ComponentType<Omit<StepComponentProps<any, any, any>, 'reverse'>>;
	data: any;
};

type ModalQueueAction = {
	type: QueueAction;
	key: string;
	Component?: QueueStepComponent['Component'];
	props?: any;
};

type ModalContextType = {
	queueModal: (
		action: QueueAction,
		key: string,
		type: QueueStepComponent['Component'],
		props: any
	) => void;
	runFlow: () => void;
	removeModal: (key: string) => void;
	registerPortal: (fun: (el: JSX.Element) => void) => void;
};
const DEFAULT_FUN = () => {
	throw new Error('Using default from context');
};

export const ModalContext = React.createContext<ModalContextType>({
	queueModal: DEFAULT_FUN,
	runFlow: DEFAULT_FUN,
	removeModal: DEFAULT_FUN,
	registerPortal: DEFAULT_FUN,
});

const reducer = (queue: QueueStepComponent[], action: ModalQueueAction) => {
	if (R.isNil(action)) {
		return queue;
	}
	const step = {
		key: action.key,
		Component: action.Component,
		data: action.props,
	};

	const stepExistsAt = R.findIndex(R.propEq('key', step.key), queue);
	if (
		stepExistsAt !== -1 &&
		(action.type === QueueAction.APPEND || action.type === QueueAction.PREPEND)
	) {
		return R.update(stepExistsAt, step, queue);
	}

	switch (action.type) {
		case QueueAction.APPEND:
			return [...queue, step];
		case QueueAction.PREPEND:
			return [step, ...queue];
		case QueueAction.PREPEND_AFTER_CURRENT:
			return R.insert(1, step, queue);
		case QueueAction.POP:
			return R.remove(stepExistsAt === -1 ? 0 : stepExistsAt, 1, queue);
		default:
			throw new Error('Unknown action');
	}
};

const ModalManager = ({ children }) => {
	const [running, setRun] = useState(false);
	const [modals, dispatch] = useReducer(reducer, []);
	const [sendToPortal, setPortal] = useState<(el: JSX.Element) => void>(() => {});

	const queueModal = useCallback(
		(action: QueueAction, key: string, type: QueueStepComponent['Component'], props: any) => {
			dispatch({ type: action, Component: type, key, props });
		},
		[]
	);
	const runFlow = useCallback(() => {
		setRun(true);
	}, []);
	const popModal = useCallback(() => {
		dispatch({ type: QueueAction.POP, key: undefined });
	}, []);
	const removeModal = useCallback((key: string) => {
		dispatch({ type: QueueAction.POP, key });
	}, []);

	const CurrentComponent = modals.length >= 1 ? modals[0].Component : null;

	useEffect(() => {
		if (running) {
			if (modals.length >= 1) {
				sendToPortal(
					<CurrentComponent key={modals[0].key} {...modals[0].data} complete={popModal} />
				);
			} else {
				sendToPortal(null);
			}
		}
	}, [modals, running, sendToPortal, popModal]);

	const value = useMemo(
		() => ({
			queueModal,
			runFlow,
			removeModal,
			registerPortal: setPortal,
		}),
		[queueModal, runFlow, removeModal]
	);

	return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const ModalPortal = () => {
	const { registerPortal } = useContext(ModalContext);
	const [Component, setComponent] = useState(<></>);

	useEffect(() => {
		registerPortal(() => setComponent);
	}, [registerPortal]);

	return <>{Component}</>;
};

export default ModalManager;
