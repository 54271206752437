import React, { FC, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import OfferCard from './OfferCard';
import OfferCardModal from '@src/features/offer/components/OfferCardModal';
import {
	CampaignOffer,
	CampaignOfferContent,
	Placement,
} from '@src/features/offer/constants/constants';
import { SnackbarContext, SnackbarType } from '@common/components/SnackbarManager';
import * as R from 'ramda';
import AcceptedOffersContext from "@util/acceptedofferscontext";

const offerSubmitSuccessMessage = `You're all set!`;

type OfferListProps = {
	placement: Placement;
	campaignOffers: CampaignOffer[];
	onOfferAccepted: (campaignOfferId: number) => void;
};

const OfferCardList: FC<OfferListProps> = ({ campaignOffers, onOfferAccepted, placement }) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { refreshAcceptedOffers } = useContext(AcceptedOffersContext);
	const [displayModal, setDisplayModal] = useState(false);
	const [modalContent, setModalContent] = useState<CampaignOfferContent[]>([]);
	const [modalCampaignOfferId, setModalCampaignOfferId] = useState<number>();

	const onOpenModalClick = (campaignOfferId: number, modalOfferContent: CampaignOfferContent[]) => {
		setModalContent(modalOfferContent);
		setModalCampaignOfferId(campaignOfferId);
		setDisplayModal(true);
	};

	const onModalClose = () => {
		setDisplayModal(false);
		setModalContent([]);
		setModalCampaignOfferId(undefined);
	};

	const onSuccessAcceptOffer = (campaignOfferId: number) => {
		openSnackbar({
			snackbarType: SnackbarType.Success,
			message: offerSubmitSuccessMessage,
		});
		onModalClose();
		onOfferAccepted(campaignOfferId);
		refreshAcceptedOffers();
	};

	const sortByPriority = R.sortBy(R.prop('campaignPlacementPriority'));
	const sortedOffers = sortByPriority(campaignOffers);

	return (
		<>
			<Grid container spacing={3}>
				{sortedOffers.map(offer => (
					<Grid item xs={12} md={6} lg={4} key={offer.id}>
						<OfferCard
							key={offer.id}
							onOfferAccepted={onSuccessAcceptOffer}
							onOpenModalClick={onOpenModalClick}
							campaignOffer={offer}
							placement={placement}
						/>
					</Grid>
				))}
			</Grid>

			<OfferCardModal
				campaignOfferId={modalCampaignOfferId}
				show={displayModal}
				onModalDismiss={onModalClose}
				onOfferAccepted={onSuccessAcceptOffer}
				placement={placement}
				modalCampaignOfferContent={modalContent}
			/>
		</>
	);
};

export default OfferCardList;
