import { useEffect, useState } from 'react';

const useClampLines = (text: string, ref: React.RefObject<HTMLElement>, maxLines: number) => {
	const [clampedText, setClampedText] = useState(text);

	useEffect(() => {
		if (!ref.current) return null;

		const computeClampedText = () => {
			const lineHeight = parseInt(window.getComputedStyle(ref.current).lineHeight, 10);
			const maxHeight = lineHeight * maxLines;
			let currentText = text;
			// eslint-disable-next-line no-param-reassign -- NJN 29/02/24 we must directly manipulate the ref's innerHTML to truncate its text
			ref.current.innerHTML = currentText;

			while (ref.current.scrollHeight > maxHeight && currentText.length > 0) {
				const matchLastCharAndTrailingWhitespace = /\s*\S\s*$/;
				currentText = currentText.replace(matchLastCharAndTrailingWhitespace, '');
				// eslint-disable-next-line no-param-reassign -- NJN 29/02/24 we must directly manipulate the ref's innerHTML to truncate its text
				ref.current.innerHTML = `${currentText}...`;
			}

			setClampedText(ref.current.innerHTML);
		};

		computeClampedText();

		window.addEventListener('resize', computeClampedText);
		return () => window.removeEventListener('resize', computeClampedText);
	}, [text, maxLines, ref]);

	return clampedText;
};

export default useClampLines;
