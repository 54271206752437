import { RoleId } from '@src/common/util/roles/constants';
import { FormNames } from '@cappex/constants';

type InviteContext = {
	show: boolean;
	roleId: RoleId;
	[FormNames.parentStudentRelationship]: string;
	parentInfo: {
		[FormNames.email]: string;
		[FormNames.firstName]: string;
	};
	studentInfo: {
		[FormNames.email]: string;
		[FormNames.firstName]: string;
	};
};

enum InviteTypeId {
	PARENT_TO_STUDENT = 1,
	STUDENT_TO_PARENT = 2,
}

const translateInviteTypeToTargetRole = (inviteTypeId: number): RoleId => {
	switch (inviteTypeId) {
		case InviteTypeId.PARENT_TO_STUDENT:
			return RoleId.STUDENT;
		case InviteTypeId.STUDENT_TO_PARENT:
			return RoleId.PARENT;
		default:
			return null;
	}
};

const convertInvite = ({ response }: any): InviteContext => {
	const role: RoleId = translateInviteTypeToTargetRole(response.typeId);
	const isParent: boolean = role === RoleId.PARENT;
	return {
		show: undefined,
		roleId: role,
		[FormNames.parentStudentRelationship]: response[FormNames.parentStudentRelationship],
		parentInfo: {
			[FormNames.email]: isParent ? response.email : response.hostEmail,
			[FormNames.firstName]: isParent ? response.firstName : response.hostFirstName,
		},
		studentInfo: {
			[FormNames.email]: !isParent ? response.email : response.hostEmail,
			[FormNames.firstName]: !isParent ? response.firstName : response.hostFirstName,
		},
	};
};

export default convertInvite;
