// Country
export const USA_COUNTRY_ID = '11';
export const USA_COUNTRY_VALUE = 'USA';

// Ethnicity
export const AFRICAN_AMERICAN_ETHNICITY_ID = '2';
export const NATIVE_AMERICAN_ETHNICITY_ID = '3';
export const ASIAN_ETHNICITY_ID = '4';
export const HISPANIC_ETHNICITY_ID = '5';
export const WHITE_ETHNICITY_ID = '6';
export const OTHER_ETHNICITY_ID = '8';

// Religion
export const OTHER_RELIGION_ID = '0';

export enum ReferenceDataDefaultValues {
	month = '6', // June
	season = '3', // Fall
}
