import React, { FC } from 'react';
import getEndpoint from '@util/request';
import request, { RequestMethod } from '@cappex/request';
import { AutomationNameDefault } from '@util/automation';
import { styled } from '@cappex/theme';
import MenuItem from '@material-ui/core/MenuItem';
import useFailedAuth from '../util/auth/hooks/useFailedAuth';

export const NavHeaderMenuItem = styled(MenuItem)`
	padding: 0.6875rem 1rem;
`;

const SidebarNavHeaderLogoutListItem: FC = () => {
	const forceAuthFailure = useFailedAuth(true);

	const logOut = (e: React.MouseEvent<EventTarget>) => {
		e.preventDefault(); // needed so that 'router link' doesn't try to navigate
		request({
			url: getEndpoint('/logout'),
			method: RequestMethod.POST,
			withCredentials: true,
		})
			.then(() => forceAuthFailure())
			.catch(() => {
				throw new Error('Error logging out');
			});
		return false;
	};

	return (
		<NavHeaderMenuItem data-qa={AutomationNameDefault.logout} onClick={logOut}>
			Log Out
		</NavHeaderMenuItem>
	);
};

export default SidebarNavHeaderLogoutListItem;
