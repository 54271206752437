import React, { FC, useMemo, useRef, useContext } from 'react';
import useFormValidation from '@util/hooks/useFormValidation';
import { FormControl, FormControlLabel, Checkbox, FormHelperText } from '@material-ui/core';
import { AutomationNameGeneric } from '../util/automation';
import { SubFormContext } from './BaseValidationForm';

export interface BaseFormToggleProps {
	id: string;
	name: string;
	label: string;
	initialValue?: string[];
	automationName?: string;
}

const BaseFormToggle: FC<BaseFormToggleProps> = ({
	id,
	name,
	label,
	initialValue = false,
	automationName = AutomationNameGeneric.checkbox,
}) => {
	const { path } = useContext(SubFormContext);
	const listRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: initialValue }), [initialValue, name]);

	const { setValue, error } = useFormValidation({
		path,
		name,
		initialValue: initialValueObj,
		fieldRef: listRef,
	});

	const onCheckboxSelect = (_, checked: boolean) => {
		setValue({ [name]: checked });
	};

	return (
		<FormControl margin="normal" fullWidth variant="outlined" error={!!error} ref={listRef}>
			<FormControlLabel
				control={<Checkbox id={id} onChange={onCheckboxSelect} data-qa={automationName} />}
				label={label}
				htmlFor={id}
			/>
			<FormHelperText>{error}</FormHelperText>
		</FormControl>
	);
};

export default BaseFormToggle;
