import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Typography, Grid } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { GridProps } from '@material-ui/core/Grid';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import NumberFormat from 'react-number-format';
import { styled } from '@cappex/theme';
import { Skeleton } from '@material-ui/lab';
import { AutomationNameGeneric } from '@common/util/automation';

export const BoldTypography = styled(Typography)`
	font-weight: bold;
`;

type ValueGridProps = GridProps & { textWidth: string; prependValue: boolean };
const ValueGrid = styled(({ textWidth, prependValue, ...rest }) => <Grid {...rest} />)<
	ValueGridProps
>`
	${props => (props.textWidth ? `width: ${props.textWidth};` : '')}
	margin-${props => (props.prependValue ? 'right' : 'left')}: 0.5rem;
	flex-shrink: 0;
`;

export interface TextCaptionItemProps {
	text: string;
	textTypographyVariant?: TypographyProps['variant'];
	textColor?: TypographyProps['color'];
	value: number;
	valueTypographyVariant?: TypographyProps['variant'];
	valueWidth?: string;
	icon?: IconDefinition;
	iconColor?: string;
	iconSize?: FontAwesomeIconProps['size'];
	thousandSeparator?: boolean;
	prefix?: string;
	suffix?: string;
	prependValue?: boolean;
	automationName?: string;
}

const TextCaptionItem: FC<TextCaptionItemProps> = ({
	text,
	textColor,
	textTypographyVariant,
	value,
	valueTypographyVariant,
	valueWidth,
	icon,
	iconColor,
	iconSize,
	thousandSeparator,
	prefix = '',
	suffix = '',
	prependValue = false,
	automationName = AutomationNameGeneric.text,
}) => {
	if (value === undefined) {
		return <Skeleton width="5rem" />;
	}

	const renderLabelText = () => (
		<Grid item>
			<Typography display="inline" color={textColor} variant={textTypographyVariant} noWrap>
				{text}
			</Typography>
		</Grid>
	);

	return (
		<Grid container spacing={2} alignItems="center" wrap="nowrap">
			{icon && (
				<Grid item>
					<FontAwesomeIcon icon={icon} fixedWidth color={iconColor} size={iconSize} />
				</Grid>
			)}
			<Grid item>
				<Grid container alignItems="baseline" wrap="nowrap">
					{!prependValue && renderLabelText()}
					<ValueGrid item textWidth={valueWidth} prependValue={prependValue}>
						<BoldTypography
							display="inline"
							variant={valueTypographyVariant}
							data-qa={automationName}
						>
							{value !== null ? (
								<NumberFormat
									value={value}
									displayType="text"
									thousandSeparator={thousandSeparator}
									prefix={prefix}
									suffix={suffix}
									isNumericString
								/>
							) : (
								`${prefix} \u2013 ${suffix}`
							)}
						</BoldTypography>
					</ValueGrid>
					{prependValue && renderLabelText()}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default TextCaptionItem;
