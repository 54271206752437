import React, { FC, MouseEventHandler } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { OptionProps } from 'react-select/lib/components/Option';
import { SearchMenuItem } from '@cappex/search';

const WrappingTypography = styled(Typography)`
	white-space: normal;
`;

type LocationData = {
	city: string;
	stateAbbreviation: string;
};

// Have to redefine OptionProps' InnerProps since it creates conflicting props with the MenuItem
type LiOptionProps = Omit<OptionProps<LocationData>, 'innerProps'> & {
	innerProps: Omit<
		OptionProps<LocationData>['innerProps'],
		'onClick' | 'onMouseMove' | 'onMouseOver'
	> & {
		onClick: MouseEventHandler<HTMLElement>;
		onMouseMove: MouseEventHandler<HTMLElement>;
		onMouseOver: MouseEventHandler<HTMLElement>;
	};
};

const CboSearchItem: FC<Partial<LiOptionProps>> = ({
	label,
	innerProps,
	innerRef,
	isFocused,
	data: { city, stateAbbreviation },
}) => {
	return (
		<SearchMenuItem buttonRef={innerRef} selected={isFocused} {...innerProps}>
			<Grid container direction="column">
				<Grid item>
					<WrappingTypography variant="body1" data-qa={`${AutomationNameGeneric.menuItem}-title`}>
						{label}
					</WrappingTypography>
				</Grid>
				<Grid item>
					<Typography
						variant="body2"
						color="textSecondary"
						data-qa={`${AutomationNameGeneric.menuItem}-location`}
					>
						{`${city}, ${stateAbbreviation}`}
					</Typography>
				</Grid>
			</Grid>
		</SearchMenuItem>
	);
};
export default CboSearchItem;
