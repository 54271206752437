// NOTE: Any changes made to this component should probably be made to
// the react-cappex version of this component too. Otherwise, we should
// reconsider moving this component to common-js
import React, { FC } from 'react';
import environment, { ReactEnvironment } from '..';

interface FeatureFlagProps {
	flag: keyof ReactEnvironment;
}

const FeatureFlag: FC<FeatureFlagProps> = ({ flag, children }) =>
	// eslint-disable-next-line react/jsx-no-useless-fragment
	environment[flag] ? <>{children}</> : null;

export default FeatureFlag;
