import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { toWholeNumber } from '@util/string/stringUtil';
import {
	validGmatTotalCriteria,
	GMAT_TOTAL_MIN,
	GMAT_TOTAL_MAX,
	GMAT_TOTAL_STEP,
} from '../util/validation/gmat';

type GMATTotalInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const GMAT_TOTAL_CRITERIA = [validGmatTotalCriteria];

const GMATTotalInput: FC<GMATTotalInputProps> = ({
	id = AutomationNameDefault.gmatTotalInput,
	name,
	label = 'GMAT Total Score',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.gmatTotalInput,
	margin,
	variant,
}) => {
	return (
		<BaseFormInput
			margin={margin}
			inputType="number"
			automationName={automationName}
			id={id}
			label={label}
			inputMin={GMAT_TOTAL_MIN}
			inputMax={GMAT_TOTAL_MAX}
			inputStep={GMAT_TOTAL_STEP}
			fullWidth={fullWidth}
			initialValue={initialValue}
			validCriteria={GMAT_TOTAL_CRITERIA}
			name={name}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			beforeChange={toWholeNumber}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			variant={variant}
		/>
	);
};

export default GMATTotalInput;
