import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { validPsatNmsqtCriteria, PSAT_NMSQT_MIN, PSAT_NMSQT_MAX } from '@util/validation/sat';
import { toWholeNumber } from '@util/string/stringUtil';

type PsatNmsqtInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const PSAT_NMSQT_CRITERIA = [validPsatNmsqtCriteria];

const PsatNmsqtInput: FC<PsatNmsqtInputProps> = ({
	id = AutomationNameDefault.psatNmsqtInput,
	name,
	label = 'PSAT / NMSQT',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.psatNmsqtInput,
	variant,
}) => (
	<BaseFormInput
		inputType="number"
		automationName={automationName}
		id={id}
		label={label}
		inputMin={PSAT_NMSQT_MIN}
		inputMax={PSAT_NMSQT_MAX}
		inputStep={10}
		fullWidth={fullWidth}
		initialValue={initialValue}
		validCriteria={PSAT_NMSQT_CRITERIA}
		name={name}
		disabled={disabled}
		onChange={onChange}
		onBlur={onBlur}
		beforeChange={toWholeNumber}
		helperText={helperText}
		LeadingIcon={LeadingIcon}
		TrailingIcon={TrailingIcon}
		required={required}
		verifiedBy={verifiedBy}
		verifies={verifies}
		variant={variant}
	/>
);

export default PsatNmsqtInput;
