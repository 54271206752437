import React, { FC, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { styled } from '@cappex/theme';
import { DialogContent } from '@material-ui/core';
import { FloatingCloseButton } from '@cappex/components';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { saveAccountCampaignOfferResponse } from '@util/hooks/useCampaignOffers';
import {
	buildCampaignOfferContentIdToValuesFromFormValues,
	buildValidationErrorFieldNames,
	findContentValueText,
	sortByPosition,
} from '@src/features/offer/util/offerUtil';
import OfferCardSubmitButton from '@src/features/offer/components/OfferCardSubmitButton';
import OfferCardHeader from '@src/features/offer/components/OfferCardHeader';
import OfferCardParagraph from '@src/features/offer/components/OfferCardParagraph';
import OfferCardText from '@src/features/offer/components/OfferCardText';
import Grid from '@material-ui/core/Grid';
import OfferCardTextInput from '@src/features/offer/components/OfferCardTextInput';
import OfferCardSelectInput from '@src/features/offer/components/OfferCardSelectInput';
import OfferCardCheckboxInput from '@src/features/offer/components/OfferCardCheckboxInput';
import OfferCardPhoneInput from '@src/features/offer/components/OfferCardPhoneInput';
import OfferCardLeadIdInput from '@src/features/offer/components/OfferCardLeadIdInput';
import { FormContext, withFormValidation } from '@util/validation/form';
import { OfferCardBaseProps } from '@src/features/offer/components/OfferCard';
import { ErrorMessages, KeyedData } from '@cappex/request';
import { SnackbarContext, SnackbarType } from '@common/components/SnackbarManager';
import OfferCardLeadIdScript from '@src/features/offer/components/OfferCardLeadIdScript';
import OfferCardDisclaimer from './OfferCardDisclaimer';
import BaseValidationForm from '@src/common/components/BaseValidationForm';
import OfferCardEmailInput from '@src/features/offer/components/OfferCardEmailInput';
import {
	AccountCampaignOfferResponse,
	CampaignOfferContent,
	CampaignOfferContentType,
	ResponseType,
} from '@src/features/offer/constants/constants';
import OfferCardNameInput from '@src/features/offer/components/OfferCardNameInput';
import OfferCardModalLogo from '@src/features/offer/components/OfferCardModalLogo';
import OfferCardLeadIdTcpaDisclosureInput from '@src/features/offer/components/OfferCardLeadIdTcpaDisclosureInput';
import OfferCardHiddenInput from './OfferCardHiddenInput';
import OfferCardSubHeader from './OfferCardSubHeader';
import OfferCardModalParagraph from './OfferCardModalParagraph';

const StyledDialogContent = styled(DialogContent)`
	padding-bottom: 1.25rem;
`;

const MemoizedLeadIdScript = React.memo(OfferCardLeadIdScript);

const getComponentForContent = (
	campaignOfferContent: CampaignOfferContent,
	submitButtonDisabled: boolean
) => {
	const { campaignOfferContentType } = campaignOfferContent;
	switch (campaignOfferContentType) {
		case CampaignOfferContentType.LOGO:
			return (
				<OfferCardModalLogo key={campaignOfferContent.id} logoImageContent={campaignOfferContent} />
			);
		case CampaignOfferContentType.PARAGRAPH:
			return (
				<OfferCardParagraph
					text={findContentValueText(campaignOfferContent)}
					automationName={AutomationNameDefault.paOfferModalBodyParagraph}
				/>
			);
		case CampaignOfferContentType.TEXT:
			return (
				<OfferCardText
					text={findContentValueText(campaignOfferContent)}
					automationName={AutomationNameDefault.paOfferModalBodyText}
				/>
			);
		case CampaignOfferContentType.HEADER:
			return (
				<OfferCardHeader
					text={findContentValueText(campaignOfferContent)}
					automationName={AutomationNameDefault.paOfferModalHeaderText}
				/>
			);
		case CampaignOfferContentType.BUTTON:
			return (
				<OfferCardSubmitButton
					disabled={submitButtonDisabled}
					automationName={AutomationNameDefault.paOfferModalSubmitButton}
					onClick={() => {}}
					buttonText={findContentValueText(campaignOfferContent)}
					type="submit"
				/>
			);
		case CampaignOfferContentType.INPUT_TEXT:
			return (
				<OfferCardTextInput
					automationName={AutomationNameDefault.paOfferInputText}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.SELECT:
			return (
				<OfferCardSelectInput
					automationName={AutomationNameDefault.paOfferInputSelect}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.CHECKBOX:
			return (
				<OfferCardCheckboxInput
					automationName={AutomationNameDefault.paOfferInputCheckbox}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.PHONE_WITH_COUNTRY_CODE:
			return (
				<OfferCardPhoneInput
					automationName={AutomationNameDefault.paOfferInputPhone}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.DISCLAIMER:
			return (
				<OfferCardDisclaimer
					automationName={AutomationNameDefault.paOfferModalDisclaimerText}
					text={findContentValueText(campaignOfferContent)}
				/>
			);
		case CampaignOfferContentType.EMAIL:
			return (
				<OfferCardEmailInput
					automationName={AutomationNameDefault.paOfferInputEmail}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.INPUT_NAME:
			return (
				<OfferCardNameInput
					automationName={AutomationNameDefault.paOfferInputName}
					content={campaignOfferContent}
				/>
			);
		case CampaignOfferContentType.HIDDEN:
			return (
				<OfferCardHiddenInput
					content={campaignOfferContent}
					automationName={AutomationNameDefault.paOfferInputHidden}
				/>
			);
		case CampaignOfferContentType.LEAD_ID_TCPA_DISCLOSURE:
			return (
				<OfferCardLeadIdTcpaDisclosureInput
					content={campaignOfferContent}
					automationName={AutomationNameDefault.paOfferLeadidTcpaDisclosure}
				/>
			);
		case CampaignOfferContentType.HERO:
			return <></>;
		case CampaignOfferContentType.SUB_HEADER:
			return <OfferCardSubHeader content={campaignOfferContent} />;
		case CampaignOfferContentType.MODAL_PARAGRAPH:
			return <OfferCardModalParagraph content={campaignOfferContent} />;
		default:
			return <></>;
	}
};

export interface OfferCardModalContentProps extends OfferCardBaseProps {
	campaignOfferId: number;
	modalCampaignOfferContent: CampaignOfferContent[];
	onModalDismiss?: () => void;
}

const OfferCardModalContent: FC<OfferCardModalContentProps> = ({
	campaignOfferId,
	modalCampaignOfferContent,
	placement,
	onModalDismiss = () => {},
	onOfferAccepted = () => {},
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getFormValues } = useContext(FormContext);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);

	const onOfferSubmitSuccess = () => {
		setSubmitButtonDisabled(false);
		onOfferAccepted(campaignOfferId);
	};

	const onError = useCallback(
		(message = ErrorMessages.unknown) => {
			setSubmitButtonDisabled(false);
			openSnackbar({
				snackbarType: SnackbarType.Error,
				message,
			});
		},
		[openSnackbar, setSubmitButtonDisabled]
	);

	const handleValidationErrors = (validationErrors: KeyedData<string>) => {
		setFormErrors(buildValidationErrorFieldNames(validationErrors, getFormValues()));
		setSubmitButtonDisabled(false);
	};

	const save = () => {
		setSubmitButtonDisabled(true);
		const form = {
			campaignOfferId,
			responseType: ResponseType.ACCEPT,
			placementName: placement,
			campaignOfferContentIdToValues: buildCampaignOfferContentIdToValuesFromFormValues(
				getFormValues()
			),
		} as AccountCampaignOfferResponse;

		// no custom question validation
		saveAccountCampaignOfferResponse(form, onOfferSubmitSuccess, handleValidationErrors, onError);
	};

	const leadIdContent = modalCampaignOfferContent.find(
		it => CampaignOfferContentType.LEAD_ID === it.campaignOfferContentType
	);
	const isLeadId = !!leadIdContent;
	const contentWithoutLeadId = modalCampaignOfferContent.filter(
		it => CampaignOfferContentType.LEAD_ID !== it.campaignOfferContentType
	);

	const sortedContent = useMemo(() => sortByPosition(contentWithoutLeadId), [contentWithoutLeadId]);

	const searchRef = useRef(null);
	const leadIdValue = searchRef?.current?.value;

	return (
		<>
			<StyledDialogContent>
				<BaseValidationForm onValid={save}>
					<Grid container spacing={2}>
						{sortedContent.map(content => (
							<Grid key={content.id} item xs={12}>
								{getComponentForContent(content, submitButtonDisabled)}
							</Grid>
						))}
						{isLeadId && (
							<OfferCardLeadIdInput
								content={leadIdContent}
								automationName={AutomationNameDefault.paOfferInputLeadId}
								inputRef={searchRef}
								initialValue={leadIdValue}
							/>
						)}
					</Grid>
				</BaseValidationForm>
				{isLeadId && <MemoizedLeadIdScript />}
			</StyledDialogContent>
			<FloatingCloseButton
				onClick={onModalDismiss}
				position={['top', 'right']}
				qa={`${AutomationNameGeneric.closeButton}-${AutomationNameDefault.paOfferModalCloseButton}`}
			/>
		</>
	);
};

export default withFormValidation(OfferCardModalContent);
