import * as R from 'ramda';
import { faCity as falCity } from '@fortawesome/pro-light-svg-icons/faCity';
import { faDiploma as falDiploma } from '@fortawesome/pro-light-svg-icons/faDiploma';
import { faGraduationCap as falGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faMapMarkerAlt as falMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { faPiggyBank as falPiggyBank } from '@fortawesome/pro-light-svg-icons/faPiggyBank';
import { faUsers as falUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faCity as fasCity } from '@fortawesome/pro-solid-svg-icons/faCity';
import { faDiploma as fasDiploma } from '@fortawesome/pro-solid-svg-icons/faDiploma';
import { faGraduationCap as fasGraduationCap } from '@fortawesome/pro-solid-svg-icons/faGraduationCap';
import { faMapMarkerAlt as fasMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { faPiggyBank as fasPiggyBank } from '@fortawesome/pro-solid-svg-icons/faPiggyBank';
import { faUsers as fasUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ReferenceData } from '../hooks/useCloudReferenceData';
import { CollegeSizeShort } from '../college/collegeDataUtil';

export const UPDATE_PREFERENCES_BUTTON_TEXT = 'Update Your Preferences';

export interface BaseFitComponentProps {
	blur?: boolean;
	showJitButton?: boolean;
	onJitOpen: () => void;
	onJitClose: () => void;
	onPreferenceChange: () => void;
}

export enum FitIneligibleReason {
	NONE = 'NONE',
	INSUFFICIENT_STUDENT_INFORMATION = 'INSUFFICIENT_STUDENT_INFORMATION',
	INSUFFICIENT_COLLEGE_INFORMATION = 'INSUFFICIENT_COLLEGE_INFORMATION',
	GENDER_NOT_PERMITTED = 'GENDER_NOT_PERMITTED',
}

export enum FitScoreGroup {
	GREAT = 1,
	GOOD,
	OKAY,
	POOR,
	NONE,
}

export enum FitScoreGroupDescription {
	GREAT = 'Great Fit',
	GOOD = 'Good Fit',
	OKAY = 'Okay Fit',
	POOR = 'Poor Fit',
}

// Enum values hard coded since the enum order affects icon sort order
export enum MatchType {
	MATCH = 0,
	NO_MATCH = 1,
	NO_DATA = 2,
}

export enum FitType {
	COLLEGE_TYPE = 'COLLEGE_TYPE',
	MAJORS = 'MAJORS',
	COST = 'COST',
	LOCATION = 'LOCATION',
	STUDENT_BODY_SIZE = 'STUDENT_BODY_SIZE',
	CAMPUS_SETTING = 'CAMPUS_SETTING',
	MODALITY = 'MODALITY',
	TEST_OPTIONAL = 'TEST_OPTIONAL',
}

export interface FitResultDetail {
	fitType: FitType;
	subScore: number;
	weight: number;
	fitScoreGroupId: FitScoreGroup;
	fitScoreGroupDescription: FitScoreGroupDescription;
	fitIneligibleReason: FitIneligibleReason;
}

export interface ModifiedFitDetails extends FitResultDetail {
	match: MatchType;
}

export interface FitResults {
	studentId: string;
	collegeId: number;
	fitScore: number;
	fitScoreGroupId: FitScoreGroup;
	fitScoreGroupDescription: FitScoreGroupDescription;
	fitIneligibleReason: FitIneligibleReason;
	fitResultDetails: FitResultDetail[];
}

export interface ModifiedFitResults extends FitResults {
	fitResultDetails: ModifiedFitDetails[];
}

export type PreferenceMappingData = {
	studentBodySizeData: ReferenceData[];
	townSizeData: ReferenceData[];
	locationData: string;
};

export interface FitPreferenceData<T = void, K = void> {
	displayName: string;
	icon: IconDefinition;
	selectedIcon: IconDefinition;
	matchingFitScoreGroups: FitScoreGroup[];
	automationId: string;
	getMatchText: (a: T, b: K) => string;
}

type AdditionalCollegeData = {
	collegeStudentBodySize: string;
	collegeTownSize: ReferenceData;
};

const mapCampusSetting = (data: ReferenceData, mappingData: PreferenceMappingData): string => {
	if (data === null) {
		return undefined;
	}

	const mappedData = R.filter(
		(refData: ReferenceData) => refData.id === data.id,
		mappingData.townSizeData
	);

	if (R.isEmpty(mappedData)) {
		return undefined;
	}

	return `In a ${mappedData[0].value.toLocaleLowerCase()}`;
};

const mapStudentBodySize = (data: string, mappingData: PreferenceMappingData): string => {
	if (data === null) {
		return undefined;
	}

	const smallSchool = `${mappingData.studentBodySizeData[0].value} school`;
	const midSizeSchool = `${mappingData.studentBodySizeData[1].value} school`;
	const largeSchool = `${mappingData.studentBodySizeData[2].value} school`;

	switch (data) {
		case CollegeSizeShort.VERY_SMALL:
			return smallSchool;
		case CollegeSizeShort.SMALL:
			return smallSchool;
		case CollegeSizeShort.MID_SIZE:
			return midSizeSchool;
		case CollegeSizeShort.LARGE:
			return largeSchool;
		case CollegeSizeShort.VERY_LARGE:
			return largeSchool;
		default:
			return undefined;
	}
};

type FitPreferenceMap = {
	COLLEGE_TYPE: FitPreferenceData;
	MAJORS: FitPreferenceData;
	COST: FitPreferenceData;
	LOCATION: FitPreferenceData<AdditionalCollegeData, PreferenceMappingData>;
	STUDENT_BODY_SIZE: FitPreferenceData<AdditionalCollegeData, PreferenceMappingData>;
	CAMPUS_SETTING: FitPreferenceData<AdditionalCollegeData, PreferenceMappingData>;
	MODALITY: FitPreferenceData;
	TEST_OPTIONAL: FitPreferenceData;
};

export const fitPreferenceMap: FitPreferenceMap = {
	[FitType.COLLEGE_TYPE]: {
		displayName: 'College Type',
		icon: falGraduationCap,
		selectedIcon: fasGraduationCap,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'college-type',
		getMatchText: () => '',
	},
	[FitType.MAJORS]: {
		displayName: 'Majors',
		icon: falDiploma,
		selectedIcon: fasDiploma,
		matchingFitScoreGroups: [
			FitScoreGroup.GREAT,
			FitScoreGroup.GOOD,
			FitScoreGroup.OKAY,
			FitScoreGroup.POOR,
		],
		automationId: 'majors',
		getMatchText: () => 'Has your major',
	},
	[FitType.COST]: {
		displayName: 'Cost',
		icon: falPiggyBank,
		selectedIcon: fasPiggyBank,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'cost',
		getMatchText: () => 'In your budget',
	},
	[FitType.LOCATION]: {
		displayName: 'Location',
		icon: falMapMarkerAlt,
		selectedIcon: fasMapMarkerAlt,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'location',
		getMatchText: (_: AdditionalCollegeData, mappingData: PreferenceMappingData) =>
			mappingData.locationData ? mappingData.locationData : undefined,
	},
	[FitType.STUDENT_BODY_SIZE]: {
		displayName: 'Size',
		icon: falUsers,
		selectedIcon: fasUsers,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'size',
		getMatchText: (collegeData: AdditionalCollegeData, mappingData: PreferenceMappingData) =>
			mapStudentBodySize(collegeData.collegeStudentBodySize, mappingData),
	},
	[FitType.CAMPUS_SETTING]: {
		displayName: 'Campus Setting',
		icon: falCity,
		selectedIcon: fasCity,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'campus-setting',
		getMatchText: (collegeData: AdditionalCollegeData, mappingData: PreferenceMappingData) =>
			mapCampusSetting(collegeData.collegeTownSize, mappingData),
	},
	[FitType.MODALITY]: {
		displayName: 'Modality',
		icon: falCity,
		selectedIcon: fasCity,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'modality',
		getMatchText: () => 'Has your attendance options',
	},
	[FitType.TEST_OPTIONAL]: {
		displayName: 'Test optional',
		icon: falPiggyBank,
		selectedIcon: falPiggyBank,
		matchingFitScoreGroups: [FitScoreGroup.GREAT],
		automationId: 'test-optional',
		getMatchText: () => 'Test optional admission',
	},
};

// Primarily used in tests and to generate below constants. Shouldn't be used in real code
export const DEFUALT_FIT_DATA: FitResults = {
	collegeId: 1,
	studentId: '2',
	fitScore: 0.7,
	fitScoreGroupId: 2,
	fitScoreGroupDescription: FitScoreGroupDescription.GOOD,
	fitIneligibleReason: FitIneligibleReason.NONE,
	fitResultDetails: [
		{
			fitType: FitType.CAMPUS_SETTING,
			subScore: null,
			weight: 2,
			fitScoreGroupId: null,
			fitScoreGroupDescription: null,
			fitIneligibleReason: FitIneligibleReason.INSUFFICIENT_STUDENT_INFORMATION,
		},
		{
			fitType: FitType.COLLEGE_TYPE,
			subScore: 1,
			weight: 5,
			fitScoreGroupId: FitScoreGroup.GREAT,
			fitScoreGroupDescription: FitScoreGroupDescription.GREAT,
			fitIneligibleReason: FitIneligibleReason.NONE,
		},
		{
			fitType: FitType.STUDENT_BODY_SIZE,
			subScore: 1,
			weight: 2,
			fitScoreGroupId: FitScoreGroup.GREAT,
			fitScoreGroupDescription: FitScoreGroupDescription.GREAT,
			fitIneligibleReason: FitIneligibleReason.NONE,
		},
		{
			fitType: FitType.LOCATION,
			subScore: 0,
			weight: 3,
			fitScoreGroupId: FitScoreGroup.POOR,
			fitScoreGroupDescription: FitScoreGroupDescription.POOR,
			fitIneligibleReason: FitIneligibleReason.NONE,
		},
		{
			fitType: FitType.COST,
			subScore: 1,
			weight: 4,
			fitScoreGroupId: FitScoreGroup.GREAT,
			fitScoreGroupDescription: FitScoreGroupDescription.GREAT,
			fitIneligibleReason: FitIneligibleReason.NONE,
		},
		{
			fitType: FitType.MAJORS,
			subScore: 0,
			weight: 5,
			fitScoreGroupId: 4,
			fitScoreGroupDescription: FitScoreGroupDescription.POOR,
			fitIneligibleReason: FitIneligibleReason.INSUFFICIENT_STUDENT_INFORMATION,
		},
	],
};

// Primarily used in tests. Shouldn't be used in real code
export const MODIFIED_FIT_DATA = {
	...DEFUALT_FIT_DATA,
	fitResultDetails: [
		{
			...DEFUALT_FIT_DATA.fitResultDetails[0],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[1],
			match: MatchType.MATCH,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[2],
			match: MatchType.MATCH,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[3],
			match: MatchType.NO_MATCH,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[4],
			match: MatchType.MATCH,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[5],
			match: MatchType.NO_MATCH,
		},
	],
};

export const EMPTY_FIT_DATA = {
	...DEFUALT_FIT_DATA,
	fitScore: 0,
	fitScoreGroupId: null,
	fitScoreGroupDescription: FitScoreGroupDescription.POOR,
	fitIneligibleReason: FitIneligibleReason.INSUFFICIENT_STUDENT_INFORMATION,
	fitResultDetails: [
		{
			...DEFUALT_FIT_DATA.fitResultDetails[0],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[1],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[2],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[3],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[4],
			match: MatchType.NO_DATA,
		},
		{
			...DEFUALT_FIT_DATA.fitResultDetails[5],
			match: MatchType.NO_DATA,
		},
	],
};
