import React from 'react';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import { styled } from '@cappex/theme';

interface PreferenceChipProps extends ChipProps {
	isSelected?: boolean;
	shouldFitContent?: boolean;
}

const PreferenceChip = styled(
	({ isSelected, shouldFitContent, ...otherProps }: PreferenceChipProps) => <Chip {...otherProps} />
)`
	height: unset;
	padding: 6px 0;
	width: ${props => (props.shouldFitContent ? 'auto' : '100%')};
	font-size: 0.875rem;

	background-color: ${props =>
		props.isSelected ? props.theme.palette.primary.transparent : props.theme.palette.common.white};
	border: 1px solid
		${props =>
			props.isSelected ? props.theme.palette.primary.main : props.theme.palette.ink.transparent};
	color: ${props => props.theme.palette.text.primary};

	& span {
		white-space: normal;
	}
	&:focus {
		border: 1px solid ${props => props.theme.palette.ink.dark};
		background-color: ${props =>
			props.isSelected ? props.theme.palette.primary.transparent : props.theme.palette.base.dark};
	}
	&:hover {
		background-color: ${props => props.theme.palette.primary.transparent};
	}
	@media (hover: none) {
		&:hover {
			background-color: ${props =>
				props.isSelected
					? props.theme.palette.primary.transparent
					: props.theme.palette.common.white};
		}
	}
`;

export default PreferenceChip;
