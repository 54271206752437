import { useContext } from 'react';
import StudentContext, { StudentView } from '..';

export enum ViewDependentLinkType {
	COLLEGE_PROFILE,
}

const typeViewLinkLookup: {
	[t in ViewDependentLinkType]: { [v in StudentView | 'default']: string };
} = {
	[ViewDependentLinkType.COLLEGE_PROFILE]: {
		[StudentView.UNDERGRADUATE]: 'college-profile',
		[StudentView.ADULT_COMPLETER]: 'college-profile',
		[StudentView.GRADUATE]: 'graduate-college-profile',
		default: 'college-profile',
	},
};

const useViewDependentLink = (type: ViewDependentLinkType) => {
	const { view } = useContext(StudentContext);

	const viewLookupForType = typeViewLinkLookup[type];

	return viewLookupForType[view] ?? viewLookupForType.default;
};

export default useViewDependentLink;
