import React, { FC } from 'react';
import { Grid, Button } from '@material-ui/core';
import { styled } from '@cappex/theme';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { AutomationNameDefault } from '@common/util/automation';

interface RegisterButtonProps {
	submitDisabled?: boolean;
	md?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	automationName?: string;
	onClick?: () => void;
	size?: 'small' | 'medium' | 'large';
	smallGutter?: boolean;
}

const StyledButton = withStyleOptions(Button);

const ButtonSpacing = styled(({ smallGutter, ...props }) => <div {...props} />)`
	margin-top: 1rem;
	margin-bottom: ${({ smallGutter }) => (smallGutter ? '1rem' : '3rem')};
`;

const RegisterButton: FC<RegisterButtonProps> = ({
	submitDisabled = false,
	children,
	md = 3,
	automationName = AutomationNameDefault.registerButton,
	onClick = () => true,
	size = 'medium',
	smallGutter = false,
}) => (
	<Grid item md={md}>
		<ButtonSpacing smallGutter={smallGutter}>
			<StyledButton
				$noneTextTransform
				$boldFontWeight
				type="submit"
				variant="contained"
				color="primary"
				// @ts-ignore -- typescript gets confused about this property apparently
				size={size}
				fullWidth
				disabled={submitDisabled}
				data-qa={automationName}
				onClick={onClick}
			>
				{children}
			</StyledButton>
		</ButtonSpacing>
	</Grid>
);

export default RegisterButton;
