import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	UrlParameter,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '../request';
import { useState, useCallback } from 'react';
import useFailedAuth from '../auth/hooks/useFailedAuth';
import { FormErrors } from '../validation/form';

interface RequestData<T> {
	endpoint: string;
	method: RequestMethod;
	params?: UrlParameter[];
	body?: Record<string, any>;
	onSuccess?: (data: T | undefined) => void;
	onError?: (error: Error) => void;
	onValidationError?: (errors: FormErrors) => void;
}

interface ExecuteOptions {
	body?: Record<string, any>;
	params?: UrlParameter[];
}

const useRequest = <T>({
	endpoint,
	method,
	body,
	params,
	onSuccess,
	onError,
	onValidationError,
}: RequestData<T>): [
	(options?: ExecuteOptions) => Promise<void | T | null>,
	boolean,
	T | undefined
] => {
	const [loading, setLoading] = useState(false);
	const [response, setResponse] = useState<T>();
	const failAuth = useFailedAuth();
	const execute = useCallback(
		async (options?: ExecuteOptions) => {
			setLoading(true);
			try {
				const { data } = await request<WebResponse<FormKeyedData, T>>({
					url: getEndpoint(endpoint, options?.params || params),
					method,
					data: options?.body || body,
					headers: [JsonAcceptHeader, JsonContentTypeHeader],
					withCredentials: true,
				});

				if (data?.meta?.success) {
					setResponse(data.response);
					onSuccess?.(data.response);
				} else if (data?.meta?.validationMessages) {
					onValidationError?.(data.meta.validationMessages);
				} else {
					onError?.(new Error(data.meta.error));
				}

				setLoading(false);
				return data.response;
			} catch (err) {
				console.error(err);
				setLoading(false);
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					return failAuth();
				}
				onError?.(err);
				return null;
			}
		},
		[endpoint, method, body, params, failAuth, onSuccess, onError, onValidationError]
	);

	return [execute, loading, response];
};

export default useRequest;
