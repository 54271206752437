import { includedInStepCount, name } from '@src/common/util/steps/constants';
import illustration from '@common/assets/desktop-main-reg-illustration.svg';
import { DataFlowConfig } from '../../dataflow/constants/types';
import BoldableTypography, {
	BoldableTypographyEnum,
} from '@src/common/components/BoldableTypography';
import { TextLinkEnum } from '@src/common/components/LegalTextAndLinks';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { DataFlowStepName } from '@cappex/constants';

const defaultConfiguration: DataFlowConfig = {
	key: 'registration__cbo_general',
	canStartMidway: false,
	steps: [
		{
			key: DataFlowStepName.CreateAccountName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: false,
				[name]: 'create',
				leftMedia: {
					media: illustration,
					text: 'Find colleges and scholarships that are right for you',
					textVariant: 'h6',
				},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.StudentPersonalInfoName,
			sortOrder: 1,
			data: {
				[includedInStepCount]: true,
				[name]: 'personal-info',
				leftMedia: {
					media: illustration,
					text: 'Find colleges and scholarships that are right for you',
					textVariant: 'h6',
				},
				rightMedia: {
					text: 'Welcome! Tell us a bit about yourself.',
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.CboGeneralCboSearchName,
			sortOrder: 2,
			data: {
				[includedInStepCount]: true,
				[name]: 'cbo-search',
				leftMedia: {
					media: illustration,
					text: 'Find colleges and scholarships that are right for you',
					textVariant: 'h6',
				},
				rightMedia: {
					text: 'Add your college readiness program, mentoring program, or charter school.',
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.StudentCollegeInfoName,
			sortOrder: 3,
			data: {
				[includedInStepCount]: true,
				[name]: 'college',
				leftMedia: {
					media: illustration,
					text: 'Find colleges and scholarships that are right for you',
					textVariant: 'h6',
				},
				rightMedia: {
					text: "This will help us figure out where you're at on your college journey.",
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.StudentMatchingInfoName,
			sortOrder: 4,
			data: {
				[includedInStepCount]: true,
				[name]: 'matching',
				leftMedia: {
					media: illustration,
					text: 'Find colleges and scholarships that are right for you',
					textVariant: 'h6',
				},
				rightMedia: {
					text:
						"We'll give you custom recommendations based on where you live and your chances to get in.",
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.ScholarshipParentData,
			sortOrder: 5,
			data: {
				[includedInStepCount]: false,
				[name]: 'parent',
				leftMedia: {
					media: illustration,
				},
				rightMedia: {
					headerVariant: 'h5',
					header: "Welcome to Appily! We're here to help you find colleges and pay for school.",
					textComponent: BoldableTypography,
					textProps: {
						variant: 'body1',
						config: [
							{
								type: BoldableTypographyEnum.TEXT,
								text: 'We matched you to the ',
							},
							{
								type: BoldableTypographyEnum.BOLD_TEXT,
								boldText: '$1,000 My Biggest Fan Scholarship.',
							},
							{
								type: BoldableTypographyEnum.TEXT,
								text: " Just enter your parent's information to apply!",
							},
						],
					},
				},
				isAllowedUnauthentic: false,
				showRightTextMedia: true,
				targetWhenUnauthentic: '/login',
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text:
							'No purchase necessary to enter or win. US residents enrolling in qualified post secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.Finalize,
			sortOrder: 6,
			data: {
				[includedInStepCount]: false,
				[name]: 'finalize',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Consent,
			sortOrder: 7,
			data: {
				[includedInStepCount]: false,
				[name]: 'consent',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 8,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				pseudo: true,
				// @ts-ignore
				to: { pathname: '/dashboard', search: '?path=default' },
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
	],
};

export default defaultConfiguration;
