import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { AutomationNameGeneric } from '@common/util/automation';
import BaseValidationForm from '@common/components/BaseValidationForm';

interface FormContainerProps {
	name: string;
	id: string;
	onSubmit?: () => void;
	md?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	lg?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	xl?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	automationNameForm?: string;
}

const FormContainer: FC<FormContainerProps> = ({
	name,
	id,
	onSubmit,
	children,
	md = 10,
	lg = 9,
	xl = 8,
	automationNameForm = AutomationNameGeneric.form,
}) => (
	<Grid container spacing={0} justifyContent="flex-start" alignItems="flex-start">
		<Grid item xs={12} sm={12} md={md} lg={lg} xl={xl}>
			<BaseValidationForm name={name} id={id} onValid={onSubmit} data-qa={automationNameForm}>
				<Grid container direction="column" spacing={2}>
					{children}
				</Grid>
			</BaseValidationForm>
		</Grid>
	</Grid>
);

export default FormContainer;
