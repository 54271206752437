import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';
import { LEVEL_OF_DEGREE_SEEKING_ID_HEAP_LABEL } from '../constants/heap';

type LevelOfDegreeSeekingSelectProps = Omit<Partial<BaseFormSelectProps>, 'references'>;
const LevelOfDegreeSeekingSelect: FC<LevelOfDegreeSeekingSelectProps> = ({
	id = AutomationNameDefault.levelOfDegreeSeekingInput,
	name = FormNames.levelOfDegreeSeekingId,
	label = 'Level of Degree Seeking',
	initialValue,
	required,
	automationName = AutomationNameGeneric.formSelect,
	variant,
}) => {
	const [levelofDegrees] = useCloudReferenceData({
		dataType: ReferenceDataTypes.levelOfDegreeSeeking,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			references={levelofDegrees}
			initialValue={initialValue}
			required={required}
			automationName={automationName}
			addEmptyValue
			variant={variant}
			onChange={val => {
				window?.heap?.addUserProperties?.({ [LEVEL_OF_DEGREE_SEEKING_ID_HEAP_LABEL]: val });
			}}
		/>
	);
};

export default LevelOfDegreeSeekingSelect;
