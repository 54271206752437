import React, { createContext, Dispatch, FC, Reducer, useMemo, useReducer } from 'react';
import * as R from 'ramda';

export type Result = {
	image: string;
	header: string;
	body?: string;
	weight?: number;
	redirectUrl?: string;
};
export type QuizResultSet = {
	[key: string]: Result;
};

export type QuizValue = {
	key: string;
	weight: number;
};

export enum QuizEventType {
	SEND,
	REVOKE,
	NEW_RESULTS,
}

export type QuizEvent = {
	type: QuizEventType;
	values?: QuizValue[];
	resultSet?: QuizResultSet;
};

type QuizContextContents = {
	results: QuizResultSet;
	getCurrentResult: () => Result;
	dispatch: Dispatch<QuizEvent>;
};

export const QuizContext = createContext<QuizContextContents>({
	results: {},
	getCurrentResult: () => ({
		image: '',
		header: 'User should never see this.',
	}),
	dispatch: () => {
		throw new Error('Using Default Context');
	},
});

type ResultMapper = (val: QuizResultSet) => QuizResultSet;
const getFunc = (combiner: typeof R.add) => ({ key, weight }: QuizValue) => {
	const lens = R.lensPath([key, 'weight']);

	return R.pipe(R.over(lens, R.defaultTo(0)), R.over(lens, combiner(weight))) as ResultMapper;
};

const compileFunctionsAndRun = (
	quizValues: QuizValue[],
	weightCombiner: typeof R.add,
	resultSet: QuizResultSet,
): QuizResultSet => {
	if (R.isNil(quizValues) || quizValues.length === 0) {
		return resultSet; // short-circuit
	}

	const funcs = quizValues.map(getFunc(weightCombiner));
	// @ts-ignore -- Typescript can't infer that this will take a QuizResultSet and return a QuizResultSet
	const fullpipe = R.pipe(...funcs) as ResultMapper;

	return fullpipe(resultSet);
};

const reducer: Reducer<QuizResultSet, QuizEvent> = (prevState, action) => {
	let fn;
	switch (action.type) {
		case QuizEventType.SEND:
			// @ts-ignore
			fn = R.add;
			break;
		case QuizEventType.REVOKE:
			// @ts-ignore
			fn = R.subtract;
			break;
		case QuizEventType.NEW_RESULTS:
			return action.resultSet;
		default:
			throw new Error('BAD MESSAGE');
	}
	const final = compileFunctionsAndRun(action.values, fn, prevState);
	return final;
};

type ContextProps = {
	results: QuizResultSet;
};
const getResult = (resultSet: QuizResultSet) =>
	resultSet ?
		R.pipe(
			R.mapObjIndexed(R.propOr(0, 'weight')),
			R.toPairs,
			// Sort by descending weight, then by ascending key
			R.sortWith([R.descend(R.nth(1)), R.ascend(R.nth(0))]),
			R.nth(0),
			R.nth(0),
			R.prop(R.__, resultSet),
		)(resultSet as Record<string, Record<'weight', number>>) : undefined; // All results here should have weights
;

const QuizContextProvider: FC<ContextProps> = ({ children, results }) => {
	const [state, dispatch] = useReducer(reducer, results);

	const constantValue = useMemo(
		() => ({ results: state, getCurrentResult: () => getResult(state), dispatch }),
		[state],
	);

	return <QuizContext.Provider value={constantValue}>{children}</QuizContext.Provider>;
};
export default QuizContextProvider;
