import React, {
	createContext,
	FC,
	FormEvent,
	FormHTMLAttributes,
	useContext,
	useMemo,
} from 'react';
import * as R from 'ramda';
import { FormContext, FormContextValue, FormFields, ValidationState } from '@util/validation/form';
import { FormNames } from '@cappex/constants';

type FormErrors = Partial<{ [key in FormNames]: string }>;

export interface BaseFormProps extends FormHTMLAttributes<HTMLFormElement> {
	onValid: () => void;
	formValidation?: (fields: FormFields) => FormErrors;
}

export const createOnSubmit = (
	formState: ValidationState,
	setFormErrors: FormContextValue['setFormErrors'],
	onValid: () => void,
	getFormValues: () => Partial<FormFields>,
	formValidation?: (fields: FormFields) => FormErrors
) => (event: FormEvent<HTMLFormElement>) => {
	event.preventDefault();

	let validationErrors = R.map<ValidationState, FormFields>(validationField => {
		const verifiesValue = formState[validationField.verifies]
			? formState[validationField.verifies].value
			: {};

		const validationMessage = validationField.validator(validationField.value, verifiesValue);

		return [validationMessage];
	}, formState);

	if (formValidation) {
		const formErrors = formValidation(getFormValues());

		validationErrors = R.mergeWith(
			(individualError, formError) => (R.isEmpty(formError) ? individualError : formError),
			validationErrors,
			formErrors
		);
	}

	// will break validationErrors into values, then run it through the conditions, short-circuiting if it finds something that fails

	const checkValidation = R.pipe(
		R.values,
		R.all(R.anyPass([R.isEmpty, R.pipe(R.head, R.isEmpty)]))
	);

	const isValid = checkValidation(validationErrors);

	setFormErrors(validationErrors);

	if (isValid) {
		onValid();
	}
};

const BaseValidationForm: FC<BaseFormProps> = ({
	children,
	onValid,
	formValidation,
	className,
	id,
	name,
}) => {
	const { formState, getFormValues, setFormErrors } = useContext(FormContext);

	const onFormSubmit = createOnSubmit(
		formState,
		setFormErrors,
		onValid,
		getFormValues,
		formValidation
	);

	// method="post" is unnecessary for functionality, but it prevents a netsparker vulnerability issue
	return (
		<form
			onSubmit={onFormSubmit}
			className={className}
			id={id}
			name={name}
			noValidate
			method="post"
		>
			{children}
		</form>
	);
};

export default BaseValidationForm;

type SubFormContextType = {
	path: string[];
};
export const SubFormContext = createContext<SubFormContextType>({ path: [] });
export const ListSubForm: FC<{ name: string[]; distinct?: boolean }> = ({
	name,
	distinct,
	children,
}) => {
	const { path } = useContext(SubFormContext);

	const value = useMemo(() => ({ path: distinct ? name : [...path, ...name] }), [
		name,
		distinct,
		path,
	]);

	return <SubFormContext.Provider value={value}>{children}</SubFormContext.Provider>;
};

export const SubForm: FC<{ name: string; distinct?: boolean }> = ({ name, distinct, children }) => {
	const memoList = useMemo(() => [name], [name]);
	return (
		<ListSubForm name={memoList} distinct={distinct}>
			{children}
		</ListSubForm>
	);
};

const EMPTY_SUBFORM = { path: [] };
export const TopLevelForm: FC = ({ children }) => (
	<SubFormContext.Provider value={EMPTY_SUBFORM}>{children}</SubFormContext.Provider>
);
