import React, { FC, useEffect } from 'react'

export type ScriptProps = {
    id: string;
    type: string;
    src: string;
    async?: boolean;
    defer?: boolean;
    crossOrigin?: string;
}

const Script: FC<ScriptProps> = ({
    id,
    type,
    async,
    defer,
    crossOrigin,
    src
}) => {
    useEffect(() => {
        if (typeof window !== 'undefined') {
            const scr = document.createElement('script')
            scr.id = id
            scr.type = type;
            scr.async = async;
            scr.defer = defer;
            scr.crossOrigin = crossOrigin;
            scr.src = src

            document.body.appendChild(scr);
        }
    }, [id, type, async, defer, crossOrigin, src])
    return <></>
}

export default Script;