import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState, useMemo } from 'react';
import checkLockout from '@util/lockout';
import getEndpoint from '@util/request';
import { Grid, Typography, Button } from '@material-ui/core';
import { SubForm } from '@src/common/components/BaseValidationForm';
import UnweightedGpaInput from '@src/common/components/UnweightedGpaInput';

import AddressBlock from '@src/common/components/AddressBlock';
import HiddenInput from '@src/common/components/HiddenInput';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames } from '@cappex/constants';
import { AuthenticState } from '@src/common/util/auth';
import useAuthentication from '@src/common/util/auth/hooks/useAuthentication';
import useUserData from '@src/common/util/hooks/useUserData';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	RequestSourceIdentifier,
	checkForFormError,
	FORM_NAME,
	getFormErrors,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import { parseStudent, Student, StudentType, getStudentType } from '@util/student/studentDataUtil';
import { FormContext, FormFields } from '@util/validation/form';
import { USA_COUNTRY_ID } from '@src/common/constants/referenceData';
import { styled } from '@cappex/theme';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import PhoneNumberInput from '@src/common/components/PhoneNumberInput';
import { useNavigate } from 'react-router-dom';

const IN_US_PROMPT = 'In the US?';
const OUT_US_PROMPT = 'Outside the US?';
const EMPTY_FUNCTION = () => {};
const MarginBottomButton = styled(Button)`
	margin-bottom: 1rem;
`;

const defaultToUS = R.anyPass([
	R.propSatisfies(R.isNil, FormNames.countryId),
	R.propEq(FormNames.countryId, USA_COUNTRY_ID),
]);

const COLLEGE_STUDENT_TEXT_OVERRIDE =
	'Last step! Let us know a bit more about yourself so that we can give you custom recommendations.';

const StudentPersonalInfoPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		isAllowedUnauthentic,
		targetWhenUnauthentic,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getValue } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const isAuthentic = useAuthentication();
	const navigate = useNavigate();
	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);
	const [userData, setUserData] = useState<Partial<Student>>({});
	const [isUS, setUS] = useState(defaultToUS(userData));
	const [studentType, setStudentType] = useState(StudentType.HIGH_SCHOOL);

	useEffect(() => {
		const newStudentType =
			getStudentType((getValue(FormNames.studentTypeId) as FormFields)?.studentTypeId) ||
			userData.studentTypeId;
		if (newStudentType && newStudentType !== studentType) {
			setStudentType(newStudentType);
		}
	}, [studentType, getValue, userData]);

	useEffect(() => {
		setUS(defaultToUS(userData));
	}, [userData]);

	const [submitDisabled, setSubmitDisabled] = useState(false);

	// Get user info to pre-populate
	useEffect(() => {
		if (R.isNil(userInfo.roleId)) {
			return;
		}

		request<any>({
			url: getEndpoint(`/student/v2/retrieve`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data.response)
			.then(data => {
				setUserData(parseStudent(data));
			});
	}, [userInfo]);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	// The flow expects this page to only be hit
	if (
		!isAllowedUnauthentic &&
		isAuthentic !== AuthenticState.Authentic &&
		isAuthentic !== AuthenticState.Unknown &&
		active
	) {
		navigate(targetWhenUnauthentic);
	}

	const rightMediaReal = useMemo(
		() => ({
			...rightMedia,
			text: studentType === StudentType.COLLEGE ? COLLEGE_STUDENT_TEXT_OVERRIDE : rightMedia.text,
		}),
		[studentType, rightMedia]
	);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMediaReal}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<SubForm name="student">
					{studentType === StudentType.HIGH_SCHOOL && (
						<SubForm name="studentHighSchoolDataForm">
							<Grid item>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h6">GPA</Typography>
									</Grid>
									<Grid item xs={12}>
										<UnweightedGpaInput
											name={FormNames.gpaUnweighted}
											initialValue={userData.gpaUnweighted}
											fullWidth
											required={active}
										/>
									</Grid>
								</Grid>
							</Grid>
						</SubForm>
					)}
					{studentType === StudentType.COLLEGE && (
						<SubForm name="studentCollegeDataForm">
							<Grid item>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										<Typography variant="h6">College GPA</Typography>
									</Grid>
									<Grid item xs={12}>
										<UnweightedGpaInput
											name={FormNames.collegeGpa}
											label="College GPA"
											initialValue={userData.collegeGpa}
											fullWidth
										/>
									</Grid>
								</Grid>
							</Grid>
						</SubForm>
					)}
					<Grid item>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<Typography variant="h6">Contact</Typography>
							</Grid>
							<SubForm name="studentAddressForm">
								{isUS ? (
									<>
										<HiddenInput name={FormNames.countryId} initialValue={USA_COUNTRY_ID} />
										<AddressBlock
											address1Label="Street Address"
											required={active}
											hideAddress2Input
											hideCityInput
											hideStateInput
											hideCountryInput
											student={userData}
										/>
									</>
								) : (
									<AddressBlock
										required={active}
										hideAddress1Input
										hideAddress2Input
										hideCityInput
										hideStateInput
										hideZipCodeInput
										student={userData}
										defaultCountry=""
										filterCountryIds={[USA_COUNTRY_ID]}
									/>
								)}
							</SubForm>
							<Grid item xs={12}>
								<MarginBottomButton
									color="primary"
									onClick={() => setUS(!isUS)}
									data-qa="location-toggle"
								>
									{isUS ? OUT_US_PROMPT : IN_US_PROMPT}
								</MarginBottomButton>
							</Grid>
							<SubForm name="studentDataForm">
								<Grid item xs={12}>
									<PhoneNumberInput />
								</Grid>
							</SubForm>
						</Grid>
					</Grid>
					<Grid item>
						<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
							{currentStep === totalSteps ? 'Finish' : 'Next'}
						</RegisterButton>
					</Grid>
				</SubForm>
			</Grid>
		</DataFlowContainer>
	);
};

export default StudentPersonalInfoPage;
