import React, { FC } from 'react';
import useAuthRefresh from '../hooks/useAuthRefresh';

interface AuthRefreshProps {
	onAuthFail?: () => void;
}

/**
 * Render nothing, simply force an auth refresh.
 */
const AuthRefresh: FC<AuthRefreshProps> = ({ onAuthFail }) => {
	useAuthRefresh()(false, onAuthFail);
	return <></>;
};

export default AuthRefresh;
