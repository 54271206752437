import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import { CardMedia } from '@material-ui/core';
import { findContentValueText, findContentValueValue } from '@src/features/offer/util/offerUtil';
import { OfferCardMediaProps } from '@src/features/offer/components/OfferCardMedia';
import { AutomationNameDefault } from '@util/automation';
import Grid from '@material-ui/core/Grid';

type OfferCardModalLogoProps = Pick<OfferCardMediaProps, 'logoImageContent'>;

export const CardMediaLogoStyled = styled(CardMedia)`
	height: 3.5rem;
	background-size: contain;
	object-fit: contain;
	background-position: left;
` as typeof CardMedia;

const OfferCardModalLogo: FC<OfferCardModalLogoProps> = ({ logoImageContent }) => {
	return (
		logoImageContent && (
			<Grid container>
				<Grid item xs={6} sm={4}>
					<CardMediaLogoStyled
						data-qa={AutomationNameDefault.paOfferModalLogo}
						image={findContentValueValue(logoImageContent)}
						title={findContentValueText(logoImageContent)}
					/>
				</Grid>
			</Grid>
		)
	);
};

export default OfferCardModalLogo;
