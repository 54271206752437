import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';
import getEndpoint from '@util/request';
import * as R from 'ramda';

type GoogleSSOValidateMetaData = {
	emailAddress?: string;
};

const getGoogleEmail = async (credentials: string, onError?: (err: string) => void) => {
	try {
		const validateRes = await request<WebResponse<GoogleSSOValidateMetaData, string>>({
			url: getEndpoint('/auth/sso/google/v1/validate'),
			method: RequestMethod.POST,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
			data: { credentials },
		});

		if (R.isNil(validateRes) || !validateRes.ok || !validateRes.data.meta.success)
			return onError(
				validateRes.data.meta.validationMessages.emailAddress?.[0] ||
					validateRes.data.meta.error ||
					'An unknown error occurred'
			);

		return validateRes.data.response;
	} catch (err) {
		return onError('An unknown error occurred');
	}
};

export default getGoogleEmail;
