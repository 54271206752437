import { DataFlowStepName } from '@cappex/constants';
import { StepConfiguration } from '@src/common/util/steps';
import { includedInStepCount, name } from '@src/common/util/steps/constants';
import { DataFlowStep } from '../../dataflow/constants/types';

/**
 * NOTICE: will need a major update if/when we get back to this.
 */
const inviteConfig: StepConfiguration<DataFlowStep> = {
	key: 'registration__student',
	steps: [
		{
			key: DataFlowStepName.CreateAccountName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: false,
				[name]: 'create',
				leftMedia: {},
				rightMedia: {},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 3,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				// @ts-ignore
				to: '/',
				isAllowedUnauthentic: true,
			},
		},
	],
};

export default inviteConfig;
