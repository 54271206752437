import React, { FC } from 'react';
import { Button, Typography, Grid } from '@material-ui/core';
import { AutomationNameGeneric } from '../util/automation';
import CenteredContentContainer from './CenteredContentContainer';
import { styled } from '@cappex/theme';
import HorizontalLogo from './HorizontalLogo';
import { useNavigate } from 'react-router-dom';

interface Props {
	title?: string;
	text?: string;
	imageTop?: string;
	redirectLabel: string;
	to: string;
}

const HeaderImageGrid = styled(Grid)`
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		text-align: center;
		&& {
			padding-bottom: 8rem;
		}
	}
`;

const LinkExpired: FC<Props> = ({
	title = 'Link Expired',
	text = 'This link has expired',
	imageTop,
	redirectLabel,
	to,
}) => {
	const navigate = useNavigate();

	return (
		<CenteredContentContainer md={3} lg={3} xl={2}>
			<HeaderImageGrid item>
				{imageTop ? (
					<img src={imageTop} data-qa={AutomationNameGeneric.image} alt="" />
				) : (
					<HorizontalLogo data-qa={AutomationNameGeneric.image} />
				)}
			</HeaderImageGrid>
			<Grid item>
				<Typography variant="h4">{title}</Typography>
			</Grid>
			<Grid item>
				<Typography variant="body1">{text}</Typography>
			</Grid>
			<Grid item>
				<Button fullWidth color="primary" variant="contained" onClick={() => navigate(to)}>
					{redirectLabel}
				</Button>
			</Grid>
		</CenteredContentContainer>
	);
};

export default LinkExpired;
