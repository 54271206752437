import { createContext } from 'react';
import { CollegeRecommendationResult } from './constants';
import { WamcChanceGroupId } from '../wamc/constants';

export type RecommendedCollegesByWamcGroup = {
	[key in WamcChanceGroupId]: CollegeRecommendationResult[]
};

export type HadRecommendationsByWamcGroup = { [key in WamcChanceGroupId]: boolean };

export interface CollegeRecommendationContextValue {
	recommendedColleges: CollegeRecommendationResult[];
	recommendedCollegesByWamcGroup: RecommendedCollegesByWamcGroup;
	hadRecommendations: boolean;
	hadRecommendationsByWamcGroup: HadRecommendationsByWamcGroup;
	refreshCollegeRecommendations: () => void;
	clearRecommendation: (collegeId: number) => void;
	dismissRecommendation: (collegeId: number) => void;
}

const defaultFunction = () => {
	throw new Error('Using default value for college recommendations context');
};

const collegeRecommendationContext = createContext<CollegeRecommendationContextValue>({
	recommendedColleges: [],
	recommendedCollegesByWamcGroup: {} as RecommendedCollegesByWamcGroup,
	hadRecommendations: false,
	hadRecommendationsByWamcGroup: {} as HadRecommendationsByWamcGroup,
	refreshCollegeRecommendations: defaultFunction,
	clearRecommendation: defaultFunction,
	dismissRecommendation: defaultFunction,
});

export default collegeRecommendationContext;
