import { createContext } from 'react';
import {
	AcceptedOffer,
	EMPTY_ACCEPTED_OFFERS,
} from '@util/acceptedofferscontext/constants/constants';

interface AcceptedOffersContext {
	acceptedOffers: AcceptedOffer[];
	setAcceptedOffers: (acceptedOffers: AcceptedOffer[]) => void;
	refreshAcceptedOffers: () => void;
}

const defaultFunction = () => {
	throw new Error('Using default value for accepted offers context');
};

const AcceptedOffersContext = createContext<AcceptedOffersContext>({
	acceptedOffers: EMPTY_ACCEPTED_OFFERS,
	setAcceptedOffers: defaultFunction,
	refreshAcceptedOffers: defaultFunction,
});

export default AcceptedOffersContext;
