import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';
import getEndpoint from '@util/request';
import * as R from 'ramda';

export interface GoogleSSOConsentResponse {
	consentType: string;
	id: string;
	text: string;
}

const getGoogleConsentText = async (onError?: (err: string) => void) => {
	try {
		const consentRes = await request<WebResponse<{}, GoogleSSOConsentResponse>>({
			url: getEndpoint('/public/governance/consent/sso/google/v1'),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});

		if (R.isNil(consentRes) || !consentRes.ok || !consentRes.data.meta.success)
			return onError(consentRes.data.meta.error || 'An unknown error occurred');

		return consentRes.data.response;
	} catch (err) {
		return onError('An unknown error occurred');
	}
};

export default getGoogleConsentText;
