import * as R from 'ramda';

/**
 * Creates a new object with all the data of the passed object and also an 'update' field for all non-nil fields
 */
const addUpdateFields = <T>(fields: Partial<T>) => ({
	...fields,
	...R.pipe(
		R.filter(field => field !== undefined),
		R.keys,
		R.map(
			R.pipe(
				R.split(''),
				R.adjust(0, R.toUpper),
				R.prepend('update'),
				R.join('')
			)
		),
		keys => R.zipObj(keys, R.map(R.T, keys))
	)(fields),
});

export default addUpdateFields;
