import React, { FC, useState } from 'react';
import EmailInput from '@common/components/EmailInput';
import PasswordInputValidation from '@common/components/PasswordInputValidation';
import { Typography, FormGroup, TextFieldProps, Box } from '@material-ui/core';
import { StepComponentProps } from '@common/util/steps';
import * as R from 'ramda';
import { FormNames } from '@cappex/constants';
import { styled } from '@cappex/theme';

const OutlinedSpacingBox = styled(({ variant, ...props }) => <Box {...props} />)`
	${({ variant }) => (variant === 'outlined' ? 'margin-bottom: 0.5rem;' : '')}
`;

interface EmailPasswordFormProps extends StepComponentProps {
	label?: string;
	emailLabel?: string;
	defaultEmailHelper?: string;
	passwordLabel?: string;
	required?: boolean;
	active: boolean;
	variant?: TextFieldProps['variant'];

	email?: string;
	emailLocked?: boolean;
}

const EmailPasswordForm: FC<EmailPasswordFormProps> = ({
	email: initialEmail,
	emailLocked,
	label,
	emailLabel,
	defaultEmailHelper,
	passwordLabel,
	complete = () => {},
	required = true,
	active,
	variant,
}) => {
	const [email, setEmail] = useState<string>(initialEmail);
	const [password, setPassword] = useState<string>(undefined);

	const evaluateComplete = (emailVisited: boolean, passwordVisited: boolean): void => {
		if (emailVisited && passwordVisited) {
			complete();
		}
	};

	const emailChangeHandler: (e: string) => void = emailLocked
		? () => {}
		: (e: string) => {
				setEmail(e);
				evaluateComplete(true, !R.isNil(password));
		  };

	const passwordChangeHandler = (p: string) => {
		setPassword(p);
		evaluateComplete(!R.isNil(email), true);
	};

	return (
		<FormGroup>
			{label && (
				<OutlinedSpacingBox variant={variant}>
					<Typography align="left" variant="h6">
						{label}
					</Typography>
				</OutlinedSpacingBox>
			)}
			<OutlinedSpacingBox variant={variant}>
				<EmailInput
					name={FormNames.email}
					disabled={emailLocked || !active}
					onChange={emailChangeHandler}
					initialValue={initialEmail}
					label={emailLabel}
					helperText={defaultEmailHelper}
					required={required && active}
					noEmailValidation={!required}
					variant={variant}
					fullWidth
				/>
			</OutlinedSpacingBox>
			<OutlinedSpacingBox variant={variant}>
				<PasswordInputValidation
					disabled={emailLocked || !active}
					name={FormNames.password}
					onChange={passwordChangeHandler}
					label={passwordLabel}
					helperText=" "
					required={required && active}
					hideValidationList={!required}
					variant={variant}
				/>
			</OutlinedSpacingBox>
		</FormGroup>
	);
};

export default EmailPasswordForm;
