import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

let previousDataLayerValue;

const SeekingMbaDataLayer = (eventType: DataLayerEventTypes, seekingMba: string) => {
	const seekingMbaValue = seekingMba == null ? null : seekingMba === 'true';
	if (previousDataLayerValue !== seekingMbaValue) {
		previousDataLayerValue = seekingMbaValue;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: seekingMbaValue },
		});
	}
};

export default SeekingMbaDataLayer;
