import React, { useContext, FC, useMemo, useEffect } from 'react';
import * as R from 'ramda';
import { StepContainerProps } from '@src/common/util/steps';
import ConsentManager from '@src/features/consent/main';
import { FormContext } from '@src/common/util/validation/form';
import { ListFormNames } from '@cappex/constants';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { DataFlowStepComponent } from '../../constants/types';

const EMPTY_FUNCTION = () => {};
const EMPTY_ARRAY = [];
const CONSENT_STEP_KEY = 'consent-step-key';
const DEFAULT_FLAGS = { prepend: false, whiteBackdrop: false };

const ConsentStep: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	data: { flags: { prepend, whiteBackdrop } = DEFAULT_FLAGS },
}) => {
	const { getValue } = useContext(FormContext);
	const { queueModal } = useContext(ModalContext);

	const values = useMemo(() => {
		const studentColleges = R.prop('studentColleges')(
			getValue(ListFormNames.studentColleges) as { studentColleges: { collegeId: string }[] }
		);

		return R.pipe(
			R.map(R.prop('collegeId')),
			R.map(Number)
		)(studentColleges === undefined ? EMPTY_ARRAY : studentColleges);
	}, [getValue]);

	useEffect(() => {
		queueModal(
			prepend ? QueueAction.PREPEND : QueueAction.APPEND,
			CONSENT_STEP_KEY,
			ConsentManager,
			{
				collegeIdsToCheck: values,
				setCollegeIdsToCheck: EMPTY_FUNCTION,
				whiteBackdrop,
			}
		);
		complete();
	}, [complete, values, queueModal, prepend, whiteBackdrop]);

	return <LoadingComponent />;
};

export default ConsentStep;
