const getLocalTrueDate = (date: string | number) => {
	/**
	 * JS interprets new Date() as being given a UTC, and then adjusts it to your timezone.
	 * Adjust it to be a local date of the same time by adding offset.
	 */
	const offsetDate = new Date(date);
	const offset = offsetDate.getTimezoneOffset();

	offsetDate.setMinutes(offsetDate.getMinutes() + offset);
	
	return offsetDate;
}

export default getLocalTrueDate;