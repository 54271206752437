import React, { FC, useState, useContext, useMemo } from 'react';
import { FormGroup } from '@material-ui/core';
import PasswordInput from '@common/components/PasswordInput';
import { InputIcon } from '@cappex/components';
import ValidationList, { ValidationItem } from '@common/components/ValidationList';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons/faEyeSlash';
import { ThemeContext } from '@cappex/theme';
import {
	eightCharactersCriteria,
	lowerCaseCriteria,
	upperCaseCriteria,
	numberCriteria,
	specialCharacterCriteria,
	matchingPasswordCriteria,
	eightCharactersCriteriaMap,
	lowerCaseCriteriaMap,
	upperCaseCriteriaMap,
	numberCriteriaMap,
	specialCharacterCriteriaMap,
} from '@common/util/validation/password';
import { AutomationNameDefault } from '@common/util/automation';
import { BaseInputProps } from './BaseFormInput';
import { Criteria } from '../util/validation/constants';

type UsableBaseProps = Pick<
	Partial<BaseInputProps>,
	Exclude<keyof BaseInputProps, 'TrailingIcon' | 'inputType' | 'validCriteria' | 'automationName'>
>;

export interface PasswordInputProps extends UsableBaseProps {
	hideValidationList?: boolean;
	automationNameInput?: string;
	automationNameIcon?: string;
	automationNameList?: string;
}

const passwordConstraints = [
	eightCharactersCriteriaMap,
	lowerCaseCriteriaMap,
	upperCaseCriteriaMap,
	numberCriteriaMap,
	specialCharacterCriteriaMap,
];

export const evaluatePasswordCriteria = (password: string): ValidationItem[] => {
	const result: ValidationItem[] = [];

	passwordConstraints.forEach(constraint =>
		result.push({
			text: constraint.message,
			// Only run validator if there is a password to prevent validation list from showing an empty password as valid
			isValid: password ? constraint.validator(password) : false,
		})
	);

	return result;
};

const PasswordInputValidation: FC<PasswordInputProps> = ({
	id,
	name,
	label,
	disabled,
	onChange = () => {},
	onBlur,
	required,
	verifiedBy,
	verifies,
	fullWidth,
	helperText,
	LeadingIcon,
	hideValidationList = false,
	automationNameInput = AutomationNameDefault.passwordInput,
	automationNameIcon = AutomationNameDefault.passwordVisibilityIcon,
	automationNameList = AutomationNameDefault.validationList,
	variant,
}) => {
	const theme = useContext(ThemeContext);
	const passwordCriteriaForInput: Criteria[] = useMemo(() => {
		const criteria = hideValidationList
			? []
			: [
					eightCharactersCriteria,
					lowerCaseCriteria,
					upperCaseCriteria,
					numberCriteria,
					specialCharacterCriteria,
			  ];
		if (verifies) {
			criteria.push(matchingPasswordCriteria);
		}
		return criteria;
	}, [hideValidationList, verifies]);

	const [isPasswordVisible, setIsPasswordVisible] = useState(false);

	const [validationList, setValidationList] = useState(evaluatePasswordCriteria(''));

	const onInputChange = (inputValue: string) => {
		setValidationList(evaluatePasswordCriteria(inputValue));

		onChange(inputValue);
	};

	const onIconClick = () => {
		setIsPasswordVisible(!isPasswordVisible);
	};

	return (
		<>
			<FormGroup>
				<PasswordInput
					id={id}
					name={name}
					label={label}
					inputType={isPasswordVisible ? 'text' : 'password'}
					disabled={disabled}
					onChange={onInputChange}
					onBlur={onBlur}
					validCriteria={passwordCriteriaForInput}
					required={required}
					verifiedBy={verifiedBy}
					verifies={verifies}
					fullWidth={fullWidth}
					helperText={helperText}
					LeadingIcon={LeadingIcon}
					automationName={automationNameInput}
					variant={variant}
					TrailingIcon={
						<InputIcon
							id={automationNameIcon}
							onClick={onIconClick}
							icon={isPasswordVisible ? faEyeSlash : faEye}
							positioning="end"
							color={theme.palette.text.secondary}
							automationName={automationNameIcon}
						/>
					}
				/>
				{!hideValidationList && (
					<ValidationList
						disableGutters
						items={validationList}
						invalidIcon={faCircle}
						validIcon={faCheckCircle}
						invalidColor={theme.palette.text.secondary}
						validColor={theme.palette.clover.main}
						automationName={automationNameList}
					/>
				)}
			</FormGroup>
		</>
	);
};

export default PasswordInputValidation;
