import React, { FC, useState } from 'react';
import { styled } from '@cappex/theme';
import { Dialog, makeStyles, Paper } from '@material-ui/core';
import { AutomationNameDefault } from '@src/common/util/automation';
import { CampaignOffer, Placement } from '@src/features/offer/constants/constants';
import { StepComponentProps } from '@src/common/util/steps';
import OfferInterstitial from './OfferInterstitial';

type OfferModalProps = {
	placement: Placement;
	campaignOffers: CampaignOffer[];
};

const DialogPaper = styled(Paper)`
	overflow-x: hidden;
`;

const useStyles = makeStyles({ root: { width: '100%' } });

const OfferInterstitialModal: FC<StepComponentProps & OfferModalProps> = ({
	complete,
	placement,
	campaignOffers,
}) => {
	const classes = useStyles();
	const [open, setOpen] = useState(true);
	const [offers, setOffers] = useState<CampaignOffer[]>(campaignOffers);

	const onOfferAccepted = (campaignOfferId: number) => {
		setOffers(offers.filter((offer: CampaignOffer) => offer.id !== campaignOfferId));
	};

	const dismissModal = () => {
		setOpen(false);
		complete();
	};
	return (
		<Dialog
			PaperComponent={DialogPaper}
			classes={{ root: classes.root }}
			open={open}
			onClose={() => complete()}
			fullScreen
			fullWidth
			scroll="paper"
			data-qa={AutomationNameDefault.paOfferInterstitialModal}
		>
			<OfferInterstitial
				placement={placement}
				campaignOffers={offers}
				onOfferAccepted={onOfferAccepted}
				onContinueButtonClicked={dismissModal}
			/>
		</Dialog>
	);
};

export default OfferInterstitialModal;
