import { validNumericRangeCriteria } from '@util/validation/numericrange';

const ACT_MIN = 1;
const ACT_MAX = 36;

const ACT_WRITING_MIN = 2;
const ACT_WRITING_MAX = 12;

const PRE_ACT_MIN = 1;
const PRE_ACT_MAX = 35;

const validActCriteria = (value: string): string => {
	return validNumericRangeCriteria(value, ACT_MIN, ACT_MAX);
};

const validActWritingCriteria = (value: string): string => {
	return validNumericRangeCriteria(value, ACT_WRITING_MIN, ACT_WRITING_MAX);
};

const validPreActCriteria = (value: string): string => {
	return validNumericRangeCriteria(value, PRE_ACT_MIN, PRE_ACT_MAX);
};

export {
	ACT_MIN,
	ACT_MAX,
	ACT_WRITING_MIN,
	ACT_WRITING_MAX,
	PRE_ACT_MIN,
	PRE_ACT_MAX,
	// validation criteria - to be used in components.
	validActCriteria,
	validActWritingCriteria,
	validPreActCriteria,
};
