export enum JitEvent {
	COLLEGE_PROFILE_VIEW = 'COLLEGE_PROFILE_VIEW',
	COLLEGE_INQUIRY = 'COLLEGE_INQUIRY',
	MAGIC_LOGIN_MARKETING_REENGAGEMENT = 'MAGIC_LOGIN_MARKETING_REENGAGEMENT',
	REGISTRATION_COMPLETION = 'REGISTRATION_COMPLETION',
}

export interface CollegePageViewEventPayload {
	eventType: JitEvent.COLLEGE_PROFILE_VIEW;
	collegeIds: string[];
}

export interface CollegeInquiryEventPayload {
	eventType: JitEvent.COLLEGE_INQUIRY;
	collegeIds: string[];
}

export interface RegistrationCompletionEventPayload {
	eventType: JitEvent.REGISTRATION_COMPLETION;
	dataFlowId: string;
}

export interface MagicLoginMarketingReengagementEventPayload {
	eventType: JitEvent.MAGIC_LOGIN_MARKETING_REENGAGEMENT;
	marketingEmailCampaignId: string;
}

export type JitEventPayload =
	| CollegePageViewEventPayload
	| CollegeInquiryEventPayload
	| RegistrationCompletionEventPayload
	| MagicLoginMarketingReengagementEventPayload;
