import React, { FC, useState, useRef } from 'react';
import { Snackbar } from '@material-ui/core';
import { ErrorMessages } from '@cappex/request';
import { styled } from '@cappex/theme';

interface SnackbarContext {
	openSnackbar: (props: Partial<SnackbarProps>) => void;
}

export enum SnackbarType {
	Error,
	Success,
}

export enum AnchorVertical {
	Bottom = 'bottom',
	Top = 'top',
}

export enum AnchorHorizontal {
	Left = 'left',
	Right = 'right',
	Center = 'center',
}

export type SnackbarProps = {
	snackbarType: SnackbarType;
	message: string;
	anchorOrigin: {
		vertical: AnchorVertical;
		horizontal: AnchorHorizontal;
	};
	autoHideDuration: number;
	id: string;
};

const contextDefault: SnackbarProps = {
	snackbarType: SnackbarType.Error,
	message: ErrorMessages.unknown,
	anchorOrigin: {
		vertical: AnchorVertical.Bottom,
		horizontal: AnchorHorizontal.Center,
	},
	autoHideDuration: 5000,
	id: 'message-id',
};

const DEFAULT_FUNCTION = () => {
	throw new Error('Using default context for SnackbarContext');
};

export const SnackbarContext = React.createContext<SnackbarContext>({
	openSnackbar: DEFAULT_FUNCTION,
});

const StyledSnackbar = styled(Snackbar)`
	& > div {
		background-color: ${({ theme: { palette } }) => palette.ink.dark};
		white-space: pre-wrap;
	}
`;

const SnackbarManager: FC = ({ children }) => {
	const [snackbarState, setSnackbarState] = useState<SnackbarProps>(contextDefault);
	const [isSnackOpen, setIsSnackOpen] = useState(false);

	const openSnackbar = (snackProps: Partial<SnackbarProps>) => {
		setSnackbarState({
			...contextDefault,
			...snackProps,
		});

		setIsSnackOpen(true);
	};

	const { current: value } = useRef({ openSnackbar });

	// TODO: differentiate between snackbar types when designs are available
	return (
		<>
			<SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>
			<StyledSnackbar
				anchorOrigin={snackbarState.anchorOrigin}
				open={isSnackOpen}
				onClose={() => setIsSnackOpen(false)}
				autoHideDuration={snackbarState.autoHideDuration}
				ContentProps={{
					'aria-describedby': snackbarState.id,
				}}
				message={<span id={snackbarState.id}>{snackbarState.message}</span>}
			/>
		</>
	);
};

export default SnackbarManager;
