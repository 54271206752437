import React, { FC } from 'react';
import BaseFormInput from '@src/common/components/BaseFormInput';
import { FormNames } from '@cappex/constants';
import { Criteria } from '@util/validation/constants';

export type OfferBaseFormInputType = {
	id?: string;
	name: string;
	automationName: string;
	label: string;
	required?: boolean;
	validCriteria?: Criteria[];
};

const OfferBaseFormInput: FC<OfferBaseFormInputType> = ({
	id,
	name,
	automationName,
	label,
	required = false,
	validCriteria,
}) => (
	<BaseFormInput
		id={id}
		name={name as FormNames}
		automationName={automationName}
		label={label}
		required={required}
		validCriteria={validCriteria}
		fullWidth
	/>
);

export default OfferBaseFormInput;
