import { ColorDefinition } from '@cappex/theme';
import {
	faBriefcase,
	faBullseyeArrow,
	faCalendarAlt,
	faChartLine,
	faFileInvoiceDollar,
	faHouseTree,
	faLaptop,
	faMountain,
	faPersonRunning,
	faSackDollar,
	faUserGroup,
	IconDefinition,
} from '@fortawesome/pro-light-svg-icons';
import { CollegeIndexFacets } from '@src/features/collegesearch/constants/constants';
import { SearchState } from 'react-instantsearch-core';
import transformCostLabel from '../../../common/util/search/transformCostLabel';
import transformDurationLabel from '../util/transformDurationLabel';

export enum GradSchoolIndexFacets {
	COST_FOR_PROGRAM = 'outOfStateTuition',
	LEVEL_OF_DEGREE = 'gradType',
	ONLINE_OFFERED = 'onlineStatus',
	PROGRAM_NAMES = 'gradProgram',
	TEST_OPTIONAL = 'testScoresOptional',
	MAJORS_FAMILY = 'majorFamily',
	ENROLLMENT = 'partTimeStatus',
	DURATION = 'duration',
	AREA_OF_STUDY = 'areasOfStudy',
}

export const transformFilterLabelMap = {
	[GradSchoolIndexFacets.DURATION]: transformDurationLabel,
	[GradSchoolIndexFacets.COST_FOR_PROGRAM]: transformCostLabel,
};

export const GradSchoolFilterDegreeFacets = [GradSchoolIndexFacets.LEVEL_OF_DEGREE];

export const gradSchoolFilterProgramFacets = [GradSchoolIndexFacets.PROGRAM_NAMES];

export const gradSchoolFilterOnline = [GradSchoolIndexFacets.ONLINE_OFFERED];

export interface GraduateProgramSearchHit {
	objectID: string;
	name: string;
	city: string;
	state: string;
	country: string;
	collegeLogoImage: string;
	collegeHeroImage: string;
	locationFormatted: string;
	id: string;
	__position: number;
	__queryID: string;
}

interface BrowseGraduateProgramsGuidanceItem {
	icon: IconDefinition;
	backgroundColor: ColorDefinition;
	header: string;
	route: string;
	buttonLabel: string;
	breadcrumbLabel: string;
	searchState: SearchState;
}

interface BrowseGraduateProgramsExploreItem {
	guidancePageRoute: string;
	icon: IconDefinition;
	backgroundColor: ColorDefinition;
	buttonLabel: string;
	header: string;
	route: string;
	breadcrumbLabel: string;
	searchState: SearchState;
}

interface BrowseGraduateProgramsExploreAOSItem {
	guidancePageRoute: string;
	icon: IconDefinition;
	backgroundColor: ColorDefinition;
	buttonLabel: string;
	header: string;
	route: string;
	breadcrumbLabel: string;
	searchState: SearchState;
}

interface BrowseGraduateProgramsConfig {
	guidancePages: BrowseGraduateProgramsGuidanceItem[];
	explorePages: BrowseGraduateProgramsExploreItem[];
	exploreAOSPages: BrowseGraduateProgramsExploreAOSItem[];
}

export const BROWSE_GRADUATE_PROGRAMS_CONFIG: BrowseGraduateProgramsConfig = {
	guidancePages: [
		{
			icon: faBriefcase,
			backgroundColor: { color: 'surf', key: 'transparent' },
			buttonLabel: 'Explore Business \n Programs and MBAs ›',
			route: '/graduate-schools-search/business-programs',
			header: 'Business Programs and MBAs',
			breadcrumbLabel: 'Business Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.AREA_OF_STUDY]: ['52'],
				},
			},
		},
	],
	explorePages: [
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faLaptop,
			backgroundColor: { color: 'clover', key: 'transparent' },
			header: 'Online Business Programs',
			route: '/graduate-schools-search/business-programs/online',
			buttonLabel: 'Online Programs',
			breadcrumbLabel: 'Online',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.ONLINE_OFFERED]: ['Online'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faHouseTree,
			backgroundColor: { color: 'primary', key: 'transparent' },
			header: 'College Towns',
			route: '/graduate-schools-search/business-programs/college-towns',
			buttonLabel: 'College Towns',
			breadcrumbLabel: 'College Towns',
			searchState: {
				refinementList: {
					[CollegeIndexFacets.CAMPUS_SETTING]: ['Suburb or town'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faSackDollar,
			backgroundColor: { color: 'lotus', key: 'transparent' },
			header: 'Affordable MBAs',
			route: '/graduate-schools-search/business-programs/affordable-mbas',
			buttonLabel: 'Affordable MBAs',
			breadcrumbLabel: 'Affordable MBAs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: [
						'Business Administration, Management and Operations',
					],
				},
				range: {
					[GradSchoolIndexFacets.COST_FOR_PROGRAM]: { min: 0, max: 20000 },
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faCalendarAlt,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Part-time MBAs',
			route: '/graduate-schools-search/business-programs/part-time-mbas',
			buttonLabel: 'Part-Time MBAs',
			breadcrumbLabel: 'Part-Time MBAs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: [
						'Business Administration, Management and Operations',
					],
					[GradSchoolIndexFacets.ENROLLMENT]: ['Part-time'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faPersonRunning,
			backgroundColor: { color: 'warning', key: 'transparent' },
			header: 'Accelerated Business Programs',
			route: '/graduate-schools-search/business-programs/accelerated-programs',
			buttonLabel: 'Accelerated Programs',
			breadcrumbLabel: 'Accelerated Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.DURATION]: ['1.0', '1.5'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faBriefcase,
			backgroundColor: { color: 'coral', key: 'transparent' },
			header: 'Executive Business Programs',
			route: '/graduate-schools-search/business-programs/executive-programs',
			buttonLabel: 'Executive Programs',
			breadcrumbLabel: 'Executive Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.PROGRAM_NAMES]: ['Executive MBA'],
				},
			},
		},
	],
	exploreAOSPages: [
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faBriefcase,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Business Administration Programs',
			route: '/graduate-schools-search/business-programs/business-administration',
			buttonLabel: 'Business Administration',
			breadcrumbLabel: 'Business Administration Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: [
						'Business Administration, Management and Operations',
					],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faFileInvoiceDollar,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Accounting Programs',
			route: '/graduate-schools-search/business-programs/accounting',
			buttonLabel: 'Accounting',
			breadcrumbLabel: 'Accounting Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: ['Accounting and Related Services'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faMountain,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Management Sciences Programs',
			route: '/graduate-schools-search/business-programs/management-sciences',
			buttonLabel: 'Management Sciences',
			breadcrumbLabel: 'Management Sciences Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: ['Management Sciences and Quantitative Methods'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faUserGroup,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Human Resources Programs',
			route: '/graduate-schools-search/business-programs/human-resources',
			buttonLabel: 'Human Resources',
			breadcrumbLabel: 'Human Resources Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: ['Human Resources Management and Services'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faChartLine,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Finance Programs',
			route: '/graduate-schools-search/business-programs/finance',
			buttonLabel: 'Finance',
			breadcrumbLabel: 'Finance Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: ['Finance and Financial Management Services'],
				},
			},
		},
		{
			guidancePageRoute: '/graduate-schools-search/business-programs',
			icon: faBullseyeArrow,
			backgroundColor: { color: 'surf', key: 'transparent' },
			header: 'Marketing Programs',
			route: '/graduate-schools-search/business-programs/marketing',
			buttonLabel: 'Marketing',
			breadcrumbLabel: 'Marketing Programs',
			searchState: {
				refinementList: {
					[GradSchoolIndexFacets.MAJORS_FAMILY]: ['Marketing'],
				},
			},
		},
	],
};
