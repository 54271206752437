import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

const IdDegreeMapping = {
	'1': 'Associates',
	'2': 'Bachelors',
	'3': 'Masters',
	'4': 'Phd',
	'5': 'Trade School',
};

let previousDataLayerValue;

const LevelOfDegreeSeekingDataLayer = (eventType: DataLayerEventTypes, degreeId: string) => {
	if (previousDataLayerValue !== degreeId) {
		previousDataLayerValue = degreeId;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: IdDegreeMapping[degreeId] },
		});
	}
};

export default LevelOfDegreeSeekingDataLayer;
