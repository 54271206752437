import { useEffect, useState } from 'react';
import * as R from 'ramda';
import getEndpoint from '@util/request';
import request, {
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	UrlParameter,
} from '@cappex/request';
import { ReferenceDataDefaultValues } from '@common/constants/referenceData';
import { ReferenceDataTypes } from '@cappex/constants';
import useFailedAuth from '../auth/hooks/useFailedAuth';

export type ReferenceData = {
	id: string;
	value: string;
};

export const EMPTY_REFERENCE: ReferenceData = {
	id: '-1',
	value: '',
};

export interface MajorFamily {
	description: string;
	displayText: string;
}

const sortById = R.sort<ReferenceData>((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));
const sortByAlpha = R.sort<ReferenceData>(R.comparator((a, b) => a.value.toLowerCase() < b.value.toLowerCase()));

export const getReferenceDataByDefaultValue = (value: ReferenceDataDefaultValues) =>
	R.pipe(R.find(R.propEq('value', value)), R.prop<string, string>('id'));

const GET_DEFAULT_FUNCTION = () => '';

const useCloudReferenceData = ({
	dataType,
	getDefault = GET_DEFAULT_FUNCTION,
	emptyAllowed = false,
	enabled = true,
	alphaSort = false,
}: {
	dataType: ReferenceDataTypes;
	getDefault?: (lst: ReferenceData[]) => string;
	emptyAllowed?: boolean;
	enabled?: boolean;
	alphaSort?: boolean;
}): [ReferenceData[], string, (n: string) => void] => {
	const [references, setReferences] = useState([]);
	const [value, setValue] = useState('');

	const failAuth = useFailedAuth();

	useEffect(() => {
		const params: UrlParameter[] = R.reject(R.isNil, [
			{ name: 'dataType', value: dataType },
			enabled === null ? undefined : { name: 'enabled', value: enabled },
			{ name: 'offset', value: 0 },
		]);

		request<any>({
			url: getEndpoint('/reference-data/v1/get-reference-data', params),
			method: RequestMethod.GET,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			withCredentials: true,
		})
			.then(res => {
				const { data } = res;
				if (data === undefined) {
					return;
				}
				if (data.meta.success && data.response.length > 0) {
					const referenceResponse: ReferenceData[] = data.response[0];
					// Get Sorted References, add an empty reference if it's allowed; filter undefined if it's in the list
					const sortedReferences = [
						emptyAllowed ? EMPTY_REFERENCE : undefined,
						...(alphaSort ? sortByAlpha(referenceResponse) : sortById(referenceResponse)),
					].filter(e => e);
					setReferences(sortedReferences);
					setValue(getDefault(sortedReferences));
				}
			})
			.catch(err => {
				if (err.response) {
					const requestResponse = err.response;
					if (requestResponse.statusCode === 401 || requestResponse.statusCode === 403) {
						failAuth();
					}
				}
			});
	}, [dataType, emptyAllowed, failAuth, getDefault, enabled, alphaSort]);

	return [references, value, setValue];
};
export default useCloudReferenceData;
