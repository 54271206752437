import React, { FC } from 'react';
import { EllipsisTypography } from '@cappex/components';

export type OfferCardBaseTextProps = {
	text: string;
	automationName: string;
};

const OfferCardParagraph: FC<OfferCardBaseTextProps> = ({ text, automationName }) =>
	text && (
		<EllipsisTypography
			lineheight="1.5"
			paddingRight={false}
			variant="body2"
			color="textSecondary"
			lines={5}
			automationName={automationName}
		>
			{text}
		</EllipsisTypography>
	);

export default OfferCardParagraph;
