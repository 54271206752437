import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { CompleteCollege } from '@util/college/collegeDataUtil';
import useCloudCollegeData from '@util/hooks/useCloudCollegeData';
import { CompleteCollegeOptions } from '@cappex/constants';

const DATA_OPTIONS = [CompleteCollegeOptions.COLLEGE, CompleteCollegeOptions.COLLEGE_CONSENT];

// when using this NEED to useMemo on the collegeIds array
// notice only COLLEGE, COLLEGE_CONSENT data options are currently being used
const useCollegesWithConsentsData = (
	collegeIds: number[]
): [CompleteCollege[], Function, boolean] => {
	const [collegesWithConsents, setCollegesWithConsents] = useState([]);
	const [, , completeColleges] = useCloudCollegeData({
		collegeIds,
		dataOptions: DATA_OPTIONS,
	});
	const [hasLoaded, setHasLoaded] = useState(false);

	useEffect(() => {
		setCollegesWithConsents(completeColleges.filter(college => !R.isEmpty(college.consents)));
		setHasLoaded(completeColleges.length === collegeIds.length);
	}, [completeColleges, collegeIds.length]);

	return [collegesWithConsents, setCollegesWithConsents, hasLoaded];
};
export default useCollegesWithConsentsData;
