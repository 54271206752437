import { Criteria } from '@common/util/validation/constants';

const eightOrMoreRegex = new RegExp(/\S{8,}/);
const hasLengthEightOrMore = (str: string) => !str || eightOrMoreRegex.test(str);
const lowerCaseRegex = new RegExp(/[a-z]/);
const hasLowerCase = (str: string) => !str || lowerCaseRegex.test(str);
const upperCaseRegex = new RegExp(/[A-Z]/);
const hasUpperCase = (str: string) => !str || upperCaseRegex.test(str);
const numberRegex = new RegExp(/[0-9]/);
const hasNumber = (str: string) => !str || numberRegex.test(str);
const specialCharacterRegex = new RegExp(/[\W]/);
const hasSpecialCharacter = (str: string) => !str || specialCharacterRegex.test(str);

const invalidPasswordMessage = 'Not all requirements met';
const nonMatchingPasswordsMessage = 'Passwords do not match';
const eightCharactersMessage = 'Eight (8) or more characters';
const lowerCaseMessage = 'One lower case letter';
const upperCaseMessage = 'One capital letter';
const numberMessage = 'One number';
const specialCharacterMessage = 'One special character';

const eightCharactersCriteria: Criteria = (password: string) => {
	return hasLengthEightOrMore(password) ? '' : invalidPasswordMessage;
};
const lowerCaseCriteria: Criteria = (password: string) => {
	return hasLowerCase(password) ? '' : invalidPasswordMessage;
};
const upperCaseCriteria: Criteria = (password: string) => {
	return hasUpperCase(password) ? '' : invalidPasswordMessage;
};
const numberCriteria: Criteria = (password: string) => {
	return hasNumber(password) ? '' : invalidPasswordMessage;
};
const specialCharacterCriteria: Criteria = (password: string) => {
	return hasSpecialCharacter(password) ? '' : invalidPasswordMessage;
};
const matchingPasswordCriteria: Criteria = (password: string, otherPassword: string) => {
	return password === otherPassword ? '' : nonMatchingPasswordsMessage;
};

type CriteriaMap = {
	id: number;
	message: string;
	validator: (s: string) => boolean;
};

const eightCharactersCriteriaMap: CriteriaMap = {
	id: 1,
	message: eightCharactersMessage,
	validator: (s: string) => !eightCharactersCriteria(s),
};
const lowerCaseCriteriaMap: CriteriaMap = {
	id: 2,
	message: lowerCaseMessage,
	validator: (s: string) => !lowerCaseCriteria(s),
};
const upperCaseCriteriaMap: CriteriaMap = {
	id: 3,
	message: upperCaseMessage,
	validator: (s: string) => !upperCaseCriteria(s),
};
const numberCriteriaMap: CriteriaMap = {
	id: 4,
	message: numberMessage,
	validator: (s: string) => !numberCriteria(s),
};
const specialCharacterCriteriaMap: CriteriaMap = {
	id: 5,
	message: specialCharacterMessage,
	validator: (s: string) => !specialCharacterCriteria(s),
};

export {
	// validation failure message - exported primarily for testing
	invalidPasswordMessage,
	nonMatchingPasswordsMessage,
	eightCharactersMessage,
	lowerCaseMessage,
	upperCaseMessage,
	numberMessage,
	specialCharacterMessage,
	// validation criteria - to be used in components.
	eightCharactersCriteria,
	lowerCaseCriteria,
	upperCaseCriteria,
	numberCriteria,
	specialCharacterCriteria,
	matchingPasswordCriteria,
	// validation criteria map - for building the password list
	eightCharactersCriteriaMap,
	lowerCaseCriteriaMap,
	upperCaseCriteriaMap,
	numberCriteriaMap,
	specialCharacterCriteriaMap,
};
