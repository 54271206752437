import React, { FC } from 'react';
import * as R from 'ramda';
import { Typography, Grid, TextFieldProps } from '@material-ui/core';
import useCloudReferenceData from '@common/util/hooks/useCloudReferenceData';
import { AutomationNameDefault } from '@common/util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import { StepComponentProps } from '../util/steps';
import BaseFormSelect from './BaseFormSelect';
import { ReferenceDataDefaultValues } from '../constants/referenceData';

export interface StartTermProps extends Partial<StepComponentProps> {
	defaultValue?: {
		year?: string;
		season?: string;
	};
	label: string;
	required?: boolean;
	automationNameYear?: string;
	automationNameSeason?: string;
	variant?: TextFieldProps['variant'];
}
const startingYear = new Date().getFullYear();
const REFERENCE_YEARS = R.range(startingYear - 50, startingYear + 11).map(year => ({
	value: String(year),
	id: String(year),
}));

const StartTermInput: FC<StartTermProps> = ({
	defaultValue = {},
	label,
	required = false,
	automationNameYear = AutomationNameDefault.startTermYear,
	automationNameSeason = AutomationNameDefault.startTermSeason,
	variant = 'outlined',
}) => {
	const [seasons] = useCloudReferenceData({
		dataType: ReferenceDataTypes.season,
	});

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6" display="inline">
					{label}
				</Typography>
			</Grid>
			<Grid item xs={6}>
				<BaseFormSelect
					required={required}
					name={FormNames.collegeStartTermId}
					id="start-term-season"
					references={seasons}
					automationName={automationNameSeason}
					initialValue={defaultValue.season || ReferenceDataDefaultValues.season}
					variant={variant}
				/>
			</Grid>
			<Grid item xs={6}>
				<BaseFormSelect
					required={required}
					name={FormNames.collegeStartYear}
					id="start-term-year"
					references={REFERENCE_YEARS}
					automationName={automationNameYear}
					initialValue={defaultValue.year || R.toString(new Date().getFullYear() + 1)}
					variant={variant}
				/>
			</Grid>
		</Grid>
	);
};

export default StartTermInput;
