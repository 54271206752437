import React, { FC, useMemo } from 'react';
import BaseFormInput, { BaseInputProps } from './BaseFormInput';
import { Criteria } from '../util/validation/constants';
import inputLengthCriteria from '../util/validation/inputlength';

type MaxLengthInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const MaxLengthInput: FC<MaxLengthInputProps> = ({
	id,
	required,
	name,
	label,
	fullWidth,
	initialValue,
	automationName,
	beforeChange,
	inputMax = 50,
	variant,
}) => {
	const INPUT_LENGTH_CRITERIA: Criteria[] = useMemo(() => {
		return [inputLengthCriteria(inputMax)];
	}, [inputMax]);

	return (
		<BaseFormInput
			id={id}
			required={required}
			name={name}
			label={label}
			fullWidth={fullWidth}
			initialValue={initialValue}
			automationName={automationName}
			beforeChange={beforeChange}
			inputMax={inputMax}
			validCriteria={INPUT_LENGTH_CRITERIA}
			variant={variant}
		/>
	);
};

export default MaxLengthInput;
