import React, { FC } from 'react';
import { findContentValueText } from '@src/features/offer/util/offerUtil';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';
import OfferCardDisclaimer from './OfferCardDisclaimer';
import { AutomationNameDefault } from '@src/common/util/automation';

export const leadIdTcpaDisclosureId = 'leadid_tcpa_disclosure';

const OfferCardLeadIdTcpaDisclosureInput: FC<OfferCardBaseInputType> = ({
	content,
	automationName,
}) => (
		<div>
			<label htmlFor={leadIdTcpaDisclosureId}>
				<input
					type="hidden"
					id={leadIdTcpaDisclosureId}
					name={`${content.id}`}
					data-qa={automationName}
				/>
				<OfferCardDisclaimer
					automationName={AutomationNameDefault.paOfferModalDisclaimerText}
					text={findContentValueText(content)}
				/>
			</label>
		</div>
	);

export default OfferCardLeadIdTcpaDisclosureInput;
