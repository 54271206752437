import { Grid, Typography } from '@material-ui/core';
import { AutomationNameDefault } from '@src/common/util/automation';
import React, { FC } from 'react';
import { Achievement } from '../util/achievementUtil';
import AchievementContainer from './AchievementContainer';

type AchievementCategoryContainerProps = {
	categoryName: string;
	achievementList: Achievement[];
};

const AchievementCategoryContainer: FC<AchievementCategoryContainerProps> = ({
	categoryName,
	achievementList,
}) => (
	<Grid container spacing={3}>
		<Grid item>
			<Typography variant="h6" data-qa={AutomationNameDefault.achievementCategory}>
				{categoryName}
			</Typography>
		</Grid>
		<Grid item container direction="row" spacing={5}>
			{achievementList.map(achievement => (
				<Grid item key={achievement.title}>
					<AchievementContainer achievement={achievement} />
				</Grid>
			))}
		</Grid>
	</Grid>
);

export default AchievementCategoryContainer;
