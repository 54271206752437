import { FormNames, ListFormNames } from '@cappex/constants';
import { FormLabel, Grid, TextField, TextFieldProps, Typography } from '@material-ui/core';
import { DataFlowStepComponent } from '@src/features/dataflow/constants/types';
import React, { useContext, useMemo, useRef } from 'react';
import { AutomationNameDefault } from '../util/automation';
import { StepContainerProps } from '../util/steps';
import * as R from 'ramda';
import useFormValidation from '../util/hooks/useFormValidation';
import requiredFieldMessage from '../util/validation/constants';
import { FormContext, FormFields } from '../util/validation/form';
import { Parent } from '../constants/types';

type OwnProps = {
	parent1Label?: string;
	parent1FirstNameLabel?: string;
	parent1LastNameLabel?: string;
	parent1EmailLabel?: string;
	parent1Required?: boolean;
	parent2Label?: string;
	parent2FirstNameLabel?: string;
	parent2LastNameLabel?: string;
	parent2EmailLabel?: string;
	hideParent2?: boolean;
};

const path = ['student'];
const initialValue = { [ListFormNames.studentParentDataForms]: [] };

export const createValidator = (active: boolean, parent1Required: boolean, field: string) => (
	input: FormFields
) => (active && parent1Required && !input[`${field}[0]`] ? requiredFieldMessage : '');

const ParentInput: React.FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	TextFieldProps &
	OwnProps> = ({
	active,
	variant,
	parent1Label = 'Parent 1',
	parent1FirstNameLabel = "Parent's First Name",
	parent1LastNameLabel = "Parent's Last Name",
	parent1EmailLabel = "Parent's Email",
	parent1Required,
	parent2Label = 'Parent 2',
	parent2FirstNameLabel = "Parent's First Name",
	parent2LastNameLabel = "Parent's Last Name",
	parent2EmailLabel = "Parent's Email",
	hideParent2,
}) => {
	const parentFirstNameId = 'parentFirstNameId';
	const parentLastNameId = 'parentLastNameId';
	const parentEmailId = 'parentEmailId';
	const controlRef = useRef(null);
	const parent1FirstNameRef = useRef(null);
	const parent1LastNameRef = useRef(null);
	const parent1EmailRef = useRef(null);
	const parent2FirstNameRef = useRef(null);
	const parent2LastNameRef = useRef(null);
	const parent2EmailRef = useRef(null);

	const parent1FirstNameValidator = useMemo(
		() => createValidator(active, parent1Required, FormNames.parentFirstName),
		[active, parent1Required]
	);
	const parent1LastNameValidator = useMemo(
		() => createValidator(active, parent1Required, FormNames.parentLastName),
		[active, parent1Required]
	);
	const parent1EmailValidator = useMemo(
		() => createValidator(active, parent1Required, FormNames.parentEmail),
		[active, parent1Required]
	);

	// This hook is used to store the form value. Separate hooks are used for
	// validation errors since validating an array of objects is tricky with our
	// current setup
	const { value, setValue } = useFormValidation({
		path,
		name: ListFormNames.studentParentDataForms,
		initialValue,
		fieldRef: controlRef,
	});

	const { setFormValue } = useContext(FormContext);

	// The following hooks are used for validation errors
	const { error: parent1FirstNameError } = useFormValidation({
		name: `${FormNames.parentFirstName}[0]`,
		fieldRef: parent1FirstNameRef,
		validator: parent1FirstNameValidator,
	});
	const { error: parent1LastNameError } = useFormValidation({
		name: `${FormNames.parentLastName}[0]`,
		fieldRef: parent1LastNameRef,
		validator: parent1LastNameValidator,
	});
	const { error: parent1EmailError } = useFormValidation({
		name: `${FormNames.parentEmail}[0]`,
		fieldRef: parent1EmailRef,
		validator: parent1EmailValidator,
	});
	const { error: parent2FirstNameError } = useFormValidation({
		name: `${FormNames.parentFirstName}[1]`,
		fieldRef: parent2FirstNameRef,
	});
	const { error: parent2LastNameError } = useFormValidation({
		name: `${FormNames.parentLastName}[1]`,
		fieldRef: parent2LastNameRef,
	});
	const { error: parent2EmailError } = useFormValidation({
		name: `${FormNames.parentEmail}[1]`,
		fieldRef: parent2EmailRef,
	});

	const handleChange = R.curry(
		(
			index: number,
			field: FormNames.parentFirstName | FormNames.parentLastName | FormNames.parentEmail,
			e: React.ChangeEvent<HTMLInputElement>
		) => {
			const newParent = R.mergeDeepLeft(
				// parent should have all fields, even if they are empty
				R.assocPath([field], e.target.value, value.studentParentDataForms[index]) as Parent,
				{
					parentFirstName: '',
					parentLastName: '',
					parentEmail: '',
				}
			);

			const newFormValue = R.assocPath(
				[ListFormNames.studentParentDataForms, index],
				newParent,
				value
			) as { [ListFormNames.studentParentDataForms]: Parent[] };

			const firstParent = newFormValue.studentParentDataForms?.[0];
			if (
				firstParent &&
				!firstParent.parentFirstName &&
				!firstParent.parentLastName &&
				!firstParent.parentEmail
			) {
				newFormValue.studentParentDataForms = R.update(
					0,
					null,
					newFormValue.studentParentDataForms
				);
			}

			const secondParent = newFormValue.studentParentDataForms?.[1];

			if (
				secondParent &&
				!secondParent.parentFirstName &&
				!secondParent.parentLastName &&
				!secondParent.parentEmail
			) {
				newFormValue.studentParentDataForms = R.filter(
					item => !!item,
					R.remove(1, 1, newFormValue.studentParentDataForms)
				);
			}

			setFormValue(`${field}[${index}]`, e.target.value);
			return setValue(newFormValue);
		}
	);

	return (
		<Grid item container spacing={4}>
			<Grid item xs={12}>
				<FormLabel>
					<Typography variant="h6" display="inline" color="textPrimary">
						{parent1Label}
					</Typography>
				</FormLabel>
			</Grid>

			<Grid item xs={12}>
				<TextField
					ref={parent1FirstNameRef}
					id={parentFirstNameId}
					name={FormNames.parentFirstName}
					label={parent1FirstNameLabel}
					fullWidth
					variant={variant}
					error={!!parent1FirstNameError}
					helperText={parent1FirstNameError}
					onChange={handleChange(0, FormNames.parentFirstName)}
					InputProps={{
						inputProps: {
							'data-qa': AutomationNameDefault.parentFirstNameInput,
						},
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					ref={parent1LastNameRef}
					id={parentLastNameId}
					name={FormNames.parentLastName}
					label={parent1LastNameLabel}
					fullWidth
					variant={variant}
					error={!!parent1LastNameError}
					helperText={parent1LastNameError}
					onChange={handleChange(0, FormNames.parentLastName)}
					InputProps={{
						inputProps: {
							'data-qa': AutomationNameDefault.parentLastNameInput,
						},
					}}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					ref={parent1EmailRef}
					id={parentEmailId}
					name={FormNames.parentEmail}
					label={parent1EmailLabel}
					fullWidth
					variant={variant}
					error={!!parent1EmailError}
					helperText={parent1EmailError}
					onChange={handleChange(0, FormNames.parentEmail)}
					InputProps={{
						inputProps: {
							'data-qa': AutomationNameDefault.parentEmailInput,
						},
					}}
				/>
			</Grid>

			{!hideParent2 && (
				<>
					<Grid item xs={12}>
						<FormLabel>
							<Typography variant="h6" display="inline" color="textPrimary">
								{parent2Label}
							</Typography>
						</FormLabel>
					</Grid>

					<Grid item xs={12}>
						<TextField
							ref={parent2FirstNameRef}
							id={parentFirstNameId}
							name={FormNames.parentFirstName}
							label={parent2FirstNameLabel}
							fullWidth
							variant={variant}
							error={!!parent2FirstNameError}
							helperText={parent2FirstNameError}
							onChange={handleChange(1, FormNames.parentFirstName)}
							InputProps={{
								inputProps: {
									'data-qa': AutomationNameDefault.parentFirstNameInput,
								},
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							ref={parent2LastNameRef}
							id={parentLastNameId}
							name={FormNames.parentLastName}
							label={parent2LastNameLabel}
							error={!!parent2LastNameError}
							helperText={parent2LastNameError}
							fullWidth
							variant={variant}
							onChange={handleChange(1, FormNames.parentLastName)}
							InputProps={{
								inputProps: {
									'data-qa': AutomationNameDefault.parentLastNameInput,
								},
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							ref={parent2EmailRef}
							id={parentEmailId}
							name={FormNames.parentEmail}
							label={parent2EmailLabel}
							fullWidth
							variant={variant}
							error={!!parent2EmailError}
							helperText={parent2EmailError}
							onChange={handleChange(1, FormNames.parentEmail)}
							InputProps={{
								inputProps: {
									'data-qa': AutomationNameDefault.parentEmailInput,
								},
							}}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default ParentInput;
