import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import { Grid } from '@material-ui/core';

interface CenteredContentContainerProps {
	md?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	lg?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	xl?: boolean | 1 | 2 | 'auto' | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const SpacedGrid = styled(Grid)`
	padding-top: 3rem;
	@media (max-width: ${props => props.theme.breakpoints.values.md - 1}px) {
		padding: 1rem;
	}
`;

const CenteredContentContainer: FC<CenteredContentContainerProps> = ({
	children,
	md = 10,
	lg = 9,
	xl = 8,
}) => (
	<SpacedGrid container spacing={0} justifyContent="center" alignItems="center">
		<Grid item xs={12} sm={12} md={md} lg={lg} xl={xl}>
			<Grid container direction="column" spacing={3}>
				{children}
			</Grid>
		</Grid>
	</SpacedGrid>
);

export default CenteredContentContainer;
