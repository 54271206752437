import * as R from 'ramda';
import React, { FC, useCallback, useContext, useMemo, useRef } from 'react';
import {
	ReferenceDataTypes,
	SEARCH_INDEX_NAMES,
	ListFormNames,
	FormNames,
} from '@cappex/constants';
import { styled } from '@cappex/theme';
import { OptionItem } from '@cappex/search';
import { Grid, Typography } from '@material-ui/core';
import useCloudReferenceData from '@src/common/util/hooks/useCloudReferenceData';
import { OpenToAnyButton } from '@src/features/collegepreferences/components/CollegePreferencesCard';
import MajorSearchInput from '@src/features/collegepreferences/components/MajorSearchInput';
import { MAX_SELECTED_MAJORS } from '@src/features/collegepreferences/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import { SubForm, SubFormContext } from './BaseValidationForm';
import { validateRequired } from '@common/components/BaseFormSelect';
import { FormFields } from '../util/validation/form';
import { AutomationNameDefault } from '../util/automation';
import BaseFormToggle from './BaseFormToggle';

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

// @ts-ignore -- 10/29/21 BLM -- typescript sometimes gets confused when you style already-styled components
const GutterTopOpenToAnyButton = styled(OpenToAnyButton)`
	margin-top: 1rem;
`;

interface MajorFormProps {
	label?: string;
	subText?: string;
	name: string;
	required?: boolean;
	defaultValue?: string[];
	useOpenToAnyButton?: boolean;
	showUndecidedMajorToggle?: boolean;
	undecidedMajorToggleLabel?: string;
	extra?: {
		searchIndex: string;
	};
}

const updateGeneralCollegePreferenceName = `update${FormNames.generalCollegePreference
	.charAt(0)
	.toUpperCase()}${FormNames.generalCollegePreference.slice(1)}`;
const initialUpdateGeneralCollegePreferenceValue = { [updateGeneralCollegePreferenceName]: true };

const EMPTY_ARRAY = [];
const MajorFormInput: FC<MajorFormProps> = ({
	label,
	subText,
	name,
	required,
	defaultValue = EMPTY_ARRAY,
	useOpenToAnyButton = false,
	showUndecidedMajorToggle = false,
	undecidedMajorToggleLabel = "I'm undecided",
	extra: { searchIndex } = { searchIndex: SEARCH_INDEX_NAMES.MAJOR.valueOf() },
}) => {
	const { path } = useContext(SubFormContext);
	const headerRef = useRef(null);

	const [majorsData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.major,
	});

	const validator = useCallback((input: FormFields) => validateRequired(name, required)(input), [
		name,
		required,
	]);

	const initialValueObj = useMemo(
		() => ({
			[ListFormNames.majorCipCodes]: defaultValue,
		}),
		[defaultValue]
	);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	useFormValidation({
		path,
		name: updateGeneralCollegePreferenceName,
		fieldRef: headerRef,
		initialValue: initialUpdateGeneralCollegePreferenceValue,
	});

	const onSelect = (option: OptionItem) => {
		const current = value[ListFormNames.majorCipCodes];
		if (current.length >= MAX_SELECTED_MAJORS) {
			return;
		}

		setValue({ ...value, [ListFormNames.majorCipCodes]: [...current, option.value] as string[] });
	};

	const onRemove = (option: OptionItem) => {
		setValue({
			...value,
			[ListFormNames.majorCipCodes]: R.reject(
				val => val === option.value,
				value[ListFormNames.majorCipCodes] as string[]
			) as string[],
		});
	};

	const clearMajors = () => {
		setValue({ ...value, [ListFormNames.majorCipCodes]: defaultValue });
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6" innerRef={headerRef}>
					{label}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
					{subText}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<SearchWrapper>
					<MajorSearchInput
						studentPreferences={value[ListFormNames.majorCipCodes] as string[]}
						referenceData={majorsData}
						onSelect={onSelect}
						onRemove={onRemove}
						searchIndex={searchIndex}
					/>
				</SearchWrapper>
			</Grid>
			{showUndecidedMajorToggle && (
				<SubForm name={FormNames.generalCollegePreference}>
					<BaseFormToggle
						id={FormNames.undecidedMajor}
						name={FormNames.undecidedMajor}
						automationName={AutomationNameDefault.undecidedMajor}
						label={undecidedMajorToggleLabel}
					/>
				</SubForm>
			)}
			{useOpenToAnyButton && (
				<GutterTopOpenToAnyButton
					type="button"
					$boldFontWeight
					$noneTextTransform
					$primaryText
					fullWidth
					variant="text"
					color="primary"
					onClick={clearMajors}
					isOpenToAny={R.isEmpty(value[ListFormNames.majorCipCodes])}
				>
					Open to any major
				</GutterTopOpenToAnyButton>
			)}
		</Grid>
	);
};

export default MajorFormInput;
