import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import {
	CompleteCollege,
	convertCollegeAdmissionResponse,
	convertCollegeInformationalResponse,
	convertOverviewParagraphResponse,
	EMPTY_COMPLETE_COLLEGE,
	getCollegeAssetsFromCompleteCollege,
} from '@util/college/collegeDataUtil';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	UrlParameter,
} from '@cappex/request';
import getEndpoint from '@util/request';
import { CompleteCollegeOptions } from '@cappex/constants';
import useFailedAuth from '../auth/hooks/useFailedAuth';

export const createGetCloudCollegeData = (
	collegeIds: number[],
	dataOptions: CompleteCollegeOptions[],
	setCompleteCollege: (college: CompleteCollege) => void,
	setCompleteColleges: (colleges: CompleteCollege[]) => void,
	failAuth: () => void,
	getCollege?: (lst: CompleteCollege[]) => CompleteCollege
) => () => {
	if (!R.isEmpty(collegeIds)) {
		const convertedOptions = dataOptions.map(option => ({
			name: 'collegeDataOptions',
			value: option,
		}));
		const collegeIdsParams = collegeIds.map(id => ({
			name: 'ids',
			value: R.isNil(id) ? '' : id.toString(),
		}));
		const params: UrlParameter[] = R.concat([...convertedOptions], [...collegeIdsParams]);
		request<any>({
			url: getEndpoint(`/colleges/v2/retrieve`, params),
			method: RequestMethod.GET,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			withCredentials: true,
		})
			.then(res => {
				const { data } = res;
				if (data.meta.success && !R.isNil(data.response)) {
					const convertedColleges = R.values(data.response).map(collegeResponse => ({
						...collegeResponse,
						collegeAssets: getCollegeAssetsFromCompleteCollege(collegeResponse, true),
						collegeCustomText: convertOverviewParagraphResponse(collegeResponse),
						collegeAdmission: convertCollegeAdmissionResponse(collegeResponse),
						collegeInformational: convertCollegeInformationalResponse(collegeResponse),
					}));

					setCompleteColleges(convertedColleges);
					setCompleteCollege(
						R.isNil(getCollege) ? EMPTY_COMPLETE_COLLEGE : getCollege(convertedColleges)
					);
				}
			})
			.catch(err => {
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					failAuth();
				}
			});
	}
};

// when using this NEED to useMemo on the collegeIds array
const useCloudCollegeData = ({
	collegeIds,
	dataOptions,
	getCollege,
}: {
	collegeIds: number[];
	dataOptions: CompleteCollegeOptions[];
	getCollege?: (lst: CompleteCollege[]) => CompleteCollege;
}): [
	CompleteCollege,
	Dispatch<SetStateAction<CompleteCollege>>,
	CompleteCollege[],
	Dispatch<SetStateAction<any[]>>
] => {
	const [completeCollege, setCompleteCollege] = useState(EMPTY_COMPLETE_COLLEGE);
	const [colleges, setColleges] = useState([]);
	const failAuth = useFailedAuth();

	const getCollegeData = useMemo(
		() =>
			createGetCloudCollegeData(
				collegeIds,
				dataOptions,
				setCompleteCollege,
				setColleges,
				failAuth,
				getCollege
			),
		[collegeIds, dataOptions, setCompleteCollege, setColleges, failAuth, getCollege]
	);

	useEffect(() => {
		getCollegeData();
	}, [getCollegeData]);

	return [completeCollege, setCompleteCollege, colleges, setColleges];
};
export default useCloudCollegeData;
