import React, { FC, useContext, useEffect, useState } from 'react';
import { styled } from '@cappex/theme';
import { Button, Grid, Typography } from '@material-ui/core';
import { ListSubForm, SubForm, TopLevelForm } from '@src/common/components/BaseValidationForm';
import BirthDateInput from '@src/common/components/BirthDateInput';
import GenderSelect from '@src/common/components/GenderSelect';
import GradDateInput from '@src/common/components/GradDateInput';
import HiddenInput from '@src/common/components/HiddenInput';
import MultiCollegeInput from '@src/common/components/MultiCollegeInput';
import NameForm from '@src/common/components/NameForm';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import StartTermInput from '@src/common/components/StartTermInput';
import UnweightedGpaInput from '@src/common/components/UnweightedGpaInput';
import { FormNames, ListFormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@src/common/util/studentcollege/constants';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@src/common/util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { getStudentType, Student, StudentType } from '@src/common/util/student/studentDataUtil';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';
import CurrentCollegeInput from '@src/common/components/CurrentCollegeInput';
import DesiredCompletionTimeFrameSelect from '@src/common/components/DesiredCompletionTimeframeSelect';
import LevelOfDegreeSeekingSelect from '@src/common/components/LevelOfDegreeSeekingSelect';
import ModalitySelect from '@src/common/components/ModalitySelect';
import StartTimeframeSelect from '@src/common/components/StartTimeframeSelect';
import PhoneNumberInput from '@src/common/components/PhoneNumberInput';
import EthnicityCheckboxList from '@common/components/EthnicityCheckboxList';
import FirstGenerationStudent from '@common/components/FirstGenerationStudent';
import TransferTermInput from '@src/common/components/TransferTermInput';

type MatchesInfo = DataFlowStepComponent<any, any> & StepContainerProps;

const PageButton = withStyleOptions(Button);

const handleFailure = (
	json: any,
	setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) => {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setButtonDisabled(false);
		const formErrors: FormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
};

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

const SearchWrapper = styled.div`
	margin-bottom: 0.5rem;
`;

const studentInfoFormPath = ['student', FormNames.studentInfoForm];
const studentDataFormPath = ['student', FormNames.studentDataForm];
const studentCollegeDataFormPath = ['student', FormNames.studentCollegeDataForm];

const PostGradStudentSpecificComponents = ({ active }) => (
	<>
		<Grid item xs={12}>
			<GutterBottomTypography variant="h6">
				Add up to 10 schools you’re considering
			</GutterBottomTypography>
			<TopLevelForm>
				<SearchWrapper>
					<MultiCollegeInput
						zindexInner={6}
						typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
						locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
					/>
				</SearchWrapper>
			</TopLevelForm>
		</Grid>
		<ListSubForm name={studentInfoFormPath} distinct>
			<Grid item xs={12}>
				<Typography variant="h6">What type of degree are you thinking about?</Typography>
				<LevelOfDegreeSeekingSelect
					required={active}
					label="Level of Degree"
					name={FormNames.levelOfDegreeSeekingId}
				/>
			</Grid>
			<Grid item xs={12}>
				<ModalitySelect
					label="How would you like to attend?"
					name={ListFormNames.modalityIds}
					gutter
				/>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6">When are you thinking of starting?</Typography>
				<StartTimeframeSelect label="Start Timeframe" name={FormNames.startTimeframeId} />
			</Grid>
			<Grid item xs={12}>
				<Typography variant="h6">When would you like to complete your degree?</Typography>
				<DesiredCompletionTimeFrameSelect
					label="Completion Timeframe"
					name={FormNames.desiredCompletionTimeframeId}
				/>
			</Grid>
		</ListSubForm>
	</>
);

const CollegeStudentSpecificComponents = ({ userData, active }) => {
	const { getValue } = useContext(FormContext);
	const transferStudent = String(
		getValue(FormNames.openToTransfer)[FormNames.openToTransfer]
	) as YesNoSelection;

	return (
		<SubForm name="studentCollegeDataForm">
			<Grid item xs={12}>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Typography variant="h6">College GPA</Typography>
					</Grid>
					<Grid item xs={12}>
						<UnweightedGpaInput
							name={FormNames.collegeGpa}
							label="College GPA"
							initialValue={userData.collegeGpa}
							fullWidth
						/>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<CurrentCollegeInput label="Current College" placeholder="Find My College" flat />
			</Grid>
			<Grid item xs={12}>
				<YesNoSelectorInput
					name={FormNames.openToTransfer}
					title="Are you considering transferring colleges?"
					required={active}
					defaultValue={YesNoSelection.NO_ANSWER}
				/>
			</Grid>
			{transferStudent === YesNoSelection.YES && (
				<>
					<Grid item xs={12}>
						<TransferTermInput label="Expected Transfer Start Term" />
					</Grid>
					<Grid item xs={12}>
						<GutterBottomTypography variant="h6">
							Which college(s) are you considering?
						</GutterBottomTypography>
						<TopLevelForm>
							<SearchWrapper>
								<MultiCollegeInput
									zindexInner={1}
									typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
									locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
								/>
							</SearchWrapper>
						</TopLevelForm>
					</Grid>
				</>
			)}
		</SubForm>
	);
};

const MatchesInfoPage: FC<MatchesInfo> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	active,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getValue } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic } = useContext(AuthContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const [userData] = useState<Partial<Student>>({});

	const [studentType, setStudentType] = useState(StudentType.HIGH_SCHOOL);
	useEffect(() => {
		const newStudentType =
			getStudentType((getValue(FormNames.studentTypeId) as FormFields)?.studentTypeId) ||
			userData.studentTypeId;
		if (newStudentType && newStudentType !== studentType) {
			setStudentType(newStudentType);
		}
	}, [studentType, getValue, userData]);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(false);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			} else {
				handleFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			complete();
		}
	}, [active, complete, isAuthentic]);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<SubForm name="student">
					<SubForm name="studentDataForm">
						<Grid item xs={12}>
							<NameForm
								fullWidth
								required={active}
								label="Name"
								firstNameLabel="First Name"
								lastNameLabel="Last Name"
							/>
						</Grid>
						{studentType !== StudentType.NOT_IN_SCHOOL && (
							<Grid item xs={12}>
								<Typography variant="h6">Gender</Typography>
								<GenderSelect name={FormNames.genderId} label="Gender (Optional)" />
							</Grid>
						)}
						<Grid item xs={12}>
							<BirthDateInput
								required={active}
								id="registration__birth_date"
								label="Date of Birth"
							/>
						</Grid>
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<>
							<SubForm name="studentCollegeDataForm">
								<Grid item xs={12}>
									<FirstGenerationStudent />
								</Grid>
							</SubForm>
							<SubForm name="studentEthnicityForm">
								<Grid item xs={12}>
									<EthnicityCheckboxList subText="Optional" />
								</Grid>
							</SubForm>
						</>
					)}
					<SubForm name="studentHighSchoolDataForm">
						{studentType !== StudentType.NOT_IN_SCHOOL && (
							<Grid item xs={12}>
								<Typography variant="h6">
									{studentType === StudentType.COLLEGE && 'High School'} GPA
								</Typography>
								<UnweightedGpaInput
									fullWidth
									required={active && studentType === StudentType.HIGH_SCHOOL}
									name={FormNames.gpaUnweighted}
									label="Unweighted GPA"
									helperText="Up to 4.00"
								/>
							</Grid>
						)}
						{studentType === StudentType.NOT_IN_SCHOOL && (
							<>
								<Grid item xs={12}>
									<Typography variant="h6">Phone</Typography>
									<ListSubForm distinct name={studentDataFormPath}>
										<PhoneNumberInput />
									</ListSubForm>
								</Grid>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Typography variant="h6">College GPA</Typography>
										</Grid>
										<Grid item xs={12}>
											<ListSubForm name={studentCollegeDataFormPath} distinct>
												<UnweightedGpaInput
													name={FormNames.collegeGpa}
													label="College GPA"
													initialValue={userData.collegeGpa}
													fullWidth
												/>
											</ListSubForm>
										</Grid>
									</Grid>
								</Grid>
							</>
						)}
						<Grid item xs={12}>
							<GradDateInput label="High School Graduation Date" />
							<HiddenInput name={FormNames.requireHighSchoolId} initialValue="false" />
						</Grid>
					</SubForm>
					{studentType === StudentType.HIGH_SCHOOL && (
						<SubForm name="studentCollegeDataForm">
							<Grid item xs={12}>
								<StartTermInput label="Expected College Start Term" />
							</Grid>
						</SubForm>
					)}
					{studentType === StudentType.COLLEGE && (
						<CollegeStudentSpecificComponents userData={userData} active={active} />
					)}
					{studentType === StudentType.NOT_IN_SCHOOL && (
						<PostGradStudentSpecificComponents active={active} />
					)}
				</SubForm>
				{studentType === StudentType.HIGH_SCHOOL && (
					<Grid item xs={12}>
						<GutterBottomTypography variant="h6">
							Add up to 10 colleges you&apos;re considering
						</GutterBottomTypography>
						<TopLevelForm>
							<SearchWrapper>
								<MultiCollegeInput
									typeTrackingValue={StudentCollegeListTypeTrackingValue.SEARCH_BAR}
									locationTrackingValue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
									multiFormInit
								/>
							</SearchWrapper>
						</TopLevelForm>
					</Grid>
				)}
				<Grid item xs={12}>
					<PageButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my matches' : 'Next'}
					</PageButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default MatchesInfoPage;
