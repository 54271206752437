export const convertArrayNumbersToStrings = (obj: Record<string, any>) => {
	const newObj = { ...obj };
	Object.keys(newObj).forEach(key => {
		if (Array.isArray(newObj[key])) {
			newObj[key] = newObj[key].map((value: any) =>
				typeof value === 'number' ? String(value) : value
			);
		}
	});
	return newObj;
};

export const convertNumbersToStrings = (val: Record<string, any>) => {
	const newObj = { ...val };
	Object.keys(newObj).forEach(key => {
		if (typeof newObj[key] === 'number') {
			newObj[key] = String(newObj[key]);
		} else if (Array.isArray(newObj[key])) {
			newObj[key] = newObj[key].map((value: any) => String(value));
		}
	});
	return newObj;
};
