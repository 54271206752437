import React, { FC, useContext, useState } from 'react';
import checkLockout from '@util/lockout';
import { Grid, Box, Typography } from '@material-ui/core';
import RegisterButton from '@src/common/components/RegisterButton';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames, ListFormNames, DataFlowContainerVariant } from '@cappex/constants';
import {
	RequestSourceIdentifier,
	checkForFormError,
	FORM_NAME,
	getFormErrors,
} from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import { FormContext } from '@util/validation/form';
import { styled } from '@cappex/theme';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { ListSubForm } from '@src/common/components/BaseValidationForm';
import ModalitySelect from '@src/common/components/ModalitySelect';
import StartTimeframeSelect from '@src/common/components/StartTimeframeSelect';
import DesiredCompletionTimeFrameSelect from '@src/common/components/DesiredCompletionTimeframeSelect';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const studentInfoFormPath = ['student', 'studentInfoForm'];

const AlrScholarshipTimelinePage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	data: { topMedia, currentStep, totalSteps },
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);

	const [submitDisabled, setSubmitDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openErrorSnack(errors[FORM_NAME]);
				}
			}
		});
		return true;
	};

	return (
		<DataFlowContainer
			variant={DataFlowContainerVariant.CENTERED}
			topMedia={topMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				<ListSubForm name={studentInfoFormPath}>
					<Grid item>
						<ModalitySelect
							label="How would you like to attend?"
							name={ListFormNames.modalityIds}
							gutter
						/>
					</Grid>
					<Grid item>
						<Typography variant="h6">When are you thinking of starting?</Typography>
						<StartTimeframeSelect label="Start Timeframe" name={FormNames.startTimeframeId} />
					</Grid>
					<Grid item>
						<Typography variant="h6">When would you like to complete your degree?</Typography>
						<DesiredCompletionTimeFrameSelect
							label="Completion Timeframe"
							name={FormNames.desiredCompletionTimeframeId}
						/>
					</Grid>
				</ListSubForm>
				<Grid item>
					<RegisterButton md={12} submitDisabled={submitDisabled} onClick={onClick}>
						Next
						<PaddedBox>
							<FontAwesomeIcon icon={faArrowRight} />
						</PaddedBox>
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default AlrScholarshipTimelinePage;
