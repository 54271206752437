import React, { FC } from 'react';
import { Typography, Button } from '@material-ui/core';
import { MainContentPadding } from '@cappex/components';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { AutomationNameDefault } from '@common/util/automation';

const ERROR_TEXT = "We can't get this page for you. You may have lost connection to the internet.";

const StyledButton = withStyleOptions(Button);

interface Props {
	retry?: () => any;
	errorText?: string;
}

const ErrorBlock: FC<Props> = ({ retry, errorText }) => (
	<MainContentPadding>
		<h1>SORRY</h1>
		<Typography paragraph>{errorText || ERROR_TEXT}</Typography>
		{retry && (
			<StyledButton
				$noneTextTransform
				$boldFontWeight
				variant="contained"
				color="primary"
				onClick={retry}
				data-qa={AutomationNameDefault.errorRetry}
			>
				Retry
			</StyledButton>
		)}
	</MainContentPadding>
);

export default ErrorBlock;
