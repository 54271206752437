import React, { useEffect, FC } from 'react';
import { useLocation } from 'react-router-dom';

const QUERY_PARAM_PATHS = ['/dashboard'];

interface DataLayerEvent {
	event: string;
	pagePath: string;
}

declare const dataLayer: DataLayerEvent[];

const GATracking: FC = () => {
	const { pathname, search } = useLocation();

	useEffect(() => {
		if (typeof dataLayer !== 'undefined') {
			const path = QUERY_PARAM_PATHS.includes(pathname) ? `${pathname}${search}` : `${pathname}`;

			dataLayer.push({
				event: 'Pageview',
				pagePath: path,
			});
		}
	}, [pathname, search]);

	return <></>;
};

export default GATracking;
