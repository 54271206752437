import React, { FC } from 'react';
import BaseFormCheckbox from '@src/common/components/BaseFormCheckbox';
import { FormNames } from '@cappex/constants';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';

type OfferBaseFormBaseFormCheckboxListType = {
	id?: string;
	name: string;
	automationName: string;
	label: string;
	required?: boolean;
	value: ReferenceData;
};

const OfferBaseFormBaseFormCheckboxList: FC<OfferBaseFormBaseFormCheckboxListType> = ({
	id,
	name,
	automationName,
	label,
	required = false,
	value,
}) => (
	<BaseFormCheckbox
		id={id}
		name={name as FormNames}
		automationName={automationName}
		label={label}
		required={required}
		reference={value}
	/>
);

export default OfferBaseFormBaseFormCheckboxList;
