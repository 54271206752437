import scholarshipConfig from './scholarshipConfig';
import initialConfig from './initialConfig';
import chancesConfig from './chancesConfig';
import matchesConfig from './matchesConfig';
import cboGeneralConfig from './cboGeneralConfig';
import cboSpecificConfig from './cboSpecificConfig';
import quizConfig from './quizConfig';
import alrScholarshipConfig from './alrScholarshipConfig';
import { DataFlowConfig } from '../../dataflow/constants/types';
import coreConfig from '@src/features/registration/constants/coreConfig';

export const MAIN_PATH = 'main';
export const SCHOLARSHIP_PATH = 'scholarship';
export const CHANCES_PATH = 'chances';
export const MATCHES_PATH = 'matches';
export const CBO_GENERAL_PATH = 'cbogeneral';
export const CBO_SPECIFIC_PATH = 'cbospecific';
export const ALR_SCHOLARSHIP_PATH = 'adult-scholarship';
export const QUIZ_PATH = 'quiz';
export const ALR_PATH = 'adult';
export const CORE_PATH = 'core';

export type RegistrationPathConfiguration = {
	config: DataFlowConfig;
	pathCode?: string;
	pathVersion?: string | number;
};

const configurations: { [key: string]: RegistrationPathConfiguration } = {
	[MAIN_PATH]: { config: initialConfig, pathCode: 'default', pathVersion: 2 },
	[SCHOLARSHIP_PATH]: { config: scholarshipConfig, pathCode: 'scholarship', pathVersion: 2 },
	[CHANCES_PATH]: { config: chancesConfig, pathCode: 'chances', pathVersion: 1 },
	[MATCHES_PATH]: { config: matchesConfig, pathCode: 'matches', pathVersion: 1 },
	[CBO_GENERAL_PATH]: { config: cboGeneralConfig, pathCode: 'cbogeneral', pathVersion: 1 },
	[CBO_SPECIFIC_PATH]: { config: cboSpecificConfig, pathCode: 'cbospecific', pathVersion: 1 },
	[QUIZ_PATH]: { config: quizConfig },
	[ALR_PATH]: { config: quizConfig },
	[ALR_SCHOLARSHIP_PATH]: {
		config: alrScholarshipConfig,
		pathCode: 'alrScholarship',
		pathVersion: 1,
	},
	[CORE_PATH]: { config: coreConfig },
};

export default configurations;
