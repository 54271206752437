import EnrolledDataLayer from './enrolledDataLayer';
import MostRecentDegreeDataLayer from './mostRecentDegreeDataLayer';
import BirthYearDataLayer from './birthYearDataLayer';
import DataLayerEventTypes from './dataLayerEventTypes';
import SeekingMbaDataLayer from '@util/dataLayer/seekingMbaDataLayer';
import EmailAddressDataLayer from '@util/dataLayer/emailAddressDataLayer';
import LevelOfDegreeSeekingDataLayer from '@util/dataLayer/levelOfDegreeSeekingDataLayer';

interface StudentDataForm {
	birthYear: string;
}

interface StudentInfoForm {
	enrolled: string;
	lastDegreeCompletedId: string;
	seekingMba: string;
	levelOfDegreeSeekingId: string;
}
interface RequestData {
	student: {
		studentDataForm: StudentDataForm;
		studentInfoForm: StudentInfoForm;
	};
	accountInfo: {
		email: string;
	};
}

const updateFlexRegistrationDataLayer = (requestData: RequestData) => {
	EnrolledDataLayer(
		DataLayerEventTypes.FLEX_REG_ENROLLED,
		requestData?.student?.studentInfoForm?.enrolled
	);
	MostRecentDegreeDataLayer(
		DataLayerEventTypes.FLEX_REG_MOST_RECENT_DEGREE,
		requestData?.student?.studentInfoForm?.lastDegreeCompletedId
	);
	BirthYearDataLayer(
		DataLayerEventTypes.FLEX_REG_BIRTH_YEAR,
		requestData?.student?.studentDataForm?.birthYear
	);
	SeekingMbaDataLayer(
		DataLayerEventTypes.FLEX_REG_SEEKING_MBA,
		requestData?.student?.studentInfoForm?.seekingMba
	);
	EmailAddressDataLayer(DataLayerEventTypes.FLEX_REG_EMAIL_INPUT, requestData?.accountInfo?.email);
	LevelOfDegreeSeekingDataLayer(
		DataLayerEventTypes.FLEX_REG_LEVEL_OF_DEGREE_SEEKING,
		requestData?.student?.studentInfoForm?.levelOfDegreeSeekingId
	);
};

export default updateFlexRegistrationDataLayer;
