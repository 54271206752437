import React, { FC, useContext, useMemo } from 'react';
import SidebarNavHeader from '@common/components/SidebarNavHeader';
import SidebarSection from '@common/components/SidebarSection';
import { AutomationNameDefault } from '@common/util/automation';
import StudentContext from '@util/studentContext';
import { StudentType } from '@src/common/util/student/studentDataUtil';
import navigationSections, {
	sectionModifiersCollegeStudent,
	sectionModifiersHighSchoolStudent,
	SectionsModifier,
} from '@common/util/links/navigationLinks';
import { styled } from '@cappex/theme';
import { Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import * as R from 'ramda';

const ListSection = styled(List)`
	padding: 0 0 0.5rem 0;
	width: 100%;
`;

const SidebarMain = styled.div`
	flex-grow: 1;
	background-color: ${props => props.theme.palette.base.main};
`;

export const HeaderDivider = styled(Divider)`
	margin-bottom: 0.5rem;
	background-color: unset;
`;

export interface Props {
	handleDrawerClose: (e: any) => void;
	automationNameNav?: string;
}

const SidebarContainer: FC<Props> = ({
	handleDrawerClose,
	automationNameNav = AutomationNameDefault.sideNavList,
}) => {
	const { student } = useContext(StudentContext);

	const NavigationSections = useMemo(() => {
		let sections = [...navigationSections];
		switch (student.studentTypeId) {
			case StudentType.HIGH_SCHOOL:
				sections = R.pipe(
					x => x,
					...((sectionModifiersHighSchoolStudent as unknown) as [SectionsModifier])
				)(sections);
				break;
			case StudentType.COLLEGE:
				sections = R.pipe(
					x => x,
					...((sectionModifiersCollegeStudent as unknown) as [SectionsModifier])
				)(sections);
				break;
			default:
				break;
		}

		return () => (
			<>
				{sections.map(sectionItem => (
					<SidebarSection
						name={sectionItem.name}
						navigationLinks={sectionItem.navigationLinks}
						handleDrawerClose={handleDrawerClose}
						icon={sectionItem.icon}
						link={sectionItem.link}
						divider={sectionItem.divider}
						key={sectionItem.key}
					/>
				))}
			</>
		);
	}, [student.studentTypeId, handleDrawerClose]);
	return (
		<SidebarMain>
			<ListSection data-qa={automationNameNav}>
				<SidebarNavHeader handleDrawerClose={handleDrawerClose} />
				<HeaderDivider />
				<NavigationSections />
			</ListSection>
		</SidebarMain>
	);
};

export default SidebarContainer;
