import React, { FC, useState, useContext } from 'react';
import { styled } from '@cappex/theme';
import { StepComponentProps } from '@common/util/steps';
import { Grid } from '@material-ui/core';
import NameForm from '@common/components/NameForm';
import GradDateInput from '@common/components/GradDateInput';
import EmailInput from '@common/components/EmailInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormContainer from '@common/components/FormContainer';
import RegisterButton from '@common/components/RegisterButton';
import getEndpoint from '@util/request';
import StartTermInput from '@common/components/StartTermInput';
import BirthDateInput from '@common/components/BirthDateInput';
import { FormNames, DataFlowStepName } from '@cappex/constants';
import HighSchoolIndexSearch from '@src/common/components/HighSchoolIndexSearch';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import StudentRelationshipInput from '@src/common/components/StudentRelationshipInput';
import { FormContext, withFormValidation, FormContextValue } from '@util/validation/form';
import useAuthRefresh from '@src/common/util/auth/hooks/useAuthRefresh';
import request, {
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestSourceIdentifier,
	ErrorMessages,
	getFormErrors,
	checkForFormError,
	FORM_NAME,
} from '@cappex/request';
import { DataFlowStep } from '../../constants/types';
import DataFlowContainer from '../DataFlowContainer';

const STUDENT_INVITE_TEXT =
	"We'll send an email to your student asking them to create an Appily account to link with your account. If they already have an Appily account, the accounts can still be linked.";

const FieldHelperText = styled(FormHelperText)`
	margin: 0.5rem 0.75rem 0;
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		max-width: 21.25rem;
	}
`;

type StudentInviteFormFields<T> = {
	[FormNames.firstName]?: T;
	[FormNames.lastName]?: T;
	[FormNames.highSchoolId]?: T;
	[FormNames.highSchoolGradMonth]?: T;
	[FormNames.highSchoolGradYear]?: T;
	[FormNames.collegeStartTermId]?: T;
	[FormNames.collegeStartYear]?: T;
	[FormNames.email]?: T;
	[FormNames.birthDay]?: T;
	[FormNames.birthMonth]?: T;
	[FormNames.birthYear]?: T;
	[FormNames.birthDate]?: T;
	[FormNames.parentStudentRelationship]?: T;
};

const createSubmitClick = (
	complete: () => void,
	setFormErrors: FormContextValue['setFormErrors'],
	setSubmitDisabled: (e: boolean) => void,
	openErrorSnack: (formError: string) => void,
	getFormValues: () => StudentInviteFormFields<string>,
	authRefresh: () => void
) => () => {
	setSubmitDisabled(true);

	request<any>({
		url: getEndpoint('/registration/v1/student-invite'),
		method: RequestMethod.POST,
		data: getFormValues(),
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			if (data.meta.success) {
				authRefresh();
				complete();
			} else {
				throw data;
			}
		})
		.catch(err => {
			let json;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				json = { meta: { error: ErrorMessages.unknown } };
			} else {
				json = err;
			}
			setSubmitDisabled(false);
			const errors = getFormErrors(json);
			setFormErrors(errors);
			if (checkForFormError(errors)) {
				openErrorSnack(errors[FORM_NAME]);
			}
		});
};

const StudentInvitePage: FC<StepComponentProps<any, any, DataFlowStep['data']>> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, getFormValues } = useContext(FormContext);
	const refreshAuth = useAuthRefresh();

	const [submitDisabled, setSubmitDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onSubmitClick = createSubmitClick(
		complete,
		setFormErrors,
		setSubmitDisabled,
		openErrorSnack,
		getFormValues,
		refreshAuth
	);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<FormContainer
				name={DataFlowStepName.StudentInviteName}
				id={DataFlowStepName.StudentInviteName}
				onSubmit={onSubmitClick}
			>
				<Grid item>
					<StudentRelationshipInput label="Relationship to Student" required />
				</Grid>
				<Grid item>
					<Grid item>
						<Grid container>
							<NameForm
								id="student-invite-"
								label="Student Information"
								fullWidth
								firstNameLabel="Student's First Name"
								lastNameLabel="Student's Last Name"
							/>
						</Grid>
					</Grid>
					<Grid item>
						<EmailInput
							label="Student's Email Address"
							helperText="example@email.com"
							fullWidth
							required
						/>
						<FieldHelperText>{STUDENT_INVITE_TEXT}</FieldHelperText>
					</Grid>
				</Grid>
				<Grid item>
					<BirthDateInput id="registration__birth_date" label="Student's Date of Birth" required />
				</Grid>
				<Grid item>
					<Grid container direction="column">
						<HighSchoolIndexSearch
							label="High School"
							placeholderText="Select Student's High School"
							required
						/>
					</Grid>
				</Grid>
				<Grid item>
					<GradDateInput label="High School Graduation Date" />
				</Grid>
				<Grid item>
					<StartTermInput label="Expected College Start Term" />
				</Grid>
				<RegisterButton submitDisabled={submitDisabled}>Finish</RegisterButton>
			</FormContainer>
		</DataFlowContainer>
	);
};

export default withFormValidation(StudentInvitePage);
