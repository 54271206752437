import React, { FC } from 'react';
import { List } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import ValidationListItem from '@common/components/ValidationListItem';
import { AutomationNameDefault } from '@common/util/automation';

export type ValidationItem = {
	text: string;
	isValid: boolean;
};

interface ValidationListProps {
	items?: ValidationItem[];
	invalidIcon: IconDefinition;
	validIcon: IconDefinition;
	invalidColor: string;
	validColor: string;
	disableGutters?: boolean;
	automationName?: string;
}

const ValidationList: FC<ValidationListProps> = ({
	items,
	invalidIcon,
	validIcon,
	invalidColor,
	validColor,
	disableGutters,
	automationName = AutomationNameDefault.validationList,
}) => {
	const getIcon = (isValid: boolean) => (isValid ? validIcon : invalidIcon);
	const getIconColor = (isValid: boolean) => (isValid ? validColor : invalidColor);

	return (
		<List data-qa={automationName} dense>
			{items &&
				items.map(item => {
					return (
						<ValidationListItem
							disableGutters={disableGutters}
							key={item.text}
							text={item.text}
							icon={getIcon(item.isValid)}
							iconColor={getIconColor(item.isValid)}
						/>
					);
				})}
		</List>
	);
};

export default ValidationList;
