import React, { FC, useCallback, useContext, useMemo, useRef } from 'react';
import * as R from 'ramda';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import useFormValidation from '@util/hooks/useFormValidation';
import {
	FormControl,
	FormLabel,
	Typography,
	FormGroup,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import { styled } from '@cappex/theme';
import { AutomationNameGeneric } from '../util/automation';
import { SubFormContext } from './BaseValidationForm';
import { validateRequired } from '@common/components/BaseFormCheckbox';

const CheckboxList = styled(FormGroup)`
	margin-left: 1rem;
`;

const SubTextFormLabel = styled(FormLabel)`
	margin-top: 0.5rem;
	margin-bottom: 1rem;
`;

export interface BaseFormCheckboxListProps {
	id: string;
	name: string;
	label: string;
	subText?: string;
	required?: boolean;
	references: ReferenceData[];
	initialValue?: string[];
	automationName?: string;
}

const defaultInitialValue = [];

const BaseFormCheckboxList: FC<BaseFormCheckboxListProps> = ({
	id,
	name,
	label,
	subText,
	required,
	references = [],
	initialValue = defaultInitialValue,
	automationName = AutomationNameGeneric.checkbox,
}) => {
	const { path } = useContext(SubFormContext);
	const listRef = useRef(null);

	const validator = useCallback(validateRequired(name, required), [name, required]);
	const initialValueObj = useMemo(() => ({ [name]: initialValue }), [initialValue, name]);
	const { value, setValue, error } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: listRef,
	});

	const onCheckboxSelect = (checkboxId: string) => () => {
		const selectedItems = value[name] as string[];
		let newItems: string[];

		const index = R.findIndex(R.equals(checkboxId), selectedItems);
		if (index >= 0) {
			newItems = R.remove(index, 1, selectedItems);
		} else {
			newItems = R.append(checkboxId, selectedItems);
		}

		setValue({ [name]: newItems });
	};

	return (
		<FormControl margin="normal" fullWidth variant="outlined" error={!!error}>
			<FormLabel>
				<Typography variant="h6" display="inline" color="textPrimary">
					{label}
				</Typography>
			</FormLabel>
			{subText && (
				<SubTextFormLabel>
					<Typography variant="body2" display="inline" color="textSecondary">
						{subText}
					</Typography>
				</SubTextFormLabel>
			)}
			<CheckboxList ref={listRef}>
				{references.map(reference => (
					<FormControlLabel
						control={
							<Checkbox
								id={`${id}-${reference.id}`}
								checked={R.includes(reference.id, value[name])}
								onChange={onCheckboxSelect(reference.id)}
								value={reference.id}
								data-qa={`${automationName}-${reference.id}`}
							/>
						}
						label={reference.value}
						key={reference.id}
						htmlFor={`${id}-${reference.id}`}
					/>
				))}
			</CheckboxList>
		</FormControl>
	);
};

export default BaseFormCheckboxList;
