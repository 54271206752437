import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setUserId, trackPageView } from '@snowplow/browser-tracker';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import usePrevious from '@src/common/util/hooks/usePrevious';

const SnowplowTracker = () => {
	const location = useLocation();
	const { accountUuid, isAuthentic } = useContext(AuthContext);
	const loadingAuth = isAuthentic === AuthenticState.Unknown;
	const prevAuthentic = usePrevious(isAuthentic);
	const wasLoadingAuth = prevAuthentic === AuthenticState.Unknown;
	const prevLocation = usePrevious(location);

	useEffect(() => {
		setUserId(accountUuid);
	}, [accountUuid]);

	useEffect(() => {
		const isNewLocation = prevLocation?.pathname !== location?.pathname;
		!loadingAuth && (wasLoadingAuth || isNewLocation) && trackPageView();
	}, [loadingAuth, wasLoadingAuth, prevLocation, location]);

	return null;
};
export default SnowplowTracker;
