import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { ColorDefinition, ExtendedTheme, styled, ThemeContext } from '@cappex/theme';
import confetti from '@common/assets/confetti.svg';
import {
	Dialog,
	Grid,
	GridProps,
	makeStyles,
	Paper,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { FloatingCloseButton } from '@cappex/components';
import CollegeOutreach from '@common/components/CollegeOutreach';
import { OutreachContext } from '@src/common/util/outreach';
import { StepComponentProps } from '@src/common/util/steps';
import { StudentCollegeListLocationTrackingValue } from '@src/common/util/studentcollege/constants';
import StudentCollegeListContext from '@util/studentcollege/StudentCollegeListContext';
import StudentContext from '@src/common/util/studentContext';

const useStyles = makeStyles({ root: { width: '100vw', height: '100vh' } });

const DialogPaper = styled(Paper)`
	width: calc(100% - 1rem);
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	${props => props.theme.breakpoints.down('xs')} {
		width: 100%;
		padding-bottom: 3rem;
	}
`;
const MarginGrid = styled(({ vertical, ...rest }: GridProps & { vertical: number }) => (
	<Grid {...rest} />
))`
	margin: ${({ vertical }) => vertical}rem auto;
	width: 100%;

	${props => props.theme.breakpoints.down('xs')} {
		max-width: 100%;
	}
`;

const CenterDiv = styled.div`
	text-align: center;
`;

const FullWidthGrid = styled(Grid)`
	width: 100%;
	flex-basis: auto;
`;

const ColoredSpan = styled<FC<{ color: ColorDefinition }>>(({ color, ...rest }) => (
	<span {...rest} />
))`
	color: ${({ theme, color: { color, key } }) => theme.palette[color][key]};
`;

const getCardsToShow = (xl: boolean, mdDown: boolean) => {
	if (xl) {
		return 2;
	}
	if (mdDown) {
		return 4;
	}
	return 3;
};

const MatchesModal: FC<StepComponentProps> = ({ complete }) => {
	const classes = useStyles();
	const { student } = useContext(StudentContext);
	const { outreachList, outreachDataRetrieved } = useContext(OutreachContext);
	const { list, isDefaultData } = useContext(StudentCollegeListContext);

	const theme = useContext<ExtendedTheme>(ThemeContext);
	const isXL = useMediaQuery(theme.breakpoints.only('xl'));
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

	const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));

	const cardsToShow = getCardsToShow(isXL, isMdDown);

	const [numMatches, setNumMatches] = useState<number>();

	useEffect(() => {
		let filteredMatches: number;
		// Update number of found matches when we get all data, but keep the number persistent
		if (outreachDataRetrieved && !isDefaultData && numMatches === undefined) {
			const outreachIds = R.map(R.prop('collegeId'))(outreachList);
			const filterList = R.intersection(R.map(R.prop('collegeId'))(list), outreachIds);
			const rejectFromList = it => R.includes(it, filterList);

			filteredMatches = R.length(R.reject(rejectFromList)(outreachIds));
			setNumMatches(filteredMatches);
		}
		// If we have outreach data, avoid the modal entirely if we have no outreach or we would filter all immediately
		if (outreachDataRetrieved && (outreachList.length === 0 || filteredMatches === 0)) {
			complete();
		}
	}, [outreachDataRetrieved, outreachList, list, isDefaultData, complete, numMatches]);

	return (
		<div>
			{outreachDataRetrieved && !isDefaultData && (
				<Dialog
					open
					fullWidth
					fullScreen={isSmDown}
					maxWidth="md"
					onClose={() => complete()}
					classes={{ root: classes.root }}
					PaperComponent={DialogPaper}
					scroll={isMdDown ? 'paper' : 'body'}
				>
					<MarginGrid
						vertical={2}
						container
						direction="column"
						spacing={3}
						justifyContent="center"
						alignItems="center"
					>
						<Grid item xs={false}>
							<CenterDiv>
								<img src={confetti} alt="" />
							</CenterDiv>
						</Grid>
						<Grid item xs={false}>
							<Typography variant="h5" align="center">
								Congrats {student.firstName}!
							</Typography>
							<Typography variant="h6" align="center">
								We&apos;ve found{' '}
								<ColoredSpan color={{ color: 'primary', key: 'main' }}>{numMatches}</ColoredSpan>{' '}
								colleges that match what you&apos;re looking for.
							</Typography>
							<Typography variant="body2" align="center" color="textSecondary">
								And they want students like you. When you like a school, they will be saved to your
								college list.
							</Typography>
						</Grid>
						<FullWidthGrid item xs={12} md={10}>
							<CollegeOutreach
								hideHeaders
								cardsToShow={cardsToShow}
								trackingLocation={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
							/>
						</FullWidthGrid>
					</MarginGrid>
					<FloatingCloseButton
						onClick={() => complete()}
						positioning={isXsDown ? 'fixed' : undefined}
					/>
				</Dialog>
			)}
		</div>
	);
};

export default MatchesModal;
