import * as R from 'ramda';
import { ConvertedLocationData, LocationDataFields } from '@util/preferences/constants';

const filterStudentPreferences = (
	studentPreferences: ConvertedLocationData[],
	filter: LocationDataFields
) => {
	if (!studentPreferences) {
		return [];
	}

	return R.pipe<ConvertedLocationData[], ConvertedLocationData[], string[]>(
		R.filter(R.propEq('type', filter)),
		R.map(value => {
			return R.prop('value', value);
		})
	)(studentPreferences);
};

export default filterStudentPreferences;
