export const SUBHEADER_GENDER_NOT_PERMITTED =
	'This school is gender specific and does not include your gender.';
export const SUBHEADER_INSUFFICIENT_COLLEGE_INFO =
	'This school does not report enough data to calculate your chances.';
export const ENTER_GPA_TEST_SCORES_BUTTON = 'Enter GPA and Test Scores';

export enum WamcIneligibleReason {
	NONE = 'NONE',
	INSUFFICIENT_COLLEGE_INFORMATION = 'INSUFFICIENT_COLLEGE_INFORMATION',
	GENDER_NOT_PERMITTED = 'GENDER_NOT_PERMITTED',
	INSUFFICIENT_STUDENT_INFORMATION = 'INSUFFICIENT_STUDENT_INFORMATION',
}
export enum MbaWamcIneligibleReason {
	NONE = 'NONE',
	COLLEGE_LACKS_MBA_PROGRAM = 'COLLEGE_LACKS_MBA_PROGRAM',
}

export enum WamcChanceGroupId {
	LIKELY = 1,
	TARGET,
	REACH,
}

export enum WamcChanceGroupDescription {
	LIKELY = 'Likely',
	TARGET = 'Target',
	REACH = 'Reach',
}

export interface WamcResults {
	studentId: string;
	collegeId: number;
	wamcScore: number;
	wamcChanceGroupId: WamcChanceGroupId;
	wamcChanceGroupDescription: WamcChanceGroupDescription;
	wamcIneligibleReason: WamcIneligibleReason;
}

// Primarily used in tests and to generate below constants.
export const DEFAULT_WAMC_DATA: WamcResults = {
	collegeId: 1,
	studentId: '2',
	wamcScore: 0.7,
	wamcChanceGroupId: WamcChanceGroupId.LIKELY,
	wamcChanceGroupDescription: WamcChanceGroupDescription.LIKELY,
	wamcIneligibleReason: WamcIneligibleReason.NONE,
};

export const EMPTY_WAMC_DATA = {
	...DEFAULT_WAMC_DATA,
	wamcScore: 0,
	wamcChanceGroupDescription: WamcChanceGroupDescription.REACH,
	wamcIneligibleReason: WamcIneligibleReason.INSUFFICIENT_COLLEGE_INFORMATION,
};
