import { useContext } from 'react';
import StudentContext, { StudentView } from '../studentContext';
import SEARCH_INDEX_NAMES from './constants';
import { collegeSearch } from '@src/features/environment';

const viewTypePostfixes = {
	[StudentView.UNDERGRADUATE]: collegeSearch.ftfViewPostfix,
	[StudentView.ADULT_COMPLETER]: collegeSearch.adcViewPostfix,
	[StudentView.GRADUATE]: collegeSearch.gradViewPostfix,
};

const useCollegeSearchIndexForViewType = () => {
	const { view } = useContext(StudentContext);
	const postFix = viewTypePostfixes[view];

	return postFix ? `${SEARCH_INDEX_NAMES.COLLEGE}-${postFix}` : SEARCH_INDEX_NAMES.COLLEGE;
};

export default useCollegeSearchIndexForViewType;
