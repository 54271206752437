import React, { FC } from 'react';

import { Grid, Typography, TextFieldProps } from '@material-ui/core';
import GreVerbalInput from '@src/common/components/GreVerbalInput';
import GreQuantInput from '@src/common/components/GreQuantInput';
import GreWritingInput from '@src/common/components/GreWritingInput';

import { styled } from '@cappex/theme';
import { FormNames } from '@cappex/constants';

const GutterBottomTypography = styled(Typography)`
	margin-bottom: 1rem;
`;

type GreCompositeProps = {
	label?: string;
	verbalRequired?: boolean;
	verbalLabel?: string;
	quantRequired?: boolean;
	quantLabel?: string;
	writingRequired?: boolean;
	writingLabel?: string;
	variant?: TextFieldProps['variant'];
};

const GreCompositeInput: FC<GreCompositeProps> = ({
	label = 'GRE Scores',
	verbalRequired = false,
	verbalLabel = 'Verbal Reasoning (130-170)',
	quantRequired = false,
	quantLabel = 'Quantitative Reasoning (130-170)',
	writingRequired = false,
	writingLabel = 'Analytical Reasoning (0-6)',
	variant = 'outlined',
}) => (
	<>
		<GutterBottomTypography variant="h6">{label}</GutterBottomTypography>
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<GreVerbalInput
					required={verbalRequired}
					label={verbalLabel}
					name={FormNames.greVerbalReasoning}
					fullWidth
					variant={variant}
				/>
			</Grid>
			<Grid item xs={12}>
				<GreQuantInput
					required={quantRequired}
					name={FormNames.greQuantitativeReasoning}
					label={quantLabel}
					fullWidth
					variant={variant}
				/>
			</Grid>
			<Grid item xs={12}>
				<GreWritingInput
					required={writingRequired}
					name={FormNames.greAnalyticalWriting}
					label={writingLabel}
					fullWidth
					variant={variant}
				/>
			</Grid>
		</Grid>
	</>
);
export default GreCompositeInput;
