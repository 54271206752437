import React, { FC } from 'react';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { Box } from '@material-ui/core';
import { styled } from '@cappex/theme';

const CssHidden = styled(Box)`
	display: none;
`;

const HiddenInput: FC<BaseInputProps> = ({
	id,
	name,
	initialValue,
	automationName,
	multiFormInit,
	removeUpdateFields,
}) => {
	return (
		<CssHidden>
			<BaseFormInput
				id={id}
				name={name}
				inputType="hidden"
				initialValue={initialValue}
				automationName={automationName}
				multiFormInit={multiFormInit}
				removeUpdateFields={removeUpdateFields}
			/>
		</CssHidden>
	);
};

export default HiddenInput;
