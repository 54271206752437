import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import { Box, CardMedia, Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { CampaignOfferContent } from '@src/features/offer/constants/constants';
import { findContentValueValue } from '@src/features/offer/util/offerUtil';
import { AutomationNameDefault } from '@util/automation';

export type OfferCardMediaProps = {
	heroImageContent: CampaignOfferContent;
	logoImageContent: CampaignOfferContent;
};

const CardMediaWrapper = styled.div`
	position: relative;
`;

const CardMediaSizing = styled(Box)`
	height: 10rem;
`;

const CardMediaStyled = styled(CardMedia)`
	height: 100%;
` as typeof CardMedia;

const CardMediaLogoStyled = styled(CardMedia)`
	width: 7.5rem;
	max-height: 3.5rem;
	background-size: contain;
	object-fit: contain;
` as typeof CardMedia;

const CardLogoStyled = styled(Card)`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 4.25rem;
	width: 8.25rem;
	position: absolute;
	border: 2px solid ${props => props.theme.palette.common.white};
	box-shadow: ${props => props.theme.shadows[6]};
	top: 6.5rem;
	margin-left: 0.75rem;
`;

const OfferCardMedia: FC<OfferCardMediaProps> = ({ heroImageContent, logoImageContent }) => {
	return (
		<CardMediaWrapper className="pa-offer-card-media">
			<CardMediaSizing>
				{heroImageContent ? (
					<CardMediaStyled
						data-qa={AutomationNameDefault.paOfferCardHero}
						component="img"
						src={findContentValueValue(heroImageContent)}
					/>
				) : (
					<Skeleton height="100%" variant="rect" />
				)}
			</CardMediaSizing>
			<CardLogoStyled>
				{logoImageContent ? (
					<CardMediaLogoStyled
						data-qa={AutomationNameDefault.paOfferCardLogo}
						component="img"
						src={findContentValueValue(logoImageContent)}
					/>
				) : (
					<Skeleton height="100%" variant="rect" />
				)}
			</CardLogoStyled>
		</CardMediaWrapper>
	);
};

export default OfferCardMedia;
