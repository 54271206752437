import { JitEventPayload } from './constants';
import request, { JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { QueueAction } from '../steps/components/ModalStepFlow';
import { StepComponentProps } from '../steps';
import JitModal from '@src/common/components/JitModal';

const checkForJitModal = async (
	data: JitEventPayload,
	queueModal: (
		action: QueueAction,
		key: string,
		type: React.ComponentType<Omit<StepComponentProps<any, any, any>, 'reverse'>>,
		props: any
	) => void
) => {
	const res = await request<any>({
		url: getEndpoint('/user-event/v1/process-event'),
		method: RequestMethod.POST,
		data,
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	});

	res.data?.response?.forEach(async configResponse => {
		if (configResponse.stepConfiguration && configResponse.jitDataFlowId) {
			queueModal(QueueAction.APPEND, `jit-modal-${configResponse.jitDataFlowId}`, JitModal, {
				id: configResponse.jitDataFlowId,
				inputConfig: configResponse.stepConfiguration,
				eventId: configResponse.triggeredEventId,
			});
		}
	});
};

export default checkForJitModal;
