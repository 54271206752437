import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import NameInput from '@common/components/NameInput';
import { OfferBaseFormInputType } from '@src/features/offer/components/inputs/OfferBaseFormInput';

const OfferBaseFormNameInput: FC<OfferBaseFormInputType> = ({
	id,
	name,
	automationName,
	label,
	required = false,
	validCriteria,
}) => (
	<NameInput
		id={id}
		name={name as FormNames}
		automationName={automationName}
		validCriteria={validCriteria}
		label={label}
		required={required}
		fullWidth
	/>
);

export default OfferBaseFormNameInput;
