import React, { FC, useState, useEffect } from 'react';
import * as R from 'ramda';
import { Grid, TextFieldProps } from '@material-ui/core';
import BaseFormSelect from '@common/components/BaseFormSelect';
import ZipCodeInput from '@common/components/ZipCodeInput';
import { AutomationNameDefault } from '@common/util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';
import { Student } from '@util/student/studentDataUtil';
import requiredFieldMessage from '@util/validation/constants';
import { FormFields } from '@util/validation/form';
import { capitalizeWords } from '@src/common/util/string/stringUtil';
import { USA_COUNTRY_ID } from '@common/constants/referenceData';
import MaxLengthInput from './MaxLengthInput';
import { styled } from '@cappex/theme';

const BottomMarginGrid = styled(Grid)`
	margin-bottom: 0.5rem;
`;

const MarginBottomGrid = styled(Grid)`
	margin-bottom: 1.75rem;
`;

const MAX_CHARACTER_LENGTH = 50;
const MAX_NON_US_ZIP_LENGTH = 12;
const MAX_PROVINCE_LENGTH = 85;

const anyAddressFieldsBlank = R.any(R.anyPass([R.isNil, R.isEmpty]));
const allAddressFieldsBlank = R.all(R.anyPass([R.isNil, R.isEmpty]));

const getRequiredMessage = (formValue: string) =>
	R.isNil(formValue) || R.isEmpty(formValue) ? requiredFieldMessage : '';

const getIsUSAddress = (value: string) => R.isNil(value) || value === USA_COUNTRY_ID;

export const validateAddressBlock = (formValues: FormFields) => {
	const isUSAddress = getIsUSAddress(formValues?.countryId);

	const addressBlock = [
		formValues.address1,
		formValues.city,
		isUSAddress ? formValues.stateId : formValues.provinceRegion,
	];
	// Address 2 can be blank but if it isn't blank evey input must be filled
	const expandedBlock = [...addressBlock, formValues.address2];

	let formErrors = {};

	if (anyAddressFieldsBlank(addressBlock) && !allAddressFieldsBlank(expandedBlock)) {
		formErrors = {
			[FormNames.address1]: [getRequiredMessage(formValues.address1)],
			[FormNames.city]: [getRequiredMessage(formValues.city)],
		};

		if (isUSAddress) {
			formErrors[FormNames.stateId] = [getRequiredMessage(formValues.stateId)];
		} else {
			formErrors[FormNames.provinceRegion] = [getRequiredMessage(formValues.provinceRegion)];
		}
	}

	return formErrors;
};

interface AddressBlockProps {
	student: Partial<
		Pick<
			Student,
			| FormNames.countryId
			| FormNames.stateId
			| FormNames.address1
			| FormNames.address2
			| FormNames.city
			| FormNames.postalCode
			| FormNames.provinceRegion
		>
	>;
	required?: boolean;
	hideAddress1Input?: boolean;
	address1Label?: string;
	hideAddress2Input?: boolean;
	hideCityInput?: boolean;
	hideStateInput?: boolean;
	hideZipCodeInput?: boolean;
	hideCountryInput?: boolean;
	defaultCountry?: string;
	filterCountryIds?: string[];
	inputStyle?: TextFieldProps['variant'];
}

const EMPTY_ARRAY = [];

const AddressBlock: FC<AddressBlockProps> = ({
	student,
	required,
	hideAddress1Input,
	address1Label = 'Address Line 1',
	hideAddress2Input,
	hideCityInput,
	hideStateInput,
	hideZipCodeInput,
	hideCountryInput,
	defaultCountry = USA_COUNTRY_ID,
	filterCountryIds = EMPTY_ARRAY,
	inputStyle,
}) => {
	const [isUSAddress, setIsUSAddress] = useState(getIsUSAddress(student?.countryId));

	const countryId = student?.countryId;
	useEffect(() => {
		setIsUSAddress(getIsUSAddress(countryId));
	}, [student, countryId]);

	const [states] = useCloudReferenceData({
		dataType: ReferenceDataTypes.state,
		alphaSort: true,
	});

	const [countries] = useCloudReferenceData({
		dataType: ReferenceDataTypes.country,
		emptyAllowed: defaultCountry === '',
		alphaSort: true,
	});
	const filteredCountries = R.differenceWith(
		(country, id) => country.id === id,
		countries,
		filterCountryIds
	);

	const onCountrySelect = (value: string) => {
		setIsUSAddress(getIsUSAddress(value));
	};

	const countryIdIntitialValue =
		R.isNil(student?.countryId) || R.contains(student?.countryId, filterCountryIds)
			? defaultCountry
			: String(student?.countryId);
	const stateIdIntitialValue = R.isNil(student?.stateId)
		? student?.stateId
		: String(student?.stateId);

	return (
		<Grid item>
			<Grid container spacing={inputStyle === 'outlined' ? 1 : 0}>
				{hideCountryInput ? null : (
					<BottomMarginGrid item xs={12}>
						<BaseFormSelect
							id={AutomationNameDefault.countryInput}
							name={FormNames.countryId}
							required={required}
							label="Country"
							references={filteredCountries}
							initialValue={countryIdIntitialValue}
							automationName={AutomationNameDefault.countryInput}
							onChange={onCountrySelect}
							variant={inputStyle}
						/>
					</BottomMarginGrid>
				)}
				{hideAddress1Input ? null : (
					<Grid item xs={12}>
						<MaxLengthInput
							required={required}
							id={AutomationNameDefault.addressInput1}
							name={FormNames.address1}
							label={address1Label}
							fullWidth
							initialValue={student?.address1}
							automationName={AutomationNameDefault.addressInput1}
							inputMax={MAX_CHARACTER_LENGTH}
							variant={inputStyle}
						/>
					</Grid>
				)}
				{hideAddress2Input ? null : (
					<Grid item xs={12}>
						<MaxLengthInput
							id={AutomationNameDefault.addressInput2}
							name={FormNames.address2}
							label="Address Line 2"
							fullWidth
							initialValue={student?.address2}
							automationName={AutomationNameDefault.addressInput2}
							inputMax={MAX_CHARACTER_LENGTH}
							variant={inputStyle}
						/>
					</Grid>
				)}
				{hideCityInput ? null : (
					<Grid item xs={12}>
						<MaxLengthInput
							required={required}
							id={AutomationNameDefault.cityInput}
							name={FormNames.city}
							label="City"
							fullWidth
							initialValue={student?.city}
							automationName={AutomationNameDefault.cityInput}
							beforeChange={capitalizeWords}
							inputMax={MAX_CHARACTER_LENGTH}
							variant={inputStyle}
						/>
					</Grid>
				)}
				{hideStateInput && hideZipCodeInput ? null : (
					<Grid item xs={12}>
						{/* We must switch inputs to reset the form validation */}
						{isUSAddress ? (
							<Grid container wrap="nowrap" spacing={1} alignItems="center">
								{hideStateInput ? null : (
									<MarginBottomGrid item xs={12}>
										<BaseFormSelect
											id={AutomationNameDefault.stateInput}
											name={FormNames.stateId}
											label="State"
											references={states}
											initialValue={stateIdIntitialValue}
											automationName={AutomationNameDefault.stateInput}
											addEmptyValue
											variant={inputStyle}
										/>
									</MarginBottomGrid>
								)}
								{hideZipCodeInput ? null : (
									<Grid item xs={12}>
										<ZipCodeInput
											required={required}
											name={FormNames.postalCode}
											fullWidth
											initialValue={student?.postalCode}
											variant={inputStyle}
										/>
									</Grid>
								)}
							</Grid>
						) : null}
						{!isUSAddress ? (
							<Grid container wrap="wrap" spacing={1} alignItems="center">
								{hideStateInput ? null : (
									<Grid item xs={12}>
										<MaxLengthInput
											required={required}
											id={AutomationNameDefault.regionInput}
											name={FormNames.provinceRegion}
											label="State / Province / Region"
											fullWidth
											initialValue={student?.provinceRegion}
											automationName={AutomationNameDefault.regionInput}
											beforeChange={capitalizeWords}
											inputMax={MAX_PROVINCE_LENGTH}
											variant={inputStyle}
										/>
									</Grid>
								)}
								{hideZipCodeInput ? null : (
									<Grid item xs={12}>
										<MaxLengthInput
											required={required}
											id={AutomationNameDefault.postalCodeinput}
											name={FormNames.postalCode}
											label="Zip / Postal Code"
											fullWidth
											initialValue={student?.postalCode}
											automationName={AutomationNameDefault.postalCodeinput}
											inputMax={MAX_NON_US_ZIP_LENGTH}
											variant={inputStyle}
										/>
									</Grid>
								)}
							</Grid>
						) : null}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

export default AddressBlock;
