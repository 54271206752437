import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { SubForm } from '@src/common/components/BaseValidationForm';
import CompositeTestScoresInput from '@src/common/components/CompositeTestScoresInput';
import GradDateInput from '@src/common/components/GradDateInput';
import HiddenInput from '@src/common/components/HiddenInput';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import StartTermInput from '@src/common/components/StartTermInput';
import UnweightedGpaInput from '@src/common/components/UnweightedGpaInput';
import { FormNames, ListFormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@src/common/util/validation/form';
import { createChancesConfig } from '../../../registration/constants/chancesConfig';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import NameForm from '@src/common/components/NameForm';
import GenderSelect from '@src/common/components/GenderSelect';
import PreTestScoresInput from '@src/common/components/PreTestScoresInput';
import AnchorButton from '@src/common/components/AnchorButton';
import FirstGenerationStudent from '@common/components/FirstGenerationStudent';
import EthnicityCheckboxList from '@common/components/EthnicityCheckboxList';

type OwnProps = {};
type StudentCollegesForm = {
	studentColleges: object[];
};

const RegisterButton = withStyleOptions(Button);

const handleFailure = (
	json: any,
	setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) => {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setButtonDisabled(false);
		const formErrors: FormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
};

const ChancesCollegeInfoPage: FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	OwnProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	complete,
	reverse,
	active,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { getValue, setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic } = useContext(AuthContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [isReverseCalled, setIsReverseCalled] = useState(false);
	const [isUsingPreActOrPsat, setIsUsingPreActOrPsat] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			} else {
				handleFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	const onAnchorButtonClick = useCallback(() => {
		setIsUsingPreActOrPsat(usingPsatOrPreAct => !usingPsatOrPreAct);
	}, []);

	const studentCollegeFormValues = useMemo(
		() => getValue(ListFormNames.studentColleges) as StudentCollegesForm,
		[getValue]
	);

	useEffect(() => {
		if (
			!isReverseCalled &&
			active &&
			(R.isEmpty(studentCollegeFormValues) || R.isEmpty(studentCollegeFormValues.studentColleges))
		) {
			setIsReverseCalled(true);
			reverse({ newConfig: createChancesConfig(), newStep: 0 });
		}
	}, [reverse, studentCollegeFormValues, isReverseCalled, active]);

	if (
		(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
		active
	) {
		complete();
	}

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<SubForm name="student">
					<SubForm name="studentHighSchoolDataForm">
						<Grid item xs={12}>
							<Typography variant="h6">GPA</Typography>
							<UnweightedGpaInput
								fullWidth
								required={active}
								name={FormNames.gpaUnweighted}
								label="Unweighted GPA"
								helperText="Up to 4.00"
							/>
						</Grid>
					</SubForm>
					<SubForm name="studentTestScoreForm">
						{isUsingPreActOrPsat ? (
							<Grid item xs={12}>
								<PreTestScoresInput
									label="Test Scores"
									subText="Only one is required"
									required={active}
								/>
								<AnchorButton
									text="I've taken the ACT or SAT"
									onClick={onAnchorButtonClick}
									center={false}
								/>
							</Grid>
						) : (
							<Grid item xs={12}>
								<CompositeTestScoresInput
									label="Test Scores"
									subText="Only one is required"
									required={active}
								/>
								<AnchorButton
									text="I haven't taken the ACT or SAT"
									onClick={onAnchorButtonClick}
									center={false}
								/>
							</Grid>
						)}
					</SubForm>
					<SubForm name="studentHighSchoolDataForm">
						<Grid item xs={12}>
							<GradDateInput label="High School Graduation Date" />
							<HiddenInput name={FormNames.requireHighSchoolId} initialValue="false" />
						</Grid>
					</SubForm>
					<SubForm name="studentCollegeDataForm">
						<Grid item xs={12}>
							<StartTermInput label="Expected College Start Term" />
						</Grid>
					</SubForm>
					<SubForm name="studentDataForm">
						<Grid item xs={12}>
							<NameForm
								fullWidth
								required={active}
								label="Name"
								firstNameLabel="First Name"
								lastNameLabel="Last Name"
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="h6">Gender</Typography>
							<GenderSelect name={FormNames.genderId} label="Gender (Optional)" />
						</Grid>
					</SubForm>
					<SubForm name="studentCollegeDataForm">
						<Grid item xs={12}>
							<FirstGenerationStudent />
						</Grid>
					</SubForm>
					<SubForm name="studentEthnicityForm">
						<Grid item xs={12}>
							<EthnicityCheckboxList subText="Optional" />
						</Grid>
					</SubForm>
				</SubForm>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my chances' : 'Next'}
					</RegisterButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default ChancesCollegeInfoPage;
