import React, { FC, useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { styled, ThemeContext } from '@cappex/theme';
import { Divider, Link, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { AutomationNameGeneric } from '@util/automation';
import { NavigationLink } from '@util/links/navigationLinks';
import NavIcon from './NavIcon';

export const ListIconWrapper = styled(ListItemIcon)`
	align-self: center;
	min-width: 1.5rem;
	display: block;
	text-align: center;
	visibility: visible;
`;

const MenuItemWrapper = styled(MenuItem)`
	padding: 0;
	border-radius: 4px;
	margin 0 0.5rem 0 0.5rem; 
	&:hover {
		background-color: ${props => props.theme.palette.common.white}
	},
	&:focus
`;

export const ItemMenuItemDiv = styled.div<{ active: boolean }>`
	${props => (props.active ? `background-color: ${props.theme.palette.primary.transparent};` : ``)};
	display: flex;
	padding: 0.75rem 1rem;
	width: 100%;
`;

export const StyledListItemText = styled(ListItemText)`
	margin: 0 1rem;
	color: ${({ theme: { palette } }) => palette.ink.dark};
`;

export const StyledListItemTextActive = styled(ListItemText)`
	margin: 0 1rem;
	color: ${({ theme: { palette } }) => palette.primary.dark};
`;

export const StyledNoIconListItemText = styled(ListItemText)`
	margin: 0 1rem 0 0.5rem;
	color: ${({ theme: { palette } }) => palette.ink.dark};
`;

export const StyledNoIconListItemTextActive = styled(ListItemText)`
	margin: 0 1rem 0 0.5rem;
	color: ${({ theme: { palette } }) => palette.primary.main};
`;

export const NavListDivider = styled(Divider)`
	margin 0.5rem;
`;

export const primarySidebarTypographyProps: TypographyProps = {
	variant: 'body1',
	color: 'inherit',
};

const BoldTypography = styled(Typography)`
	font-weight: bold;
`;

type Props = {
	handleDrawerClose: (e: any) => void;
	listItem: NavigationLink;
};

const SidebarListItem: FC<Props> = ({ handleDrawerClose, listItem }) => {
	const theme = useContext(ThemeContext);
	const isActive = useLocation().pathname.includes(listItem.link);

	return (
		<>
			<Link underline="none" to={listItem.link} component={RouterLink} color="inherit">
				<MenuItemWrapper button onClick={handleDrawerClose}>
					<ItemMenuItemDiv active={isActive}>
						{isActive ? (
							<>
								<ListIconWrapper>
									<NavIcon
										data-qa={`${AutomationNameGeneric.section}-${AutomationNameGeneric.icon}`}
										iconName={listItem.icon}
										color={theme.palette.primary.main}
									/>
								</ListIconWrapper>
								<StyledListItemTextActive
									data-qa={AutomationNameGeneric.link}
									primary={
										<BoldTypography {...primarySidebarTypographyProps}>
											{listItem.name}
										</BoldTypography>
									}
									primaryTypographyProps={primarySidebarTypographyProps}
								/>
							</>
						) : (
							<>
								<ListIconWrapper>
									<NavIcon
										data-qa={`${AutomationNameGeneric.section}-${AutomationNameGeneric.icon}`}
										iconName={listItem.icon}
										color={theme.palette.ink.main}
									/>
								</ListIconWrapper>
								<StyledListItemText
									data-qa={AutomationNameGeneric.link}
									primary={listItem.name}
									primaryTypographyProps={primarySidebarTypographyProps}
								/>
							</>
						)}
					</ItemMenuItemDiv>
				</MenuItemWrapper>
			</Link>
			{listItem.divider && <NavListDivider color={theme.palette.base.dark} />}
		</>
	);
};

export default SidebarListItem;
