import request, { JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { CollegeDismissal } from '../CollegeDismissalConstants';

const dismissCollege = async (collegeDismissal: CollegeDismissal, onError?: () => void, failAuth?: () => void): Promise<boolean> => {
	try {
		const { data } = await request<any>({
			url: getEndpoint('/college-dismissal/v2/create'),
			method: RequestMethod.POST,
			data: collegeDismissal,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		})

		return data.meta.success;
	} catch (err) {
		if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
			failAuth?.()
		}
		onError?.()

		return false;
	}
};

export default dismissCollege;