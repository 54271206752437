import React, { FC } from 'react';
import { CircularProgress } from '@material-ui/core';
import ErrorBlock from './ErrorBlock';
import { styled } from '@cappex/theme';

const WrapperDiv = styled.div`
	overflow: hidden;
`;

const StyledDiv = styled.div`
	width: 100%;
	padding: 8rem 46%;
`;

const Loading: FC<any> = ({ error, retry }) => {
	if (error) {
		return <ErrorBlock retry={retry} />;
	}
	return (
		<WrapperDiv>
			<StyledDiv>
				<CircularProgress size="8%" disableShrink />
			</StyledDiv>
		</WrapperDiv>
	);
};

export default Loading;
