import { withStyles } from '@material-ui/core';

const globalStyles = {
	'@global': {
		'#onetrust-banner-sdk.otFlat': {
			top: 0,
			bottom: 'unset !important',
		},
	},
};

const GlobalCss = withStyles(globalStyles)(() => null);

export default GlobalCss;
