import * as R from 'ramda';
import { useEffect, useState, useCallback } from 'react';
import useFailedAuth from '@common/util/auth/hooks/useFailedAuth';
import getEndpoint, { FormKeyedData } from '@util/request';
import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';
import { WamcResults } from '../wamc/constants';

type WamcDataWebResponse = WebResponse<FormKeyedData, WamcResults[]>;

type UseStudentCollegeWamcScoreReturn = [WamcResults, () => void];

const useStudentCollegeWamcScore = (
	collegeId: number,
	onError: () => void
): UseStudentCollegeWamcScoreReturn => {
	const [wamcScore, setWamcScore] = useState<WamcResults>();
	const failAuth = useFailedAuth();

	const refreshWamc = useCallback(() => {
		if (R.isNil(collegeId)) {
			return;
		}

		request<WamcDataWebResponse>({
			url: getEndpoint('/wamc/v1/calculateWamcForStudent', [
				{ name: 'collegeId', value: collegeId },
			]),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		})
			.then(res => setWamcScore(res.data.response[0]))
			.catch(err => {
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					failAuth();
					return;
				}
				onError();
			});
	}, [collegeId, failAuth, onError]);

	useEffect(() => {
		refreshWamc();
	}, [refreshWamc]);

	return [wamcScore, refreshWamc];
};

export default useStudentCollegeWamcScore;
