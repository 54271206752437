import { ConvertedLocationData } from '@util/preferences/constants';
import { styled } from '@cappex/theme';
import { Grid } from '@material-ui/core';

export const OUTSIDE_US_TEXT = 'Outside the U.S.';
export const TERRITORY_REGION_INDEX = 4;
export const MAX_SELECTED_MAJORS = 5;
export const MAX_SELECTED_CHIPS = 2;

export interface LocationOptionProps {
	isSelected: boolean;
	studentPreferences: ConvertedLocationData[];
	setStudentPreferences: (preferences: ConvertedLocationData[]) => void;
}

export const ControlWrapper = styled.div`
	padding-top: 0.5rem;
	padding-bottom: 1rem;
`;

export const ChipContainer = styled(Grid)`
	margin-top: 0.5rem;
	margin-bottom: 0;
`;
