import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';

type MostRecentDegreeProps = Omit<Partial<BaseFormSelectProps>, 'references'>;
const MostRecentDegree: FC<MostRecentDegreeProps> = ({
	id = AutomationNameDefault.mostRecentDegree,
	name = FormNames.lastDegreeCompletedId,
	label = 'What is your most recent degree?',
	initialValue,
	required,
	automationName = AutomationNameGeneric.formSelect,
	variant,
}) => {
	const [lastDegreeCompleted] = useCloudReferenceData({
		dataType: ReferenceDataTypes.mostRecentDegree,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			references={lastDegreeCompleted}
			initialValue={initialValue}
			required={required}
			automationName={automationName}
			addEmptyValue
			variant={variant}
		/>
	);
};

export default MostRecentDegree;
