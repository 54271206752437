import React, { FC } from 'react';
import {
    CampaignOfferContent,
} from '@src/features/offer/constants/constants'
import OfferBaseFormHiddenInput from '@src/features/offer/components/inputs/OfferBaseFormHiddenInput';
import { findContentValueValue } from '@src/features/offer/util/offerUtil';

const OfferCardHiddenInput: FC<{ content: CampaignOfferContent; automationName: string; }> = ({
    content,
    automationName,
}) => {
    const contentId = `${content.id}`;
    const value = findContentValueValue(content);
    return (
        <div>
            <OfferBaseFormHiddenInput
                id={contentId}
                name={contentId}
                automationName={automationName}
                value={value}
            />
        </div>
    );
};

export default OfferCardHiddenInput;
