import * as R from 'ramda';
import { PUBLIC_HOMEPAGE_URL, REDIRECT_KEY } from '@common/util/constants';

const checkLockout = (data: { response: { [x: string]: any } }) => {
	if (!R.isNil(data.response) && !R.isNil(data.response[REDIRECT_KEY])) {
		window.location.href = PUBLIC_HOMEPAGE_URL;
		return true;
	}
	return false;
};

export default checkLockout;
