export enum DismissalType {
	GENERAL = 'GENERAL',
	OUTREACH = 'OUTREACH',
	MATCH = 'MATCH'
}

export type CollegeDismissal = {
	collegeDismissalType: DismissalType;
	collegeId: number;
};
