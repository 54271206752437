import React, { FC } from 'react';
import { Box, Card, CardContent, CardMedia, Container, Grid, Hidden } from '@material-ui/core';
import { AutomationNameDefault } from '@src/common/util/automation';
import StepCounter, { StepCounterVariant } from '@src/common/util/steps/components/StepCounter';
import { styled } from '@cappex/theme';
import { DataFlowMedia } from '../constants/types';
import DataFlowMediaDisplay from './DataFlowMediaDisplay';
import * as R from 'ramda';
import { DataFlowContainerVariant } from '@cappex/constants';
import Footer from '@src/common/components/Footer';
import HorizontalLogo from '@src/common/components/HorizontalLogo';

type DataFlowContainerProps = {
	variant?: DataFlowContainerVariant;
	leftMedia?: DataFlowMedia;
	rightMedia?: DataFlowMedia;
	topMedia?: DataFlowMedia;
	backgroundMedia?: string;
	showLeftTextMediaMobile?: boolean;
	hideLeftMediaImageMobile?: boolean;
	showRightTextMedia?: boolean;
	currentStep?: number;
	totalSteps?: number;
	centered?: boolean;
	customLogoUrl?: string;
};

const MarginBottomGrid = styled(Grid)`
	margin-bottom: 10rem;
`;

const PaddingBottom = styled.div`
	padding-bottom: 2.5rem;
`;

const MaxWidthCenteredGrid = styled(Grid)`
	position: relative;
	min-height: calc(100vh - 5rem);
	max-width: 60rem;
	margin: auto;
	width: 100%;
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		padding: 1rem;
		margin-top: 3rem;
	}
`;
const FullWidthGrid = styled(Grid)`
	width: 100%;
`;

const TopMarginGrid = styled(Grid)`
	margin-top: 3.5rem;
`;

const FullSizeImage = styled.img`
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	object-fit: cover;
`;

const DefaultBackground = styled.div`
	position: fixed;
	top: 0;
	height: 100vh;
	width: 100vw;
	background-color: ${props => props.theme.palette.base.main};
`;

const RelativeCard = styled(Card)`
	overflow: visible;
	position: relative;
	border-radius: 0;

	@media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
		margin: 3rem 0;
		border-radius: 0.5rem;
		& > img {
			border-radius: 0.5rem 0.5rem 0 0;
		}
	}
`;

const PaddedContainer = styled.div`
	position: relative;
	padding-bottom: 5rem;
	min-height: calc(100vh - 8rem);
`;

const ModalDataFlowContainer: FC<Pick<
	DataFlowContainerProps,
	'topMedia' | 'backgroundMedia' | 'currentStep' | 'totalSteps'
>> = ({ topMedia, currentStep, totalSteps, children }) => (
	<div>
		{topMedia?.media && <CardMedia component="img" height="176" image={topMedia?.media} />}
		<CardContent>
			<Grid container spacing={4} direction="column" justifyContent="center" alignItems="center">
				<Grid item />
				{R.not(R.isNil(currentStep) || R.isNil(totalSteps)) && (
					<FullWidthGrid item xs={11}>
						<StepCounter
							variant={StepCounterVariant.LOADING}
							current={currentStep}
							total={totalSteps}
						/>
					</FullWidthGrid>
				)}
				{topMedia && (
					<Grid item xs={11}>
						<DataFlowMediaDisplay
							centerText
							header={topMedia?.header}
							headerVariant="h5"
							text={topMedia?.text}
							innerSpacing={2}
						/>
					</Grid>
				)}
				<FullWidthGrid xs={11} item>
					{children}
				</FullWidthGrid>
			</Grid>
		</CardContent>
	</div>
);

const CenteredDataFlowContainer: FC<Pick<
	DataFlowContainerProps,
	'topMedia' | 'backgroundMedia' | 'currentStep' | 'totalSteps' | 'customLogoUrl'
>> = ({ topMedia, backgroundMedia, currentStep, totalSteps, children, customLogoUrl }) => (
	<PaddedContainer>
		<Box>{backgroundMedia ? <FullSizeImage src={backgroundMedia} /> : <DefaultBackground />}</Box>
		<Container maxWidth="xs" disableGutters>
			<RelativeCard raised>
				{topMedia?.media && <CardMedia component="img" height="176" image={topMedia?.media} />}
				<CardContent>
					<Grid
						container
						spacing={4}
						direction="column"
						justifyContent="center"
						alignItems="center"
					>
						<Grid item />
						{R.not(R.isNil(currentStep) || R.isNil(totalSteps)) && (
							<FullWidthGrid item xs={11}>
								<StepCounter
									variant={StepCounterVariant.LOADING}
									current={currentStep}
									total={totalSteps}
								/>
							</FullWidthGrid>
						)}
						{topMedia && (
							<Grid item xs={11}>
								<DataFlowMediaDisplay
									centerText
									header={topMedia?.header}
									headerVariant="h5"
									text={topMedia?.text}
									innerSpacing={2}
								/>
							</Grid>
						)}
						<FullWidthGrid xs={11} item>
							{children}
						</FullWidthGrid>
						<Grid item>
							<PaddingBottom>
								<HorizontalLogo logoUrl={customLogoUrl} />
							</PaddingBottom>
						</Grid>
					</Grid>
				</CardContent>
			</RelativeCard>
			<Footer usingCustomBackground={!!backgroundMedia} />
		</Container>
	</PaddedContainer>
);

const TwoColumnDataFlowContainer: FC<Exclude<
	DataFlowContainerProps,
	'topMedia' | 'variant' | 'backgroundMedia'
>> = ({
	leftMedia,
	rightMedia,
	showLeftTextMediaMobile,
	hideLeftMediaImageMobile = false,
	showRightTextMedia,
	currentStep,
	totalSteps,
	children,
	centered,
	customLogoUrl,
}) => (
	<MaxWidthCenteredGrid container spacing={4} justifyContent="space-between">
		<Hidden smDown>
			<TopMarginGrid item md={5} data-qa={AutomationNameDefault.dataFlowLeftContainer}>
				<DataFlowMediaDisplay centerText {...leftMedia} />
			</TopMarginGrid>
		</Hidden>
		<MarginBottomGrid item xs={12} md={5} data-qa={AutomationNameDefault.dataFlowRightContainer}>
			<Grid
				container
				direction="column"
				spacing={3}
				justifyContent="flex-start"
				alignItems={centered ? 'center' : undefined}
			>
				<Grid item>
					<HorizontalLogo logoUrl={customLogoUrl} />
				</Grid>
				{showLeftTextMediaMobile && (
					<Hidden mdUp>
						<Grid item>
							<DataFlowMediaDisplay
								limitMediaHeight
								hideImageMobile={hideLeftMediaImageMobile}
								{...leftMedia}
							/>
						</Grid>
					</Hidden>
				)}
				{((currentStep && totalSteps) || showRightTextMedia) && (
					<Grid item>
						{currentStep && totalSteps && <StepCounter current={currentStep} total={totalSteps} />}
						{showRightTextMedia && <DataFlowMediaDisplay limitMediaHeight {...rightMedia} />}
					</Grid>
				)}
				<Grid item>{children}</Grid>
			</Grid>
		</MarginBottomGrid>
		<Footer />
	</MaxWidthCenteredGrid>
);

const DataFlowContainer: FC<DataFlowContainerProps> = ({
	variant = DataFlowContainerVariant.TWO_COLUMN,
	leftMedia,
	rightMedia,
	topMedia,
	backgroundMedia,
	showLeftTextMediaMobile,
	hideLeftMediaImageMobile = false,
	showRightTextMedia,
	currentStep,
	totalSteps,
	children,
	centered,
	customLogoUrl,
}) => {
	switch (variant) {
		case DataFlowContainerVariant.CENTERED:
			return (
				<CenteredDataFlowContainer
					topMedia={topMedia}
					backgroundMedia={backgroundMedia}
					currentStep={currentStep}
					totalSteps={totalSteps}
					customLogoUrl={customLogoUrl}
				>
					{children}
				</CenteredDataFlowContainer>
			);
		case DataFlowContainerVariant.MODAL:
			return (
				<ModalDataFlowContainer
					topMedia={topMedia}
					currentStep={currentStep}
					totalSteps={totalSteps}
				>
					{children}
				</ModalDataFlowContainer>
			);
		case DataFlowContainerVariant.TWO_COLUMN:
		default:
			return (
				<TwoColumnDataFlowContainer
					leftMedia={leftMedia}
					rightMedia={rightMedia}
					showLeftTextMediaMobile={showLeftTextMediaMobile}
					hideLeftMediaImageMobile={hideLeftMediaImageMobile}
					showRightTextMedia={showRightTextMedia}
					currentStep={currentStep}
					totalSteps={totalSteps}
					centered={centered}
					customLogoUrl={customLogoUrl}
				>
					{children}
				</TwoColumnDataFlowContainer>
			);
	}
};

export default DataFlowContainer;
