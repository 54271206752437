import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import { Dialog, makeStyles } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { AutomationNameDefault } from '@util/automation';
import { CampaignOfferContent } from '@src/features/offer/constants/constants';
import { OfferCardBaseProps } from './OfferCard';
import { v4 as uuidv4 } from 'uuid';
import { IFrameData, PAOfferData } from '@src/common/window/Window';
import { OfferCardModalContentProps } from './OfferCardModalContent';

const DialogPaper = styled(Paper)`
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	width: 100%;
`;

const useStyles = makeStyles({
	root: { width: '100vw' },
	iframe: { border: 'none', width: '100%', }
});

export interface OfferCardModalProps extends OfferCardBaseProps {
	campaignOfferId: number;
	show: boolean;
	onModalDismiss?: () => void;
	modalCampaignOfferContent: CampaignOfferContent[];
}

const resizeIframe = (id) => {
	const iframe = document.getElementById(id) as HTMLIFrameElement;
	iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
	window.addEventListener("message", () => {
		setTimeout(() => {
			iframe.height = `${iframe.contentWindow.document.body.scrollHeight}px`;
		}, 400)
	}, { once: true })
}

const OfferCardModal: FC<OfferCardModalProps> = ({
	campaignOfferId,
	show = false,
	onModalDismiss = () => { },
	onOfferAccepted = () => { },
	placement,
	modalCampaignOfferContent,
}) => {
	const classes = useStyles();

	const uuid = uuidv4();
	window.iframeData = window.iframeData || {} as IFrameData;
	window.iframeData.PAOfferData = window.iframeData.PAOfferData || {} as PAOfferData;

	if (show) {
		window.iframeData.PAOfferData[uuid] = {
			campaignOfferId,
			onOfferAccepted: (offerId) => {
				onOfferAccepted(offerId);
				const { [uuid]: _, ...data } = window.iframeData.PAOfferData
				window.iframeData.PAOfferData = data
			},
			onModalDismiss: () => {
				onModalDismiss();
				const { [uuid]: _, ...data } = window.iframeData.PAOfferData
				window.iframeData.PAOfferData = data
			},
			modalCampaignOfferContent,
			placement
		} as OfferCardModalContentProps
	}

	return (
		<Dialog
			PaperComponent={DialogPaper}
			classes={{ root: classes.root }}
			onClose={onModalDismiss}
			open={show}
			scroll="body"
			maxWidth="sm"
			data-qa={AutomationNameDefault.paOfferModal}
		>
			<iframe
				className={classes.iframe}
				id={`${campaignOfferId}-iframe`}
				title={`${campaignOfferId}-iframe`}
				src={`/offer/pa/modal?uuid=${uuid}`}
				onLoad={() => resizeIframe(`${campaignOfferId}-iframe`)}
			/>
		</Dialog>
	);
};

export default OfferCardModal
