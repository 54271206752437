import React, { FC, useEffect, useRef, useState } from 'react';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faGift } from '@fortawesome/pro-light-svg-icons/faGift';
import { styled, theme } from '@cappex/theme';
import ListItem from '@material-ui/core/ListItem';
import { Paper } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Link from '@material-ui/core/Link';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { NavigationLink } from '@util/links/navigationLinks';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import SidebarNavHeaderLogoutListItem, {
	NavHeaderMenuItem,
} from '@common/components/SidebarNavHeaderLogoutListItem';
import HorizontalLogo from './HorizontalLogo';

export const OFFERS_PATH = '/offers';

const navigationLinks = [
	{
		name: 'Settings',
		link: '/settings',
	},
	{
		name: 'Help',
		link: 'https://help.appily.com/support/home',
		openInNewTab: true,
	},
] as NavigationLink[];

const NavHeaderListItem = styled(ListItem)`
	height: 3.5rem;
	padding-left: 1.75rem;
	justify-content: space-between;
	&:hover {
		background: none;
		cursor: initial;
	}
`;

const StyledPaper = styled(Paper)`
	border-radius: 4px;
	width: 15.75rem;
`;

const StyledPopper = styled(Popper)`
	z-index: 1;
`;

const NavHeaderMenuList = styled(MenuList)`
	border-radius: 4px;
	padding: 0;
`;

const StyledIconButton = styled(IconButton)`
	margin-left: 2.5rem;
`;

const StyledLink = styled(Link)`
	height: 1.1875rem;
` as typeof Link;

const getNavLinkDynamicProps = (navLink: NavigationLink) =>
	navLink.openInNewTab
		? {
				href: navLink.link,
				target: '_blank',
				rel: 'noreferrer',
		  }
		: {
				component: RouterLink,
				to: navLink.link,
		  };

const SidebarNavHeader: FC<{
	handleDrawerClose: (e: any) => void;
}> = ({ handleDrawerClose }) => {
	const [open, setOpen] = useState(false);
	const anchorRef = useRef<HTMLButtonElement>(null);
	const isOffersActive = useLocation().pathname.includes(OFFERS_PATH);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpen(false);
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	useEffect(() => {
		if (prevOpen.current && !open && anchorRef.current) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	const { pathname } = useLocation();

	return (
		<NavHeaderListItem button disableRipple data-qa={AutomationNameDefault.sideNavHeader}>
			<StyledLink
				to="/dashboard"
				component={RouterLink}
				onClick={e => {
					handleDrawerClose(e);
				}}
			>
				<HorizontalLogo data-qa={AutomationNameDefault.logo} />
			</StyledLink>
			<Link underline="none" to={OFFERS_PATH} component={RouterLink} color="inherit">
				<StyledIconButton
					ref={anchorRef}
					onClick={e => {
						handleDrawerClose(e);
					}}
				>
					<FontAwesomeIcon
						data-qa={AutomationNameGeneric.icon}
						icon={faGift}
						color={isOffersActive ? theme.palette.primary.main : theme.palette.ink.light}
					/>
				</StyledIconButton>
			</Link>
			<IconButton
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<FontAwesomeIcon
					data-qa={AutomationNameGeneric.icon}
					icon={faCog}
					color={theme.palette.ink.light}
				/>
			</IconButton>
			<StyledPopper placement="bottom-end" open={open} anchorEl={anchorRef.current} disablePortal>
				<StyledPaper elevation={10}>
					<ClickAwayListener onClickAway={handleClose}>
						<NavHeaderMenuList
							data-qa={AutomationNameDefault.sideNavMenuList}
							autoFocusItem={open}
							id="menu-list-grow"
						>
							{navigationLinks.map(navLink => (
								<Link
									{...getNavLinkDynamicProps(navLink)}
									data-qa={navLink.name}
									key={navLink.name}
									underline="none"
									color="textPrimary"
									onClick={e => {
										handleClose(e);
										handleDrawerClose(e);
									}}
								>
									<NavHeaderMenuItem selected={pathname.includes(navLink.link)}>
										{navLink.name}
									</NavHeaderMenuItem>
								</Link>
							))}
							<SidebarNavHeaderLogoutListItem />
						</NavHeaderMenuList>
					</ClickAwayListener>
				</StyledPaper>
			</StyledPopper>
		</NavHeaderListItem>
	);
};

export default SidebarNavHeader;
