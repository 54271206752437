import React, { FC, useContext, useState } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import { StepContainerProps } from '@src/common/util/steps';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContainer from '../DataFlowContainer';
import { AutomationNameDefault } from '@src/common/util/automation';
import { styled } from '@cappex/theme';
import useUserData from '@src/common/util/hooks/useUserData';
import { appUrl } from '@src/features/environment';
import { InviteReferralCard } from '@src/common/components/InviteReferralCard';

type OwnProps = {};

const RegisterButton = withStyleOptions(Button);

const NoThanksButton = styled(withStyleOptions(Button))`
	font-size: 1rem;
	margin-top: 1rem;
`;

const EMPTY_FUNCTION = () => {};

const InviteFriendLinkPage: FC<DataFlowStepComponent<any, any> & StepContainerProps & OwnProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		legalTextConfig,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);
	const [isLinkDisplayed, setIsLinkDisplayed] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const friendLink = `${appUrl}/register/scholarship?utm_source=referral&utm_content=${userInfo.accountUuid}`;

	const copyLinkClick = async () => {
		if (!navigator.clipboard) {
			return;
		}
		try {
			await navigator.clipboard.writeText(friendLink);
			openSnackbar({
				snackbarType: SnackbarType.Success,
				message: 'Link copied!',
			});
		} catch (e) {
			openErrorSnack('Error copying link');
		}
	};

	const showLinkClick = () => {
		setIsLinkDisplayed(true);
	};

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<InviteReferralCard
						friendLink={friendLink}
						isLinkDisplayed={isLinkDisplayed}
						showLinkClick={showLinkClick}
						copyLinkClick={copyLinkClick}
					/>
				</Grid>
				<Grid item xs={12}>
					<LegalTextAndLinks configs={legalTextConfig} />
				</Grid>
				<Grid item xs={12}>
					<RegisterButton
						data-qa={AutomationNameDefault.registerButton}
						$noneTextTransform
						$boldFontWeight
						variant="contained"
						color="primary"
						fullWidth
						onClick={complete}
					>
						Next
					</RegisterButton>
					<NoThanksButton
						data-qa="no-thanks-button"
						onClick={complete}
						$grey500Text
						type="button"
						$noneTextTransform
						$boldFontWeight
						fullWidth
					>
						No Thanks
					</NoThanksButton>
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default InviteFriendLinkPage;
