import { FC, useEffect, useContext } from 'react';
import { ConsentType } from '@src/features/consent/consentUtil';
import useCollegesWithConsentsData from '@util/hooks/useCollegesWithConsentsData';
import { StepComponentProps } from '@src/common/util/steps';
import * as R from 'ramda';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import GDPRConsentModal from './components/GDPRConsentModal';
import TCPAConsentModal from './components/LibertyTCPAConsentModal';
import { CompleteCollege } from '@src/common/util/college/collegeDataUtil';
import { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';

type ConsentModalProps = StepComponentProps & {
	collegeIdsToCheck: number[];
	setCollegeIdsToCheck: (ids: number[]) => void;
	whiteBackdrop?: boolean;
	onClose?: () => void;
};

const ConsentManager: FC<ConsentModalProps> = ({
	collegeIdsToCheck,
	complete,
	whiteBackdrop,
	onClose,
}) => {
	// find out which colleges have consents; skip the second returned value
	const [collegesToDisplayConsent, , hasLoaded] = useCollegesWithConsentsData(collegeIdsToCheck);
	const { queueModal } = useContext(ModalContext);

	useEffect(() => {
		if (!hasLoaded) {
			return;
		}

		// Split the consents by type
		const sortedConsentColleges = R.pipe(
			R.map(({ id, college, consents }) =>
				R.map((consent: ReferenceData) => ({
					id,
					consentId: consent?.id,
					consents: [consent],
					college,
				}))(consents)
			),
			R.flatten,
			R.groupBy(R.prop('consentId'))
		)(collegesToDisplayConsent) as { [key in ConsentType]?: Partial<CompleteCollege>[] };

		// TCPA Lists a new modal for each college
		const TCPAConsentColleges = sortedConsentColleges[ConsentType.TCPA];
		if (!R.isNil(TCPAConsentColleges) && !R.isEmpty(TCPAConsentColleges)) {
			R.forEach((college: Partial<CompleteCollege>) =>
				queueModal(
					QueueAction.PREPEND_AFTER_CURRENT,
					`TCPA_CONSENT_${college.college.name}`,
					TCPAConsentModal,
					{
						collegeToDisplayConsent: college,
						whiteBackdrop,
						onClose,
					}
				)
			)(TCPAConsentColleges);
		}

		// GDPR Consents go in one big swoop
		const GDPRConsentColleges = sortedConsentColleges[ConsentType.GDPR];
		if (!R.isNil(GDPRConsentColleges) && !R.isEmpty(GDPRConsentColleges)) {
			queueModal(QueueAction.PREPEND_AFTER_CURRENT, 'GDPR_CONSENT', GDPRConsentModal, {
				collegesToDisplayConsent: GDPRConsentColleges,
				whiteBackdrop,
				onClose,
			});
		}

		complete();
	}, [collegesToDisplayConsent, complete, queueModal, hasLoaded, whiteBackdrop, onClose]);

	return null;
};

export default ConsentManager;
