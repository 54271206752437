import { Criteria } from '@common/util/validation/constants';

const validEmailRegex = new RegExp(/^.+@.+\..+$/);
const isEmailValid = (str: string): boolean => !str || validEmailRegex.test(str);

const emailMessage = 'Please enter a valid email address';
const nonMatchingEmailMessage = 'Email Addresses do not match';

const validEmailCriteria: Criteria = (email: string) => {
	const isValid = isEmailValid(email.toLocaleLowerCase());
	return isValid ? '' : emailMessage;
};

const matchingEmailCriteria: Criteria = (email: string, otherEmail: string) => {
	return email === otherEmail ? '' : nonMatchingEmailMessage;
};

export {
	// validation failure message - exported primarily for testing
	emailMessage,
	nonMatchingEmailMessage,
	// validation criteria - to be used in components.
	validEmailCriteria,
	matchingEmailCriteria,
};
