import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';

type SeekingMBAProps = Omit<Partial<BaseFormSelectProps>, 'references'>;

const SeekingMBA: FC<SeekingMBAProps> = ({ required }) => (
	<YesNoSelectorInput
		title=""
		name={FormNames.seekingMba}
		defaultValue={YesNoSelection.NO_ANSWER}
		required={required}
	/>
);

export default SeekingMBA;
