import React, { FC, useEffect, useState, useMemo } from 'react';
import { RoleId } from '@src/common/util/roles/constants';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useUrlParam from '@src/common/util/hooks/useUrlParam';
import getEndpoint, { FormKeyedData } from '@util/request';
import * as R from 'ramda';
import useCloudReferenceData, { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';
import useFailedAuth from '@src/common/util/auth/hooks/useFailedAuth';
import request, {
	RequestMethod,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	WebResponse,
} from '@cappex/request';
import InviteTokenUrlParam from '../constants';
import InviteDialog from '../components/InviteDialog';
import convertInvite from '../util/InviteConversion';

type Token = [string, string];

const inviteModalAction = (setShow: (shouldShow: boolean) => void, token: Token) => (
	status: boolean
) => {
	setShow(false);
	request({
		url: getEndpoint('/invite/v1/finalize'),
		method: RequestMethod.POST,
		data: {
			uuid1: token[0],
			uuid2: token[1],
			accept: status,
		},
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	});
};

const InviteManager: FC<{}> = () => {
	const firstToken = useUrlParam(InviteTokenUrlParam.first);
	const secondToken = useUrlParam(InviteTokenUrlParam.second);

	const token: Token = useMemo(() => [firstToken, secondToken], [firstToken, secondToken]);

	const failAuth = useFailedAuth();

	const [show, setShow] = useState(false);
	const [roleId, setRoleId] = useState(undefined);
	const [hostEmail, setHostEmail] = useState('');
	const [hostFirstName, setHostFirstName] = useState('');
	const [relationships] = useCloudReferenceData({
		dataType: ReferenceDataTypes.parentStudentRelationship,
	});
	const [parentStudentRelationship, setParentStudentRelationship] = useState('');
	useEffect(() => {
		// No Token, bail out
		if (token.filter(x => x).length === 0) {
			return;
		}

		request<WebResponse<FormKeyedData, any>>({
			url: getEndpoint('/invite/v1/retrieve', [
				{ name: 'uuid1', value: token[0] },
				{ name: 'uuid2', value: token[1] },
			]),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		})
			.then(res => res.data)
			.then(convertInvite)
			.then(data => {
				if (R.isNil(data.roleId)) {
					return; // Do Nothing if we get no valid response
				}
				setRoleId(data.roleId);
				const host = data.roleId === RoleId.PARENT ? data.studentInfo : data.parentInfo;
				setHostEmail(host[FormNames.email]);
				setHostFirstName(host[FormNames.firstName]);

				setParentStudentRelationship(data[FormNames.parentStudentRelationship]);

				setShow(true);
			})
			.catch(err => {
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					failAuth();
				}
			});
	}, [failAuth, token]);

	const relationshipRefData: ReferenceData = R.find<ReferenceData>(
		R.propEq('id', R.toString(parentStudentRelationship)),
		relationships
	) || { id: '0', value: 'Parent' };

	return (
		<InviteDialog
			show={show}
			roleId={roleId}
			hostEmail={hostEmail}
			hostFirstName={hostFirstName}
			parentStudentRelationshipName={relationshipRefData.value}
			action={inviteModalAction(setShow, token)}
		/>
	);
};

export default InviteManager;
