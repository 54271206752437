import { createContext } from 'react';
import { Student, EMPTY_STUDENT } from '@util/student/studentDataUtil';

export enum StudentView {
	UNDERGRADUATE,
	ADULT_COMPLETER,
	GRADUATE,
}

interface StudentContext {
	student: Student;
	setStudent: (student: Student) => void;
	refreshStudent: () => Promise<Student>;
	view: StudentView;
	setView: (n: StudentView) => void;
	loading: boolean;
}

const defaultFunction = () => {
	throw new Error('Using default value for student context');
};

const StudentContext = createContext<StudentContext>({
	student: EMPTY_STUDENT,
	setStudent: defaultFunction,
	refreshStudent: defaultFunction,
	view: StudentView.UNDERGRADUATE,
	setView: defaultFunction,
	loading: false,
});

export default StudentContext;
