export interface Cbo {
	cboId: string;
	data: CboData;
}

export interface CboData {
	enabled: boolean;
	organizationName: string;
}

export enum CboDataOptions {
	DATA = 'DATA',
	SERVICES_OFFERED = 'SERVICES_OFFERED',
	STUDENTS_SERVED = 'STUDENTS_SERVED',
}
