import { validNumericRangeCriteria } from '@util/validation/numericrange';
import { validIsMultipleCriteria } from '@util/validation/isMultiple';

const GMAT_TOTAL_MIN = 200;
const GMAT_TOTAL_MAX = 800;
const GMAT_TOTAL_STEP = 10;

const validGmatTotalCriteria = (value: string): string => {
	return (
		validNumericRangeCriteria(value, GMAT_TOTAL_MIN, GMAT_TOTAL_MAX) ||
		validIsMultipleCriteria(value, GMAT_TOTAL_STEP)
	);
};

export {
	GMAT_TOTAL_MIN,
	GMAT_TOTAL_MAX,
	GMAT_TOTAL_STEP,
	// validation criteria - to be used in components.
	validGmatTotalCriteria,
};
