import { validNumericRangeCriteria } from '@util/validation/numericrange';

const GPA_MIN = 0.0;
const GPA_MAX = 4.0;
const WEIGHTED_GPA_MAX = 999.0;

const gpaErrorMessage = 'Must be between 0.00 and 4.00';

const validGpaCriteria = (value: string): string => {
	return validNumericRangeCriteria(value, GPA_MIN, GPA_MAX, gpaErrorMessage);
};

const weightedGpaErrorMessage = 'Must be between 0.00 and 999.00';

const validWeightedGpaCriteria = (value: string): string => {
	return validNumericRangeCriteria(value, GPA_MIN, WEIGHTED_GPA_MAX, weightedGpaErrorMessage);
};

export {
	GPA_MIN,
	GPA_MAX,
	WEIGHTED_GPA_MAX,
	gpaErrorMessage,
	weightedGpaErrorMessage,
	// validation criteria - to be used in components.
	validGpaCriteria,
	validWeightedGpaCriteria,
};
