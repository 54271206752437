import React, { FC, useContext, useEffect, useState } from 'react';
import { ExtendedTheme, styled, ThemeContext } from '@cappex/theme';
import {
	Dialog,
	Grid,
	GridProps,
	makeStyles,
	Paper,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { FloatingCloseButton } from '@cappex/components';
import { StepComponentProps } from '@src/common/util/steps';
import { OutreachContext } from '@src/common/util/outreach';
import { Result } from '@src/common/util/quiz';
import { StudentCollegeListLocationTrackingValue } from '@src/common/util/studentcollege/constants';
import * as R from 'ramda';
import { AutomationNameDefault, AutomationNameGeneric } from '@src/common/util/automation';
import CollegeOutreach from '@common/components/CollegeOutreach';

type QuizModalProps = {
	result: Result;
};

const RecommendationsGrid = styled(Grid)`
	max-width: 90%;
	margin: auto;
	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		margin: 0;
		max-width: 100%;
	}
	padding-bottom: 2rem;
`;

const DialogPaper = styled(Paper)`
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		width: 100%;
		padding-bottom: 3rem;
	}
`;

const MarginGrid = styled(({ vertical, ...rest }: GridProps & { vertical: number }) => (
	<Grid {...rest} />
))`
	margin: ${({ vertical }) => vertical}rem auto 1rem;
	width: 100%;
	max-width: 90%;

	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		max-width: 100%;
	}
`;
const MaxHeightImage = styled.img`
	max-height: 3rem;
`;
const BoldTypography = styled(Typography)`
	font-weight: bold;
`;

type StandardDisplayProps = Result;

const StandardDisplay: FC<StandardDisplayProps> = ({ image, header, body }) => {
	const theme = useContext(ThemeContext);
	const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
	const { outreachList } = useContext(OutreachContext);

	return (
		<>
			<MarginGrid
				vertical={isSmallBreakpoint ? 2 : 1.5}
				container
				direction="column"
				spacing={isSmallBreakpoint ? 1 : 2}
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs>
					<MaxHeightImage
						src={image}
						alt=""
						data-qa={`${AutomationNameDefault.quizModal}-${AutomationNameGeneric.image}`}
					/>
				</Grid>
				<Grid item xs>
					<Typography
						variant="h5"
						align="center"
						data-qa={`${AutomationNameDefault.quizModal}-${AutomationNameGeneric.headerText}`}
					>
						{header}
					</Typography>
				</Grid>
				<Grid item xs>
					<Typography
						variant="body2"
						align="center"
						data-qa={`${AutomationNameDefault.quizModal}-${AutomationNameGeneric.text}`}
					>
						{body}
					</Typography>
				</Grid>
			</MarginGrid>
			<RecommendationsGrid container spacing={2} direction="column">
				{outreachList?.length > 0 ? (
					<Grid item xs>
						{' '}
						<BoldTypography variant="body1" align="center">
							We found {outreachList.length} colleges that match!
						</BoldTypography>
					</Grid>
				) : null}
				<Grid item xs>
					<CollegeOutreach
						cardsToShow={3}
						hideHeaders
						trackingLocation={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
					/>
				</Grid>
			</RecommendationsGrid>
		</>
	);
};

const useStyles = makeStyles({ root: { width: '100vw', height: '100vh' } });

export const GenericOutreachModalDialog: FC<Pick<StepComponentProps, 'complete'> &
	QuizModalProps> = ({ complete, result }) => {
	const theme = useContext<ExtendedTheme>(ThemeContext);
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
	const isXsDown = useMediaQuery(theme.breakpoints.down('xs'));
	const classes = useStyles();

	return (
		<Dialog
			open
			fullWidth
			maxWidth="md"
			fullScreen={isSmDown}
			onClose={() => complete()}
			scroll={isMdDown ? 'paper' : 'body'}
			classes={{ root: classes.root }}
			PaperComponent={DialogPaper}
			data-qa={AutomationNameDefault.quizModal}
		>
			<StandardDisplay header={result.header} image={result.image} body={result.body} />
			<FloatingCloseButton
				positioning={isXsDown ? 'fixed' : undefined}
				onClick={() => complete()}
			/>
		</Dialog>
	);
};

const GenericOutreachModal: FC<StepComponentProps & QuizModalProps> = ({ complete, result }) => {
	const [latch, setLatch] = useState(false);

	useEffect(() => {
		if (R.isNil(result) && !latch) {
			setLatch(true);
			complete();
		}
	}, [latch, result, complete]);

	if (R.isNil(result)) {
		return null;
	}

	return <GenericOutreachModalDialog complete={complete} result={result} />;
};

export default GenericOutreachModal;
