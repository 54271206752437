import * as R from 'ramda';
import React from 'react';
import { CookieBuilder } from '@cappex/cookie';
import useUrlParam from '@src/common/util/hooks/useUrlParam';
import { cookieDomain } from '@src/features/environment';

const utmSourceField = 'utm_source';
const utmMediumField = 'utm_medium';
const utmCampaignField = 'utm_campaign';
const utmTermField = 'utm_term';
const utmContentField = 'utm_content';

const TrackingManager = () => {
	const utmData = new Map<string, string>();
	utmData[utmSourceField] = useUrlParam(utmSourceField);
	utmData[utmMediumField] = useUrlParam(utmMediumField);
	utmData[utmCampaignField] = useUrlParam(utmCampaignField);
	utmData[utmTermField] = useUrlParam(utmTermField);
	utmData[utmContentField] = useUrlParam(utmContentField);

	if (!R.all(R.isNil, R.values(utmData))) {
		document.cookie = new CookieBuilder()
			.addNameValue('utmParamsInitial', encodeURIComponent(JSON.stringify(utmData)))
			.addPath('/')
			.addMaxAge(86400)
			.addSameSite(true)
			.addDomain(cookieDomain)
			.build();
	}

	if (!(R.isNil(document.referrer) || R.isEmpty(document.referrer))) {
		document.cookie = new CookieBuilder()
			.addNameValue('siteReferralReact', document.referrer)
			.addPath('/')
			.addMaxAge(86400)
			.addSameSite(true)
			.addDomain(cookieDomain)
			.build();
	}

	return <></>;
};
export default TrackingManager;
