import { WamcIneligibleReason } from '../wamc/constants';

export const COLLEGE_ADDED_MESSAGE = 'College added';
export const COLLEGE_REMOVED_MESSAGE = 'College removed';
export const COLLEGE_ALREADY_ADDED_MESSAGE = 'College already added';
export const COLLEGE_COULD_NOT_BE_ADDED_MESSAGE = 'College could not be added';
export const ADD_A_COLLEGE_MESSAGE = 'Add a college to your list';

export enum StudentCollegeListStatus {
	ACTIVE = 'ACTIVE',
	ARCHIVED = 'ARCHIVED',
}
export enum LevelOfInterest {
	LOW = 'LOW',
	MEDIUM = 'MEDIUM',
	HIGH = 'HIGH',
}
export enum StudentCollegeConnectionType {
	GENERAL = 'GENERAL',
}

export type StudentCollegeConnection = {
	collegeData: {
		city: string;
		country: string;
		logoUrl: string;
		heroImageUrl: string;
		name: string;
		stateAbbr: string;
	};
	levelOfInterest?: LevelOfInterest;
	collegeApplicationStatus: string;
	collegeId: number;
	collegeListStatus: StudentCollegeListStatus;
	createdOn: Date;
	id: number;
	studentId: string;
	wamcIneligibleReason: WamcIneligibleReason;
	wamcScore: {
		bucket: string;
		score: number;
	};
};

export enum StudentCollegeListTrackingType {
	LOCATION = 'LOCATION',
	TYPE = 'TYPE',
	EXPERIENCE = 'EXPERIENCE',
	RECOMMENDATION_VERSION = 'RECOMMENDATION_VERSION',
}

export enum StudentCollegeListLocationTrackingValue {
	ADMISSION_OFFERS = 'ADMISSION_OFFERS',
	COLLEGE_PROFILE = 'COLLEGE_PROFILE',
	GRADUATE_COLLEGE_PROFILE = 'GRADUATE_COLLEGE_PROFILE',
	GRAD_SEARCH = 'GRAD_SEARCH',
	COLLEGE_LIST = 'COLLEGE_LIST',
	HOME_PAGE = 'HOME_PAGE',
	QUICK_VIEW = 'QUICK_VIEW',
	EXPLORE_COLLEGES = 'EXPLORE_COLLEGES',
	REGISTRATION_PATH = 'REGISTRATION_PATH',
	CHANCES_TO_GET_IN = 'CHANCES_TO_GET_IN',
	EXTERNAL = 'EXTERNAL',
	COLLEGE_PLANNER = 'COLLEGE_PLANNER',
}
export enum StudentCollegeListTypeTrackingValue {
	EXPLORE = 'EXPLORE',
	RECOMMENDATION = 'RECOMMENDATION',
	SEARCH_BAR = 'SEARCH_BAR',
	OUTREACH = 'OUTREACH',
	HYPERLINK_PROGRAM_WEBSITE = 'HYPERLINK_PROGRAM_WEBSITE',
	HYPERLINK_APPLICATION = 'HYPERLINK_APPLICATION',
	HYPERLINK_TUITION_BREAKDOWN = 'HYPERLINK_TUITION_BREAKDOWN',
	HYPERLINK_SCHOLARSHIPS = 'HYPERLINK_SCHOLARSHIPS',
	MATCH = 'MATCH',
}
export enum StudentCollegeListExperienceTrackingValue {
	UNDERGRADUATE = 'UNDERGRADUATE',
	ADULT_COMPLETER = 'ADULT_COMPLETER',
	GRADUATE = 'GRADUATE',
}

export type StudentCollegeListTrackingValue =
	| StudentCollegeListLocationTrackingValue
	| StudentCollegeListTypeTrackingValue
	| StudentCollegeListExperienceTrackingValue
	| string; // Added string because recVersion is defined externally and can't enforce what gets passed in.

export type StudentCollegeListTrackingData = {
	[key in StudentCollegeListTrackingType]?: StudentCollegeListTrackingValue;
};

export interface AddListItemParams {
	collegeId: number;
	trackingData: StudentCollegeListTrackingData;
	savedMessage?: string;
	cannotSaveMessage?: string;
	onSuccess?: (collegeId: number) => void;
}
