import { googleSSOClientId } from '@src/features/environment';
import React, { useEffect, useRef } from 'react';
import { withErrorBoundary } from './ErrorBoundary';

type Props = Partial<google.accounts.id.GsiButtonConfiguration> & {
	onSignIn: (response: string) => void;
};

const SignInWithGoogleButton: React.FC<Props> = ({
	onSignIn,
	locale,
	// eslint-disable-next-line camelcase
	logo_alignment = 'center',
	shape = 'pill',
	size = 'large',
	text,
	theme,
	type = 'standard',
	width = '338',
}) => {
	const buttonRef = useRef();

	useEffect(() => {
		window.google?.accounts.id.initialize({
			client_id: googleSSOClientId,
			callback: res => onSignIn(res.credential),
		});
	}, [onSignIn]);

	useEffect(() => {
		if (window.google) {
			window.google?.accounts.id.renderButton(buttonRef.current, {
				locale,
				logo_alignment,
				shape,
				size,
				text,
				theme,
				type,
				width,
			});
		}
		// eslint-disable-next-line camelcase
	}, [locale, logo_alignment, shape, size, text, theme, type, width]);

	return <div ref={buttonRef} />;
};

export default withErrorBoundary(SignInWithGoogleButton);
