import { createContext } from 'react';

const defaultFn = () => {
	throw new Error('Using Default Context Value');
};
type DataFlowContextType = {
	setPreHook: (fn: () => void) => void;
	setPostHook: (fn: (data: any) => void) => void;
	setErrorHook: (fn: () => void) => void;
};
const DataFlowContext = createContext<DataFlowContextType>({
	setPreHook: defaultFn,
	setPostHook: defaultFn,
	setErrorHook: defaultFn,
});

export default DataFlowContext;
