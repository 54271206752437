import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { BoldTypography } from '@common/components/TextCaptionItem';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { styled } from '@cappex/theme';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import withStyleOptions from '@util/style/styleOptions';

const ColoredCard = styled(Card)`
	background-color: ${props => props.theme.palette.grey[100]};
	padding: 1rem;
` as typeof Card;

const ColoredButton = styled(Button)`
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: ${props => props.theme.palette.common.white};
`;

const PaddedIcon = styled(FontAwesomeIcon)`
	margin-right: 0.5rem;
`;

const ButtonWithStyles = withStyleOptions(Button);

type InviteReferralCardProps = {
	friendLink: string;
	copyLinkClick: () => void;
	isLinkDisplayed: boolean;
	showLinkClick: () => void;
};

export const InviteReferralCard: React.FC<InviteReferralCardProps> = ({
	friendLink,
	copyLinkClick,
	isLinkDisplayed,
	showLinkClick,
}) => (
	<ColoredCard raised>
		<Grid container justifyContent="center">
			<Grid item xs={12}>
				<BoldTypography variant="body1" align="center">
					Share this Scholarship with Friends
				</BoldTypography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" align="center">
					Copy your referral link below and share it with your friends. Your chances of winning
					increase with every friend who joins Appily - so share away!
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<ColoredButton
					size="large"
					data-qa="copy-link-button"
					fullWidth
					variant="outlined"
					color="default"
					onClick={async () => {
						await copyLinkClick();
					}}
				>
					<PaddedIcon icon={faLink} />
					Copy Link
				</ColoredButton>
			</Grid>
			<Grid item xs={12}>
				{!isLinkDisplayed && (
					<ButtonWithStyles
						data-qa="show-link-button"
						onClick={showLinkClick}
						$grey500Text
						type="button"
						$noneTextTransform
						$boldFontWeight
						fullWidth
					>
						Show Link
					</ButtonWithStyles>
				)}
				{isLinkDisplayed && (
					<Typography variant="body2" align="center">
						{friendLink}
					</Typography>
				)}
			</Grid>
		</Grid>
	</ColoredCard>
);

export default InviteReferralCard;
