import { keyframes, styled, theme, css } from '@cappex/theme';
import { faHeart as faHeartRegular } from '@fortawesome/pro-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Fab, FabProps, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { clsx } from 'clsx';

type ColorVariant = 'default' | 'coral';

type Props = Partial<FabProps> & {
	faIconProps?: Partial<FontAwesomeIconProps>;
	selected?: boolean;
	onClick?: () => void;
	colorVariant?: ColorVariant;
	label?: string;
};

const FabStyled = styled(Fab)<{
	$colorVariant: ColorVariant;
	$selected: boolean;
	$animate: boolean;
}>`
	transition: 200ms;
	background-color: ${props =>
		props.$colorVariant === 'coral'
			? props.theme.palette.coral.main
			: props.theme.palette.common.white};
	svg {
		transition: 200ms;
	}

	&:hover {
		background-color: ${props =>
			props.$colorVariant === 'coral'
				? props.theme.palette.coral.dark
				: props.theme.palette.common.white};
		svg {
			transform: scale(1.1);
			${props =>
				props.$colorVariant === 'default' &&
				!props.$selected &&
				!props.$animate &&
				`color: ${props.theme.palette.ink.main}`};
		}
	}
`;

const TypographyStyled = styled(Typography)<{ $color: string }>`
	color: ${props => props.$color};
	margin-left: 0.5rem;
`;

const clickEffect = keyframes`
	0% { transform: scale(1);	}
	50% { transform: scale(1.3); }
	100% { transform: scale(1);	}
`;

const animationMixin = css`
	animation: ${clickEffect} 300ms ${theme.transitions.easing.easeOut};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)<{ $animate: boolean }>`
	${props => props.$animate && animationMixin};
`;

const useStyles = makeStyles({
	mouseDown: {
		transform: 'scale(0.75) !important',
	},
});

const HeartButton: React.FC<Props> = ({
	faIconProps,
	selected,
	onClick,
	colorVariant = 'default',
	label,
	...props
}) => {
	const [mouseDown, setMouseDown] = useState(false);
	const [animate, setAnimate] = useState(false);
	const classes = useStyles();

	const heartColor = useMemo(() => {
		let returnValue = theme.palette.ink.dark;
		if (selected || animate) returnValue = theme.palette.coral.main;
		if (colorVariant === 'coral') returnValue = theme.palette.common.white;
		return returnValue;
	}, [colorVariant, selected, animate]);

	return (
		<FabStyled
			$animate={animate}
			$selected={selected}
			$colorVariant={colorVariant}
			onClick={e => e.stopPropagation()}
			disableRipple
			onMouseDown={() => setMouseDown(true)}
			onMouseUp={() => {
				setMouseDown(false);
				!selected && setAnimate(true);
				setTimeout(
					() => {
						!animate && !props.disabled && onClick && onClick();
					},
					selected ? 0 : 250
				);
			}}
			{...props}
		>
			<StyledFontAwesomeIcon
				$animate={animate}
				icon={selected || animate ? faHeartSolid : faHeartRegular}
				color={heartColor}
				onAnimationEnd={() => {
					setAnimate(false);
				}}
				className={clsx(mouseDown && classes.mouseDown)}
				fontSize="1.5rem"
				{...faIconProps}
			/>
			{label && (
				<TypographyStyled $color={heartColor} variant="body1" noWrap>
					{label}
				</TypographyStyled>
			)}
		</FabStyled>
	);
};

export default HeartButton;
