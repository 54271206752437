import * as R from 'ramda';
import React, { FC, useRef, useContext, useMemo, useCallback } from 'react';
import CboSearchInput, { MAX_SELECTED_CBOS } from './CboSearchInput';
import { SubFormContext } from './BaseValidationForm';
import { Grid, Typography } from '@material-ui/core';
import { ListFormNames } from '@cappex/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import { OptionItem } from '@cappex/search';
import { CboDataOptions } from '../util/cbo/constants';
import useCloudCboData from '../util/hooks/useCloudCboData';
import { validateRequired } from '@common/components/BaseFormSelect';
import StudentContext from '../util/studentContext';

const EMPTY_ARRAY = [];
const dataOptions = [CboDataOptions.DATA];

type CboFormInputProps = {
	header?: string;
	subHeader?: string;
	name?: string;
	required?: boolean;
	studentLat: string;
	studentLng: string;
};

const CboFormInput: FC<CboFormInputProps> = ({
	header,
	subHeader,
	name = ListFormNames.studentCbos,
	required,
	studentLat,
	studentLng,
}) => {
	const { path } = useContext(SubFormContext);
	const { student } = useContext(StudentContext);
	const headerRef = useRef(null);
	const memoStudentCboIds = useMemo(() => student.studentCbos || EMPTY_ARRAY, [
		student.studentCbos,
	]);
	const [cbos] = useCloudCboData({
		cboIds: memoStudentCboIds,
		dataOptions,
	});

	const initialValueObj = useMemo(
		() => ({
			[ListFormNames.studentCbos]: memoStudentCboIds,
		}),
		[memoStudentCboIds]
	);

	const validator = useCallback(input => validateRequired(name, required)(input), [name, required]);

	const { value, setValue } = useFormValidation({
		path,
		name,
		validator,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	const onSelect = (option: OptionItem) => {
		const current = value[ListFormNames.studentCbos];
		if (current.length >= MAX_SELECTED_CBOS) {
			return;
		}

		setValue({
			...value,
			[ListFormNames.studentCbos]: [...current, option.value],
		});
	};

	const onRemove = (option: OptionItem) => {
		setValue({
			...value,
			[ListFormNames.studentCbos]: R.reject(
				val => val === option.value,
				value[ListFormNames.studentCbos] as string[]
			) as string[],
		});
	};

	return (
		<Grid container spacing={2} direction="column">
			<Grid item xs={12}>
				<Typography variant="h6" innerRef={headerRef}>
					{header}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography>{subHeader}</Typography>
			</Grid>
			<Grid item xs={12}>
				<CboSearchInput
					onSelect={onSelect}
					onRemove={onRemove}
					studentCboIds={value[ListFormNames.studentCbos] as string[]}
					cbos={cbos}
					studentLat={studentLat}
					studentLng={studentLng}
				/>
			</Grid>
		</Grid>
	);
};

export default CboFormInput;
