import React, { FC } from 'react';
import Link from '@material-ui/core/Link';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import { AutomationNameGeneric } from '@common/util/automation';
import { styled } from '@cappex/theme';

const BoldLink = styled(Link)`
	font-weight: bold;
`;

export enum TextLinkEnum {
	TEXT,
	LINK,
}

export type TextLinkObject = {
	type: TextLinkEnum;
	text: string;
	href?: string;
	qa?: string;
};

interface LegalTextAndLinksProps {
	configs?: TextLinkObject[];
	gutterBottom?: boolean;
	variant?: TypographyProps['variant'];
	color?: TypographyProps['color'];
	align?: TypographyProps['align'];
}

export const defaultLegalTextConfig: TextLinkObject[] = [
	{
		type: TextLinkEnum.TEXT,
		text: 'By signing up, you agree to our ',
	},
	{
		type: TextLinkEnum.LINK,
		text: 'Terms of Use',
		href: 'https://www.appily.com/terms-and-conditions',
		qa: `${AutomationNameGeneric.link}-terms`,
	},
	{
		type: TextLinkEnum.TEXT,
		text: ' and ',
	},
	{
		type: TextLinkEnum.LINK,
		text: 'Privacy Policy.',
		href: 'https://www.appily.com/privacy-policy',
		qa: `${AutomationNameGeneric.link}-privacy`,
	},
	{
		type: TextLinkEnum.TEXT,
		text: ' ',
	},
	{
		type: TextLinkEnum.LINK,
		text: 'California Privacy Notice; Sale Opt Out',
		href: 'https://www.appily.com/your-privacy-rights/',
		qa: `${AutomationNameGeneric.link}-ccpa`,
	},
];

const mapTextLinkToComponent = ({ type, text, href, qa }: TextLinkObject, i: number) => {
	switch (type) {
		case TextLinkEnum.TEXT:
			return text;
		case TextLinkEnum.LINK:
			return (
				<BoldLink
					key={i}
					href={href}
					target="_blank"
					underline="hover"
					color="inherit"
					data-qa={qa}
				>
					{text}
				</BoldLink>
			);
		default:
			return '';
	}
};

const LegalTextAndLinks: FC<LegalTextAndLinksProps> = ({
	configs = defaultLegalTextConfig,
	gutterBottom = false,
	variant = 'caption',
	color,
	align,
}) => (
	<Typography variant={variant} color={color} align={align} gutterBottom={gutterBottom}>
		{configs.map(mapTextLinkToComponent)}
	</Typography>
);

export default LegalTextAndLinks;
