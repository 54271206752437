import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { styled } from '@cappex/theme';
import { IndexSearch, IndexSearchProps, defaultComponents, OptionItem } from '@cappex/search';
import { FormNames } from '@cappex/constants';
import { AutomationNameDefault } from '@common/util/automation';
import useFormValidation from '@common/util/hooks/useFormValidation';
import requiredFieldMessage from '@util/validation/constants';
import { FormFields } from '@util/validation/form';
import SEARCH_INDEX_NAMES from '@util/search/constants';
import { searchIndexPrefix } from '@src/features/environment';
import HighSchoolSearchItem from './HighSchoolSearchItem';
import getHighSchool from '../util/highschool/HighSchoolUtil';
import { SubFormContext } from './BaseValidationForm';

interface HighSchoolIndexSearchProps {
	label?: string;
	placeholderText: string;
	name?: string;
	required?: boolean;
	defaultHighSchoolId?: string;
	automationNameSearch?: string;
	automationNameError?: string;
	stylingComponents?: typeof defaultComponents;
	onItemRemove?: (e: any) => void;
	aroundLatitude?: string;
	aroundLongitude?: string;
}

const ErrorText = styled(FormHelperText)`
	padding-left: 1rem;
`;

export const createValidation = (name: string, required: boolean) => (input: FormFields) =>
	required && !input[name] ? requiredFieldMessage : '';

interface HighSchoolOptionItem extends OptionItem {
	city?: string;
	state?: string;
}

const HighSchoolOptionSearch = IndexSearch as FC<IndexSearchProps<HighSchoolOptionItem>>;

const HighSchoolIndexSearch: FC<HighSchoolIndexSearchProps> = ({
	label,
	placeholderText,
	name = FormNames.highSchoolId,
	required,
	defaultHighSchoolId,
	automationNameSearch = AutomationNameDefault.highSchoolSearch,
	automationNameError = AutomationNameDefault.highSchoolSearchError,
	stylingComponents = defaultComponents,
	onItemRemove,
	aroundLatitude,
	aroundLongitude,
}) => {
	const gridRef = useRef(null);
	const [lastDefault, setLastDefault] = useState(null);
	const { path } = useContext(SubFormContext);

	const validate = useMemo(() => createValidation(name, required), [name, required]);

	const initialValue = useMemo(() => ({ [name]: defaultHighSchoolId || '' }), [
		name,
		defaultHighSchoolId,
	]);
	const { setValue, error, value } = useFormValidation({
		name,
		path,
		initialValue,
		validator: validate,
		fieldRef: gridRef,
	});

	const [placeholder, setPlaceholder] = useState(defaultHighSchoolId ? '' : placeholderText);

	useEffect(() => {
		if (defaultHighSchoolId && lastDefault !== defaultHighSchoolId) {
			getHighSchool(defaultHighSchoolId).then(highSchool => {
				setValue({ [name]: highSchool?.id });
				setPlaceholder(highSchool?.name ?? placeholderText);
				setLastDefault(defaultHighSchoolId);
			});
		}
	}, [lastDefault, defaultHighSchoolId, name, placeholderText, setValue]);

	useEffect(() => {
		if (value[FormNames.highSchoolId]) {
			getHighSchool(value[FormNames.highSchoolId]).then(highSchool => {
				setPlaceholder(highSchool?.name ?? placeholderText);
			});
		}
	}, [value, placeholderText]);

	return (
		<>
			<Grid item data-qa={automationNameSearch} ref={gridRef}>
				<HighSchoolOptionSearch
					data-qa={AutomationNameDefault.highSchoolSearch}
					name={name}
					error={error || null}
					isMultiSelect={false}
					onItemSelect={highSchool => {
						setPlaceholder(highSchool.label);
						setValue({ [name]: highSchool.value });
					}}
					onItemRemove={onItemRemove}
					indexName={SEARCH_INDEX_NAMES.HIGH_SCHOOL}
					environment={searchIndexPrefix}
					filterHeader={label}
					placeholderText={placeholder}
					components={{
						...stylingComponents,
						Option: HighSchoolSearchItem,
					}}
					transient
					hitMapping={({
						objectID,
						name: itemName,
						city,
						stateAbbreviation,
					}): HighSchoolOptionItem => ({
						city,
						state: stateAbbreviation,
						value: objectID,
						label: itemName,
					})}
					aroundLatitude={aroundLatitude}
					aroundLongitude={aroundLongitude}
				/>
			</Grid>
			<Grid item data-qa={automationNameError}>
				<ErrorText error={!!error}>{error}</ErrorText>
			</Grid>
		</>
	);
};

export default HighSchoolIndexSearch;
