import { useMemo } from 'react';

const useAllUrlParams = (): { [x in string]: string } => {
	const allParams = useMemo(() => {
		const params = new URLSearchParams(window.location.search);
		const paramObj = {};
		params.forEach((value, key) => {
			paramObj[key] = value;
		});
		return paramObj;
	}, []);
	return allParams;
};

export default useAllUrlParams;
