import React, { FC } from 'react';
import OfferBaseFormSelect from '@src/features/offer/components/inputs/OfferBaseFormSelect';
import { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';
import * as R from 'ramda';
import { findRequiredValidation } from '../util/offerUtil';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';

const isNotNil = R.complement(R.isNil);

const OfferCardSelectInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const contentId = `${content.id}`;
	const required = findRequiredValidation(content.campaignOfferContentValidations);
	// Find everything that has a value
	const values = (content.campaignOfferContentValue || []).filter(it => isNotNil(it.value));
	// Sort the values by position
	values.sort((a, b) => (a.position > b.position ? 1 : -1));
	// Map the values to the ReferenceData form for use
	const sortedValues = values.map(it => ({
		id: it.value,
		value: it.text,
	}));

	// Find the content value without a value
	const label = (content.campaignOfferContentValue || [])
		.filter(it => R.isNil(it.value)) // Remove anything that has a value
		.map(it => it.text)
		.shift();

	return (
		<OfferBaseFormSelect
			id={contentId}
			name={contentId}
			required={required}
			automationName={automationName}
			label={label}
			values={sortedValues as ReferenceData[]}
		/>
	);
};

export default OfferCardSelectInput;
