import * as R from 'ramda';

const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

const camelCaseToTitleCase = (text: string) => {
	if (!text.match(/[a-z]([A-Z])/g)) return capitalizeFirstLetter(text);
	const result = text.replace(/([A-Z])/g, ' $1');
	return capitalizeFirstLetter(result);
};

const inequalitySymbolToText = text => {
	const result = text.replace(/.*(<= |< )/g, 'Up to $');
	return result;
};

const insertThousandsSeparator = (x: string) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const transformCostLabel = R.pipe(
	camelCaseToTitleCase,
	inequalitySymbolToText,
	insertThousandsSeparator
);

export default transformCostLabel;
