import React, { FC } from 'react';
import { EllipsisTypography } from '@cappex/components';
import { AutomationNameDefault } from '@src/common/util/automation';
import { CampaignOfferContent } from '../constants/constants';
import { findContentValueText } from '../util/offerUtil';

type OfferCardModalParagraphProps = {
	content: CampaignOfferContent;
	automationName?: string;
};

const OfferCardModalParagraph: FC<OfferCardModalParagraphProps> = ({
	content,
	automationName = AutomationNameDefault.paOfferModalParagraph,
}) => {
	const text = findContentValueText(content);
	return (
		text && (
			<EllipsisTypography
				paddingRight={false}
				variant="body1"
				lines={5}
				automationName={automationName}
			>
				{text}
			</EllipsisTypography>
		)
	);
};
export default OfferCardModalParagraph;
