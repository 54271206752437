import React, { useContext, useEffect, useState } from 'react';
import { DataFlowStepComponent } from '../../constants/types';
import { StepContainerProps } from '@src/common/util/steps';
import useRequest from '@src/common/util/hooks/useRequest';
import { RequestMethod } from '@cappex/request';
import {
	CanObtainConsentRequest,
	CanObtainConsentResponse,
} from '@src/features/consent/consentUtil';
import DataFlowContainer from '../DataFlowContainer';
import { Box, CircularProgress } from '@material-ui/core';
import RegisterButton from '@src/common/components/RegisterButton';
import DataFlowContext from '../../util/DataFlowContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@cappex/theme';
import { FormContext } from '@src/common/util/validation/form';
import { FormNames } from '@cappex/constants';
import StudentContext from '@src/common/util/studentContext';
import ConsentCheckboxAndText from '@src/common/components/ConsentCheckboxAndText';

const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const Padding = styled.div`
	padding-bottom: 2rem;
`;

const FlexConsentStep: React.FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	active,
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		consentTypeId,
	},
	customLogoUrl,
}) => {
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { student } = useContext(StudentContext);
	const { getValue } = useContext(FormContext);
	const [isButtonDisabled, setButtonDisabled] = useState(false);
	const [displayedConsent, setDisplayedConsent] = useState(false);

	const body: CanObtainConsentRequest = {
		consentTypeId,
		birthDate:
			getValue(FormNames.birthDate)[FormNames.birthDate] ||
			(student?.birthDay && student?.birthMonth && student?.birthYear
				? `${student?.birthYear}-${student?.birthMonth}-${student?.birthDay}`
				: undefined),
		stateId: getValue(FormNames.stateId)[FormNames.stateId] || student?.stateId,
		zipCode: getValue(FormNames.postalCode)[FormNames.postalCode] || student?.postalCode,
	};

	const [checkCanObtainConsent, loading, response] = useRequest<CanObtainConsentResponse>({
		endpoint: '/public/governance/consent/v1/can-obtain-consent',
		method: RequestMethod.POST,
		body,
		onSuccess: data => {
			if (data?.canObtainConsent) {
				setDisplayedConsent(true);
			} else {
				complete(null, true);
			}
		},
		onError: () => complete(null, true),
		onValidationError: () => complete(null, true),
	});

	useEffect(() => {
		active && !displayedConsent && checkCanObtainConsent();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [active]);

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				setButtonDisabled(false);
				complete();
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
		});
		return true;
	};

	return loading ? (
		<CircularProgress />
	) : (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			{response?.canObtainConsent && (
				<ConsentCheckboxAndText
					hasValidCorrespondingInput
					consentText={response.consentText}
					consentTextId={response.consentTextId}
					consentTypeId={response.consentTypeId}
					addInitialValueOnlyWhenActive
					active={active}
				/>
			)}

			<Padding />

			<RegisterButton md={12} onClick={onClick} size="large" submitDisabled={isButtonDisabled}>
				{buttonConfig?.text ?? 'Next'}
				{buttonConfig?.icon && (
					<PaddedBox>
						<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
					</PaddedBox>
				)}
			</RegisterButton>
		</DataFlowContainer>
	);
};

export default FlexConsentStep;
