import * as R from 'ramda';
import React, { FC, useContext, useEffect } from 'react';
import { Grid, PropTypes, TextFieldProps, Typography } from '@material-ui/core';
import { FormNames } from '@cappex/constants';
import { FormContext } from '../util/validation/form';
import requiredFieldMessage from '../util/validation/constants';
import UnweightedGpaInput from './UnweightedGpaInput';
import { AutomationNameDefault } from '../util/automation';
import { SubForm } from './BaseValidationForm';

type CompositeGpaInputProps = {
	centered?: boolean;
	singleColumnStyle?: boolean;
	inputMargin?: PropTypes.Margin;
	hsGpaLabel?: string;
	collegeGpaLabel?: string;
	label?: string;
	subText?: string;
	required?: boolean;
	variant?: TextFieldProps['variant'];
};

const CompositeGpaInput: FC<CompositeGpaInputProps> = ({
	centered = false,
	singleColumnStyle,
	inputMargin,
	hsGpaLabel,
	collegeGpaLabel,
	label = 'GPA',
	subText,
	required = false,
	variant,
}) => {
	const { getValue, getError, setError } = useContext(FormContext);

	const hsGpaIsEmpty = R.isEmpty(getValue(FormNames.gpaUnweighted)[FormNames.gpaUnweighted]);
	const collegeGpaIsEmpty = R.isEmpty(getValue(FormNames.collegeGpa)[FormNames.collegeGpa]);

	useEffect(() => {
		if (!collegeGpaIsEmpty && getError(FormNames.gpaUnweighted) === requiredFieldMessage) {
			setError(FormNames.gpaUnweighted, ['']);
		}

		if (!hsGpaIsEmpty && getError(FormNames.collegeGpa) === requiredFieldMessage) {
			setError(FormNames.collegeGpa, ['']);
		}
	}, [collegeGpaIsEmpty, hsGpaIsEmpty, getError, setError]);

	return (
		<Grid container spacing={4} justifyContent={centered ? 'center' : 'flex-start'}>
			<SubForm distinct name="student">
				<Grid container item xs={12} spacing={1}>
					{label && (
						<Grid item xs={12}>
							<Typography variant="h6">{label}</Typography>
						</Grid>
					)}
					{subText && (
						<Grid item xs={12}>
							<Typography variant="body2" color="textSecondary">
								{subText}
							</Typography>
						</Grid>
					)}
					<Grid item xs={singleColumnStyle ? 12 : 6}>
						<SubForm name="studentHighSchoolDataForm">
							<UnweightedGpaInput
								name={FormNames.gpaUnweighted}
								margin={inputMargin}
								label={hsGpaLabel}
								fullWidth
								required={required && collegeGpaIsEmpty}
								variant={variant}
								automationName={AutomationNameDefault.hsGpaInput}
							/>
						</SubForm>
					</Grid>
					<Grid item xs={singleColumnStyle ? 12 : 6}>
						<SubForm name="studentCollegeDataForm">
							<UnweightedGpaInput
								name={FormNames.collegeGpa}
								margin={inputMargin}
								label={collegeGpaLabel}
								fullWidth
								required={required && hsGpaIsEmpty}
								variant={variant}
								automationName={AutomationNameDefault.collegeGpaInput}
							/>
						</SubForm>
					</Grid>
				</Grid>
			</SubForm>
		</Grid>
	);
};

export default CompositeGpaInput;
