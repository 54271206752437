import React, { FC, ReactNode } from 'react';
import { Grid } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { AutomationNameDefault } from '@common/util/automation';
import { GOOGLE_BUTTON_FIXED_WIDTH } from '@src/common/constants/signInWithGoogle';
import HorizontalLogo from './HorizontalLogo';

export interface Props {
	leftTop: ReactNode;
	rightBottom: ReactNode;
	automationNameLogo?: string;
}

const LeftTopGrid = styled(Grid)`
	border-bottom: 1px solid ${props => props.theme.palette.grey[200]};
	justify-content: center;
	${props => props.theme.breakpoints.up('md')} {
		justify-content: flex-end;
		padding-right: 3rem;
		background-color: ${props => props.theme.palette.common.white};
		border-bottom: none;
		padding-top: 8.75rem;
	}
`;

const RightBottomGrid = styled(Grid)`
	justify-content: center;
	${props => props.theme.breakpoints.up('md')} {
		justify-content: flex-start;
		padding-left: 3rem;
		padding-top: 8.75rem;
	}
`;

const ContentBox = styled.div`
	padding: 1em;
	width: 100%;
	max-width: ${GOOGLE_BUTTON_FIXED_WIDTH};
`;

const MarginBottom = styled.div`
	margin-bottom: 2rem;
`;

const SplitContainer: FC<Props> = ({
	leftTop,
	rightBottom,
	automationNameLogo = AutomationNameDefault.logo,
}) => (
	<Grid container spacing={0}>
		<LeftTopGrid spacing={0} xs={12} md={6} container item>
			<ContentBox>
				<MarginBottom>
					<HorizontalLogo data-qa={automationNameLogo} />
				</MarginBottom>
				{leftTop}
			</ContentBox>
		</LeftTopGrid>
		<RightBottomGrid spacing={0} xs={12} md={6} container item>
			<ContentBox>{rightBottom}</ContentBox>
		</RightBottomGrid>
	</Grid>
);

export default SplitContainer;
