import * as R from 'ramda';

enum NoAuthAllowedRoutes {
	LOGIN = '/login',
	MAGIC_LOGIN = '/magic-login',
	FORGOT_PASSWORD = '/forgot-password',
	RESET_PASSWORD = '/reset-password',
	REGISTRATION = '/register',
	EXPIRED_LINK = '/expired-link',
}

export const isAllowedUnauthentic: (location: string) => boolean = R.anyPass(
	R.map<string, (x: string) => boolean>(R.includes)(R.values(NoAuthAllowedRoutes))
);

export default NoAuthAllowedRoutes;
