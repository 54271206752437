import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import request, {
	ErrorMessages,
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import useFailedAuth from '@util/auth/hooks/useFailedAuth';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import AcceptedOffersContext from '@util/acceptedofferscontext';
import {
	AcceptedOffer,
	EMPTY_ACCEPTED_OFFERS,
} from '@util/acceptedofferscontext/constants/constants';

type AcceptedOffersWebResponse = WebResponse<FormKeyedData, AcceptedOffer[]>;

export const createRefreshAcceptedOffers = (
	setAcceptedOffers: (acceptedOffers: AcceptedOffer[]) => void,
	failAuth: () => void,
	onError: () => void
) => async () => {
	try {
		const { data } = await request<AcceptedOffersWebResponse>({
			url: getEndpoint(`/campaign-offers/v1/account-campaign-offer-responses/accepted`),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
		});

		if (data === undefined) {
			return;
		}

		if (data.meta.success && !R.isNil(data.response)) {
			setAcceptedOffers(data.response);
		} else {
			onError();
		}
	} catch (err) {
		if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
			failAuth();
			return;
		}

		onError();
	}
};

const AcceptedOffersProvider: FC = ({ children }) => {
	const { openSnackbar } = useContext(SnackbarContext);

	const [acceptedOffers, setAcceptedOffers] = useState(EMPTY_ACCEPTED_OFFERS);
	const failAuth = useFailedAuth();

	const onError = useCallback(() => {
		openSnackbar({
			snackbarType: SnackbarType.Error,
			message: ErrorMessages.unknown,
		});
	}, [openSnackbar]);

	const refreshAcceptedOffers = useMemo(
		() => createRefreshAcceptedOffers(setAcceptedOffers, failAuth, onError),
		[setAcceptedOffers, failAuth, onError]
	);

	useEffect(() => {
		refreshAcceptedOffers();
	}, [refreshAcceptedOffers]);

	const acceptedOffersContextValue = useMemo(
		() => ({ acceptedOffers, setAcceptedOffers, refreshAcceptedOffers }),
		[acceptedOffers, setAcceptedOffers, refreshAcceptedOffers]
	);

	return (
		<AcceptedOffersContext.Provider value={acceptedOffersContextValue}>
			{children}
		</AcceptedOffersContext.Provider>
	);
};

export default AcceptedOffersProvider;
