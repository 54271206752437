import React, { FC } from 'react';
import logo from '@src/common/assets/38759-Appily-logo_1-color-mark-RGB.svg';
import { styled } from '@cappex/theme';

const StyledImg = styled.img`
	width: 6.5rem;
	display: inline-block;
`;

interface Props {
	logoUrl?: string
}

const HorizontalLogo: FC<Props> = ({logoUrl = logo}) => <StyledImg src={logoUrl} alt="Appily Logo" />;

export default HorizontalLogo;
