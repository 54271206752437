import { useEffect, useState, useCallback } from 'react';
import * as R from 'ramda';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	KeyedData,
	RequestMethod,
	UrlParameter,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import useFailedAuth from '../auth/hooks/useFailedAuth';
import {
	AccountCampaignOfferResponse,
	CampaignOffer,
	Placement,
} from '@src/features/offer/constants/constants';

const EMPTY_ARRAY = [];
type CampaignOfferWebResponse = WebResponse<FormKeyedData, CampaignOffer[]>;

const getCampaignOffers = (
	placementName: Placement,
	setCampaignOffers: React.Dispatch<React.SetStateAction<CampaignOffer[]>>,
	onError: () => void,
	failAuth: () => void,
	setIsCampaignOffersReady: () => void
) => {
	request<CampaignOfferWebResponse>({
		url: getEndpoint(`/campaign-offers/v1/placement/${placementName}`),
		method: RequestMethod.GET,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
		withCredentials: true,
	})
		.then(res => {
			const { data } = res;
			if (data.meta.success && !R.isNil(data.response)) {
				setCampaignOffers(data.response);

				// update offer views
				if (!R.isEmpty(data.response)) {
					const offerIds = R.pluck('id', data.response);
					const params: UrlParameter[] = offerIds.map(id => ({
						name: 'campaignOfferIds',
						value: id,
					}));
					request({
						url: getEndpoint(`/campaign-offers/v1/updateOffersViews/${placementName}`, params),
						method: RequestMethod.POST,
						headers: [JsonAcceptHeader, JsonContentTypeHeader],
						withCredentials: true,
					});
				}
			} else {
				setCampaignOffers(EMPTY_ARRAY);
			}
			// set rdy even if not success response
			setIsCampaignOffersReady();
		})
		.catch(err => {
			setCampaignOffers(EMPTY_ARRAY);
			setIsCampaignOffersReady();
			if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
				failAuth();
				return;
			}
			onError();
		});
};

export const saveAccountCampaignOfferResponse = (
	form: AccountCampaignOfferResponse,
	onSuccess: () => void,
	onValidationError: (fieldToError: KeyedData<string>) => void,
	onError: () => void
) => {
	request<WebResponse<KeyedData<string>, boolean>>({
		url: getEndpoint('/campaign-offers/v1'),
		method: RequestMethod.POST,
		data: form,
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	})
		.then(res => {
			if (res.data.meta.success) {
				onSuccess();
				return;
			}
			if (!R.isNil(res.data.meta.data) && !R.isEmpty(res.data.meta.data)) {
				onValidationError(res.data.meta.data);
			} else {
				onError();
			}
		})
		.catch(() => {
			onError();
		});
};

export type UseCampaignOffers = [CampaignOffer[], boolean];

const DEFAULT_ON_ERROR = () => {};

const useCampaignOffers = (placementName, onError = DEFAULT_ON_ERROR): UseCampaignOffers => {
	const [isCampaignOffersReady, setIsCampaignOffersReady] = useState(false);
	const [campaignOffers, setCampaignOffers] = useState<CampaignOffer[]>();
	const failAuth = useFailedAuth();

	useEffect(() => {
		let isSubscribed = true;
		getCampaignOffers(
			placementName,
			offers => {
				if (isSubscribed) {
					setCampaignOffers(offers);
				}
			},
			onError,
			failAuth,
			() => isSubscribed && setIsCampaignOffersReady(true)
		);

		return () => {
			isSubscribed = false;
		};
	}, [placementName, setCampaignOffers, onError, failAuth]);

	return [campaignOffers, isCampaignOffersReady];
};

export default useCampaignOffers;

export type UseCampaignOffersWithGetOffersCall = [CampaignOffer[], () => void, boolean];

export const useCampaignOffersWithGetOffersCall = (
	placementName,
	onError = DEFAULT_ON_ERROR
): UseCampaignOffersWithGetOffersCall => {
	const [isCampaignOffersReady, setIsCampaignOffersReady] = useState(false);
	const [campaignOffers, setCampaignOffers] = useState<CampaignOffer[]>();
	const failAuth = useFailedAuth();

	const getOffers = useCallback(() => {
		getCampaignOffers(placementName, setCampaignOffers, onError, failAuth, () =>
			setIsCampaignOffersReady(true)
		);
	}, [placementName, setCampaignOffers, onError, failAuth]);

	return [campaignOffers, getOffers, isCampaignOffersReady];
};
