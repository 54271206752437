import { FC, useEffect } from 'react';
import useUserData from '../hooks/useUserData';

const EMPTY_FUNCTION = () => {};

const HeapTracking: FC = () => {
	const [userInfo] = useUserData(EMPTY_FUNCTION);
	const { accountUuid } = userInfo;

	useEffect(() => {
		if (accountUuid) {
			window.heap?.identify(accountUuid);
		}
	}, [accountUuid]);

	return null;
};

export default HeapTracking;
