import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { ACT_MAX, ACT_MIN, validActCriteria } from '@util/validation/act';
import { toWholeNumber } from '@util/string/stringUtil';

type ActInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const ACT_CRITERIA = [validActCriteria];

const ActInput: FC<ActInputProps> = ({
	id = AutomationNameDefault.actCompositeInput,
	name,
	label = 'ACT Composite',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.actCompositeInput,
	margin,
	variant,
}) => {
	return (
		<BaseFormInput
			margin={margin}
			inputType="number"
			automationName={automationName}
			id={id}
			label={label}
			inputMin={ACT_MIN}
			inputMax={ACT_MAX}
			inputStep={1}
			fullWidth={fullWidth}
			initialValue={initialValue}
			validCriteria={ACT_CRITERIA}
			name={name}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			beforeChange={toWholeNumber}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			variant={variant}
		/>
	);
};

export default ActInput;
