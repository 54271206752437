import { styled } from '@cappex/theme';
import { ComponentType } from 'react';
import { AllTransformStyles } from './baseStyleOptions';

const StyleOptions = styled('div')<AllTransformStyles>`
	color: ${props => {
		let color: string;
		const grey = props.theme.palette.grey[500];
		if (props.$primaryText) color = props.theme.palette.primary.main;
		if (props.$darkPrimaryText) color = props.theme.palette.primary.dark;
		if (props.$lightPrimaryText) color = props.theme.palette.primary.light;
		if (props.$secondaryText) color = props.theme.palette.secondary.main;
		if (props.$inkText) color = props.theme.palette.ink.main;
		if (props.$errorText) color = props.theme.palette.error.main;
		if (props.$grey500Text) color = grey;
		if (props.$blackText) color = props.theme.palette.common.black;
		if (props.$whiteText) color = props.theme.palette.common.white;
		return color;
	}};

	font-weight: ${props => {
		let weight: string;
		if (props.$normalFontWeight) weight = 'normal';
		if (props.$lightFontWeight) weight = '300';
		if (props.$boldFontWeight) weight = 'bold';
		return weight;
	}};

	text-transform: ${props => {
		let transform: string;
		if (props.$noneTextTransform) transform = 'none';
		if (props.$initialTextTransform) transform = 'initial';
		if (props.$upperCaseTextTransform) transform = 'uppercase';
		if (props.$lowerCaseTextTransform) transform = 'lowercase';
		if (props.$capitalizeTextTransform) transform = 'capitalize';
		return transform;
	}};

	background-color: ${props => {
		let color: string;
		if (props.$defaultBackground) color = props.theme.palette.background.default;
		if (props.$primaryBackground) color = props.theme.palette.primary.main;
		if (props.$transparentPrimaryBackground) color = props.theme.palette.primary.transparent;
		if (props.$secondaryBackground) color = props.theme.palette.secondary.main;
		if (props.$blackBackground) color = props.theme.palette.common.black;
		if (props.$whiteBackground) color = props.theme.palette.common.white;
		return color;
	}};

	:hover: {
		background-color: ${props => {
			let color: string;
			if (props.$primaryHoverBackground) color = props.theme.palette.primary.main;
			if (props.$secondaryHoverBackground) color = props.theme.palette.secondary.main;
			if (props.$blackHoverBackground) color = props.theme.palette.common.black;
			if (props.$whiteHoverBackground) color = props.theme.palette.common.white;
			return color;
		}};
	}
`;

const withStyleOptions = <T,>(component: ComponentType<T>) => StyleOptions.withComponent(component);

export default withStyleOptions;
