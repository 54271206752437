import { Dialog, Fab, makeStyles } from '@material-ui/core';
import React from 'react';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { AutomationNameDefault } from '../util/automation';
import { styled } from '@cappex/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import splitIdIntoParts from '../../features/dataflow/util/splitIdIntoParts';
import { DataFlowConfig } from '@src/features/dataflow/constants/types';
import JitWidget from './JitWidget';

const StyledFab = styled(Fab)`
	top: 0.5rem;
	right: 0.5rem;
	position: absolute;
	box-shadow: none;
	background: ${props => props.theme.palette.common.white};
`;

const useStyles = makeStyles({ root: { width: '100vw', height: '100vh' } });

interface Props {
	complete: () => void;
	inputConfig: DataFlowConfig;
	id: string;
	eventId: string;
}

const JitModal: React.FC<Props> = ({ inputConfig, complete: closeModal, id, eventId }) => {
	const classes = useStyles();

	const saveAbandonDataFlowTracking = () => {
		const { dataFlowCode, dataFlowVersion } = splitIdIntoParts(id);
		request<any>({
			url: getEndpoint('/data-flow/v1/save-abandoned-tracking'),
			method: RequestMethod.POST,
			withCredentials: true,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			data: { dataFlowCode, dataFlowVersion, eventId },
		});
	};

	const onClose = () => {
		saveAbandonDataFlowTracking();
		closeModal();
	};

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={onClose}
			classes={{ root: classes.root }}
			data-qa={AutomationNameDefault.jitModal}
		>
			<StyledFab size="small" onClick={onClose}>
				<FontAwesomeIcon icon={faClose} />
			</StyledFab>
			<JitWidget inputConfig={inputConfig} id={id} onLastStep={closeModal} eventId={eventId} />
		</Dialog>
	);
};

export default JitModal;
