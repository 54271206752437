import { useState, useEffect, useContext } from 'react';
import * as R from 'ramda';
import getEndpoint, { FormKeyedData } from '@util/request';
import {
	CollegePreference,
	CollegePreferenceNames,
	collegePreferencesData,
	ConvertedLocationData,
	LocationData,
	CollegePreferencesData,
	GeneralCollegePreference,
	GeneralCollegePreferenceData,
} from '@util/preferences/constants';
import StudentContext from '@util/studentContext';
import request, {
	RequestMethod,
	JsonContentTypeHeader,
	WebResponse,
	UrlParameter,
} from '@cappex/request';
import useFailedAuth from '../auth/hooks/useFailedAuth';

interface StudentCollegePreferences {
	levelOfInstitutionId: number;
	townSizeIds: number[];
	majorCipCodes: string[];
	costId: number;
	collegeSizeIds: number[];
	locationCollegePreference: LocationData;
	generalCollegePreference: GeneralCollegePreference;
}

export interface NormalizedCollegePreferences {
	levelOfInstitutionId: string[];
	townSizeIds: string[];
	majorCipCodes: string[];
	costId: string[];
	collegeSizeIds: string[];
	locationCollegePreference: ConvertedLocationData[];
	generalCollegePreference: GeneralCollegePreference;
}

export type StudentCollegePreferencesBody = StudentCollegePreferences & {
	studentId: string;
};
type StudentCollegePreferencesResponse = WebResponse<FormKeyedData, StudentCollegePreferencesBody>;

interface StudentPreference {
	studentPreferences: string[];
	setStudentPreferences: (preferences: string[]) => void;
	clearStudentPreferences: () => void;
}

interface LocationStudentPreference {
	studentPreferences: ConvertedLocationData[];
	setStudentPreferences: (preferences: ConvertedLocationData[]) => void;
	clearStudentPreferences: () => void;
}

interface GeneralCollegePreferenceProps {
	studentPreferences: GeneralCollegePreference;
	setStudentPreferences: (preferences: GeneralCollegePreference) => void;
	clearStudentPreferences: () => void;
}

export type StudentPreferencesMap = Record<
	Exclude<CollegePreferenceNames, CollegePreferenceNames.LOCATION>,
	StudentPreference
> &
	Record<CollegePreferenceNames.LOCATION, LocationStudentPreference> &
	Record<CollegePreferenceNames.GENERAL, GeneralCollegePreferenceProps>;

type convertPreferences = (preferences: StudentCollegePreferences) => NormalizedCollegePreferences;
const convertPreferences = R.mapObjIndexed((value: any, key) => {
	if (collegePreferencesData[key]?.idName !== CollegePreferenceNames.GENERAL) {
		return (collegePreferencesData[key] as CollegePreference<any, any>).importMap(value);
	}
	return value;
}) as convertPreferences;

export const dataOptionParams = R.pipe<
	CollegePreferencesData,
	[string, CollegePreference<any, any>][],
	UrlParameter[]
>(
	R.toPairs,
	R.map(([, value]) => ({
		name: 'dataOptions',
		value: value.dataOption,
	}))
)(collegePreferencesData);

export const createGetStudentPreferences = (
	studentId: string,
	setStudentPreference: (preferences: NormalizedCollegePreferences) => void,
	onError: () => void,
	failAuth: () => void
) => () => {
	if (!studentId) {
		return;
	}

	request<StudentCollegePreferencesResponse>({
		url: getEndpoint('/student-college-preferences/v1/retrieve', [
			...dataOptionParams,
			{ name: 'studentId', value: studentId },
		]),
		method: RequestMethod.GET,
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	})
		.then(res => {
			const { studentId: _, ...preferenceValues } = res.data.response;
			setStudentPreference(convertPreferences(preferenceValues));
		})
		.catch(err => {
			if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
				failAuth();
				return;
			}
			onError();
		});
};

export const createUpdateStudentPreferences = (
	studentId: string,
	preference: CollegePreference<any, any> | GeneralCollegePreferenceData,
	studentPreferences: NormalizedCollegePreferences,
	setStudentPreference: (preferences: NormalizedCollegePreferences) => void,
	onError: () => void,
	onChange: () => void,
	failAuth: () => void
) => (newPreferences: string[] | ConvertedLocationData[] | GeneralCollegePreference) => {
	setStudentPreference({
		...studentPreferences,
		[preference.idName]: newPreferences,
	});

	request<StudentCollegePreferencesResponse>({
		url: getEndpoint('/student-college-preferences/v1/save'),
		method: RequestMethod.POST,
		data: {
			studentId,
			dataOptions: [preference.dataOption],
			[preference.idName]: !Array.isArray(newPreferences)
				? newPreferences
				: (preference as CollegePreference<any, any>).exportMap(newPreferences as string[]),
		},
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	})
		.then(() => {
			onChange();
		})
		.catch(err => {
			if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
				failAuth();
				return;
			}
			onError();
		});
};

export const createDeleteStudentPreferences = (
	studentId: string,
	preference: CollegePreference<any, any> | GeneralCollegePreferenceData,
	studentPreferences: NormalizedCollegePreferences,
	setStudentPreference: (preferences: NormalizedCollegePreferences) => void,
	onError: () => void,
	onChange: () => void,
	failAuth: () => void
) => () => {
	setStudentPreference({
		...studentPreferences,
		[preference.idName]: [],
	});

	request<StudentCollegePreferencesResponse>({
		url: getEndpoint('/student-college-preferences/v1/delete', [
			{ name: 'dataOptions', value: preference.dataOption },
			{ name: 'studentId', value: studentId },
		]),
		method: RequestMethod.POST,
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	})
		.then(() => {
			onChange();
		})
		.catch(err => {
			if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
				failAuth();
				return;
			}
			onError();
		});
};

export const mapPreferencesToState = (
	studentId: string,
	studentPreferences: NormalizedCollegePreferences,
	setStudentPreferences: (preferences: NormalizedCollegePreferences) => void,
	onError: () => void,
	onChange: () => void,
	failAuth: () => void
) =>
	(R.mapObjIndexed(
		(value, key) => ({
			studentPreferences: value,
			setStudentPreferences: createUpdateStudentPreferences(
				studentId,
				collegePreferencesData[key],
				studentPreferences,
				setStudentPreferences,
				onError,
				onChange,
				failAuth
			),
			clearStudentPreferences: createDeleteStudentPreferences(
				studentId,
				collegePreferencesData[key],
				studentPreferences,
				setStudentPreferences,
				onError,
				onChange,
				failAuth
			),
		}),
		studentPreferences
	) as unknown) as StudentPreferencesMap;

const useStudentCollegePreferences = (
	onError: () => void,
	onChange = () => {}
): StudentPreferencesMap => {
	const { student } = useContext(StudentContext);

	const failAuth = useFailedAuth();

	const [studentPreferences, setStudentPreferences] = useState<NormalizedCollegePreferences>(
		{} as NormalizedCollegePreferences
	);

	const studentIdIfPresent = student && student.studentId;

	useEffect(
		() =>
			createGetStudentPreferences(studentIdIfPresent, setStudentPreferences, onError, failAuth)(),
		[studentIdIfPresent, onError, failAuth]
	);

	return mapPreferencesToState(
		student && student.studentId,
		studentPreferences,
		setStudentPreferences,
		onError,
		onChange,
		failAuth
	);
};

export default useStudentCollegePreferences;
