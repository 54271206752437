import React, { FC } from 'react'
import Script from '@src/common/components/Script'

const OfferCardLeadIdScript: FC = () => {
    const token = 'a933656d-a191-92b3-4520-572caad03328';
    return <Script
        id='LeadiDscript_campaign'
        type='text/javascript'
        async
        defer
        src={`//create.lidstatic.com/campaign/${token}.js?snippet_version=2`}
    />
}

export default OfferCardLeadIdScript