import DataLayerEventTypes from './dataLayerEventTypes';

interface DataLayerEvent {
	eventType: DataLayerEventTypes;
	eventObj: { [key in DataLayerEventTypes]?: number | string | boolean };
}

const DataLayerTracking = (dlEvent: DataLayerEvent) => {
	const { dataLayer } = (window as any) ?? {};
	if (dataLayer !== undefined) {
		dataLayer.push({
			event: dlEvent.eventType,
			...dlEvent.eventObj,
		});
	}
};

export default DataLayerTracking;
