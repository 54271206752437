import { FC, useEffect } from 'react';
import useUserData from '@common/util/hooks/useUserData';

const EMPTY_FUNCTION = () => {};

const SmartScriptTracking: FC = () => {
	const [currentUser] = useUserData(EMPTY_FUNCTION);
	useEffect(() => {
		if ((window as any)?.YVSmartScript?.analytics) {
			(window as any)?.YVSmartScript.analytics.setUserId(currentUser.username);
		}
	}, [currentUser?.username]);
	return null;
};

export default SmartScriptTracking;
