import { SnackbarContext } from '@common/components/SnackbarManager';
import { useContext, useCallback } from 'react';
import useAuthRefresh from './useAuthRefresh';

const useFailedAuth = (suppressSnackbar = false) => {
	const authRefresh = useAuthRefresh();
	const { openSnackbar } = useContext(SnackbarContext);

	return useCallback(() => {
		if (!suppressSnackbar && openSnackbar) {
			openSnackbar({
				message: 'Session expired. Please log in again.',
			});
		}

		authRefresh(true);
	}, [authRefresh, openSnackbar, suppressSnackbar]);
};

export default useFailedAuth;
