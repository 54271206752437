import React, { useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExtendedTheme, styled } from '@cappex/theme';
import { IconButton, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import {
	StudentCollegeListTrackingType,
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTypeTrackingValue,
} from '@util/studentcollege/constants';
import useSelectedColleges from '../util/hooks/useSelectedColleges';
import insightsClient from 'search-insights';
import AuthContext from '@src/common/util/auth';
import { collegeConvertEventName } from './search/constants';
import HeartButton from './HeartButton';

const FixedSizeFontAwesomeIcon = styled(FontAwesomeIcon)`
	&& {
		height: 1rem;
		width: 1rem;
	}
`;

const SlugHeader = () => {
	const { slug } = useParams<{ slug: string }>();
	return (
		<Typography variant="h6" noWrap data-qa="slug-header">
			{slug}
		</Typography>
	);
};
const HeartButtonStyled = styled(HeartButton)`
	box-shadow: none !important;
	@media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
		margin-right: 0.5rem;
	}
`;
const WidthOverrideGrid = styled(Grid)`
	width: 100vw;
	margin: 0;
	@media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
		width: inherit;
	}
`;

const trackingData = {
	[StudentCollegeListTrackingType.LOCATION]:
		StudentCollegeListLocationTrackingValue.COLLEGE_PROFILE,
	[StudentCollegeListTrackingType.TYPE]: StudentCollegeListTypeTrackingValue.EXPLORE,
};

const CollegeProfileCustomHeader = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { queryID, objectID, index } = location.state || {};
	const isFromSearch = index && queryID && objectID;
	const { accountUuid } = useContext(AuthContext);
	const { id } = useParams<{ id: string }>();
	const { breakpoints } = useTheme<ExtendedTheme>();
	const memoIdList = useMemo(() => [Number(id)], [id]);

	const { [id]: selectedCollege } = useSelectedColleges(memoIdList, trackingData);
	const { save, unsave, selected } = selectedCollege ?? {};
	const isSmUp = useMediaQuery(breakpoints.up('sm'));

	return (
		<WidthOverrideGrid container alignItems="center" spacing={1} wrap="nowrap">
			<Grid item>
				<IconButton
					edge={isSmUp ? 'start' : false}
					data-qa="back-button"
					onClick={() => {
						// Go back to just before this was mounted
						navigate(-1);
					}}
				>
					<FixedSizeFontAwesomeIcon icon={faChevronLeft} />
				</IconButton>
			</Grid>
			<Grid item zeroMinWidth xs>
				<SlugHeader />
			</Grid>
			<Grid item>
				<HeartButtonStyled
					data-qa="college-heart"
					onClick={() => {
						// Go back to just before this was mounted
						if (selected) {
							unsave();
						} else {
							isFromSearch &&
								insightsClient('convertedObjectIDsAfterSearch', {
									userToken: accountUuid,
									index,
									eventName: collegeConvertEventName,
									queryID,
									objectIDs: [objectID],
								});
							save();
						}
					}}
					size="medium"
					faIconProps={{ fontSize: '1.25rem' }}
					selected={selected}
				/>
			</Grid>
		</WidthOverrideGrid>
	);
};

export default CollegeProfileCustomHeader;
