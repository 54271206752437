import { useContext, useEffect, RefObject, useCallback } from 'react';
import { FormContext, ValidationActions, FormFields } from '@util/validation/form';
import * as R from 'ramda';

type useFormValidationReturn = {
	value: FormFields;
	setValue: (o: FormFields) => void;
	error: string;
	setError: (s: string[]) => void;
	verifiesValue: FormFields;
	clearVerifierError: () => void;
};

type UseFormValidationParams = {
	path?: string[];
	name: string;
	fieldRef: RefObject<HTMLElement>;
	initialValue?: FormFields;
	validator?: (value: FormFields, verifies: FormFields) => string;
	verifies?: string;
	verifiedBy?: string;
	multiFormInit?: boolean;
	removeUpdateFields?: boolean;
};

const defaultValidator = () => '';
const defaultInitialValue = {};
const defaultPath = [];

const useFormValidation = ({
	path = defaultPath,
	name,
	initialValue = defaultInitialValue,
	validator = defaultValidator,
	verifies,
	verifiedBy,
	fieldRef,
	multiFormInit = false,
	removeUpdateFields = false,
}: UseFormValidationParams): useFormValidationReturn => {
	const { dispatch, getValue, getError } = useContext(FormContext);

	useEffect(() => {
		!R.isNil(name) &&
			dispatch({
				type: ValidationActions.INIT,
				field: name,
				init: {
					verifiedBy,
					verifies,
					fieldName: name,
					value: initialValue,
					error: '',
					fieldRef,
					path,
					removeUpdateFields,
				},
				multiInit: multiFormInit,
			});

		return () => {
			!R.isNil(name) &&
				dispatch({
					type: ValidationActions.UNMOUNT,
					field: name,
				});
		};
	}, [
		dispatch,
		fieldRef,
		initialValue,
		name,
		path,
		verifiedBy,
		verifies,
		multiFormInit,
		removeUpdateFields,
	]);

	useEffect(() => {
		!R.isNil(name) &&
			dispatch({
				type: ValidationActions.SET_VALIDATOR,
				field: name,
				validator,
			});
	}, [dispatch, validator, name, initialValue, fieldRef, path, verifiedBy, verifies]);

	const setValue = useCallback(
		(value: FormFields) => {
			!R.isNil(name) && dispatch({ value, type: ValidationActions.SET_VALUE, field: name });
		},
		[dispatch, name]
	);

	const setError = useCallback(
		(error: string[]) => {
			!R.isNil(name) &&
				dispatch({
					error,
					type: ValidationActions.SET_ERROR,
					field: name,
				});
		},
		[dispatch, name]
	);

	const clearVerifierError = useCallback(() => {
		dispatch({
			type: ValidationActions.SET_ERROR,
			field: verifiedBy,
			error: [''],
		});
	}, [dispatch, verifiedBy]);

	return {
		value: getValue(name),
		setValue,
		error: getError(name),
		setError,
		verifiesValue: getValue(verifies),
		clearVerifierError,
	};
};

export default useFormValidation;
