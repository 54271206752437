import React, { FC, useMemo, MouseEventHandler } from 'react';
import {
	AutocompleteProvided,
	Configure,
	connectHitInsights,
	Hit,
	WrappedInsightsClient,
} from 'react-instantsearch-core';
import { connectAutoComplete } from 'react-instantsearch-dom';
import {
	BaseSearch,
	OptionItem,
	ReactSelect,
	WarningBoundary,
	defaultComponents,
} from '@cappex/search';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import { searchIndexPrefix } from '@src/features/environment';
import { MAX_SELECTED_MAJORS } from '../constants';
import { SEARCH_INDEX_NAMES } from '@cappex/constants';
import ErrorBoundary from '@src/common/components/ErrorBoundary';
import BaseSearchFallback from '@src/common/components/BaseSearchFallback';
import defaultClient from 'search-insights';
import { majorClickEventName } from '@src/common/components/search/constants';

export interface AlgoliaMajorHit {
	id: string;
	enabled: boolean;
	majorCipCode: string;
	majorName: string;
	majorFamilyCipCode: string;
	majorFamilyName: string;
	majorAreaOfStudyCipCode: string;
	majorAreaOfStudyName: string;
}

interface MajorSearchInputProps {
	studentPreferences: string[];
	referenceData: ReferenceData[];
	onSelect: (option: OptionItem) => void;
	onRemove: (option: OptionItem) => void;
}

interface ConfigMajorSearchProps {
	searchIndex?: string;
}

type MajorInputProps = MajorSearchInputProps & AutocompleteProvided<AlgoliaMajorHit>;
type ConfigMajorInputProps = MajorSearchInputProps & ConfigMajorSearchProps;

export const mapHitsToOptions = (hits: Hit<AlgoliaMajorHit>[]): OptionItem[] =>
	hits.map(hit => ({
		hit,
		value: hit.majorCipCode,
		label: hit.majorName,
	}));

export const getSelectedOptions = (
	studentPreferences: string[] = [],
	referenceData: ReferenceData[] = []
): OptionItem[] => {
	const options: OptionItem[] = [];

	referenceData.some(data => {
		const matchedPreference = studentPreferences.find(preference => data.id === preference);

		if (matchedPreference) {
			options.push({ value: data.id, label: data.value });
		}

		return options.length === studentPreferences.length;
	});

	return options;
};

// Have to redefine OptionProps' InnerProps since it creates conflicting props with the MenuItem
type LiOptionProps = {
	insights?: WrappedInsightsClient;
	hit: any;
	data: any;
	innerProps: Partial<{
		id?: string;
		key?: string;
		onClick?: MouseEventHandler<HTMLDivElement>;
		onMouseMove?: MouseEventHandler<HTMLDivElement>;
		onMouseOver?: MouseEventHandler<HTMLDivElement>;
		tabIndex?: number;
	}>;
};

const ClickTrackingOption: FC<LiOptionProps> = ({
	hit,
	insights,
	innerProps: { id, key, onClick, onMouseMove, onMouseOver, tabIndex },
	...props
}) => {
	const { Option } = defaultComponents;
	return (
		// @ts-ignore 11/10/2022 BLM - There's currently a disconnect in the react-select versions in this project & common-js which causes TS errors
		<Option
			{...props}
			innerProps={{
				id,
				key,
				onMouseMove,
				onMouseOver,
				tabIndex,
				onClick: e => {
					insights('clickedObjectIDsAfterSearch', { eventName: majorClickEventName });
					return onClick(e);
				},
			}}
		/>
	);
};
const ConnectedClickTrackingOption = connectHitInsights(defaultClient)(
	ClickTrackingOption
) as typeof ClickTrackingOption;

const ClickTrackingOptionWrapped = ({ data: { hit, ...data }, innerProps, ...props }) => (
	<WarningBoundary>
		<ConnectedClickTrackingOption hit={hit} data={data} innerProps={innerProps} {...props} />
	</WarningBoundary>
);

export const MajorInput: FC<MajorInputProps> = ({
	hits,
	refine,
	studentPreferences,
	referenceData,
	onSelect,
	onRemove,
}) => {
	const optionsToShow = useMemo(() => mapHitsToOptions(hits), [hits]);
	const selectedItems = useMemo(() => getSelectedOptions(studentPreferences, referenceData), [
		studentPreferences,
		referenceData,
	]);

	return (
		<ReactSelect
			id="Major-search"
			name="major-search"
			placeholderText="Search Majors"
			value={selectedItems}
			isMultiSelect
			closeMenuOnSelect
			components={{ ...defaultComponents, Option: ClickTrackingOptionWrapped }}
			options={optionsToShow}
			onItemSelect={onSelect}
			onItemRemove={onRemove}
			onInputChange={refine}
			disabled={selectedItems.length >= MAX_SELECTED_MAJORS}
		/>
	);
};

const ConnectedMajorSearchInput = connectAutoComplete<AlgoliaMajorHit>(MajorInput);

const MajorSearchInput: FC<ConfigMajorInputProps> = ({
	searchIndex = SEARCH_INDEX_NAMES.MAJOR,
	...props
}) => (
	<ErrorBoundary Fallback={BaseSearchFallback}>
		<BaseSearch indexName={searchIndex} environment={searchIndexPrefix}>
			<Configure clickAnalytics />
			<WarningBoundary>
				<ConnectedMajorSearchInput {...props} />
			</WarningBoundary>
		</BaseSearch>
	</ErrorBoundary>
);

export default MajorSearchInput;
