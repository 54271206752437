import { FormNames, DataFlowType } from '@cappex/constants';
import DataFlowContext from '@src/features/dataflow/util/DataFlowContext';
import stepMapping from '@src/features/dataflow/util/stepMapping';
import React, { useContext, useMemo, useState } from 'react';
import QuizContextProvider from '../util/quiz';
import { FormContext, withFormValidation } from '../util/validation/form';
import BaseValidationForm from './BaseValidationForm';
import HiddenInput from './HiddenInput';
import { DataFlowConfig } from '@src/features/dataflow/constants/types';
import stateBasedOneStepAtATimeFactory from '../util/steps/components/StateBasedOneStepAtATimeFlow';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';

const EMPTY = () => {};

const createDataSubmission = ({
	getFormValues,
	preHook,
	postHook,
	errorHook,
	resetHooks,
}) => () => {
	const requestData = {
		...getFormValues(true, true, true),
	};
	resetHooks();
	preHook();

	request<any>({
		url: getEndpoint('/data-flow/v1/submit-data'),
		method: RequestMethod.POST,
		data: requestData,
		withCredentials: true,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
	})
		.then(res => res.data)
		.then(data => {
			postHook(data);
		})
		.catch(errorHook);
};

interface Props {
	inputConfig: DataFlowConfig;
	id: string;
	onLastStep?: () => void;
	eventId?: string;
}

const JitWidget: React.FC<Props> = ({ inputConfig, onLastStep, eventId, id }) => {
	const { getFormValues } = useContext(FormContext);

	const FlowComponent = useMemo(() => stateBasedOneStepAtATimeFactory(stepMapping, onLastStep), [
		onLastStep,
	]);

	const [preHook, setPreHook] = useState(() => () => {});
	const [postHook, setPostHook] = useState(() => () => {});
	const [errorHook, setErrorHook] = useState(() => () => {});

	const resetHooks = () => {
		setPreHook(EMPTY);
		setPostHook(EMPTY);
		setErrorHook(EMPTY);
	};

	const onValid = useMemo(
		() => createDataSubmission({ getFormValues, preHook, postHook, errorHook, resetHooks }),
		[getFormValues, preHook, postHook, errorHook]
	);
	const value = useMemo(() => ({ setPreHook, setPostHook, setErrorHook }), [
		setPreHook,
		setPostHook,
		setErrorHook,
	]);

	return (
		<BaseValidationForm data-qa="form" id="jit-modal" name="jit-modal" onValid={onValid}>
			<HiddenInput name={FormNames.eventId} initialValue={eventId} />
			<DataFlowContext.Provider value={value}>
				<QuizContextProvider results={inputConfig?.results}>
					<FlowComponent
						initialConfig={inputConfig}
						initialId={id}
						initialDataFlowType={DataFlowType.JIT}
					/>
				</QuizContextProvider>
			</DataFlowContext.Provider>
		</BaseValidationForm>
	);
};

export default withFormValidation(JitWidget);
