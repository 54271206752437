import React, { FC, useMemo } from 'react';
import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { styled, ColorDefinition, theme } from '@cappex/theme';
import { Typography } from '@material-ui/core';
import { getWamcGroupConfigFromScore } from '@src/common/util/wamc/wamcConfigUtil';
import ChanceChartLabel from './ChanceChartLabel';

export enum ChanceChartDataKeys {
	NAME = 'name',
	ACTUAL = 'actual',
}

type ChanceChartProps = {
	wamcScore: number;
	textColor?: ColorDefinition;
	barColor?: ColorDefinition;
	barBackgroundColor?: ColorDefinition;
	labelText: string;
	labelOffsetX: number;
	hideLabel?: boolean;
	barSize?: number;
};

const RelativeDiv = styled.div`
	position: relative;
`;

const AbsoluteTopPositionedTypography = styled(Typography)`
	position: absolute;
	top: 0rem;
`;

const LowLabelTypography = styled(AbsoluteTopPositionedTypography)`
	left: 1rem;
`;

const HighLabelTypography = styled(AbsoluteTopPositionedTypography)`
	right: 1rem;
`;

const ChanceDescriptionWrapper = styled.div`
	position: relative;
	bottom: 2rem;
`;

// Score padding adjusts the range of values the bar chart uses,
// so we'll always have a little filled on the left when the score
// is 0, and a little space on the right when the score is 1.
const SCORE_PADDING = 0.2;

const chartData = [];

const chartInnerLabelFactory = ({ textColor, barColor, labelText, labelOffsetX }) => props => (
	<ChanceChartLabel
		{...props}
		text={labelText}
		textColor={
			textColor ? theme.palette[textColor.color][textColor.key] : theme.palette.common.white
		}
		color={barColor ? theme.palette[barColor.color][barColor.key] : theme.palette.primary.main}
		textOffsetX={labelOffsetX}
	/>
);

const ChanceChart: FC<ChanceChartProps> = ({
	wamcScore,
	textColor,
	barColor,
	barBackgroundColor,
	labelText,
	labelOffsetX,
	hideLabel,
	barSize = 10,
}) => {
	const chanceConfig = getWamcGroupConfigFromScore(wamcScore);

	// chartData is a work-around for a bug where recharts sometimes doesn't render the label.
	chartData.length = 0;
	chartData.push({
		[ChanceChartDataKeys.NAME]: 'chance',
		[ChanceChartDataKeys.ACTUAL]: wamcScore + SCORE_PADDING,
		label: chanceConfig.name,
	});

	const ChanceInnerLabel = useMemo(
		() => chartInnerLabelFactory({ textColor, barColor, labelText, labelOffsetX }),
		[textColor, barColor, labelText, labelOffsetX]
	);

	return (
		<RelativeDiv>
			<ResponsiveContainer width="100%" height={90}>
				<BarChart
					margin={{
						left: theme.spacing(2),
						right: theme.spacing(2),
					}}
					data={chartData}
					layout="vertical"
				>
					<XAxis hide domain={[0, 1 + SCORE_PADDING * 2]} type="number" />
					<YAxis hide type="category" dataKey={ChanceChartDataKeys.NAME} />
					<Bar
						barSize={barSize}
						dataKey={ChanceChartDataKeys.ACTUAL}
						fill={
							barColor ? theme.palette[barColor.color][barColor.key] : theme.palette.primary.main
						}
						background={{
							fill: barBackgroundColor
								? theme.palette[barBackgroundColor.color][barBackgroundColor.key]
								: theme.palette.primary.transparent,
						}}
					>
						{!hideLabel && <LabelList dataKey="label" position="top" content={ChanceInnerLabel} />}
					</Bar>
				</BarChart>
			</ResponsiveContainer>
			<ChanceDescriptionWrapper>
				<LowLabelTypography variant="caption" display="inline" color="textSecondary">
					Low
				</LowLabelTypography>
				<HighLabelTypography variant="caption" display="inline" color="textSecondary">
					High
				</HighLabelTypography>
			</ChanceDescriptionWrapper>
		</RelativeDiv>
	);
};

export default ChanceChart;
