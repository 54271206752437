import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { zipCodeValidCriteria } from '@util/validation/zip';

type ZipCodeInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const ZIP_CODE_CRITERIA = [zipCodeValidCriteria];

const ZipCodeInput: FC<ZipCodeInputProps> = ({
	id = AutomationNameDefault.postalCodeinput,
	name,
	label = 'Zip Code',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.postalCodeinput,
	variant,
}) => {
	return (
		<BaseFormInput
			id={id}
			name={name}
			label={label}
			inputType="text"
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			initialValue={initialValue}
			validCriteria={ZIP_CODE_CRITERIA}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			fullWidth={fullWidth}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			automationName={automationName}
			variant={variant}
		/>
	);
};

export default ZipCodeInput;
