import React, { useContext, useEffect, FC } from 'react';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import LoadingComponent from '@src/common/components/LoadingComponent';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';

const SnackbarStep: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	data,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);

	useEffect(() => {
		openSnackbar({
			message: data.message,
		});
		complete();
	}, [complete, openSnackbar, data.message]);

	return <LoadingComponent />;
};

export default SnackbarStep;
