import React, { FC, useContext, useState } from 'react';
import checkLockout from '@util/lockout';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { SubForm } from '@src/common/components/BaseValidationForm';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames } from '@cappex/constants';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { StepContainerProps } from '@src/common/util/steps';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';
import NameInput from '@src/common/components/NameInput';
import EmailInput from '@src/common/components/EmailInput';
import { AutomationNameDefault } from '@src/common/util/automation';
import { styled } from '@cappex/theme';

interface CreateAccountFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.password]?: string;
	[FormNames.uuid1]?: string;
	[FormNames.uuid2]?: string;
}

type OwnProps = {};

const RegisterButton = withStyleOptions(Button);

function handleFailure(
	json: any,
	setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setButtonDisabled(false);
		const formErrors: CreateAccountFormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
}

const NoThanksButton = styled(withStyleOptions(Button))`
	font-size: 1rem;
	margin-top: 1rem;
`;

const ScholarshipParentDataPage: FC<DataFlowStepComponent<any, any> &
	StepContainerProps &
	OwnProps> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		legalTextConfig,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors, setFormValue } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);

	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				complete();
				setButtonDisabled(false);
			} else {
				handleFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	const parentFirstNameId = 'parentFirstNameId';
	const parentLastNameId = 'parentLastNameId';
	const parentEmailId = 'parentEmailId';

	const noThanksClick = () => {
		// Clear out the parent data if they say no thanks, so we don't save it later.
		setFormValue(FormNames.parentFirstName, '');
		setFormValue(FormNames.parentLastName, '');
		setFormValue(FormNames.parentEmail, '');
		complete();
	};

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<SubForm name="student">
					<SubForm name="studentParentDataForm">
						<Grid item>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<NameInput
										id={parentFirstNameId}
										name={FormNames.parentFirstName}
										label="Parent's First Name"
										required={active}
										automationName={AutomationNameDefault.parentFirstNameInput}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<NameInput
										id={parentLastNameId}
										name={FormNames.parentLastName}
										label="Parent's Last Name"
										required={active}
										automationName={AutomationNameDefault.parentLastNameInput}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<EmailInput
										id={parentEmailId}
										name={FormNames.parentEmail}
										label="Parent's Email"
										required={active}
										automationName={AutomationNameDefault.parentEmailInput}
										fullWidth
									/>
								</Grid>
							</Grid>
						</Grid>
					</SubForm>
				</SubForm>
				<Grid item xs={12}>
					<RegisterButton
						data-qa={AutomationNameDefault.registerButton}
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled}
						onClick={onClick}
					>
						Submit
					</RegisterButton>
					<NoThanksButton
						data-qa="no-thanks-button"
						onClick={noThanksClick}
						$grey500Text
						type="button"
						$noneTextTransform
						$boldFontWeight
						fullWidth
					>
						No Thanks
					</NoThanksButton>
				</Grid>
				<Grid item xs={12}>
					<LegalTextAndLinks configs={legalTextConfig} />
				</Grid>
			</Grid>
		</DataFlowContainer>
	);
};

export default ScholarshipParentDataPage;
