import React, { FC } from 'react';
import {
    CampaignOfferContent,
} from '@src/features/offer/constants/constants';
import OfferBaseFormHiddenInput from '@src/features/offer/components/inputs/OfferBaseFormHiddenInput';

export type OfferCardTextInput = {
    content: CampaignOfferContent;
    automationName: string;
    inputRef?: React.MutableRefObject<HTMLInputElement>;
    initialValue?: string;
}

const OfferCardTextInput: FC<OfferCardTextInput> = ({
    content,
    automationName,
    inputRef,
    initialValue,
}) => {
    const contentId = `${content.id}`;
    return (
        <div>
            <input
                type="hidden"
                id='leadid_token'
                name={contentId}
                data-qa={automationName}
                ref={inputRef}
            />
            <OfferBaseFormHiddenInput
                id={contentId}
                name={contentId}
                automationName={automationName}
                value={initialValue}
            />
        </div>
    );
};

export default OfferCardTextInput;
