/* eslint-disable react/no-unstable-nested-components */
import React, { FC } from 'react';
import ReactMarkdown, { ReactMarkdownProps } from 'react-markdown';
import { Typography, TypographyVariant } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';

interface HeadingProps {
	level: number;
}

const heading: FC<HeadingProps> = ({ level, ...restProps }) => {
	const variant = `h${level}` as TypographyVariant;

	return <Typography variant={variant} color="textPrimary" {...restProps} />;
};

const Paragraph: FC<{ variant?: TypographyVariant }> = ({ variant = 'body1', ...props }) => (
	<Typography component="p" color="textPrimary" variant={variant} {...props} />
);

const ListItem: FC<{ variant?: TypographyVariant; color?: TypographyProps['color'] }> = ({
	variant,
	color,
	...props
}) => <Typography color={color || 'textPrimary'} component="li" variant={variant} {...props} />;

interface CodeProps {
	value: string;
}

const code: FC<CodeProps> = ({ value, ...restProps }: { value: string }) => (
	<Typography color="textPrimary" component="pre" {...restProps}>
		<code>{value}</code>
	</Typography>
);

interface TableCellProps {
	isHeader: boolean;
}

const tableCell: FC<TableCellProps> = ({ isHeader, children }) => {
	const component = isHeader ? 'th' : 'td';

	return (
		<Typography color="textPrimary" component={component}>
			{children}
		</Typography>
	);
};

export type MarkdownProps = Omit<ReactMarkdownProps, 'renderers'> & {
	variant?: TypographyVariant;
	color?: TypographyProps['color'];
};

export const Markdown: FC<MarkdownProps> = props => {
	const { variant, color } = props;
	const renderers = {
		heading,
		paragraph: reactMarkdownProp => <Paragraph {...reactMarkdownProp} variant={variant} />,
		listItem: reactMarkdownProp => (
			<ListItem {...reactMarkdownProp} variant={variant} color={color} />
		),
		code,
		tableCell,
	};

	return <ReactMarkdown renderers={renderers} {...props} />;
};
