import * as R from 'ramda';
import request, { WebResponse, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import { RoleId } from '@util/roles/constants';

export enum PermissionContext {
	STUDENT = 1,
	PARENT = 2,
	INVITE = 3,
}

export enum Action {
	CREATE = 1,
	UPDATE = 2,
	DELETE = 3,
	VIEW = 4,
}

type PermissionsAndActionsResponse = {
	[permission: string]: number[];
};

export interface UserAccountResponse {
	permissionsAndActionsMap: PermissionsAndActionsResponse;
	roleId: RoleId;
	username: string;
	externalRedirect?: string;
	completedRegistration?: boolean;
	accountUuid: string;
	isAuthenticated: boolean;
}

export type PermissionsActionsMap = Map<PermissionContext, Set<Action>>;

export interface UserAccount {
	permissionsAndActionsMap: PermissionsActionsMap;
	roleId: RoleId;
	username: string;
	completedRegistration?: boolean;
	accountUuid: string;
	externalRedirect?: string;
}

const permissionsAndActionsToMap: (
	permissionsAndActionsResponse: PermissionsAndActionsResponse
) => PermissionsActionsMap = R.pipe(
	R.toPairs as (p: PermissionsAndActionsResponse) => [string, number[]][],
	R.map(([key, value]: [string, number[]]): [PermissionContext, Set<Action>] => [
		parseInt(key, 10) as PermissionContext,
		new Set(value as Action[]),
	]),
	values => new Map(values)
);

export const parseUser = (userData: UserAccountResponse): UserAccount => {
	const { isAuthenticated, ...rest } = userData;
	return {
		...rest,
		permissionsAndActionsMap: permissionsAndActionsToMap(userData.permissionsAndActionsMap),
	};
};

export type UserInfoWebResponse = WebResponse<FormKeyedData, UserAccountResponse>;

export const loadUserAccount = async (
	onSuccess: (userAccount: UserAccount) => void,
	onError: () => void,
	onAuthFail: () => void
) => {
	try {
		const { data } = await request<UserInfoWebResponse>({
			url: getEndpoint('/auth/v2/check'),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});

		if (data.meta.success && !R.isNil(data.response)) {
			if (!data.response.isAuthenticated) {
				onAuthFail();
				return;
			}

			onSuccess(parseUser(data.response));
		} else {
			onError();
		}
	} catch (err) {
		if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
			onAuthFail();
			return;
		}
		onError();
	}
};
