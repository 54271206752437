const reactEnv = (name: string) => process.env[`REACT_APP_${name}`];

type Env = 'dev' | 'qa' | 'uat' | 'hot' | 'prod';

export type ReactEnvironment = {
	env: Env;
	edgeUrl: string;
	appUrl: string;
	cookieDomain: string;
	enableHighSchool: boolean;
	enableIncentivization: boolean;
	enableAdcView: boolean;
	enableGoogleSSO: boolean;
	buildId: string;
	recaptchaSiteKey: string;
	snowplowTrackingUrl: string;

	searchIndexPrefix: string;
	collegeSearch: {
		ftfViewPostfix: string;
		adcViewPostfix: string;
		gradViewPostfix: string;

		geoSearchBucketPrecision: number;
	};
};

export const env = reactEnv('ENV') as Env;
export const edgeUrl = reactEnv('EDGE_URL');
export const appUrl = reactEnv('URL');
export const searchIndexPrefix = reactEnv('SEARCH_INDEX_PREFIX');
export const cookieDomain = reactEnv('COOKIE_DOMAIN');
export const enableHighSchool = reactEnv('ENABLE_HIGH_SCHOOL') === 'true';
export const enableIncentivization = reactEnv('ENABLE_INCENTIVIZATION') === 'true';
export const enableAdcView = reactEnv('ENABLE_ADC_VIEW') === 'true';
export const enableGoogleSSO = reactEnv('ENABLE_GOOGLE_SSO') === 'true';
export const buildId = reactEnv('BUILD_ID');
export const recaptchaSiteKey = reactEnv('RECAPTCHA_SITE_KEY');
export const googleSSOClientId = reactEnv('GOOGLE_SSO_CLIENT_ID');
export const snowplowTrackingUrl = reactEnv('SNOWPLOW_TRACKER_URL');

const searchCollegeFtfPostfix = reactEnv('SEARCH_INDEX_COLLEGE_VIEW_FTF');
const searchCollegeAdcPostfix = reactEnv('SEARCH_INDEX_COLLEGE_VIEW_ADC');
const searchCollegeGradPostfix = reactEnv('SEARCH_INDEX_COLLEGE_VIEW_GRAD');
const geoSearchBucketPrecision = parseInt(reactEnv('SEARCH_GEO_BUCKET_PRECISION'), 10);

export const collegeSearch = {
	ftfViewPostfix: searchCollegeFtfPostfix,
	adcViewPostfix: searchCollegeAdcPostfix,
	gradViewPostfix: searchCollegeGradPostfix,

	geoSearchBucketPrecision,
};

const environment: ReactEnvironment = {
	env,
	edgeUrl,
	appUrl,
	searchIndexPrefix,
	cookieDomain,
	enableHighSchool,
	enableIncentivization,
	enableAdcView,
	enableGoogleSSO,
	buildId,
	recaptchaSiteKey,
	snowplowTrackingUrl,

	collegeSearch,
};

export default environment;

export const isLowerEnv = env !== 'prod';
