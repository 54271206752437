import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import { AutomationNameDefault, AutomationNameGeneric } from '@util/automation';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';

type GenderSelectProps = Omit<Partial<BaseFormSelectProps>, 'references'>;
const GenderSelect: FC<GenderSelectProps> = ({
	id = AutomationNameDefault.genderInput,
	name = FormNames.genderId,
	label = 'Gender',
	initialValue,
	required,
	automationName = AutomationNameGeneric.formSelect,
	variant,
}) => {
	const [genders] = useCloudReferenceData({
		dataType: ReferenceDataTypes.gender,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			references={genders}
			initialValue={initialValue}
			required={required}
			automationName={automationName}
			addEmptyValue
			variant={variant}
		/>
	);
};

export default GenderSelect;
