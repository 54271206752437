import React, { FC, useContext, useState } from 'react';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';
import RegisterButton from '@src/common/components/RegisterButton';
import {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	RequestSourceIdentifier,
} from '@cappex/request';
import DataFlowContext from '../../util/DataFlowContext';
import { FormContext } from '@src/common/util/validation/form';
import checkLockout from '@src/common/util/lockout';
import { SnackbarContext, SnackbarType } from '@src/common/components/SnackbarManager';
import DataFlowContainer from '../DataFlowContainer';
import { Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@cappex/theme';
import AnchorButton from '@src/common/components/AnchorButton';
import Button from '@material-ui/core/Button';
import { appUrl } from '@src/features/environment';
import useUserData from '@util/hooks/useUserData';
import withStyleOptions from '@util/style/styleOptions';
import { InviteReferralCard } from '@common/components/InviteReferralCard';

library.add(fal);
const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;

const SpacingDiv = styled.div`
	padding-top: 2rem;
	padding-bottom: 2rem;
	text-align: center;
`;

const MarginDiv = styled.div`
	margin-top: 1rem;
`;

const NoThanksButton = styled(withStyleOptions(Button))`
	font-size: 1rem;
	margin-top: 1rem;
`;

const EMPTY_FUNCTION = () => {};

const FlexStepReferralPage: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		legalTextConfig,
		showAlreadyHaveAccountLink,
	},
	active,
	customLogoUrl,
}) => {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { setFormErrors } = useContext(FormContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const [isLinkDisplayed, setIsLinkDisplayed] = useState(false);
	const [userInfo] = useUserData(EMPTY_FUNCTION, active, true);

	const friendLink = `${appUrl}/register/scholarship?utm_source=referral&utm_content=${userInfo.accountUuid}`;

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openSnackbar({
						message: errors[FORM_NAME],
					});
				}
			}
		});
		return true;
	};

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const copyLinkClick = async () => {
		if (!navigator.clipboard) {
			return;
		}
		try {
			await navigator.clipboard.writeText(friendLink);
			openSnackbar({
				snackbarType: SnackbarType.Success,
				message: 'Link copied!',
			});
		} catch (e) {
			openErrorSnack('Error copying link');
		}
	};

	const showLinkClick = () => {
		setIsLinkDisplayed(true);
	};

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<InviteReferralCard
				friendLink={friendLink}
				copyLinkClick={copyLinkClick}
				isLinkDisplayed={isLinkDisplayed}
				showLinkClick={showLinkClick}
			/>
			{(legalTextConfig || showAlreadyHaveAccountLink) && (
				<SpacingDiv>
					{legalTextConfig && <LegalTextAndLinks configs={legalTextConfig} />}
					{showAlreadyHaveAccountLink && (
						<MarginDiv>
							<AnchorButton text="Already have an account?" href="/login" />
						</MarginDiv>
					)}
				</SpacingDiv>
			)}
			<RegisterButton
				md={12}
				submitDisabled={submitDisabled}
				onClick={onClick}
				size="large"
				smallGutter={!!legalTextConfig}
			>
				{buttonConfig?.text ?? 'Next'}
				{buttonConfig?.icon && (
					<PaddedBox>
						<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
					</PaddedBox>
				)}
			</RegisterButton>
			<NoThanksButton
				data-qa="no-thanks-button"
				onClick={complete}
				$grey500Text
				type="button"
				$noneTextTransform
				$boldFontWeight
				fullWidth
			>
				No Thanks
			</NoThanksButton>
		</DataFlowContainer>
	);
};

export default FlexStepReferralPage;
