import React, { FC, useMemo } from 'react';
import { AutomationNameGeneric } from '@util/automation';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ConsentType } from '@src/features/consent/consentUtil';
import { StepComponentProps } from '@src/common/util/steps';
import { CompleteCollege } from '@src/common/util/college/collegeDataUtil';
import LegalTextAndLinks, { TextLinkEnum } from '@src/common/components/LegalTextAndLinks';
import GenericConsentModal from './GenericConsentModal';

const consentText =
	'I agree that Liberty University and its affiliates may email, call and/or text me about its offerings by any email address or phone number I have provided and may provide in the future, including any wireless number, using automated technology.';
const consentLegal = [
	{
		type: TextLinkEnum.TEXT,
		text:
			"Message and data rates may apply. For additional information, text HELP to 49596. You may opt out at any time by sending STOP to 49596. Check Liberty's website for ",
	},
	{
		type: TextLinkEnum.LINK,
		text: 'Terms & Conditions',
		href: 'https://www.liberty.edu/informationservices/index.cfm?PID=31265',
	},
	{ type: TextLinkEnum.TEXT, text: ' and ' },
	{
		type: TextLinkEnum.LINK,
		text: 'Privacy Policy.',
		href: 'https://www.liberty.edu/offices/privacy-policy/',
	},
];

type TCPAModalProps = Omit<StepComponentProps, 'reverse'> & {
	collegeToDisplayConsent: CompleteCollege;
	whiteBackdrop?: boolean;
	onClose?: () => void;
};

const TCPAConsentModal: FC<TCPAModalProps> = ({
	complete,
	collegeToDisplayConsent,
	whiteBackdrop = false,
	onClose,
}) => {
	const college = useMemo(() => [collegeToDisplayConsent], [collegeToDisplayConsent]);

	return (
		<GenericConsentModal
			complete={complete}
			collegesToDisplayConsent={college}
			consentType={ConsentType.TCPA}
			whiteBackdrop={whiteBackdrop}
			onClose={onClose}
		>
			<DialogContentText
				paragraph
				data-qa={`${AutomationNameGeneric.text}`}
				align="center"
				color="textSecondary"
				variant="body1"
			>
				{consentText}
			</DialogContentText>
			<LegalTextAndLinks
				configs={consentLegal}
				variant="body1"
				color="textSecondary"
				align="center"
			/>
		</GenericConsentModal>
	);
};

export default TCPAConsentModal;
