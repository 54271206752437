import * as R from 'ramda';
import { ColorDefinition } from '@cappex/theme';
import { WamcIneligibleReason } from '@src/common/util/wamc/constants';
import LikelyImg from '../../assets/wamc/likely.svg';
import ReachImg from '../../assets/wamc/reach.svg';
import TargetImg from '../../assets/wamc/target.svg';

enum WamcGroupEnum {
	LIKELY = 'likely',
	TARGET = 'target',
	REACH = 'reach',
}

type WamcGroupConfig = {
	name: string;
	enum: WamcGroupEnum;
	min: number;
	max: number;
	textColor: ColorDefinition;
	color: ColorDefinition;
	backgroundColor: ColorDefinition;
	profileText: (coedStatus?: string) => string;
	profileImg: string;
	profileImgAltText: string;
	profileHeader: string;

	// SHJ 11/7/2019: Yes, this is kind of weird.  We need the x-offet for the label text because of svg shenanigans.
	// There wasn't a better way... :c
	chancesLabelOffsetX: number;
	chancesLabelText: string;
	hasScore: boolean;
	testScoresNeeded: boolean;
};

const EMPTY_WAMC_GROUP: WamcGroupConfig = {
	name: null,
	enum: null,
	min: null,
	max: null,
	textColor: { color: 'base', key: 'transparent' },
	color: { color: 'lotus', key: 'main' },
	backgroundColor: { color: 'lotus', key: 'transparent' },
	profileHeader: null,
	profileText: () => null,
	profileImg: null,
	profileImgAltText: null,
	hasScore: false,
	testScoresNeeded: false,
	chancesLabelText: null,
	chancesLabelOffsetX: null,
};

export const WAMC_CONFIG: {
	reach: WamcGroupConfig;
	target: WamcGroupConfig;
	likely: WamcGroupConfig;
	noCollegeData: WamcGroupConfig;
	noStudentData: WamcGroupConfig;
	wrongGender: WamcGroupConfig;
} = {
	reach: {
		name: 'Reach',
		enum: WamcGroupEnum.REACH,
		min: 0,
		max: 0.4,
		textColor: { color: 'base', key: 'transparent' },
		color: { color: 'lotus', key: 'main' },
		backgroundColor: { color: 'lotus', key: 'transparent' },
		profileHeader: 'Reach School',
		profileText: () =>
			'This school may be a challenge for you to get into, but you should still reach for it.',
		profileImg: ReachImg,
		profileImgAltText: 'girl with backpack hiking up stack of books with walking stick',
		chancesLabelText: 'Reach',
		chancesLabelOffsetX: 15,
		hasScore: true,
		testScoresNeeded: false,
	},
	target: {
		name: 'Target',
		enum: WamcGroupEnum.TARGET,
		min: 0.4,
		max: 0.75,
		textColor: { color: 'base', key: 'transparent' },
		color: { color: 'surf', key: 'main' },
		backgroundColor: { color: 'surf', key: 'transparent' },
		profileHeader: 'Target School',
		profileText: () =>
			"You're right on track! You're academically similar to students that get accepted here.",
		profileImg: TargetImg,
		profileImgAltText: 'girl with backpack sitting on books reading book',
		chancesLabelText: 'Target',
		chancesLabelOffsetX: 14,
		hasScore: true,
		testScoresNeeded: false,
	},
	likely: {
		name: 'Likely',
		enum: WamcGroupEnum.LIKELY,
		min: 0.75,
		max: 1.01,
		textColor: { color: 'ink', key: 'main' },
		color: { color: 'solar', key: 'light' },
		backgroundColor: { color: 'primary', key: 'light' },
		profileHeader: 'Likely School',
		profileText: () =>
			"Way to go! It looks like you're above average. You have a great chance of getting in.",
		profileImg: LikelyImg,
		profileImgAltText: 'girl with backpack sitting on books crosslegged meditating',
		chancesLabelText: 'Likely',
		chancesLabelOffsetX: 17,
		hasScore: true,
		testScoresNeeded: false,
	},
	noCollegeData: {
		...EMPTY_WAMC_GROUP,
		name: 'Insufficient College Data',
		profileText: () =>
			'Unfortunately this school does not report enough data to calculate your chances.',
	},
	noStudentData: {
		...EMPTY_WAMC_GROUP,
		name: 'Insufficient Student Data',
		profileHeader: 'Calculate your chances to get in',
		profileText: () => 'All we need is a little more info...',
		testScoresNeeded: true,
	},
	wrongGender: {
		...EMPTY_WAMC_GROUP,
		name: 'Gender Specific College',
		profileHeader: 'Reach School',
		profileText: coedStatus =>
			`This is a Reach School because it is a ${coedStatus.toLowerCase()}.`,
		profileImg: ReachImg,
		chancesLabelText: 'Reach',
		chancesLabelOffsetX: 15,
		hasScore: true,
	},
};

const DEFAULT_GROUP = WAMC_CONFIG.reach;

export const getWamcGroupConfigFromScore = (wamcScore: number): WamcGroupConfig => {
	if (R.isNil(wamcScore)) {
		return DEFAULT_GROUP;
	}

	const wamcConfig = Object.values(WAMC_CONFIG).find(
		value => value.min <= wamcScore && wamcScore < value.max
	);

	return R.isEmpty(wamcConfig) ? DEFAULT_GROUP : wamcConfig;
};

export const getWamcGroupConfigFromScoreAndEligibility = (
	wamcScore: number,
	ineligibilityReason: WamcIneligibleReason
): WamcGroupConfig => {
	switch (ineligibilityReason) {
		case WamcIneligibleReason.NONE:
			return getWamcGroupConfigFromScore(wamcScore);
		case WamcIneligibleReason.INSUFFICIENT_COLLEGE_INFORMATION:
			return WAMC_CONFIG.noCollegeData;
		case WamcIneligibleReason.INSUFFICIENT_STUDENT_INFORMATION:
			return WAMC_CONFIG.noStudentData;
		case WamcIneligibleReason.GENDER_NOT_PERMITTED:
			return WAMC_CONFIG.wrongGender;
		default:
			return DEFAULT_GROUP;
	}
};
