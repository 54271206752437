import React from 'react';
import loadable from '@loadable/component';
import LoadingComponent from '@src/common/components/LoadingComponent';

const createLoadableComponent = (importLambda: () => Promise<any>) =>
	loadable(importLambda, {
		fallback: <LoadingComponent />,
	});

export default createLoadableComponent;
