import React, { FC } from 'react';
import { Grid, GridSpacing, Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { DataFlowMedia } from '../constants/types';

type DataFlowMediaDisplayProps = DataFlowMedia & {
	centerText?: boolean;
	limitMediaHeight?: boolean;
	hideImageMobile?: boolean;
	innerSpacing?: GridSpacing;
};

const BoundedImg = styled(({ limitMediaHeight, ...props }) => <img alt="" {...props} />)`
	max-width: 100%;
	display: block;
	margin: auto;
	max-height: ${({ limitMediaHeight }) => (limitMediaHeight ? '15rem' : '100%')};
`;

const DataFlowMediaDisplay: FC<DataFlowMediaDisplayProps> = ({
	header,
	headerVariant = 'h4',
	headerColor = 'textPrimary',
	headerComponent: HeaderComponent,
	headerProps,
	text,
	textVariant = 'body1',
	textColor = 'textPrimary',
	textComponent: TextComponent,
	textProps,
	media,
	centerText = false,
	limitMediaHeight = false,
	hideImageMobile = false,
	innerSpacing = 1,
}) => (
	<Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={3}>
		{(header || text || HeaderComponent || TextComponent) && (
			<Grid item>
				<Grid
					container
					direction="column"
					justifyContent="center"
					alignItems="stretch"
					spacing={innerSpacing}
				>
					{HeaderComponent ? (
						<Grid item>
							<HeaderComponent {...headerProps} />
						</Grid>
					) : (
						header && (
							<Grid item>
								<Typography
									variant={headerVariant}
									color={headerColor}
									data-qa={AutomationNameGeneric.headerText}
									align={centerText ? 'center' : 'left'}
									{...headerProps}
								>
									{header}
								</Typography>
							</Grid>
						)
					)}
					{TextComponent ? (
						<Grid item>
							<TextComponent {...textProps} />
						</Grid>
					) : (
						text && (
							<Grid item>
								<Typography
									variant={textVariant}
									color={textColor}
									data-qa={AutomationNameGeneric.text}
									align={centerText ? 'center' : 'left'}
									{...textProps}
								>
									{text}
								</Typography>
							</Grid>
						)
					)}
				</Grid>
			</Grid>
		)}
		{media && !hideImageMobile && (
			<Grid item>
				<BoundedImg
					limitMediaHeight={limitMediaHeight}
					src={media}
					data-qa={AutomationNameGeneric.image}
				/>
			</Grid>
		)}
	</Grid>
);

export default DataFlowMediaDisplay;
