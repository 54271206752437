import { styled } from '@cappex/theme';
import { Box } from '@material-ui/core';
import React from 'react';

const CssHideWhen = styled(({ when, component: C, ...props }) =>
	C ? <C {...props} /> : <Box {...props} />
)`
	${({ when }) => (when ? 'display: none;' : '')}
`;

export default CssHideWhen;
