import React, { FC } from 'react';
import icon from '@common/assets/OutreachCollegeIcon.svg';
import { Grid, Typography } from '@material-ui/core';
import { ShowMoreButton } from '@src/features/collegelist/components/StudentCollegeListCarousel';
import { styled } from '@cappex/theme';

export type OutreachEmptyStateProps = {
	useInteractionEmptyState: boolean;
	onClick: () => void;
};

type TopMarginGridProps = {
	value: string;
};

const StyledIcon = styled.img`
	margin: auto;
	height: 2.875rem;
	width: 2.875rem;
`;

const TopMarginGrid = styled(Grid)<TopMarginGridProps>`
	margin-top: ${props => props.value};
`;

export const StyledShowMoreButton = styled(ShowMoreButton)`
	width: 17.5rem;
`;

const OutreachEmptyState: FC<OutreachEmptyStateProps> = ({ useInteractionEmptyState, onClick }) => (
	<TopMarginGrid
		container
		alignItems="center"
		justifyContent="center"
		direction="column"
		spacing={1}
		value={useInteractionEmptyState ? '3rem' : '7rem'}
	>
		{useInteractionEmptyState && (
			<>
				<Grid item xs>
					<StyledIcon src={icon} />
				</Grid>
				<TopMarginGrid item xs value="0.5rem">
					<Typography align="center" variant="h5">
						Woohoo!
					</Typography>
				</TopMarginGrid>
			</>
		)}
		<Grid item xs>
			<Typography variant="body1" color="textSecondary" align="center">
				{useInteractionEmptyState
					? 'You made it through your matches for today'
					: "That's all for now. Check back later for more matches."}
			</Typography>
		</Grid>
		{useInteractionEmptyState && (
			<TopMarginGrid item xs value="0.5rem">
				<StyledShowMoreButton
					type="button"
					$boldFontWeight
					$noneTextTransform
					fullWidth
					variant="contained"
					color="primary"
					onClick={onClick}
				>
					Show me more
				</StyledShowMoreButton>
			</TopMarginGrid>
		)}
	</TopMarginGrid>
);

export default OutreachEmptyState;
