import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import AnchorButton from '@src/common/components/AnchorButton';
import { SubForm } from '@src/common/components/BaseValidationForm';
import EmailPasswordForm from '@src/common/components/EmailPasswordForm';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import { FormNames } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import checkLockout from '@util/lockout';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { RoleId } from '@src/common/util/roles/constants';
import { StepContainerProps } from '@src/common/util/steps';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { FormContext, FormContextValue, FormFields } from '@src/common/util/validation/form';
import { DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import MatchesModal from '../MatchesModal';
import DataFlowContainer from '../DataFlowContainer';

interface CreateAccountFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.password]?: string;
	[FormNames.uuid1]?: string;
	[FormNames.uuid2]?: string;
}

type MatchesCreateAccount = DataFlowStepComponent<any, any> & StepContainerProps;

const PageButton = withStyleOptions(Button);

const handleCreateAccountFailure = (
	json: any,
	setCreateAccountDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) => {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setCreateAccountDisabled(false);
		const formErrors: CreateAccountFormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
};

const MatchesCreateAccountPage: FC<MatchesCreateAccount> = ({
	data: {
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic, setValidAuth } = useContext(AuthContext);
	const { queueModal } = useContext(ModalContext);

	const [emailPasswordComplete, setEmailPasswordComplete] = useState(false);
	const [isButtonDisabled, setButtonDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	const onClick = () => {
		setPreHook(() => () => {
			setButtonDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				if (!R.isEmpty(responseData.response.accountId)) {
					setValidAuth(
						RoleId.STUDENT,
						responseData.response.userAccessPermissions,
						false,
						responseData.response.accountUuid
					);
				}

				queueModal(QueueAction.APPEND, 'matches', MatchesModal, {});
				complete();
				setButtonDisabled(false);
			} else {
				handleCreateAccountFailure(responseData, setButtonDisabled, setFormErrors, openErrorSnack);
			}
		});

		setErrorHook(() => () => {
			setButtonDisabled(false);
			handleCreateAccountFailure(
				{
					meta: { success: false },
				},
				setButtonDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			complete();
		}
	}, [active, complete, isAuthentic]);

	return (
		<DataFlowContainer
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={4}>
				<Grid item xs={12}>
					<SubForm name="accountInfo">
						<EmailPasswordForm
							complete={() => setEmailPasswordComplete(true)}
							reverse={() => {}}
							required={active}
							active={active}
							passwordLabel="Create Password"
						/>
					</SubForm>
				</Grid>
				<Grid item xs={12}>
					<PageButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isButtonDisabled || !emailPasswordComplete}
						onClick={onClick}
					>
						{totalSteps === currentStep ? 'See my matches' : 'Next'}
					</PageButton>
				</Grid>
				<Grid item xs={12}>
					<LegalTextAndLinks />
				</Grid>
				<AnchorButton text="Already have an account?" href="/login" />
			</Grid>
		</DataFlowContainer>
	);
};

export default MatchesCreateAccountPage;
