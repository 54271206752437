import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { SAT_MAX, SAT_MIN, validSatCriteria } from '@util/validation/sat';
import { toWholeNumber } from '@util/string/stringUtil';

type SatInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const SAT_CRITERIA = [validSatCriteria];

const SatInput: FC<SatInputProps> = ({
	id = AutomationNameDefault.satCompositeInput,
	name,
	label = 'SAT Composite',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.satCompositeInput,
	margin,
	variant,
}) => {
	return (
		<BaseFormInput
			margin={margin}
			inputType="number"
			automationName={automationName}
			id={id}
			label={label}
			inputMin={SAT_MIN}
			inputMax={SAT_MAX}
			inputStep={10}
			fullWidth={fullWidth}
			initialValue={initialValue}
			validCriteria={SAT_CRITERIA}
			name={name}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			beforeChange={toWholeNumber}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			variant={variant}
		/>
	);
};

export default SatInput;
