import { styled } from '@cappex/theme';
import Select from '@material-ui/core/Select';

const OutlinedSelect = styled(Select)`
	select:focus {
		background: none;
	}
`;

export default OutlinedSelect;
