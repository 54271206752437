import illustration from '@common/assets/Matches.svg';
import { includedInStepCount, name } from '@src/common/util/steps/constants';
import { DataFlowConfig } from '../../dataflow/constants/types';
import { TextLinkEnum } from '@src/common/components/LegalTextAndLinks';
import { AutomationNameGeneric } from '@src/common/util/automation';
import BoldableTypography, {
	BoldableTypographyEnum,
} from '@src/common/components/BoldableTypography';
import { DataFlowStepName } from '@cappex/constants';

const defaultConfiguration: DataFlowConfig = {
	key: 'registration__matches',
	canStartMidway: false,
	steps: [
		{
			key: DataFlowStepName.MatchesPreferencesName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: true,
				[name]: 'preferences',
				leftMedia: {
					media: illustration,
					headerVariant: 'h5',
					header: 'Find your perfect college match',
				},
				rightMedia: {
					text: "Let us know what you're looking for and we'll find colleges right for you.",
				},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.MatchesInfoName,
			sortOrder: 1,
			data: {
				[includedInStepCount]: true,
				[name]: 'info',
				leftMedia: {
					media: illustration,
					headerVariant: 'h5',
					header: 'Find your perfect college match',
				},
				rightMedia: {
					text: 'Tell us more about yourself to personalize your results.',
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.MatchesCreateAccountName,
			sortOrder: 2,
			data: {
				[includedInStepCount]: true,
				[name]: 'create',
				leftMedia: {
					media: illustration,
					headerVariant: 'h5',
					header: 'Find your perfect college match',
				},
				rightMedia: {
					text: 'Complete your profile to see your college matches.',
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.InviteFriendLinkName,
			sortOrder: 3,
			data: {
				[includedInStepCount]: false,
				[name]: 'friend',
				leftMedia: {
					media: illustration,
				},
				rightMedia: {
					headerVariant: 'h5',
					header: 'Welcome to Appily! We found a new scholarship for you.',
					textComponent: BoldableTypography,
					textProps: {
						variant: 'body1',
						config: [
							{
								type: BoldableTypographyEnum.TEXT,
								text:
									"Invite a friend to Appily and you'll both be entered to earn our $1,000 Friends Scholarship.",
							},
						],
					},
				},
				isAllowedUnauthentic: false,
				showRightTextMedia: true,
				targetWhenUnauthentic: '/login',
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text:
							'No purchase necessary to enter or win. US residents enrolling in qualified post secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.Finalize,
			sortOrder: 4,
			data: {
				[includedInStepCount]: false,
				[name]: 'finalize',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Consent,
			sortOrder: 5,
			data: {
				[includedInStepCount]: false,
				[name]: 'consent',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 6,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				pseudo: true,
				// @ts-ignore
				to: { pathname: '/dashboard', search: '?path=matches' },
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
	],
};

export default defaultConfiguration;
