import React, { FC, useContext } from 'react';
import OfferBaseFormPhoneInput from '@src/features/offer/components/inputs/OfferBaseFormPhoneInput';
import * as R from 'ramda';
import { findRequiredValidation } from '../util/offerUtil';
import StudentContext from '@util/studentContext';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';
import {
	offerCountryCodeNamePrefix,
	offerPhoneNamePrefix,
} from '@src/features/offer/constants/constants';

const OfferCardPhoneInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const { student } = useContext(StudentContext);

	const contentId = `${content.id}`;
	const required = findRequiredValidation(content.campaignOfferContentValidations);
	const label = (content.campaignOfferContentValue || [])
		.filter(it => R.isNil(it.value)) // Remove anything that has a value
		.map(it => it.text)
		.shift();
	return (
		<OfferBaseFormPhoneInput
			id={contentId}
			required={required}
			initialCountryCodeValue={student.countryCode}
			initialPhoneValue={student.phone}
			phoneName={`${offerPhoneNamePrefix}${contentId}`}
			countryCodeName={`${offerCountryCodeNamePrefix}${contentId}`}
			label={label}
			automationName={automationName}
		/>
	);
};

export default OfferCardPhoneInput;
