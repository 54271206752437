import * as R from 'ramda';
import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { OutreachContext, OutreachData } from '..';
import getOutreachData from '../util/OutreachUtil';

const EMPTY_OUTREACH_LIST: OutreachData[] = [];
const LIMIT = 30;

const OutreachProvider = ({ children }) => {
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [outreachDataRetrieved, setOutreachDataRetrieved] = useState(false);
	const [noMatches, setNoMatches] = useState(false);
	const [outreachList, setOutreachList] = useState<OutreachData[]>(EMPTY_OUTREACH_LIST);
	const isDataLoading = useRef(false);

	const removeListItem = useCallback(
		(collegeId: number) => {
			setOutreachList(R.reject(R.propEq('collegeId', collegeId))(outreachList));
		},
		[outreachList]
	);

	const refresh = useCallback(() => {
		if (!isDataLoading.current) {
			isDataLoading.current = true;
			setOutreachDataRetrieved(false);
			getOutreachData(LIMIT).then((outreachData: OutreachData[]) => {
				setOutreachList(outreachData);
				setNoMatches(R.isEmpty(outreachData));
				setOutreachDataRetrieved(true);
				isDataLoading.current = false;
			});
		}
	}, [isDataLoading]);

	const value = useMemo(
		() => ({
			outreachList,
			outreachDataRetrieved,
			noMatches,
			refresh,
			removeListItem,
		}),
		[outreachList, outreachDataRetrieved, noMatches, refresh, removeListItem]
	);

	useEffect(() => {
		// Only load data automatically once per login
		if (!isDataLoaded) {
			setIsDataLoaded(true);
			refresh();
		}
	}, [isDataLoaded, refresh]);

	return <OutreachContext.Provider value={value}>{children}</OutreachContext.Provider>;
};

export default OutreachProvider;
