import React, { FC } from 'react';
import {
	defaultComponents,
	defaultSearchComponents,
	IndexSearch,
	IndexSearchProps,
	OptionItem,
	WarningBoundary,
} from '@cappex/search';
import SEARCH_INDEX_NAMES from '@util/search/constants';
import { searchIndexPrefix, collegeSearch } from '@src/features/environment';
import StudentCollegeSearchItem from './StudentCollegeSearchItem';
import { ListFormNames } from '@cappex/constants';
import useCollegeSearchIndexForViewType from '@src/common/util/search/useCollegeSearchIndexForViewType';
import { Configure } from 'react-instantsearch-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { theme } from '@cappex/theme';

type OwnProps = {
	automationName?: string;
	defaultValue?: string;
	defaultSelectedValues?: { label: string; value: string }[];
	name?: string;
	onItemSelect?: (e: any) => void;
	onItemRemove?: (e: any) => void;
	filterHeader?: string;
	placeholderText: string;
	isMultiSelect?: boolean;
	transient?: boolean;
	stylingComponents?: typeof defaultComponents;
	disabled?: boolean;
	filterValues?: any;
	zindexInner?: number;
	searchIndex?: SEARCH_INDEX_NAMES | string;
};

interface StudentCollegeOptionItem extends OptionItem {
	city?: string;
	state?: string;
	country?: string;
	hit?: any;
}

const StudentCollegeIndexSearch = IndexSearch as FC<IndexSearchProps<StudentCollegeOptionItem>>;

const StudentCollegeSearch: FC<OwnProps> = ({
	automationName,
	defaultValue,
	defaultSelectedValues,
	name = ListFormNames.studentColleges,
	onItemSelect,
	onItemRemove,
	filterHeader,
	placeholderText,
	isMultiSelect = false,
	transient = true,
	stylingComponents = defaultSearchComponents,
	disabled = false,
	filterValues = [],
	zindexInner,
	searchIndex,
}) => {
	const indexByView = useCollegeSearchIndexForViewType();

	return (
		<WarningBoundary>
			<StudentCollegeIndexSearch
				data-qa={automationName}
				transient={transient}
				defaultValue={defaultValue}
				defaultSelectedValues={defaultSelectedValues}
				name={name}
				error={null}
				isMultiSelect={isMultiSelect}
				onItemSelect={onItemSelect}
				onItemRemove={onItemRemove}
				indexName={searchIndex || indexByView}
				environment={searchIndexPrefix}
				filterHeader={filterHeader}
				placeholderText={placeholderText}
				listItemAvatar={
					<FontAwesomeIcon fontSize="1.125rem" icon={faHeart} color={theme.palette.coral.main} />
				}
				components={
					{
						...stylingComponents,
						Option: StudentCollegeSearchItem,
					} as any
				}
				hitMapping={(hit): StudentCollegeOptionItem => ({
					hit,
					city: hit.city,
					state: hit.state,
					country: hit.country,
					value: hit.objectID,
					label: hit.name,
				})}
				disabled={disabled}
				filterValues={filterValues}
				zindexInner={zindexInner}
				configure={{
					aroundLatLngViaIP: true,
					aroundPrecision: collegeSearch.geoSearchBucketPrecision,
					aroundRadius: 'all',
				}}
			>
				<Configure clickAnalytics />
			</StudentCollegeIndexSearch>
		</WarningBoundary>
	);
};

export default StudentCollegeSearch;
