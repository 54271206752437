import * as R from 'ramda';

const isValid = (value: string, modulus: number): boolean => {
	const number: number = parseFloat(value);
	if (!R.is(Number, number)) {
		return false;
	}

	return R.modulo(number, modulus) === 0;
};

const isEmpty = (value: string) => R.isNil(value) || R.isEmpty(value);

const isMultipleErrorMessage = (modulus: number) => `Must be an increment of ${modulus}`;

const validIsMultipleCriteria = (value: string, modulus: number, errorMessage?: string): string => {
	return isEmpty(value) || isValid(value, modulus)
		? ''
		: errorMessage || isMultipleErrorMessage(modulus);
};

export { isMultipleErrorMessage, validIsMultipleCriteria };
