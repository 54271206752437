import request, { JsonContentTypeHeader, RequestMethod, WebResponse } from '@cappex/request';
import { ConsentType } from '@src/features/consent/consentUtil';
import getEndpoint from '@util/request';
import * as R from 'ramda';

export interface ConsentTextResponse {
	id: string;
	text: string;
	consentType: string;
}

const getConsentText = async (consentTextId: string, consentTypeId: ConsentType) => {
	try {
		const consentTextResponse = await request<WebResponse<{}, ConsentTextResponse>>({
			url: getEndpoint('/public/governance/consent/get-consent-text', [
				{
					name: 'consentTextId',
					value: consentTextId,
				},
				{
					name: 'consentTypeId',
					value: consentTypeId,
				},
			]),
			method: RequestMethod.GET,
			withCredentials: true,
			headers: [JsonContentTypeHeader],
		});

		if (
			R.isNil(consentTextResponse) ||
			!consentTextResponse.ok ||
			!consentTextResponse.data.meta.success
		)
			return null;

		return consentTextResponse.data.response;
	} catch (err) {
		return null;
	}
};

export default getConsentText;
