import { validNumericRangeCriteria } from '@util/validation/numericrange';
import { validIsMultipleCriteria } from '@util/validation/isMultiple';

const GRE_MIN = 130;
const GRE_MAX = 170;
const GRE_STEP = 1;

const GRE_WRITING_MIN = 0;
const GRE_WRITING_MAX = 6;
const GRE_WRITING_STEP = 0.5;

const validGreCriteria = (value: string): string => {
	return (
		validNumericRangeCriteria(value, GRE_MIN, GRE_MAX) || validIsMultipleCriteria(value, GRE_STEP)
	);
};

const validGreWritingCriteria = (value: string): string => {
	return (
		validNumericRangeCriteria(value, GRE_WRITING_MIN, GRE_WRITING_MAX) ||
		validIsMultipleCriteria(value, GRE_WRITING_STEP)
	);
};

export {
	GRE_MIN,
	GRE_MAX,
	GRE_STEP,
	GRE_WRITING_MIN,
	GRE_WRITING_MAX,
	GRE_WRITING_STEP,
	// validation criteria - to be used in components.
	validGreCriteria,
	validGreWritingCriteria,
};
