import React, { FC, useMemo } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import { validEmailCriteria, matchingEmailCriteria } from '@util/validation/email';
import { Criteria } from '@util/validation/constants';
import { FormNames } from '@cappex/constants';
import BaseFormInput, { BaseInputProps } from './BaseFormInput';
import inputLengthCriteria from '@src/common/util/validation/inputlength';

type UsableBaseProps = Pick<
	Partial<BaseInputProps>,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;
export interface EmailInputProps extends UsableBaseProps {
	noEmailValidation?: boolean;
}

const EmailInput: FC<EmailInputProps> = ({
	id = 'email',
	name = FormNames.email,
	label = 'Email Address',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	noEmailValidation = false,
	required,
	verifiedBy,
	verifies,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	automationName = AutomationNameDefault.emailInput,
	inputMax = 254,
	variant,
}) => {
	const validCriteria: Criteria[] = useMemo(() => {
		const criteria = noEmailValidation ? [] : [validEmailCriteria, inputLengthCriteria(inputMax)];
		if (verifies) {
			criteria.push(matchingEmailCriteria);
		}
		return criteria;
	}, [noEmailValidation, verifies, inputMax]);

	return (
		<BaseFormInput
			id={id}
			name={name}
			label={label}
			inputType="email"
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			initialValue={initialValue}
			validCriteria={validCriteria}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			fullWidth={fullWidth}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			automationName={automationName}
			variant={variant}
		/>
	);
};

export default EmailInput;
