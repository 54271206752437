import * as R from 'ramda';

export interface BasePreferenceCardProps<T = string> {
	studentPreferences: T[];
	setStudentPreferences: (preferences: T[]) => void;
	clearStudentPreferences: () => void;
	compact?: boolean;
}

export enum DataOption {
	LEVEL_OF_INSTITUTION = 'LEVEL_OF_INSTITUTION',
	TOWN_SIZE = 'TOWN_SIZE',
	COST = 'COST',
	COLLEGE_SIZE = 'COLLEGE_SIZE',
	MAJOR = 'MAJOR',
	LOCATION = 'LOCATION',
	GENERAL = 'GENERAL',
}

export interface CollegePreference<T, K> {
	dataOption: DataOption;
	idName: CollegePreferenceNames;
	exportMap: (data: T[]) => K;
	importMap: (value: K) => T[];
}

export enum CollegePreferenceNames {
	LEVEL_OF_INSTITUTION = 'levelOfInstitutionId',
	TOWN_SIZE = 'townSizeIds',
	COST = 'costId',
	COLLEGE_SIZE = 'collegeSizeIds',
	MAJOR = 'majorCipCodes',
	LOCATION = 'locationCollegePreference',
	GENERAL = 'generalCollegePreference',
}

export interface GeneralCollegePreference {
	undecidedMajor: boolean;
}

export interface GeneralCollegePreferenceData {
	dataOption: DataOption;
	idName: CollegePreferenceNames;
}

export type CollegePreferencesData = Record<
	CollegePreferenceNames.LEVEL_OF_INSTITUTION | CollegePreferenceNames.COST,
	CollegePreference<string, number>
> &
	Record<
		CollegePreferenceNames.TOWN_SIZE | CollegePreferenceNames.COLLEGE_SIZE,
		CollegePreference<string, number[]>
	> &
	Record<CollegePreferenceNames.MAJOR, CollegePreference<string, string[]>> &
	Record<CollegePreferenceNames.LOCATION, CollegePreference<ConvertedLocationData, LocationData>> &
	Record<CollegePreferenceNames.GENERAL, GeneralCollegePreferenceData>;

export enum LocationDataFields {
	distanceId = 'distanceId',
	international = 'international',
	stateIds = 'stateIds',
	regionIds = 'regionIds',
}

export interface ConvertedLocationData {
	type: LocationDataFields;
	value: string;
}

export interface LocationData {
	distanceId: number;
	international: boolean;
	stateIds: number[];
	regionIds: number[];
	[key: string]: number | boolean | number[];
}

const exportValue = (data: string[]) => parseInt(data[0], 10);
const importValue = (value: number): string[] => (!R.isNil(value) ? [String(value)] : []);

const exportArray = (data: string[]) => R.map(dataPoint => parseInt(dataPoint, 10), data);
const importArray = (value: number[]): string[] => value.map(val => String(val));

const exportMajor = (data: string[]) => data;
const importMajor = (value: string[]) => value;

const exportLocation = (data: ConvertedLocationData[]): LocationData =>
	data.reduce(
		(acc, locationData) => {
			switch (locationData.type) {
				case LocationDataFields.distanceId: {
					acc.distanceId = parseInt(locationData.value, 10);
					break;
				}
				case LocationDataFields.international: {
					acc.international = true;
					break;
				}
				case LocationDataFields.stateIds: {
					acc.stateIds.push(parseInt(locationData.value, 10));
					break;
				}
				case LocationDataFields.regionIds: {
					acc.regionIds.push(parseInt(locationData.value, 10));
					break;
				}
				default:
					break;
			}

			return acc;
		},
		{
			distanceId: null,
			international: null,
			stateIds: [],
			regionIds: [],
		} as LocationData
	);
type LocationPair = [string, number | number[] | boolean];
const importLocation = R.pipe<
	LocationData,
	LocationPair[],
	LocationPair[],
	ConvertedLocationData[]
>(
	R.toPairs,
	R.filter(([, value]: LocationPair) => !R.isNil(value)),
	R.chain(([key, value]: LocationPair) => {
		switch (key) {
			case LocationDataFields.regionIds:
			case LocationDataFields.stateIds: {
				return (value as number[]).map(val => ({
					type: LocationDataFields[key],
					value: String(val),
				}));
			}
			default: {
				return [{ type: LocationDataFields[key], value: String(value) }];
			}
		}
	})
);

export const collegePreferencesData: CollegePreferencesData = {
	[CollegePreferenceNames.LEVEL_OF_INSTITUTION]: {
		dataOption: DataOption.LEVEL_OF_INSTITUTION,
		idName: CollegePreferenceNames.LEVEL_OF_INSTITUTION,
		exportMap: exportValue,
		importMap: importValue,
	},
	[CollegePreferenceNames.TOWN_SIZE]: {
		dataOption: DataOption.TOWN_SIZE,
		idName: CollegePreferenceNames.TOWN_SIZE,
		exportMap: exportArray,
		importMap: importArray,
	},
	[CollegePreferenceNames.COST]: {
		dataOption: DataOption.COST,
		idName: CollegePreferenceNames.COST,
		exportMap: exportValue,
		importMap: importValue,
	},
	[CollegePreferenceNames.COLLEGE_SIZE]: {
		dataOption: DataOption.COLLEGE_SIZE,
		idName: CollegePreferenceNames.COLLEGE_SIZE,
		exportMap: exportArray,
		importMap: importArray,
	},
	[CollegePreferenceNames.MAJOR]: {
		dataOption: DataOption.MAJOR,
		idName: CollegePreferenceNames.MAJOR,
		exportMap: exportMajor,
		importMap: importMajor,
	},
	[CollegePreferenceNames.LOCATION]: {
		dataOption: DataOption.LOCATION,
		idName: CollegePreferenceNames.LOCATION,
		exportMap: exportLocation,
		importMap: importLocation,
	},
	[CollegePreferenceNames.GENERAL]: {
		dataOption: DataOption.GENERAL,
		idName: CollegePreferenceNames.GENERAL,
	},
};
