import React, { FC, useMemo } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { WEIGHTED_GPA_MAX, GPA_MIN, validWeightedGpaCriteria } from '@util/validation/gpa';
import { toFixedDecimal } from '@src/common/util/string/stringUtil';

type WeightedGpaInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const WEIGHTED_GPA_CRITERIA = [validWeightedGpaCriteria];

const WeightedGpaInput: FC<WeightedGpaInputProps> = ({
	id = AutomationNameDefault.weightedGpaInput,
	name,
	label = 'Weighted GPA',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.weightedGpaInput,
	variant,
}) => {
	const truncateTo2Decimals = useMemo(() => toFixedDecimal(2), []);

	return (
		<BaseFormInput
			inputType="number"
			automationName={automationName}
			id={id}
			label={label}
			inputMin={GPA_MIN}
			inputMax={WEIGHTED_GPA_MAX}
			inputStep={0.01}
			fullWidth={fullWidth}
			initialValue={initialValue}
			validCriteria={WEIGHTED_GPA_CRITERIA}
			name={name}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			beforeChange={truncateTo2Decimals}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			variant={variant}
		/>
	);
};

export default WeightedGpaInput;
