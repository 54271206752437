import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@src/common/util/request';
import { CanObtainConsentResponse, ConsentType } from '@src/features/consent/consentUtil';

const checkInquiryConsent = async (
	onSuccess: (res: CanObtainConsentResponse) => void,
	onComplete?: () => void
) => {
	const { data: res } = await request<WebResponse<FormKeyedData, CanObtainConsentResponse>>({
		url: getEndpoint('/public/governance/consent/v1/can-obtain-consent'),
		data: {
			consentTypeId: ConsentType.INQUIRY_CONSENT,
		},
		method: RequestMethod.POST,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
		withCredentials: true,
	});

	if (res.meta.success) {
		onSuccess(res.response);
	}

	onComplete?.();
};

export default checkInquiryConsent;
