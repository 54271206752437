import React, { Component, ComponentType, ErrorInfo, ReactNode } from 'react';

const DefaultFallback = () => null;

type ErrorBoundaryProps = {
	children: ReactNode;
	Fallback?: ComponentType;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, { error: any; errorInfo: any }> {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({
			error,
			errorInfo,
		});
	}

	render() {
		const { errorInfo, error } = this.state;
		const { children, Fallback = DefaultFallback } = this.props;

		if (errorInfo || error) {
			return <Fallback />;
		}
		// Normally, just render children
		return children;
	}
}

export const withErrorBoundary = <P extends {}>(
	WrappedComponent: ComponentType<P>,
	Fallback?: ComponentType
) => (props: P) => (
	<ErrorBoundary Fallback={Fallback}>
		<WrappedComponent {...props} />
	</ErrorBoundary>
);

export default ErrorBoundary;
