import React, { FC, useMemo } from 'react';
import { LocationDataFields } from '@util/preferences/constants';
import { Collapse, Divider, Grid } from '@material-ui/core';
import { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';
import { AutomationNameGeneric } from '@util/automation';
import PreferenceChip from './PreferenceChip';
import filterStudentPreferences from '../util/filterStudentPreferences';
import { LocationOptionProps, ChipContainer, ControlWrapper } from '../constants';

interface DistanceProps extends LocationOptionProps {
	distanceData: ReferenceData[];
	suppressDivider?: boolean;
}

const Distance: FC<DistanceProps> = ({
	suppressDivider = false,
	isSelected,
	studentPreferences,
	setStudentPreferences,
	distanceData,
}) => {
	const selectedDistanceIds = useMemo(
		() => filterStudentPreferences(studentPreferences, LocationDataFields.distanceId),
		[studentPreferences]
	);

	const onChipClick = (id: string) => {
		setStudentPreferences([{ type: LocationDataFields.distanceId, value: id }]);
	};

	return (
		<ControlWrapper>
			{!suppressDivider && <Divider variant="fullWidth" />}
			<Collapse in={isSelected}>
				<ChipContainer container spacing={2}>
					{distanceData.map(preference => {
						return (
							<Grid item xs={12} key={preference.id}>
								<PreferenceChip
									data-qa={`distance-${AutomationNameGeneric.chipButton}`}
									clickable
									onClick={() => onChipClick(preference.id)}
									label={preference.value}
									isSelected={selectedDistanceIds.includes(preference.id)}
								/>
							</Grid>
						);
					})}
				</ChipContainer>
			</Collapse>
		</ControlWrapper>
	);
};

export default Distance;
