import * as R from 'ramda';
import React, { FC, useMemo } from 'react';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { capitalizeWords } from '@src/common/util/string/stringUtil';
import inputLengthCriteria from '../util/validation/inputlength';
import { Criteria } from '../util/validation/constants';

const NameInput: FC<BaseInputProps> = ({
	id = 'name',
	name,
	label = 'Name',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	validCriteria,
	required,
	verifiedBy,
	verifies,
	automationName,
	inputMax = 85,
	variant,
}) => {
	const VALID_CRITERIA: Criteria[] = useMemo(() => {
		return R.isNil(validCriteria)
			? [inputLengthCriteria(inputMax)]
			: [].concat([inputLengthCriteria(inputMax)], validCriteria);
	}, [inputMax, validCriteria]);

	return (
		<BaseFormInput
			id={id}
			name={name}
			label={label}
			inputType="text"
			disabled={disabled}
			beforeChange={capitalizeWords}
			onChange={onChange}
			onBlur={onBlur}
			initialValue={initialValue}
			validCriteria={VALID_CRITERIA}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			fullWidth={fullWidth}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			automationName={automationName}
			variant={variant}
		/>
	);
};

export const invalidNameMessage = 'You can only use letters and special characters';

const validNameRegEx = /^([\D])+$/;

const isNameValid: (s: string) => boolean = RegExp.prototype.test.bind(validNameRegEx);

export const nameValidCriteria = (n: string): string => (isNameValid(n) ? '' : invalidNameMessage);

export default NameInput;
