import React, { FC } from 'react';
import * as R from 'ramda';
import { Grid, Typography, TextFieldProps } from '@material-ui/core';
import { AutomationNameDefault } from '@common/util/automation';
import { ReferenceDataDefaultValues } from '@common/constants/referenceData';
import { StepComponentProps } from '../util/steps';
import { FormNames } from '@cappex/constants';
import BaseFormSelect from './BaseFormSelect';

interface GradDateProps extends Partial<StepComponentProps> {
	defaultValue?: {
		year?: string;
		month?: string;
	};
	label: string;
	innerNames?: {
		month: FormNames.highSchoolGradMonth | FormNames.collegeGradMonth;
		year: FormNames.highSchoolGradYear | FormNames.collegeGradYear;
	};
	required?: boolean;
	automationNameYear?: string;
	automationNameMonth?: string;
	variant?: TextFieldProps['variant'];
}
const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

const REFERENCE_MONTHS = months.map((month, idx) => ({
	value: String(month),
	id: String(idx + 1),
}));

const currentYear = new Date().getFullYear();
const REFERENCE_YEARS = R.range(currentYear - 50, currentYear + 7).map(year => ({
	value: String(year),
	id: String(year),
}));

const GradDateInput: FC<GradDateProps> = ({
	defaultValue = {},
	label,
	required = true,
	innerNames = { month: FormNames.highSchoolGradMonth, year: FormNames.highSchoolGradYear },
	automationNameYear = AutomationNameDefault.gradDateYear,
	automationNameMonth = AutomationNameDefault.gradDateMonth,
	variant = 'outlined',
}) => (
	<Grid container spacing={1}>
		<Grid item xs={12}>
			<Typography variant="h6" display="inline">
				{label}
			</Typography>
		</Grid>
		<Grid item xs={6}>
			<BaseFormSelect
				required={required}
				name={innerNames.month}
				id="grad-date-month"
				references={REFERENCE_MONTHS}
				automationName={automationNameMonth}
				initialValue={defaultValue.month || ReferenceDataDefaultValues.month}
				variant={variant}
			/>
		</Grid>
		<Grid item xs={6}>
			<BaseFormSelect
				required={required}
				name={innerNames.year}
				id="grad-date-year"
				references={REFERENCE_YEARS}
				automationName={automationNameYear}
				initialValue={defaultValue.year || R.toString(new Date().getFullYear() + 1)}
				variant={variant}
			/>
		</Grid>
	</Grid>
);

export default GradDateInput;
