import React, { FC, useContext, useEffect, useState } from 'react';
import { ModalContext, QueueAction } from '@src/common/util/steps/components/ModalStepFlow';
import { StepComponentProps } from '@src/common/util/steps';
import { DataFlowStepConfig } from '../../constants/types';
import { QuizContext } from '@src/common/util/quiz';
import Modal from '@common/components/GenericOutreachModal';
import LoadingComponent from '@src/common/components/LoadingComponent';

const QuizModalStep: FC<StepComponentProps<any, any, DataFlowStepConfig>> = ({
	active,
	complete,
}) => {
	const { queueModal } = useContext(ModalContext);
	const { getCurrentResult } = useContext(QuizContext);
	const [latch, setLatch] = useState(false);

	useEffect(() => {
		if (active && !latch) {
			setLatch(true);
			const result = getCurrentResult();
			if (result?.redirectUrl) {
				window.location.replace(result.redirectUrl);
			} else {
				queueModal(QueueAction.PREPEND_AFTER_CURRENT, 'QUIZ_RESULT', Modal, { result });
				complete();
			}
		}
	}, [active, queueModal, getCurrentResult, latch, complete]);

	return <LoadingComponent />;
};
export default QuizModalStep;
