import React, { FC, useMemo, useRef, useContext } from 'react';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import { styled } from '@cappex/theme';
import { StepComponentProps } from '@common/util/steps';
import requiredFieldMessage, { Criteria } from '@util/validation/constants';
import useFormValidation from '@util/hooks/useFormValidation';
import { FormFields } from '@util/validation/form';
import { PropTypes } from '@material-ui/core';
import { FormNames, ListFormNames } from '@cappex/constants';
import { SubFormContext } from './BaseValidationForm';

export interface BaseInputProps extends Partial<StepComponentProps> {
	id?: string;
	name: FormNames | ListFormNames;
	label?: string;
	inputType?: string;
	disabled?: boolean;
	beforeChange?: (value: string) => string;
	onChange?: (value: string) => void;
	onBlur?: (value: string) => void;
	initialValue?: string | number | boolean | string[] | object;
	helperText?: string;
	fullWidth?: boolean;
	LeadingIcon?: React.ReactNode;
	TrailingIcon?: React.ReactNode;
	validCriteria?: Criteria[];
	required?: boolean;
	verifiedBy?: FormNames;
	verifies?: FormNames;
	automationName?: string;
	inputMin?: number;
	inputMax?: number;
	inputStep?: number;
	margin?: PropTypes.Margin;
	variant?: TextFieldProps['variant'];
	multiFormInit?: boolean;
	removeUpdateFields?: boolean;
}

const TextFieldStyled = styled(TextField)`
	svg {
		${props => (props.error ? `color: ${props.theme.palette.error.main}` : '')};
	}
`;

export const createValidation = (
	name: string,
	verifies: string,
	required: boolean,
	validCriteria: Criteria[]
) => (input: FormFields, verifiesObj: FormFields): string => {
	const inputValue: string = input[name];
	const verifiesValue: string = verifiesObj && verifiesObj[verifies];

	if (required && !inputValue) {
		return requiredFieldMessage;
	}

	let result = '';

	validCriteria.some(validator => {
		result = validator(inputValue, verifiesValue);
		return !!result;
	});

	return result;
};

const DEFAULT_VALIDATION_CRITERIA = [];

const BaseFormInput: FC<BaseInputProps> = ({
	id,
	name,
	label,
	inputType = 'text',
	disabled = false,
	beforeChange = str => str,
	onChange = () => {},
	onBlur = () => {},
	initialValue = '',
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	validCriteria = DEFAULT_VALIDATION_CRITERIA,
	required = false,
	verifiedBy,
	verifies,
	complete = () => {},
	automationName,
	inputMin,
	inputMax,
	inputStep,
	margin,
	variant = 'filled',
	multiFormInit = false,
	removeUpdateFields = false,
}) => {
	const validate = useMemo(() => createValidation(name, verifies, required, validCriteria), [
		name,
		required,
		validCriteria,
		verifies,
	]);
	const { path } = useContext(SubFormContext);

	const inputRef = useRef(null);

	const initialValueObj = useMemo(() => ({ [name]: initialValue }), [initialValue, name]);

	const { value, setValue, error, setError, verifiesValue, clearVerifierError } = useFormValidation(
		{
			path,
			name,
			initialValue: initialValueObj,
			validator: validate,
			fieldRef: inputRef,
			verifies,
			verifiedBy,
			multiFormInit,
			removeUpdateFields,
		}
	);
	const inputValue = value[name] || '';

	const onInputChange = (inputVal: string): void => {
		const modifiedValue = beforeChange(inputVal);

		setValue({ [name]: modifiedValue });

		if (verifiedBy) {
			clearVerifierError();
		}

		onChange(modifiedValue);
	};

	const onInputBlur = (inputVal: string): void => {
		onBlur(inputVal);
		const validationMessage = validate(value, verifiesValue);

		setError([validationMessage]);
		complete(validationMessage);
	};

	return (
		<TextFieldStyled
			margin={margin}
			variant={variant}
			id={id}
			name={name}
			label={label}
			type={inputType}
			required={required}
			disabled={disabled}
			onChange={e => onInputChange(e.target.value)}
			onBlur={e => onInputBlur(e.target.value)}
			value={inputValue}
			error={!!error}
			fullWidth={fullWidth}
			helperText={!error ? helperText : error}
			ref={inputRef}
			InputLabelProps={{
				classes: { focused: 'labelFocus' },
			}}
			InputProps={{
				startAdornment: LeadingIcon,
				endAdornment: TrailingIcon,
				inputProps: {
					min: inputMin,
					max: inputMax,
					step: inputStep,
					'data-qa': automationName,
				},
			}}
		/>
	);
};

export default BaseFormInput;
