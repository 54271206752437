import React, { FC } from 'react';
import RadioChipSelector from './RadioChipSelector';
import { FormNames } from '@cappex/constants';

const EMPTY_FUNCTION = () => {};

export enum YesNoSelection {
	YES = 'true',
	NO = 'false',
	NO_ANSWER = '',
}
const BOOLEAN_OPTIONS = [
	{ displayValue: 'Yes', value: YesNoSelection.YES },
	{ displayValue: 'No', value: YesNoSelection.NO },
];

export type YesNoSelectorInputProps = {
	name: FormNames;
	title: string;
	defaultValue?: YesNoSelection | any;
	onSelect?: (val: any) => void;
	required?: boolean;
};

const YesNoSelectorInput: FC<YesNoSelectorInputProps> = ({
	name,
	title,
	defaultValue = YesNoSelection.NO,
	onSelect = EMPTY_FUNCTION,
	required = false,
}) => (
	<RadioChipSelector
		title={title}
		defaultValue={defaultValue}
		options={BOOLEAN_OPTIONS}
		radioName={name}
		complete={onSelect}
		reverse={EMPTY_FUNCTION}
		required={required}
		chipColumns={{ xs: 6 }}
	/>
);

export default YesNoSelectorInput;
