import React, { FC } from 'react';
import { EllipsisTypography } from '@cappex/components';
import { OfferCardBaseTextProps } from '@src/features/offer/components/OfferCardParagraph';

const OfferCardHeader: FC<OfferCardBaseTextProps> = ({ text, automationName }) => {
	return (
		text && (
			<EllipsisTypography
				paddingRight={false}
				variant="h6"
				lines={3}
				automationName={automationName}
				gutterBottom
			>
				{text}
			</EllipsisTypography>
		)
	);
};

export default OfferCardHeader;
