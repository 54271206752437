import { useContext, useEffect, useState } from 'react';
import { FormContext } from '@src/common/util/validation/form';
import checkInquiryConsent from '@src/features/consent/utils/checkInquiryConsent';
import {
	CanObtainConsentResponse,
	ConsentForm,
	ConsentResponse,
} from '@src/features/consent/consentUtil';
import { ListFormNames } from '@cappex/constants';

const useInquiryConsent = active => {
	const { getValue, setFormValue } = useContext(FormContext);
	const [inquiryConsentRes, setInquiryConsentRes] = useState<CanObtainConsentResponse>();
	const [loadingConsent, setLoadingConsent] = useState(true);

	useEffect(() => {
		if (active && !inquiryConsentRes?.canObtainConsent) {
			checkInquiryConsent(setInquiryConsentRes, () => setLoadingConsent(false));
		}
	}, [active, inquiryConsentRes?.canObtainConsent]);

	useEffect(() => {
		if (inquiryConsentRes?.canObtainConsent) {
			const currentConsents = getValue(ListFormNames.consents) as { consents: ConsentForm[] };
			const newConsent: ConsentForm = {
				consentTextId: inquiryConsentRes.consentTextId,
				responseTypeId: ConsentResponse.YES,
				consentTypeId: inquiryConsentRes.consentTypeId,
			};

			const formHasInquiryConsent = currentConsents.consents.some(
				consent =>
					consent.consentTextId === inquiryConsentRes.consentTextId &&
					consent.responseTypeId === ConsentResponse.YES
			);

			if (!formHasInquiryConsent) {
				currentConsents.consents.push(newConsent);
				setFormValue(ListFormNames.consents, currentConsents.consents);
			}
		}
	}, [getValue, setFormValue, inquiryConsentRes]);

	return {
		inquiryConsentRes,
		loadingConsent,
	};
};

export default useInquiryConsent;
