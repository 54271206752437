import React, { FC } from 'react';
import { styled } from '@cappex/theme';
import withStyleOptions from '@util/style/styleOptions';
import { Button } from '@material-ui/core';

const SignUpButton = styled(withStyleOptions(Button))`
	font-size: 1rem;
		.MuiButton-label {
				text-overflow: ellipsis;
				overflow: hidden;
				text-align: center;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
		}
`;

type OfferCardSubmitButtonProps = {
	onClick: () => void;
	buttonText: string;
	automationName: string;
	disabled?: boolean;
	type?: 'submit' | 'button' | 'reset';
};

const OfferCardSubmitButton: FC<OfferCardSubmitButtonProps> = ({
	disabled = false,
	onClick,
	buttonText,
	automationName,
	type = 'button',
}) => (
	<SignUpButton
		disabled={disabled}
		onClick={onClick}
		type={type}
		$boldFontWeight
		$noneTextTransform
		fullWidth
		variant="contained"
		color="primary"
		data-qa={automationName}
	>
		{buttonText}
	</SignUpButton>
);

export default OfferCardSubmitButton;
