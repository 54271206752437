import {
	CountryCode,
	formatIncompletePhoneNumber,
	getCountryCallingCode,
	parseIncompletePhoneNumber,
	parsePhoneNumberFromString,
} from 'libphonenumber-js/max';
import metadata from 'libphonenumber-js/metadata.full.json';
import * as R from 'ramda';
import React, { ChangeEvent, FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FormNames, ListFormNames } from '@cappex/constants';
import { FormControl, Grid, MenuItem, TextField, TextFieldProps } from '@material-ui/core';
import { AutomationNameDefault } from '@util/automation';
import useFormValidation from '@util/hooks/useFormValidation';
import { FormContext, FormFields } from '@util/validation/form';
import requiredFieldMessage from '../util/validation/constants';
import { SubFormContext } from './BaseValidationForm';
import ConsentCheckboxAndText from '@common/components/ConsentCheckboxAndText';
import { ConsentForm, ConsentResponse, ConsentType } from '@src/features/consent/consentUtil';

const metadataCountries = Object.keys(metadata.countries);

export const validatePhoneNumber = (
	countryCodeName: FormNames,
	countryCode: FormFields,
	phoneName: FormNames,
	phone: FormFields,
	required: boolean
) => {
	const phoneValue = phone[phoneName];
	const countryCodeValue = countryCode[countryCodeName];

	if (required && !phoneValue) {
		return requiredFieldMessage;
	}

	const phoneNumberObj = parsePhoneNumberFromString(countryCodeValue + phoneValue);

	const isValid = !phoneValue || (phoneNumberObj && phoneNumberObj.isValid());

	return isValid ? '' : 'Invalid Phone Number';
};

const createPhoneValidation = (
	countryCodeName: FormNames,
	phoneName: FormNames,
	required: boolean
) => (phoneInput: FormFields, countryCodeInput: FormFields) =>
	validatePhoneNumber(countryCodeName, countryCodeInput, phoneName, phoneInput, required);

const createCountryCodeValidation = (countryCodeName: FormNames, phoneName: FormNames) => (
	countryCodeInput: FormFields,
	phoneInput: FormFields
) => validatePhoneNumber(countryCodeName, countryCodeInput, phoneName, phoneInput, false);

const getPhoneDefault = (phone: string) => (R.isNil(phone) ? '' : phone);
const getCountryCodeDefault = (countryCode: string) =>
	R.isNil(countryCode) || R.isEmpty(countryCode) ? '+1' : countryCode;

interface PhoneNumberInputProps {
	id?: string;
	phoneName?: FormNames;
	countryCodeName?: FormNames;
	label?: string;
	countryCodeLabel?: string;
	initialPhoneValue?: string;
	initialCountryCodeValue?: string;
	automationName?: string;
	required?: boolean;
	variant?: TextFieldProps['variant'];
	consentTextId?: string;
	consentTypeId?: ConsentType;
	enableConsent?: boolean;
	defaultHideConsent?: boolean;
}

const PhoneNumberInput: FC<PhoneNumberInputProps> = ({
	id = AutomationNameDefault.phoneNumber,
	phoneName = FormNames.phone,
	countryCodeName = FormNames.countryCode,
	label = 'Phone Number',
	countryCodeLabel = 'Country Code',
	initialPhoneValue,
	initialCountryCodeValue,
	automationName = AutomationNameDefault.phoneNumber,
	required = false,
	variant = 'filled',
	consentTextId = null,
	consentTypeId = null,
	enableConsent = false,
	defaultHideConsent = false,
}) => {
	const [displayConsent, setDisplayConsent] = useState(!defaultHideConsent);

	const countryRef = useRef(null);
	const phoneRef = useRef(null);
	const { path } = useContext(SubFormContext);
	const { getValue, setFormValue } = useContext(FormContext);
	const [countryCodeAbr, setCountryCodeAbr] = useState<CountryCode>('US');

	const defaultConsent = {
		consentTextId,
		responseTypeId: ConsentResponse.NO_RESPONSE,
		consentTypeId,
	};

	useEffect(() => {
		const phone = getPhoneDefault(initialPhoneValue);
		const countryCode = getCountryCodeDefault(initialCountryCodeValue);

		const parsedPhoneNumber = parsePhoneNumberFromString(countryCode + phone);
		if (parsedPhoneNumber) {
			setCountryCodeAbr(parsedPhoneNumber.country);
		}
	}, [initialPhoneValue, initialCountryCodeValue]);

	const validatePhone = useMemo(() => createPhoneValidation(countryCodeName, phoneName, required), [
		countryCodeName,
		phoneName,
		required,
	]);
	const validateCountryCode = useMemo(
		() => createCountryCodeValidation(countryCodeName, phoneName),
		[countryCodeName, phoneName]
	);

	const initialPhoneValueObj = useMemo(
		() => ({
			[phoneName]: getPhoneDefault(initialPhoneValue),
		}),
		[initialPhoneValue, phoneName]
	);
	const {
		value: phoneValue,
		setValue: setPhoneValue,
		error: phoneError,
		setError: setPhoneError,
		clearVerifierError: clearCountryCodeError,
	} = useFormValidation({
		path,
		name: phoneName,
		initialValue: initialPhoneValueObj,
		validator: validatePhone,
		verifies: countryCodeName,
		verifiedBy: countryCodeName,
		fieldRef: phoneRef,
	});
	const { [phoneName]: phone } = phoneValue;
	const initialCountryCodeValueObj = useMemo(
		() => ({
			[countryCodeName]: getCountryCodeDefault(initialCountryCodeValue),
		}),
		[initialCountryCodeValue, countryCodeName]
	);
	const {
		value: countryCodeValue,
		setValue: setCountryCodeValue,
		error: countryCodeError,
		clearVerifierError: clearPhoneError,
	} = useFormValidation({
		path,
		name: countryCodeName,
		initialValue: initialCountryCodeValueObj,
		validator: validateCountryCode,
		verifies: phoneName,
		verifiedBy: phoneName,
		fieldRef: countryRef,
	});

	useEffect(() => {}, [getValue]);

	const handleConsent = (phoneNumber: string) => {
		const currentConsent = (getValue(ListFormNames.consents) as unknown) as {
			consents: ConsentForm[];
		};

		// no consent yet
		if (
			currentConsent.consents === undefined ||
			currentConsent.consents === null ||
			!currentConsent.consents.some(consent => consent.consentTextId === consentTextId)
		) {
			if (phoneNumber.length > 0) {
				if (currentConsent.consents !== undefined && currentConsent.consents !== null) {
					currentConsent.consents.push(defaultConsent);
					setFormValue(ListFormNames.consents, currentConsent.consents);
				} else {
					setFormValue(ListFormNames.consents, [defaultConsent]);
				}
			}
		} else {
			// consent exists
			const targetedConsent = currentConsent.consents.find(
				(consent: ConsentForm) => consent.consentTextId === consentTextId
			);

			// remove consent if response is 'no response' and there is no phone number
			if (
				phoneNumber.length === 0 &&
				targetedConsent.responseTypeId === ConsentResponse.NO_RESPONSE
			) {
				const idx = currentConsent.consents.indexOf(targetedConsent);
				currentConsent.consents.splice(idx, 1);
				setFormValue(ListFormNames.consents, currentConsent.consents);
			}
		}
	};

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;

		if (enableConsent) {
			handleConsent(newValue);
		}

		let newNumber = parseIncompletePhoneNumber(newValue);
		// Strip out any leading + since we want country codes to be handled elsewhere and keeping it confuses the formatter
		newNumber = newNumber[0] === '+' ? newNumber.slice(1) : newNumber;

		const formattedNumber = formatIncompletePhoneNumber(phone || '', countryCodeAbr);

		// If the new number matches the old number then formatting was deleted or an invalid character added.
		// If the formatted number contains the input value at position 0 then it must be formatting that was deleted
		// Protects against the case where the user presses delete on the value (123) and nothing chnages since the formatting will be added back in
		if (newNumber === phone && formattedNumber.indexOf(newValue) === 0) {
			newNumber = newNumber.slice(0, -1);
		}

		clearCountryCodeError();
		setPhoneValue({ [phoneName]: newNumber });
	};

	const onBlur = () => {
		setPhoneError([validatePhone(phoneValue, countryCodeValue)]);
	};

	const onCountryChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const newCountry = event.target.value as CountryCode;

		const newCountryCode = `+${metadata.countries[newCountry][0]}`;

		setCountryCodeAbr(newCountry);

		clearPhoneError();
		setCountryCodeValue({ [countryCodeName]: newCountryCode });
	};

	const countrySelectId = `${id}-country-select`;
	const hasError = !!phoneError || !!countryCodeError;
	const error = phoneError || countryCodeError;

	useEffect(() => {
		if (defaultHideConsent) {
			if (phoneValue != null && phoneValue.phone && phoneValue.phone.length > 0) {
				setDisplayConsent(true);
			} else {
				setDisplayConsent(false);
			}
		}
	}, [defaultHideConsent, phoneValue]);

	return (
		<>
			<Grid container wrap="nowrap" spacing={1}>
				<Grid item xs={4}>
					<FormControl fullWidth error={hasError}>
						<TextField
							select
							variant={variant}
							label={countryCodeLabel}
							id={countrySelectId}
							value={countryCodeAbr}
							onChange={onCountryChange}
							onBlur={onBlur}
							fullWidth
							inputProps={{
								'data-qa': `${automationName}-country-select`,
							}}
							ref={countryRef}
						>
							{metadataCountries.map((code: CountryCode) => (
								<MenuItem value={code} key={code}>
									{`${code} +${getCountryCallingCode(code)}`}
								</MenuItem>
							))}
						</TextField>
					</FormControl>
				</Grid>
				<Grid item xs={9}>
					<TextField
						variant={variant}
						id={`${id}-input`}
						value={formatIncompletePhoneNumber(phone || '', countryCodeAbr)}
						onChange={onChange}
						onBlur={onBlur}
						type="tel"
						fullWidth
						label={label}
						error={hasError}
						helperText={hasError ? error : ' '}
						inputProps={{
							'data-qa': `${automationName}-input`,
						}}
						ref={phoneRef}
					/>
				</Grid>
			</Grid>
			{enableConsent && displayConsent && consentTextId && consentTypeId && (
				<ConsentCheckboxAndText
					consentTextId={consentTextId}
					consentTypeId={consentTypeId}
					hasValidCorrespondingInput={!!phoneValue.phone}
					allowNoResponse
				/>
			)}
		</>
	);
};

export default PhoneNumberInput;
