import illustration from '@common/assets/Scholarship.svg';
import { defaultLegalTextConfig, TextLinkEnum } from '@src/common/components/LegalTextAndLinks';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { includedInStepCount, name } from '@src/common/util/steps/constants';
import { DataFlowConfig } from '../../dataflow/constants/types';
import BoldableTypography, {
	BoldableTypographyEnum,
} from '@src/common/components/BoldableTypography';
import { DataFlowStepName } from '@cappex/constants';

const defaultConfiguration: DataFlowConfig = {
	key: 'registration__scholarship',
	canStartMidway: false,
	steps: [
		{
			key: DataFlowStepName.ScholarshipInfoName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: true,
				[name]: 'info',
				leftMedia: {
					media: illustration,
					headerVariant: 'h5',
					header: 'Enter to win our $1,000 Appily Easy Money Scholarship',
				},
				rightMedia: {
					text: 'No essay or minimum GPA required to win.',
				},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text:
							'No purchase necessary to enter or win. US residents enrolling in qualified post secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.CreateAccountName,
			sortOrder: 1,
			data: {
				[includedInStepCount]: true,
				[name]: 'create',
				leftMedia: {
					media: illustration,
					headerVariant: 'h5',
					header: 'Enter to win our $1,000 Appily Easy Money Scholarship',
				},
				rightMedia: {
					text: 'Last step! Complete your profile to be entered to win.',
				},
				showLeftTextMediaMobile: false,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text: 'By signing up and entering, you agree to our ',
					},
					// Preceding text is different from default config
					...defaultLegalTextConfig.slice(1),
					{
						type: TextLinkEnum.TEXT,
						text:
							' No purchase necessary to enter or win. US residents enrolling in qualified post-secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.InviteFriendLinkName,
			sortOrder: 2,
			data: {
				[includedInStepCount]: false,
				[name]: 'friend',
				leftMedia: {
					media: illustration,
				},
				rightMedia: {
					headerVariant: 'h5',
					header: 'Welcome to Appily! We found a new scholarship for you.',
					textComponent: BoldableTypography,
					textProps: {
						variant: 'body1',
						config: [
							{
								type: BoldableTypographyEnum.TEXT,
								text:
									"Invite a friend to Appily and you'll both be entered to earn our $1,000 Friends Scholarship.",
							},
						],
					},
				},
				isAllowedUnauthentic: false,
				showRightTextMedia: true,
				targetWhenUnauthentic: '/login',
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text:
							'No purchase necessary to enter or win. US residents enrolling in qualified post secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.Finalize,
			sortOrder: 4,
			data: {
				[includedInStepCount]: false,
				[name]: 'finalize',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.SnackbarStepName,
			sortOrder: 5,
			data: {
				[includedInStepCount]: false,
				[name]: 'snackbar',
				pseudo: true,
				isAllowedUnauthentic: false,
				message: 'Application Submitted!',
			},
		},
		{
			key: DataFlowStepName.Consent,
			sortOrder: 6,
			data: {
				[includedInStepCount]: false,
				[name]: 'consent',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 7,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				pseudo: true,
				// @ts-ignore
				to: { pathname: '/dashboard', search: '?path=scholarship' },
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
	],
};

export default defaultConfiguration;
