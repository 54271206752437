import React, { FC } from 'react';
import NameInput from '@common/components/NameInput';
import { Typography, Grid, TextFieldProps } from '@material-ui/core';
import { AutomationNameDefault } from '@common/util/automation';
import { FormNames } from '@cappex/constants';

interface NameFormProps {
	id?: string;
	label?: string;
	fullWidth?: boolean;
	firstNameLabel?: string;
	lastNameLabel?: string;
	required?: boolean;
	defaultValue?: {
		first?: string;
		last?: string;
	};
	automationNameFirst?: string;
	automationNameLast?: string;
	variant?: TextFieldProps['variant'];
}

const NameForm: FC<NameFormProps> = ({
	id = '',
	label,
	fullWidth = false,
	firstNameLabel = 'First Name',
	lastNameLabel = 'Last Name',
	required = true,
	defaultValue = {},
	automationNameFirst = AutomationNameDefault.firstNameInput,
	automationNameLast = AutomationNameDefault.lastNameInput,
	variant,
}) => (
	<Grid item container spacing={variant === 'outlined' ? 1 : 0}>
		{label && (
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Typography align="left" variant="h6">
					<b>{label}</b>
				</Typography>
			</Grid>
		)}
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<NameInput
				id={`${id}firstName`}
				name={FormNames.firstName}
				fullWidth={fullWidth}
				label={firstNameLabel}
				initialValue={defaultValue.first}
				required={required}
				automationName={automationNameFirst}
				variant={variant}
			/>
		</Grid>
		<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
			<NameInput
				id={`${id}lastName`}
				name={FormNames.lastName}
				fullWidth={fullWidth}
				label={lastNameLabel}
				initialValue={defaultValue.last}
				required={required}
				automationName={automationNameLast}
				variant={variant}
			/>
		</Grid>
	</Grid>
);

export default NameForm;
