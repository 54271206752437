import React, { FC } from 'react';
import { BaseInputProps } from './BaseFormInput';
import { ListSubForm } from './BaseValidationForm';
import HiddenInput from './HiddenInput';

type HiddenFlexProps = BaseInputProps & {
	path: string[];
};

const HiddenFlexInput: FC<HiddenFlexProps> = ({ id, name, path, initialValue, automationName }) => {
	return (
		<ListSubForm name={path} distinct>
			<HiddenInput
				id={id}
				name={name}
				initialValue={initialValue}
				automationName={automationName}
			/>
		</ListSubForm>
	);
};

export default HiddenFlexInput;
