import { useEffect, useRef, useState } from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
	const ref = useRef(null);

	const handleClickOutside = event => {
		if (ref.current && !ref.current.contains(event.target)) {
			setIsComponentVisible(false);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		document.addEventListener('touchend', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
			document.removeEventListener('touchend', handleClickOutside, true);
		};
	});

	return { ref, isComponentVisible, setIsComponentVisible };
}
