import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import { loadUserAccount, UserAccount } from '@util/user/userUtil';
import useFailedAuth from '../auth/hooks/useFailedAuth';

const useUserData = (
	onError: () => void,
	doRefresh = true,
	suppressSnackbar = false
): [UserAccount, Dispatch<SetStateAction<UserAccount>>, () => void] => {
	const [currentUser, setCurrentUser] = useState({} as UserAccount);
	const failAuth = useFailedAuth(suppressSnackbar);

	const refreshData = useCallback(() => loadUserAccount(setCurrentUser, onError, failAuth), [
		failAuth,
		onError,
	]);

	useEffect(() => {
		if (doRefresh) {
			refreshData();
		}
	}, [doRefresh, refreshData]);

	return [currentUser, setCurrentUser, refreshData];
};

export default useUserData;
