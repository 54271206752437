enum DataLayerEventTypes {
	APPLICATION_BIRTH_YEAR = 'applicationBirthYear',
	FLEX_REG_BIRTH_YEAR = 'flexRegBirthYear',
	FLEX_REG_ENROLLED = 'flexRegEnrolled',
	FLEX_REG_MOST_RECENT_DEGREE = 'flexRegMostRecentDegree',
	FLEX_REG_SEEKING_MBA = 'flexRegSeekingMba',
	FLEX_REG_EMAIL_INPUT = 'flexRegEmailInput',
	FLEX_REG_LEVEL_OF_DEGREE_SEEKING = 'flexRegLevelOfDegreeSeeking',
}

export default DataLayerEventTypes;
