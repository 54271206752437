import * as R from 'ramda';
import request, { JsonAcceptHeader, JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { HighSchoolData } from './HighSchoolData';

const getHighSchool = (highSchoolId: string): Promise<HighSchoolData> => {
	return request<any>({
		url: getEndpoint(`/reference-data/v1/getHighSchools?ids=${highSchoolId}`),
		method: RequestMethod.GET,
		headers: [JsonAcceptHeader, JsonContentTypeHeader],
		withCredentials: true,
	})
		.then(res => {
			const { data } = res;
			const { response } = data;

			if (data.meta.success && !R.isNil(response)) {
				return data.response[0];
			}
			throw new Error();
		})
		.catch<HighSchoolData>(() => {
			return null;
		});
};

export default getHighSchool;
