import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import EmailPasswordForm from '@common/components/EmailPasswordForm';
import checkLockout from '@util/lockout';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AnchorButton from '@src/common/components/AnchorButton';
import { SubForm } from '@src/common/components/BaseValidationForm';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { FormNames, DataFlowStepName } from '@cappex/constants';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import useUrlParam from '@src/common/util/hooks/useUrlParam';
import { checkForFormError, FORM_NAME, getFormErrors } from '@cappex/request';
import { RoleId } from '@src/common/util/roles/constants';
import { StepContainerProps } from '@src/common/util/steps';
import withStyleOptions from '@src/common/util/style/styleOptions';
import InviteTokenUrlParam from '@src/features/invite/constants';
import { FormContext, FormContextValue, FormFields } from '@util/validation/form';
import inviteConfig from '../../../registration/constants/inviteConfig';
import { DataFlowStepConfig, DataFlowStepComponent } from '../../constants/types';
import DataFlowContext from '../../util/DataFlowContext';
import DataFlowContainer from '../DataFlowContainer';

interface CreateAccountFormFields extends FormFields {
	[FormNames.email]?: string;
	[FormNames.password]?: string;
	[FormNames.uuid1]?: string;
	[FormNames.uuid2]?: string;
}

type OwnProps = {};

interface RedirectStepType extends DataFlowStepConfig {
	to: string;
}

const RegisterButton = withStyleOptions(Button);

function handleCreateAccountFailure(
	json: any,
	setCreateAccountDisabled: React.Dispatch<React.SetStateAction<boolean>>,
	setFormErrors: FormContextValue['setFormErrors'],
	openErrorSnack: (formError: string) => void
) {
	const lockedOut = checkLockout(json);
	if (!lockedOut) {
		setCreateAccountDisabled(false);
		const formErrors: CreateAccountFormFields = getFormErrors(json);
		setFormErrors(formErrors);
		if (checkForFormError(formErrors)) {
			openErrorSnack(formErrors[FORM_NAME]);
		}
	}
}

const CreateAccountPage: FC<DataFlowStepComponent<any, any> & StepContainerProps & OwnProps> = ({
	data: {
		topMedia,
		backgroundMedia,
		leftMedia,
		rightMedia,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		legalTextConfig,
		variant,
		inputVariant = 'standard',
	},
	active,
	complete,
	customLogoUrl,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const { setFormErrors } = useContext(FormContext);
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { isAuthentic, setValidAuth } = useContext(AuthContext);

	const [emailPasswordComplete, setEmailPasswordComplete] = useState(false);
	const [isCreateAccountDisabled, setCreateAccountDisabled] = useState(false);

	const openErrorSnack = (formError: string) => {
		openSnackbar({
			message: formError,
		});
	};

	// Get and setup to reconfigure if invite
	const email = useUrlParam('email');
	const uuid1 = useUrlParam('uuid1');
	const uuid2 = useUrlParam('uuid2');
	const alternateConfig = email && uuid1 && uuid2 && inviteConfig;

	const onClick = () => {
		setPreHook(() => () => {
			setCreateAccountDisabled(true);
		});

		setPostHook(() => responseData => {
			if (responseData.meta.success) {
				if (uuid1 && uuid2) {
					const redirectStepIndex = R.findIndex(
						R.propEq('key', DataFlowStepName.Redirect),
						alternateConfig.steps
					);
					const { to } = alternateConfig.steps[redirectStepIndex].data as RedirectStepType;

					if (!to.includes(InviteTokenUrlParam.first)) {
						// Set the redirect to have query parameters if it needs them and doesn't have them
						(alternateConfig.steps[redirectStepIndex].data as RedirectStepType).to = `${to}${
							to.includes('?') ? '&' : '?'
						}${InviteTokenUrlParam.first}=${uuid1}&${InviteTokenUrlParam.second}=${uuid2}`;
					}
				}
				if (!R.isEmpty(responseData.response.accountId)) {
					setValidAuth(
						RoleId.STUDENT,
						responseData.response.userAccessPermissions,
						false,
						responseData.response.accountUuid
					);
				}

				complete({ newConfig: alternateConfig });
				setCreateAccountDisabled(false);
			} else {
				handleCreateAccountFailure(
					responseData,
					setCreateAccountDisabled,
					setFormErrors,
					openErrorSnack
				);
			}
		});

		setErrorHook(() => () => {
			setCreateAccountDisabled(false);
			handleCreateAccountFailure(
				{
					meta: { success: false },
				},
				setCreateAccountDisabled,
				setFormErrors,
				openErrorSnack
			);
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			complete();
		}
	}, [isAuthentic, active, complete]);

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			variant={variant}
			customLogoUrl={customLogoUrl}
		>
			<Grid container justifyContent="center" spacing={3}>
				<Grid item xs={12}>
					<SubForm name="accountInfo">
						<EmailPasswordForm
							complete={() => setEmailPasswordComplete(true)}
							reverse={() => {}}
							email={email}
							emailLocked={!R.isNil(email)}
							required={active}
							active={active}
							passwordLabel="Create Password"
							variant={inputVariant}
						/>
					</SubForm>
				</Grid>
				<Grid item xs={12}>
					<RegisterButton
						data-qa="create-account-button"
						$noneTextTransform
						$boldFontWeight
						type="submit"
						variant="contained"
						color="primary"
						fullWidth
						disabled={isCreateAccountDisabled || !emailPasswordComplete}
						onClick={onClick}
					>
						Get Started
					</RegisterButton>
				</Grid>
				<Grid item xs={11}>
					<LegalTextAndLinks configs={legalTextConfig} />
				</Grid>
				<AnchorButton text="Already have an account?" href="/login" />
			</Grid>
		</DataFlowContainer>
	);
};

export default CreateAccountPage;
