import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';

type SeekingTestOptionalAdmissionProps = Omit<Partial<BaseFormSelectProps>, 'references'>;

const SeekingTestOptionalAdmissions: FC<SeekingTestOptionalAdmissionProps> = ({ required }) => (
	<YesNoSelectorInput
		title=""
		name={FormNames.seekingTestOptionalAdmissions}
		defaultValue={YesNoSelection.NO_ANSWER}
		required={required}
	/>
);

export default SeekingTestOptionalAdmissions;
