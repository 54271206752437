import React, { FC, useContext, useMemo, useRef, useState, useEffect } from 'react';
import * as R from 'ramda';
import { styled } from '@cappex/theme';
import { FormHelperText, Typography } from '@material-ui/core';
import { defaultComponents } from '@cappex/search';
import StudentCollegeSearch from '@src/features/collegelist/components/StudentCollegeSearch';
import { FormNames, CompleteCollegeOptions } from '@cappex/constants';
import useFormValidation from '../util/hooks/useFormValidation';
import requiredFieldMessage from '../util/validation/constants';
import { FormFields } from '../util/validation/form';
import { SubFormContext } from './BaseValidationForm';
import { createGetCloudCollegeData } from '../util/hooks/useCloudCollegeData';

type CurrentCollegeInputProps = {
	label: string;
	placeholder?: string;
	onSelect?: (collegeObject: object) => void;
	collegeId?: string;
	flat?: boolean;
	required?: boolean;
};

const ErrorText = styled(FormHelperText)`
	padding-left: 1rem;
`;

const createValidation = (name: string, required: boolean) => (input: FormFields) =>
	required && (R.isNil(input[name]) || R.isEmpty(input[name])) ? requiredFieldMessage : '';

const CurrentCollegeInput: FC<CurrentCollegeInputProps> = ({
	label,
	placeholder = 'Add a College',
	onSelect,
	collegeId,
	flat,
	required = false,
}) => {
	const { path } = useContext(SubFormContext);

	const gridRef = useRef(null);

	const validate = useMemo(() => createValidation(FormNames.currentCollegeId, required), [
		required,
	]);

	const [placeholderText, setPlaceholderText] = useState(placeholder);
	const [hasLoadedOnce, setLoaded] = useState(false);
	useEffect(() => setPlaceholderText(placeholder), [placeholder]);

	const initialValueObj = useMemo(
		() => ({
			[FormNames.currentCollegeId]: collegeId || '',
		}),
		[collegeId]
	);

	const { error, setValue, value } = useFormValidation({
		path,
		name: FormNames.currentCollegeId,
		initialValue: initialValueObj,
		validator: validate,
		fieldRef: gridRef,
	});

	useEffect(() => {
		if (!hasLoadedOnce && collegeId) {
			createGetCloudCollegeData(
				[Number(collegeId)],
				[CompleteCollegeOptions.COLLEGE],
				() => {},
				colleges => {
					setPlaceholderText(colleges[0]?.college?.name ?? placeholderText);
				},
				() => {}
			)();
			setLoaded(true);
		}
	}, [collegeId, hasLoadedOnce, placeholderText]);

	useEffect(() => {
		if (value[FormNames.currentCollegeId] && !collegeId) {
			createGetCloudCollegeData(
				[Number(value[FormNames.currentCollegeId])],
				[CompleteCollegeOptions.COLLEGE],
				() => {},
				colleges => {
					setPlaceholderText(colleges[0]?.college?.name ?? placeholderText);
				},
				() => {}
			)();
		}
	}, [value, collegeId, placeholderText]);

	return (
		<>
			<Typography gutterBottom variant="h6" ref={gridRef}>
				{label}
			</Typography>
			<StudentCollegeSearch
				placeholderText={placeholderText}
				onItemSelect={collegeObject => {
					setPlaceholderText(collegeObject.label);
					setValue({ [FormNames.currentCollegeId]: collegeObject.value });
					onSelect && onSelect(collegeObject);
				}}
				stylingComponents={flat && defaultComponents}
			/>
			<ErrorText error={!!error}>{error}</ErrorText>
		</>
	);
};

export default CurrentCollegeInput;
