import { createContext } from 'react';
import { ReferenceData } from '../hooks/useCloudReferenceData';
import { StudentCollegeConnection, AddListItemParams } from './constants';

type SCLContext = {
	list: StudentCollegeConnection[];
	collegeApplicationStatuses: ReferenceData[];
	isDefaultData: boolean;
	refresh: () => void;
	setListItem: (updatedItem: StudentCollegeConnection) => void;
	addListItem: (params: AddListItemParams) => void;
	removeListItem: (collegeId: number) => void;
	isSavingCollege: number | null;
};

const DEFAULT_FUNCTION = () => {
	throw new Error('Using default context is disallowed for: Student College List Context');
};

const StudentCollegeListContext = createContext<SCLContext>({
	list: [],
	collegeApplicationStatuses: [],
	isDefaultData: false,
	refresh: DEFAULT_FUNCTION,
	setListItem: DEFAULT_FUNCTION,
	addListItem: DEFAULT_FUNCTION,
	removeListItem: DEFAULT_FUNCTION,
	isSavingCollege: null,
});

export default StudentCollegeListContext;
