import React, { FC } from 'react';
import PhoneNumberInput from '@src/common/components/PhoneNumberInput';
import { FormNames } from '@cappex/constants';

interface OfferBaseFormPhoneInputType {
	id?: string;
	phoneName?: string;
	countryCodeName?: string;
	label?: string;
	countryCodeLabel?: string;
	automationName?: string;
	required?: boolean;
	initialCountryCodeValue?: string;
	initialPhoneValue?: string;
}

const OfferBaseFormPhoneInput: FC<OfferBaseFormPhoneInputType> = ({
	id,
	phoneName,
	countryCodeName,
	label,
	countryCodeLabel,
	automationName,
	required = false,
	initialCountryCodeValue,
	initialPhoneValue,
}) => (
	<PhoneNumberInput
		id={id}
		phoneName={phoneName as FormNames}
		countryCodeName={countryCodeName as FormNames}
		label={label}
		countryCodeLabel={countryCodeLabel}
		initialCountryCodeValue={initialCountryCodeValue} // PhoneNumberInput defaults to +1 if nil
		initialPhoneValue={initialPhoneValue}
		automationName={automationName}
		required={required}
	/>
);

export default OfferBaseFormPhoneInput;
