import { validNumericRangeCriteria } from '@util/validation/numericrange';
import { validIsMultipleCriteria } from '@util/validation/isMultiple';

const YEARS_MIN = 0;
const YEARS_MAX = 99;
const YEARS_STEP = 1;

const validYearsCriteria = (value: string): string => {
	return (
		validNumericRangeCriteria(value, YEARS_MIN, YEARS_MAX) ||
		validIsMultipleCriteria(value, YEARS_STEP)
	);
};

export {
	YEARS_MIN,
	YEARS_MAX,
	YEARS_STEP,
	// validation criteria - to be used in components.
	validYearsCriteria,
};
