import * as R from 'ramda';
import React, { FC, useContext, useEffect } from 'react';
import { Grid, TextFieldProps, Typography } from '@material-ui/core';
import PsatNmsqtInput from './PsatNmsqtInput';
import { FormNames } from '@cappex/constants';
import PreActInput from './PreActInput';
import requiredFieldMessage from '../util/validation/constants';
import { FormContext } from '../util/validation/form';

type PreTestScoresInputProps = {
	centered?: boolean;
	singleColumnStyle?: boolean;
	label?: string;
	subText?: string;
	required?: boolean;
	variant?: TextFieldProps['variant'];
};

const PreTestScoresInput: FC<PreTestScoresInputProps> = ({
	centered = false,
	singleColumnStyle,
	label = 'Test Scores',
	subText,
	required = false,
	variant,
}) => {
	const { getValue, getError, setError } = useContext(FormContext);

	const preActIsEmpty = R.isEmpty(getValue(FormNames.preAct)[FormNames.preAct]);
	const psatIsEmpty = R.isEmpty(getValue(FormNames.psatNmsqt)[FormNames.psatNmsqt]);

	useEffect(() => {
		if (!psatIsEmpty && getError(FormNames.preAct) === requiredFieldMessage) {
			setError(FormNames.preAct, ['']);
		}

		if (!preActIsEmpty && getError(FormNames.psatNmsqt) === requiredFieldMessage) {
			setError(FormNames.psatNmsqt, ['']);
		}
	}, [preActIsEmpty, psatIsEmpty, getError, setError]);

	return (
		<Grid container spacing={4} justifyContent={centered ? 'center' : 'flex-start'}>
			<Grid container item xs={12} spacing={1}>
				<Grid item xs={12}>
					<Typography variant="h6">{label}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="body2" color="textSecondary">
						{subText}
					</Typography>
				</Grid>
				<Grid item xs={singleColumnStyle ? 12 : 6}>
					<PsatNmsqtInput
						name={FormNames.psatNmsqt}
						fullWidth
						required={required && preActIsEmpty}
						variant={variant}
					/>
				</Grid>
				<Grid item xs={singleColumnStyle ? 12 : 6}>
					<PreActInput
						name={FormNames.preAct}
						fullWidth
						required={required && psatIsEmpty}
						variant={variant}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default PreTestScoresInput;
