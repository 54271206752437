import { buildId } from '@src/features/environment';

// New Relic is injected by script. Declare this to keep ts happy
declare const newrelic: any;

const enableNewRelic = () => {
	if (typeof newrelic !== 'undefined') {
		newrelic.addRelease('ReactCappex', buildId);
	}
};

export default enableNewRelic;
