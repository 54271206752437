import {
	CHANCES_PATH,
	MAIN_PATH,
	MATCHES_PATH,
	SCHOLARSHIP_PATH,
} from '@src/features/registration/constants/registrationPathConfigs';

export const offerPhoneNamePrefix = 'phone-';
export const offerCountryCodeNamePrefix = 'countryCode-';

export enum ResponseType {
	ACCEPT = 'ACCEPT',
}

export enum Placement {
	FEATURED_OFFER_PAGE = 'FEATURED_OFFER_PAGE',
	REGISTRATION_INTERSTITIAL__MAIN = 'REGISTRATION_INTERSTITIAL__MAIN',
	REGISTRATION_INTERSTITIAL__SCHOLARSHIPS = 'REGISTRATION_INTERSTITIAL__SCHOLARSHIPS',
	REGISTRATION_INTERSTITIAL__CHANCES = 'REGISTRATION_INTERSTITIAL__CHANCES',
	REGISTRATION_INTERSTITIAL__MATCHES = 'REGISTRATION_INTERSTITIAL__MATCHES',
	LOG_IN_INTERSTITIAL = 'LOG_IN_INTERSTITIAL',
	FEATURED_SCHOLARSHIPS_CAROUSEL__FIND_SCHOLARSHIPS = 'FEATURED_SCHOLARSHIPS_CAROUSEL__FIND_SCHOLARSHIPS',
}

export interface CampaignOfferByIdResponse extends CampaignOffer {
	isCompleted: boolean;
	isMatching: boolean;
}

export type CampaignOffer = {
	id: number;
	campaignId: number;
	externalId: string;
	campaignPlacementPriority: number;
	name: string;
	active: boolean;
	campaignOfferContent: CampaignOfferContent[];
};

export type CampaignOfferContent = {
	id: number;
	campaignOfferId: number;
	position: number;
	campaignOfferContentType: CampaignOfferContentType;
	campaignOfferContentDisplayType: CampaignOfferContentDisplayType;
	campaignOfferContentValue: CampaignOfferContentValue[];
	campaignOfferContentValidations: CampaignOfferContentValidation[];
};

export type CampaignOfferContentValidation = {
	campaignOfferContentId: number;
	campaignOfferContentValidationType: CampaignOfferValidationType;
	value: string;
};

export type CampaignOfferContentValue = {
	id: number;
	campaignOfferContentId: number;
	offerContentValueActions: CampaignOfferContentValueAction[];
	position: number;
	text: string;
	value: string;
};

export type CampaignOfferContentValueAction = {
	campaignOfferContentValueId: number;
	value: string;
	campaignOfferContentValueActionType: CampaignOfferContentValueActionType;
};

export enum CampaignOfferContentValueActionType {
	SUBMIT = 'SUBMIT',
	SHOW_MODAL = 'SHOW_MODAL',
	LINK_OUT = 'LINK_OUT',
}

export enum CampaignOfferContentDisplayType {
	MAIN = 'MAIN',
	MODAL = 'MODAL',
}

export enum CampaignOfferContentType {
	LOGO = 'LOGO',
	HERO = 'HERO',
	PARAGRAPH = 'PARAGRAPH',
	INPUT_TEXT = 'INPUT_TEXT',
	TEXT = 'TEXT',
	HEADER = 'HEADER',
	BUTTON = 'BUTTON',
	PHONE_WITH_COUNTRY_CODE = 'PHONE_WITH_COUNTRY_CODE',
	SELECT = 'SELECT',
	CHECKBOX = 'CHECKBOX',
	DISCLAIMER = 'DISCLAIMER',
	LEAD_ID = 'LEAD_ID',
	LEAD_ID_TCPA_DISCLOSURE = 'LEAD_ID_TCPA_DISCLOSURE',
	EMAIL = 'EMAIL',
	INPUT_NAME = 'INPUT_NAME',
	HIDDEN = 'HIDDEN',
	SUB_HEADER = 'SUB_HEADER',
	MODAL_PARAGRAPH = 'MODAL_PARAGRAPH',
	PARAGRAPH_WITH_HTML = 'PARAGRAPH_WITH_HTML',
}

export type CampaignOfferContentIdToValues = {
	[key: number]: string[];
};

export type AccountCampaignOfferResponse = {
	campaignOfferId: number;
	responseType: ResponseType;
	placementName: Placement;
	campaignOfferContentIdToValues: CampaignOfferContentIdToValues;
};

export enum CampaignOfferValidationType {
	REQUIRED = 'REQUIRED',
	PHONE_NUMBER = 'PHONE_NUMBER',
	NO_NUMERIC = 'NO_NUMERIC',
	EMAIL = 'EMAIL',
}

export type CampaignOfferContentToContent = {
	[key in CampaignOfferContentType]: CampaignOfferContent;
};

export const regPathToRegInterstitialPlacement = {
	[MAIN_PATH]: Placement.REGISTRATION_INTERSTITIAL__MAIN,
	[SCHOLARSHIP_PATH]: Placement.REGISTRATION_INTERSTITIAL__SCHOLARSHIPS,
	[CHANCES_PATH]: Placement.REGISTRATION_INTERSTITIAL__CHANCES,
	[MATCHES_PATH]: Placement.REGISTRATION_INTERSTITIAL__MATCHES,
};
