import React, { FC } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { toWholeNumber } from '@util/string/stringUtil';
import { validPreActCriteria, PRE_ACT_MIN, PRE_ACT_MAX } from '../util/validation/act';

type PreActInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const PRE_ACT_CRITERIA = [validPreActCriteria];

const PreActInput: FC<PreActInputProps> = ({
	id = AutomationNameDefault.preActInput,
	name,
	label = 'PreAct',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.preActInput,
	margin,
	variant,
}) => (
	<BaseFormInput
		margin={margin}
		inputType="number"
		automationName={automationName}
		id={id}
		label={label}
		inputMin={PRE_ACT_MIN}
		inputMax={PRE_ACT_MAX}
		inputStep={1}
		fullWidth={fullWidth}
		initialValue={initialValue}
		validCriteria={PRE_ACT_CRITERIA}
		name={name}
		disabled={disabled}
		onChange={onChange}
		onBlur={onBlur}
		beforeChange={toWholeNumber}
		helperText={helperText}
		LeadingIcon={LeadingIcon}
		TrailingIcon={TrailingIcon}
		required={required}
		verifiedBy={verifiedBy}
		verifies={verifies}
		variant={variant}
	/>
);

export default PreActInput;
