import React, { useState, useContext, useRef, useMemo, FC, useCallback, useEffect } from 'react';
import * as R from 'ramda';
import useCloudReferenceData from '@src/common/util/hooks/useCloudReferenceData';
import { ReferenceDataTypes, ListFormNames } from '@cappex/constants';
import useFormValidation from '@src/common/util/hooks/useFormValidation';
import { SubFormContext } from '@src/common/components/BaseValidationForm';
import { AutomationNameDefault, AutomationNameGeneric } from '@src/common/util/automation';
import { Typography, Grid } from '@material-ui/core';
import { OpenToAnyButton } from './CollegePreferencesCard';
import { MAX_SELECTED_CHIPS } from '../constants';
import PreferenceChip from './PreferenceChip';

type SizeFormComponentProps = {
	label: string;
	name: string;
	defaultValue?: string[];
};

const EMPTY_ARRAY = [];

const SizeFormComponent: FC<SizeFormComponentProps> = ({
	label,
	name,
	defaultValue = EMPTY_ARRAY,
}) => {
	const [sizeData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.studentBodySize,
	});
	const automationNameClearPreference = `size-${AutomationNameDefault.clearPreferencesButton}`;
	const automationNamePreferenceChipButton = `size-${AutomationNameGeneric.chipButton}`;
	const { path } = useContext(SubFormContext);
	const [selectedItems, setSelectedItems] = useState<string[]>(defaultValue);

	const headerRef = useRef(null);
	const initialValueObj = useMemo(
		() => ({
			[ListFormNames.collegeSizeIds]: defaultValue,
		}),
		[defaultValue]
	);

	const { value, setValue } = useFormValidation({
		path,
		name,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	useEffect(() => {
		// keep the selected items in sync with the form value when the form value changes from outside
		if (value[ListFormNames.collegeSizeIds] !== selectedItems) {
			setSelectedItems(value[ListFormNames.collegeSizeIds] as string[]);
		}
	}, [value, selectedItems]);

	const handleMultipleSelect = useCallback(
		(id: string) => {
			const updatedState = [...selectedItems];
			let hasUpdated = false;

			const idIndex = selectedItems && selectedItems.findIndex(itemId => itemId === id);
			if (idIndex >= 0) {
				updatedState.splice(idIndex, 1);
				hasUpdated = true;
			} else if (selectedItems.length < MAX_SELECTED_CHIPS) {
				updatedState.push(id);
				hasUpdated = true;
			}

			if (hasUpdated) {
				setSelectedItems(updatedState);
				setValue({
					...value,
					[ListFormNames.collegeSizeIds]: updatedState,
				});
			}
		},
		[selectedItems, setValue, value]
	);

	const clear = useCallback(() => {
		setSelectedItems([]);
		setValue({
			...value,
			[ListFormNames.collegeSizeIds]: [],
		});
	}, [value, setValue]);

	return (
		<Grid container spacing={2} innerRef={headerRef}>
			{label && (
				<Grid item xs={12}>
					<Typography variant="h6">{label}</Typography>
				</Grid>
			)}
			<Grid item xs={12}>
				<Typography variant="body2" color="textSecondary">
					Select up to {MAX_SELECTED_CHIPS}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{sizeData.map(preference => (
						<Grid item xs={12} key={preference.id}>
							<PreferenceChip
								clickable
								onClick={() => handleMultipleSelect(preference.id)}
								label={preference.value}
								data-qa={automationNamePreferenceChipButton}
								isSelected={selectedItems && selectedItems.includes(preference.id)}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<OpenToAnyButton
					type="button"
					$boldFontWeight
					$noneTextTransform
					$primaryText
					fullWidth
					variant="text"
					color="primary"
					onClick={clear}
					isOpenToAny={R.isEmpty(selectedItems)}
					data-qa={automationNameClearPreference}
				>
					Open to any size
				</OpenToAnyButton>
			</Grid>
		</Grid>
	);
};

export default SizeFormComponent;
