import * as R from 'ramda';
import { useMemo, useEffect, useState } from 'react';
import request, {
	JsonAcceptHeader,
	JsonContentTypeHeader,
	RequestMethod,
	UrlParameter,
	WebResponse,
} from '@cappex/request';
import getEndpoint, { FormKeyedData } from '@util/request';
import useFailedAuth from '../auth/hooks/useFailedAuth';
import { Cbo, CboDataOptions } from '../cbo/constants';

const EMPTY_CBO_ARRAY: Cbo[] = [];
type MappedCboData = {
	[id: string]: Cbo;
};
type cboWebResponse = WebResponse<FormKeyedData, MappedCboData>;

export const createGetCloudCboByIds = (
	cboIds: string[],
	dataOptions: CboDataOptions[],
	setCbos: (cbos: Cbo[]) => void,
	failAuth: () => void
) => () => {
	if (!R.isEmpty(cboIds) && !R.isEmpty(dataOptions)) {
		const convertedOptions = dataOptions.map(option => {
			return {
				name: 'dataOptions',
				value: option,
			};
		});

		const cboIdsParam = cboIds.map(id => {
			return {
				name: 'id',
				value: id,
			};
		});

		const params: UrlParameter[] = R.concat([...convertedOptions], [...cboIdsParam]);

		request<cboWebResponse>({
			url: getEndpoint(`/cbo/v1/getCboByIds`, params),
			method: RequestMethod.GET,
			headers: [JsonAcceptHeader, JsonContentTypeHeader],
			withCredentials: true,
		})
			.then(res => {
				const { data } = res;
				if (data.meta.success && !R.isNil(data.response)) {
					const cbos = R.values(data.response).map(cboData => {
						return {
							...cboData,
						};
					});

					setCbos(cbos);
				}
			})
			.catch(err => {
				if (err.response && (err.response.statusCode === 401 || err.response.statusCode === 403)) {
					failAuth();
				}
			});
	}
};

const useCloudCboData = ({
	cboIds,
	dataOptions,
}: {
	cboIds: string[];
	dataOptions: CboDataOptions[];
}): [Cbo[]] => {
	const [cbos, setCbos] = useState(EMPTY_CBO_ARRAY);
	const failAuth = useFailedAuth();

	const getCbos = useMemo(() => createGetCloudCboByIds(cboIds, dataOptions, setCbos, failAuth), [
		cboIds,
		dataOptions,
		setCbos,
		failAuth,
	]);

	useEffect(() => {
		getCbos();
	}, [getCbos]);

	return [cbos];
};

export default useCloudCboData;
