import { Criteria } from '@common/util/validation/constants';

const isValidLength = (str: string, maxLength: number) => !str || str.length <= maxLength;

const inputLengthCriteria = (maxLength: number): Criteria => {
	return (input: string) => {
		return isValidLength(input, maxLength) ? '' : `Must be less than ${maxLength} characters`;
	};
};

export default inputLengthCriteria;
