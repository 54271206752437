import React, { FC, ChangeEvent, useMemo, useRef, useCallback } from 'react';
import * as R from 'ramda';
import {
	Typography,
	FormControl,
	FormHelperText,
	Grid,
	OutlinedInput,
	InputLabel,
} from '@material-ui/core';
import useCloudReferenceData from '@common/util/hooks/useCloudReferenceData';
import { AutomationNameDefault } from '@common/util/automation';
import useFormValidation from '@util/hooks/useFormValidation';
import requiredFieldMessage from '@util/validation/constants';
import { FormFields } from '@util/validation/form';
import OutlinedSelect from './OutlinedSelect';
import { FormNames, ReferenceDataTypes } from '@cappex/constants';
import { StepComponentProps } from '../util/steps';

interface StudentRelationshipProps extends Partial<StepComponentProps> {
	label: string;
	name?: string;
	defaultValue?: string;
	required?: boolean;
	automationName?: string;
}

export const createValidation = (name: string, required: boolean) => (input: FormFields) => {
	const inputValue = input[name];

	return required && !(inputValue && parseInt(inputValue, 10) >= 0) ? requiredFieldMessage : '';
};

const StudentRelationshipInput: FC<StudentRelationshipProps> = ({
	label,
	name = FormNames.parentStudentRelationship,
	complete = () => {},
	defaultValue = '',
	required = false,
	automationName = AutomationNameDefault.relationshipInput,
}) => {
	const getDefaultRelationship = useCallback(() => defaultValue || '', [defaultValue]);

	const [relationships] = useCloudReferenceData({
		dataType: ReferenceDataTypes.parentStudentRelationship,
		getDefault: getDefaultRelationship,
		emptyAllowed: true,
	});

	const validate = useMemo(() => createValidation(name, required), [name, required]);

	const initialValue = useMemo(() => ({ [name]: defaultValue }), [name, defaultValue]);

	const controlRef = useRef(null);

	const { value, setValue, error, setError } = useFormValidation({
		name,
		initialValue,
		validator: validate,
		fieldRef: controlRef,
	});
	const relationship = value[name];

	const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const newValue = {
			[FormNames.parentStudentRelationship]: event.target.value,
		};
		const validationMessage = validate(newValue);

		setValue(newValue);
		setError([validationMessage]);
		complete();
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12}>
				<Typography variant="h6">{label}</Typography>
			</Grid>
			<Grid item xs={12}>
				<FormControl margin="normal" fullWidth variant="outlined" error={!!error} ref={controlRef}>
					<InputLabel htmlFor="parent-student-relationship-select">Select Relationship</InputLabel>
					<OutlinedSelect
						native
						input={
							<OutlinedInput
								labelWidth={135.797}
								name={FormNames.parentStudentRelationship}
								id="parent-student-relationship-select"
								inputProps={{ 'data-qa': automationName }}
							/>
						}
						onChange={onChange}
						value={relationship}
					>
						{R.map(
							m => (
								<option key={m.id} value={m.id}>
									{m.value}
								</option>
							),
							relationships
						)}
					</OutlinedSelect>
					<FormHelperText>{error}</FormHelperText>
				</FormControl>
			</Grid>
		</Grid>
	);
};

export default StudentRelationshipInput;
