import React, { useMemo, FC } from 'react';
import { ConsentType, getConsentText } from '@src/features/consent/consentUtil';
import { StepComponentProps } from '@src/common/util/steps';
import { CompleteCollege } from '@src/common/util/college/collegeDataUtil';
import GenericConsentModal from './GenericConsentModal';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { DialogContentText } from '@material-ui/core';

type GDPRModalProps = Omit<StepComponentProps, 'reverse'> & {
	collegesToDisplayConsent: CompleteCollege[];
	whiteBackdrop?: boolean;
	onClose?: () => void;
};

const GDPRConsentModal: FC<GDPRModalProps> = ({
	complete,
	collegesToDisplayConsent,
	whiteBackdrop = false,
	onClose,
}) => {
	const consentText = useMemo(() => getConsentText(collegesToDisplayConsent), [
		collegesToDisplayConsent,
	]);
	return (
		<GenericConsentModal
			complete={complete}
			collegesToDisplayConsent={collegesToDisplayConsent}
			consentType={ConsentType.GDPR}
			whiteBackdrop={whiteBackdrop}
			onClose={onClose}
		>
			<DialogContentText
				paragraph
				data-qa={`${AutomationNameGeneric.text}`}
				align="center"
				color="textSecondary"
				variant="body1"
			>
				{consentText}
			</DialogContentText>
		</GenericConsentModal>
	);
};

export default GDPRConsentModal;
