import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { OfferCardBaseTextProps } from '@src/features/offer/components/OfferCardParagraph';
import styled from '@cappex/theme/lib/themed-styled-components';

const StyledCaption = styled(Typography)`
	a {
		color: ${props => props.theme.palette.ink.dark};
		font-weight: bold;
		text-decoration: none;
	}
`;

const OfferCardDisclaimer: FC<OfferCardBaseTextProps> = ({ text, automationName }) =>
	text && (
		<StyledCaption
			data-qa={automationName}
			variant="caption"
			dangerouslySetInnerHTML={{ __html: text }} // this is needed because there are links etc created by admins
		/>
	);

export default OfferCardDisclaimer;
