import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

let previousDataLayerValue;

const BirthYearDataLayer = (eventType: DataLayerEventTypes, birthYear: string) => {
	if (previousDataLayerValue !== birthYear) {
		previousDataLayerValue = birthYear;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: Number(birthYear) },
		});
	}
};

export default BirthYearDataLayer;
