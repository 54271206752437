import * as R from 'ramda';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import { CompleteCollegeOptions } from '@cappex/constants';
import { ColorDefinition, ExtendedTheme, styled, ThemeContext } from '@cappex/theme';
import confetti from '@common/assets/confetti.svg';
import noDataIllustration from '@common/assets/noDataIllustration.svg';
import {
	Button,
	Card,
	CardMedia,
	Dialog,
	Divider,
	Grid,
	GridProps,
	makeStyles,
	Paper,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { FloatingCloseButton } from '@cappex/components';
import ChanceChart from '@src/common/components/chart/ChanceChart';
import collegeRecommendationContext from '@src/common/util/collegerecommendation';
import CollegeRecomendationProvider from '@src/common/util/collegerecommendation/components/CollegeRecommendationProvider';
import useCloudCollegeData from '@src/common/util/hooks/useCloudCollegeData';
import useStudentCollegeWamcScore from '@src/common/util/hooks/useStudentCollegeWamc';
import { StepComponentProps } from '@src/common/util/steps';
import { StudentCollegeListLocationTrackingValue } from '@src/common/util/studentcollege/constants';
import { WamcIneligibleReason, WamcResults } from '@src/common/util/wamc/constants';
import { getWamcGroupConfigFromScore } from '@src/common/util/wamc/wamcConfigUtil';
import StudentCollegeListCarousel from '@src/features/collegelist/components/StudentCollegeListCarousel';

type ChancesModalProps = {
	name: string;
	id: number;
};

// TODO: Replace this with just using subtitle1 when we do Typography rescale
const SpecialTypography = styled(Typography)`
	font-size: 1.125rem;
	line-height: 1.375rem;
`;

const LogoStyled = styled(Card)`
	margin: auto;
	height: 3.5rem;
	width: 3.5rem;
	border: 4px solid ${props => props.theme.palette.common.white};
	box-shadow: ${props => props.theme.shadows[2]};
`;

const FullWidthGrid = styled(Grid)`
	width: 100%;
	flex-basis: auto;
`;

const SpacedDivider = styled(Divider)`
	margin: -1rem 0 0.4rem 0;
`;

const ColoredSpan = styled<FC<{ color: ColorDefinition }>>(({ color, ...rest }) => (
	<span {...rest} />
))`
	color: ${({ theme, color: { color, key } }) => theme.palette[color][key]};
`;

const NegativeMarginTopDiv = styled.div`
	margin-top: -1rem;
	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		max-width: 19.5rem;
		margin-left: auto;
		margin-right: auto;
	}
`;

const RecommendationsGrid = styled(Grid)`
	max-width: 90%;
	margin: auto;
	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		margin: 0;
		max-width: 100%;
	}
`;
const RecommendationsTypography = styled(Typography)`
	margin-left: 1rem;
	margin-right: 1rem;
`;

const DialogPaper = styled(Paper)`
	width: calc(100% - 1rem);
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		width: 100%;
		padding-bottom: 3rem;
	}
`;

const EMPTY_FUNCTION = () => {};

const MarginGrid = styled(
	({ vertical, maxWidth, ...rest }: GridProps & { vertical: number; maxWidth: number }) => (
		<Grid {...rest} />
	)
)`
	margin: ${({ vertical }) => vertical}rem auto;
	max-width: ${({ maxWidth }) => maxWidth}rem;
	width: 100%;

	@media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
		max-width: 100%;
	}
`;

type NoDataProps = {
	name: string;
	complete: () => void;
};
const NoDataDisplay: FC<NoDataProps> = ({ name, complete }) => (
	<MarginGrid
		vertical={7}
		maxWidth={19}
		container
		direction="column"
		spacing={5}
		justifyContent="center"
		alignItems="center"
	>
		<Grid item>
			<img src={noDataIllustration} alt="not enough data" />
		</Grid>
		<Grid item>
			<Typography variant="subtitle1" align="center">
				It looks like {name} doesn&apos;t report enough data to calculate your chances...
			</Typography>
			<br />
			<Typography variant="subtitle1" align="center">
				On the bright side, we can calculate your chances for plenty of other schools!
			</Typography>
		</Grid>
		<FullWidthGrid item>
			<Button color="primary" variant="contained" fullWidth onClick={() => complete()}>
				Get Started
			</Button>
		</FullWidthGrid>
	</MarginGrid>
);

type StandardDisplayProps = {
	name: string;
	wamc: WamcResults;
	logo: string;
};

const StandardDisplay: FC<StandardDisplayProps> = ({ name, wamc, logo }) => {
	const { color, profileText, backgroundColor } = getWamcGroupConfigFromScore(wamc.wamcScore);
	const theme = useContext(ThemeContext);
	const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));
	const {
		recommendedColleges,
		hadRecommendations,
		refreshCollegeRecommendations,
		dismissRecommendation,
	} = useContext(collegeRecommendationContext);

	return (
		<>
			<MarginGrid
				vertical={isSmallBreakpoint ? 1 : 1.5}
				maxWidth={19}
				container
				direction="column"
				spacing={isSmallBreakpoint ? 1 : 2}
				justifyContent="center"
				alignItems="center"
			>
				<Grid item xs>
					<img src={confetti} alt="" />
				</Grid>
				<Grid item xs>
					<Typography variant="h5" align="center">
						You&apos;re all set!
					</Typography>
				</Grid>
				<Grid item xs>
					<SpecialTypography variant="body1" align="center" gutterBottom>
						We&apos;ve calculated your chances for {name}.
					</SpecialTypography>
				</Grid>
				{logo && (
					<Grid item xs>
						<LogoStyled>
							<CardMedia component="img" src={logo} />
						</LogoStyled>
					</Grid>
				)}
				<Grid item xs>
					<Typography variant="h5" align="center">
						<ColoredSpan color={color}>{wamc.wamcChanceGroupDescription}</ColoredSpan> School
					</Typography>
				</Grid>
				<Grid item xs>
					<Typography variant="body2" align="center">
						{profileText()}
					</Typography>
					<NegativeMarginTopDiv>
						<ChanceChart
							wamcScore={wamc.wamcScore}
							barColor={color}
							barBackgroundColor={backgroundColor}
							labelText=""
							labelOffsetX={0}
							hideLabel
							barSize={8}
						/>
					</NegativeMarginTopDiv>
				</Grid>
			</MarginGrid>
			<SpacedDivider />
			<RecommendationsGrid container spacing={2} direction="column">
				<Grid item xs>
					<RecommendationsTypography variant="h6">Our Picks for You</RecommendationsTypography>
					<RecommendationsTypography variant="body2">
						Add schools to your list and see your chances.
					</RecommendationsTypography>
				</Grid>
				<Grid item xs>
					<StudentCollegeListCarousel
						recommendedColleges={recommendedColleges}
						hadRecommendations={hadRecommendations}
						refreshCollegeRecommendations={refreshCollegeRecommendations}
						dismissRecommendation={dismissRecommendation}
						locationTrackingvalue={StudentCollegeListLocationTrackingValue.REGISTRATION_PATH}
					/>
				</Grid>
			</RecommendationsGrid>
		</>
	);
};

const useStyles = makeStyles({ root: { width: '100vw', height: '100vh' } });

const ChancesModal: FC<StepComponentProps & ChancesModalProps> = ({ complete, name, id }) => {
	const [wamc] = useStudentCollegeWamcScore(id, EMPTY_FUNCTION);
	const theme = useContext<ExtendedTheme>(ThemeContext);
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
	const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));
	const [collegeIds, dataOptions] = useMemo(() => [[id], [CompleteCollegeOptions.COLLEGE_ASSET]], [
		id,
	]);
	const [college] = useCloudCollegeData({
		collegeIds,
		dataOptions,
		getCollege: R.head,
	});
	const classes = useStyles();

	const logo = college.collegeAssets && college.collegeAssets.COLLEGE_LOGO.externalFilePath;

	useEffect(() => {
		// Don't want to let someone
		if (wamc === undefined) {
			const handle = setTimeout(complete, 10000);
			return clearTimeout(handle);
		}
		return () => {};
	}, [wamc, complete]);

	return (
		<CollegeRecomendationProvider>
			{wamc ? (
				<Dialog
					open={!!wamc}
					fullWidth
					maxWidth="md"
					fullScreen={isSmDown}
					onClose={() => complete()}
					scroll={isMdDown ? 'paper' : 'body'}
					classes={{ root: classes.root }}
					PaperComponent={DialogPaper}
				>
					{wamc.wamcIneligibleReason === WamcIneligibleReason.INSUFFICIENT_COLLEGE_INFORMATION ? (
						<NoDataDisplay name={name} complete={complete} />
					) : (
						<StandardDisplay name={name} logo={logo} wamc={wamc} />
					)}
					<FloatingCloseButton onClick={() => complete()} />
				</Dialog>
			) : null}
		</CollegeRecomendationProvider>
	);
};

export default ChancesModal;
