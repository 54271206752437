import { Grid } from '@material-ui/core';
import React from 'react';
import withStyleOptions from '@util/style/styleOptions';
import Link, { LinkProps } from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import Hidden from '@material-ui/core/Hidden';
import { AllTransformStyles } from '@src/common/util/style/baseStyleOptions';

const DONT_HAVE_ACCOUNT_TEXT = "Don't have an account yet?";
const FORGOT_PASSWORD_TEXT = 'Forgot password?';

const ForgotPasswordLink = withStyleOptions(
	React.forwardRef<HTMLAnchorElement, LinkProps & AllTransformStyles>((props, ref) => (
		<Link component={RouterLink} to="/forgot-password" innerRef={ref} {...props} />
	))
);
const DontHaveAccountLink = withStyleOptions(
	React.forwardRef<HTMLAnchorElement, LinkProps & AllTransformStyles>((props, ref) => (
		<Link component={RouterLink} to="/register/core/main1" innerRef={ref} {...props} />
	))
);

const LoginLinks = () => (
	<Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
		<Hidden mdUp>
			<Grid item xs={12} />
		</Hidden>
		<Grid item xs={12}>
			<ForgotPasswordLink color="textPrimary" variant="body2" data-qa="forgot-password-link">
				{FORGOT_PASSWORD_TEXT}
			</ForgotPasswordLink>
		</Grid>
		<Grid item xs={12}>
			<DontHaveAccountLink color="textPrimary" variant="body2" data-qa="account-registration-link">
				{DONT_HAVE_ACCOUNT_TEXT}
			</DontHaveAccountLink>
		</Grid>
	</Grid>
);

export default LoginLinks;
