import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import EmailInput from '@common/components/EmailInput';
import { OfferBaseFormInputType } from '@src/features/offer/components/inputs/OfferBaseFormInput';

type OfferBaseFormEmailInputProps = Exclude<OfferBaseFormInputType, 'validCriteria'> & {
	noEmailValidation?: boolean;
};

const OfferBaseFormEmailInput: FC<OfferBaseFormEmailInputProps> = ({
	id,
	name,
	automationName,
	label,
	required = false,
	noEmailValidation = false,
}) => (
	<EmailInput
		id={id}
		name={name as FormNames}
		automationName={automationName}
		noEmailValidation={noEmailValidation}
		label={label}
		required={required}
		fullWidth
	/>
);

export default OfferBaseFormEmailInput;
