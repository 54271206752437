import React, { FC } from 'react';
import BaseFormInput, { BaseInputProps } from './BaseFormInput';
import { FormNames } from '@cappex/constants';
import { AutomationNameDefault } from '../util/automation';

const PasswordInput: FC<Partial<BaseInputProps>> = ({
	id = 'password',
	name = FormNames.password,
	label = 'Password',
	inputType = 'password',
	disabled = false,
	onChange,
	onBlur,
	validCriteria,
	required,
	verifiedBy,
	verifies,
	fullWidth = false,
	helperText = ' ',
	LeadingIcon,
	TrailingIcon,
	automationName = AutomationNameDefault.passwordInput,
	variant,
}) => (
	<BaseFormInput
		id={id}
		name={name}
		label={label}
		inputType={inputType}
		disabled={disabled}
		onChange={onChange}
		onBlur={onBlur}
		validCriteria={validCriteria}
		required={required}
		verifiedBy={verifiedBy}
		verifies={verifies}
		fullWidth={fullWidth}
		helperText={helperText}
		LeadingIcon={LeadingIcon}
		TrailingIcon={TrailingIcon}
		automationName={automationName}
		variant={variant}
	/>
);

export default PasswordInput;
