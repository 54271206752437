import * as R from 'ramda';
import React, { AllHTMLAttributes, FC, useContext, useEffect, useMemo, useState } from 'react';
import { ExtendedTheme, styled, ThemeContext } from '@cappex/theme';
import { Tooltip } from '@cappex/components';
import { AutomationNameDefault, AutomationNameGeneric } from '@common/util/automation';
import { IconDefinition, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronUp } from '@fortawesome/pro-light-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { CardProps } from '@material-ui/core/Card';
import { GridProps } from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import BlurredContent from '@src/common/components/BlurredContent';
import TextGradient from '@src/common/components/TextGradient';
import withStyleOptions from '@src/common/util/style/styleOptions';
import { ReferenceData } from '@util/hooks/useCloudReferenceData';
import PreferenceChip from './PreferenceChip';
import { AllTransformStyles } from '@src/common/util/style/baseStyleOptions';

export interface PreferencesCardProps {
	title: string;
	icon: IconDefinition;
	selectedIcon: IconDefinition;
	instructions: string;
	subText?: string;
	toolTip?: string[];
	openToAnyText: string;
	selectedItems: string[];
	referenceData?: ReferenceData[];
	useGradient?: boolean;
	useCustomCard?: boolean;
	useSelectedItemText?: boolean;
	compact?: boolean;
	maxSelectedItems?: number;
	setSelectedItems?: (preference: string[]) => void;
	clearSelectedItems: () => void;
	automationNameId: string;
}

type CompactableComponent = {
	compact: boolean;
};

type ExpandableComponent = {
	isExpanded: boolean;
};

interface CardBaseProps extends CardProps, CompactableComponent, ExpandableComponent {
	showSelectedOutline: boolean;
}

export const CardBase = styled(
	({ showSelectedOutline, compact, isExpanded, ...rest }: CardBaseProps) => <Card {...rest} />
)`
	width: 100%;
	min-height: ${props => (props.compact ? `auto` : `7.5rem`)};
	border: ${props =>
		props.showSelectedOutline && !props.compact
			? `1px solid ${props.theme.palette.ink.main}`
			: `1px solid transparent`};
	overflow: visible;
	border-radius: ${props => (props.compact ? `0rem` : `0.5rem`)};
	margin: ${props => (props.compact && props.isExpanded ? `1rem 0` : `0`)};
	transition: box-shadow 0.15s;
	&:hover {
		box-shadow: ${({ theme: { shadows } }) => shadows[8]};
	}
`;

const CardHeader = styled(CardActionArea)`
	span {
		background-color: transparent;
	}
`;

type CardHeaderBodyProps = CompactableComponent & ExpandableComponent & GridProps;
const CardHeaderBody = styled(({ isExpanded, compact, ...rest }: CardHeaderBodyProps) => (
	<Grid {...rest} />
))`
	min-height: ${props => (props.compact || props.isExpanded ? `auto` : `7.5rem`)};
	padding: 0.25rem 0.5rem;
	margin: 0;
	width: 100%;
`;

type CardIconProps = GridProps & CompactableComponent;
const CardIcon = styled(({ compact, ...rest }: CardIconProps) => <Grid {...rest} />)`
	font-size: 1rem;
	width: ${props => (props.compact ? `2.5rem` : `5rem`)};
`;

export const TitleSubText = styled(Grid)`
	margin: 0;
`;

type CardChevronProps = CompactableComponent & GridProps;
const CardChevron = styled(({ compact, ...rest }: CardChevronProps) => <Grid {...rest} />)`
	position: absolute;
	top: ${props => (props.compact ? `0.625rem` : `1.5rem`)};
	right: ${props => (props.compact ? `0.625rem` : `1.5rem`)};
	font-size: 1.125rem;
`;

const StyledButton = withStyleOptions(Button);

type OpenToAnyProps = ButtonProps &
	AllTransformStyles &
	AllHTMLAttributes<any> & {
		isOpenToAny: boolean;
	};
export const OpenToAnyButton = styled(({ isOpenToAny, as, ...rest }: OpenToAnyProps) => (
	<StyledButton {...rest} />
))`
	background-color: ${props =>
		props.isOpenToAny ? props.theme.palette.primary.transparent : props.theme.palette.common.white};
	margin-bottom: 1rem;

	@media (hover: none) {
		&&:hover {
			background-color: ${props =>
				props.isOpenToAny
					? props.theme.palette.primary.transparent
					: props.theme.palette.common.white};
		}
	}
`;

export const DoneButton = styled(StyledButton)`
	margin-top: 1rem;
`;

const CollegePreferencesCard: FC<PreferencesCardProps> = ({
	children,
	title,
	icon,
	selectedIcon,
	instructions,
	subText,
	toolTip,
	openToAnyText,
	selectedItems,
	clearSelectedItems,
	referenceData = [],
	useGradient = false,
	useCustomCard = false,
	useSelectedItemText = false,
	compact = false,
	maxSelectedItems = 1,
	setSelectedItems,
	automationNameId,
}) => {
	const theme = useContext<ExtendedTheme>(ThemeContext);
	const [isExpanded, setIsExpanded] = useState(false);
	const [showOutline, setShowOutline] = useState(false);

	useEffect(() => {
		if (!isExpanded && !R.isNil(selectedItems)) {
			setShowOutline(!R.isEmpty(selectedItems));
		}
	}, [isExpanded, selectedItems]);

	const displaySkeleton = selectedItems === undefined;

	const preferenceMap = useMemo(
		() =>
			referenceData.reduce((acc, preference) => {
				acc[preference.id] = preference.value;
				return acc;
			}, {}),
		[referenceData]
	);

	const automationNameTitle = `${automationNameId}-${AutomationNameDefault.preferenceCardTitle}`;
	const automationNameSubtext = `${automationNameId}-${AutomationNameDefault.preferenceCardSubtext}`;
	const automationNameToggle = `${automationNameId}-${AutomationNameGeneric.toggleButton}`;
	const automationNameClearPreference = `${automationNameId}-${AutomationNameDefault.clearPreferencesButton}`;
	const automationNameDone = `${automationNameId}-${AutomationNameGeneric.doneButton}`;
	const automationNamePreferenceChipButton = `${automationNameId}-${AutomationNameGeneric.chipButton}`;

	const onCardToggle = () => {
		// Don't allow card interaction while loading
		if (displaySkeleton) {
			return;
		}

		setIsExpanded(!isExpanded);
	};

	const getColor = () =>
		showOutline ? theme.palette.secondary.main : theme.palette.text.secondary;

	const getIcon = (lightIcon, solidIcon) => (showOutline ? solidIcon : lightIcon);

	const getIconSize = (): SizeProp => (compact ? undefined : '2x');

	const getChevronOrientation = (): undefined | 180 => (isExpanded ? undefined : 180);

	const getListText = () =>
		selectedItems.map(id => (
			<Grid item key={id}>
				<Typography variant="body1" color="textSecondary">
					{preferenceMap[id]}
				</Typography>
			</Grid>
		));

	const getGradientText = () => {
		const selectedItemsToDisplay = useSelectedItemText
			? selectedItems
			: selectedItems.map(id => preferenceMap[id]);

		const text = selectedItemsToDisplay.join(', ');

		return <TextGradient variant="body1" text={text} lineNumbers={2} textColor="textSecondary" />;
	};

	const getSubText = () => {
		if (selectedItems == null || R.isEmpty(selectedItems)) {
			return (
				<Typography variant="body1" color="textSecondary">
					{displaySkeleton ? <Skeleton /> : openToAnyText}
				</Typography>
			);
		}

		return useGradient ? getGradientText() : getListText();
	};

	const handleSingleSelect = (id: string) => {
		if (id !== selectedItems[0]) {
			setSelectedItems([id]);
		}
	};

	const handleMultipleSelect = (id: string) => {
		const updatedState = [...selectedItems];
		let hasUpdated = false;

		const idIndex = selectedItems && selectedItems.findIndex(itemId => itemId === id);
		if (idIndex >= 0) {
			updatedState.splice(idIndex, 1);
			hasUpdated = true;
		} else if (selectedItems.length < maxSelectedItems) {
			updatedState.push(id);
			hasUpdated = true;
		}

		if (hasUpdated) {
			setSelectedItems(updatedState);
		}
	};

	const onChipClick = (id: string) => {
		if (maxSelectedItems > 1) {
			handleMultipleSelect(id);
		} else {
			handleSingleSelect(id);
		}
	};

	return (
		<CardBase
			elevation={4}
			showSelectedOutline={showOutline}
			compact={compact}
			isExpanded={isExpanded}
			data-qa={`${automationNameId}-${AutomationNameGeneric.wrapper}`}
		>
			<CardHeader disableRipple onClick={onCardToggle} data-qa={automationNameToggle}>
				<CardHeaderBody
					isExpanded={isExpanded}
					compact={compact}
					container
					wrap="nowrap"
					spacing={compact ? 1 : 3}
					alignItems="flex-start"
				>
					<CardIcon item compact={compact}>
						<BlurredContent blur={displaySkeleton}>
							<FontAwesomeIcon
								size={getIconSize()}
								icon={getIcon(icon, selectedIcon)}
								color={getColor()}
								fixedWidth
							/>
						</BlurredContent>
					</CardIcon>
					<Grid item xs={12}>
						<Typography variant={compact ? 'body1' : 'h6'} data-qa={automationNameTitle}>
							{title}
						</Typography>
						{!(isExpanded || compact) && (
							<TitleSubText container direction="column" data-qa={automationNameSubtext}>
								{getSubText()}
							</TitleSubText>
						)}
					</Grid>
					<CardChevron compact={compact}>
						<FontAwesomeIcon
							icon={faChevronUp}
							rotation={getChevronOrientation()}
							color={theme.palette.text.secondary}
						/>
					</CardChevron>
				</CardHeaderBody>
			</CardHeader>
			<Collapse in={isExpanded}>
				<CardContent>
					<Grid container direction="column" wrap="nowrap" spacing={2}>
						<Grid item container spacing={1} direction="column">
							<Grid item>
								<Typography variant="body1">
									{instructions}
									&nbsp;
									{toolTip && <Tooltip text={toolTip} />}
								</Typography>
							</Grid>
							{subText && (
								<Grid item>
									<Typography variant="body2" color="textSecondary">
										{subText}
									</Typography>
								</Grid>
							)}
						</Grid>
						{useCustomCard && <Grid item>{children}</Grid>}
						{!useCustomCard && (
							<Grid item container spacing={2}>
								{referenceData.map(preference => (
									<Grid item xs={12} key={preference.id}>
										<PreferenceChip
											clickable
											onClick={() => onChipClick(preference.id)}
											label={preference.value}
											data-qa={automationNamePreferenceChipButton}
											isSelected={selectedItems && selectedItems.includes(preference.id)}
										/>
									</Grid>
								))}
							</Grid>
						)}
						<Grid item>
							<OpenToAnyButton
								type="button"
								$noneTextTransform
								fullWidth
								variant="text"
								color="default"
								onClick={clearSelectedItems}
								isOpenToAny={R.isEmpty(selectedItems)}
								data-qa={automationNameClearPreference}
							>
								{openToAnyText}
							</OpenToAnyButton>
						</Grid>
						{!compact && (
							<>
								<Divider />
								<Grid item xs={12}>
									<DoneButton
										type="button"
										$boldFontWeight
										$noneTextTransform
										fullWidth
										variant="contained"
										color="primary"
										onClick={onCardToggle}
										data-qa={automationNameDone}
									>
										Done
									</DoneButton>
								</Grid>
							</>
						)}
					</Grid>
				</CardContent>
			</Collapse>
		</CardBase>
	);
};

export default CollegePreferencesCard;
