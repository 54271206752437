import { includedInStepCount, name } from '@src/common/util/steps/constants';
import illustration from '@common/assets/AlrScholarship.svg';
import { DataFlowConfig } from '../../dataflow/constants/types';
import { DataFlowContainerVariant, DataFlowStepName } from '@cappex/constants';

const alrScholarshipConfig: DataFlowConfig = {
	canStartMidway: false,
	results: {
		default: {
			image: '',
			header: 'Your scholarships await!',
			body:
				'Our up-to-date scholarship database is one of the nation’s largest. Find money to help you fund your education.',
			weight: 1,
		},
	},
	steps: [
		{
			key: DataFlowStepName.AlrScholarshipInfoName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: true,
				[name]: 'personal-info',
				topMedia: {
					header: 'You’re eligible for college scholarships.',
					text:
						'Completing your degree could be a huge step toward reaching your goals. Find scholarships to help you pay for it. Fill out this brief form to get started.',
					media: illustration,
				},
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.AlrScholarshipGpaName,
			sortOrder: 1,
			data: {
				[includedInStepCount]: true,
				[name]: 'gpa',
				topMedia: {
					header: 'What’s your college GPA?',
				},
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.AlrScholarshipTimelineName,
			sortOrder: 2,
			data: {
				[includedInStepCount]: true,
				[name]: 'timeline',
				topMedia: {
					header: 'Interested in going back to school? Let us know how and when.',
				},
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.AlrScholarshipDegreeName,
			sortOrder: 3,
			data: {
				[includedInStepCount]: true,
				[name]: 'degree',
				topMedia: {
					header: "Let's get an idea about how and when you'd like to attend school.",
				},
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.CreateAccountName,
			sortOrder: 4,
			data: {
				[includedInStepCount]: true,
				[name]: 'create',
				topMedia: {
					header: 'Last step! Complete your profile to see scholarships.',
				},
				variant: DataFlowContainerVariant.CENTERED,
				inputVariant: 'outlined',
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.Finalize,
			sortOrder: 5,
			data: {
				[includedInStepCount]: false,
				[name]: 'finalize',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Consent,
			sortOrder: 6,
			data: {
				[includedInStepCount]: false,
				[name]: 'consent',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.QuizResult,
			sortOrder: 7,
			data: {
				includedInStepCount: false,
				[name]: 'modal',
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
				pseudo: true,
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 8,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				pseudo: true,
				// @ts-ignore
				to: { pathname: '/scholarship-search' },
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
	],
};

export default alrScholarshipConfig;
