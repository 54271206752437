import * as R from 'ramda';

export const capitalizeWords = (name: string): string => {
	if (R.isNil(name)) {
		return '';
	}

	return name
		.split(/\s+/)
		.map(n => n.charAt(0).toUpperCase() + n.slice(1))
		.join(' ');
};

export const toFixedDecimal = (decimal = 2) => (value: string) => {
	if (R.isNil(value)) {
		return '';
	}

	const parts = value.split('.');

	if (R.isNil(parts[1])) {
		return value;
	}

	const formattedDecimal = parts[1].length <= decimal ? parts[1] : parts[1].slice(0, decimal);

	return `${parts[0]}.${formattedDecimal}`;
};

export const toWholeNumber = (value: string) => {
	// Using split instead of replace to handle copying decimal values (3.14 -> 3)
	return R.isNil(value) ? '' : value.split('.')[0];
};
