import React, { FC, useEffect } from 'react'
import OfferCardModalContent from '@src/features/offer/components/OfferCardModalContent'

const OfferCardModalIframe: FC = () => {
    const dataLookup = new URLSearchParams(window.location.search).get('uuid') // Pull from the URL
    const {
        campaignOfferId,
        onOfferAccepted,
        onModalDismiss,
        modalCampaignOfferContent,
        placement,
    } = window.parent.iframeData.PAOfferData[dataLookup];

    useEffect(() => {
        window.parent.postMessage(true, "*")
    })

    return (
        <OfferCardModalContent
            campaignOfferId={campaignOfferId}
            modalCampaignOfferContent={modalCampaignOfferContent}
            onOfferAccepted={onOfferAccepted}
            onModalDismiss={onModalDismiss}
            placement={placement}
        />
    )
}

export default OfferCardModalIframe