import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { create } from 'jss';
import {
	createGenerateClassName,
	jssPreset,
	MuiThemeProvider,
	StylesProvider,
} from '@material-ui/core/styles';
import AuthProvider from '@src/common/util/auth/components/AuthProvider';
import { snowplowTrackingUrl, cookieDomain } from '@src/features/environment';
import ScrollToTop from '@common/components/ScrollToTop';
import { Provider as ThemeProvider, theme } from '@cappex/theme';
import App from './components/App';
import AlgoliaInsightsProvider from '@src/common/components/search/AlgoliaInsightsProvider';
import enableNewRelic from './util/newRelic';
import { newTracker } from '@snowplow/browser-tracker';
import isInternalDomain from './util/isInternalDomain';
import GlobalCss from './components/GlobalCss';

const jss = create({
	...jssPreset(),
	insertionPoint: document.getElementById('jss-insertion-point'),
});
const generateClassName = createGenerateClassName();

enableNewRelic();

newTracker('sp', snowplowTrackingUrl, {
	appId: 'cappex',
	platform: 'web',
	cookieDomain,
	respectDoNotTrack: true,
	contexts: {
		webPage: true,
		session: true,
		// @ts-ignore
		gaCookies: true,
	},

	crossDomainLinker: linkElement => isInternalDomain(linkElement.hostname),
});

const WrappedApp = () => (
	<StylesProvider jss={jss} generateClassName={generateClassName}>
		<ThemeProvider>
			<MuiThemeProvider theme={theme}>
				<GlobalCss />
				<BrowserRouter>
					<AlgoliaInsightsProvider>
						<AuthProvider>
							<ScrollToTop />
							<App />
						</AuthProvider>
					</AlgoliaInsightsProvider>
				</BrowserRouter>
			</MuiThemeProvider>
		</ThemeProvider>
	</StylesProvider>
);

export default WrappedApp;
