import React, { FC, useContext, useMemo, useState } from 'react';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';
import mapNameToGridComponent from '../../util/flexComponentMapping';
import RegisterButton from '@src/common/components/RegisterButton';
import {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	RequestSourceIdentifier,
} from '@cappex/request';
import useAuthentication from '@src/common/util/auth/hooks/useAuthentication';
import DataFlowContext from '../../util/DataFlowContext';
import { FormContext } from '@src/common/util/validation/form';
import checkLockout from '@src/common/util/lockout';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import DataFlowContainer from '../DataFlowContainer';
import { AuthenticState } from '@src/common/util/auth';
import { Box, Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { styled } from '@cappex/theme';
import AnchorButton from '@src/common/components/AnchorButton';
import * as R from 'ramda';
import { QuizContext } from '@src/common/util/quiz';
import redirectToQuizResult, { emailInUseError } from '../../util/redirectToQuizResult';
import { useNavigate } from 'react-router-dom';

library.add(fal);
const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;
const SpacingDiv = styled.div`
	padding-bottom: 2rem;
	text-align: center;
`;
const MarginDiv = styled.div`
	margin-top: 1rem;
`;

const FlexStep: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	active,
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		isAllowedUnauthentic,
		targetWhenUnauthentic,
		flexConfig = [],
		buttonConfig,
		legalTextConfig,
		additionalTextConfig,
		showAlreadyHaveAccountLink,
	},
	customLogoUrl,
	redirectIfAccountExists,
}) => {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const isAuthentic = useAuthentication();
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { setFormErrors, formState } = useContext(FormContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const { getCurrentResult } = useContext(QuizContext);
	const navigate = useNavigate();

	type NestedObject = { [key: string]: NestedObject | string };

	const getMostNestedKeyAndValue = (
		obj: NestedObject,
		currentPath: string[] = []
	): { key: string; value: string } => {
		let currentObject: NestedObject | string = obj;

		while (typeof currentObject === 'object') {
			const keys = Object.keys(currentObject);

			if (keys.length === 1) {
				const key = keys[0];
				currentObject = currentObject[key];
				currentPath.push(key);
			} else {
				// Handle the case where the object has multiple properties
				// At time of impl, there is no need but subject to change in the future.
				break;
			}
		}

		const keyPath = currentPath.join('.');
		return { key: keyPath, value: currentObject as string };
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				const emailAlreadyInUse = errors?.email?.[0] === emailInUseError;
				if (redirectIfAccountExists && emailAlreadyInUse) {
					redirectToQuizResult(getCurrentResult);
					return;
				}

				const { key, value } = getMostNestedKeyAndValue(errors);
				setFormErrors(errors);

				if (checkForFormError(errors)) {
					console.log('Error with data parsing', data.meta);
					openSnackbar({
						message: errors[FORM_NAME],
					});
				} else {
					console.log(`${value} for ${key}`);
					openSnackbar({
						message: `Sorry, something went wrong. Please select a different answer and try again`,
					});
				}
			}
		});
		return true;
	};

	// The flow expects this page to only be hit
	if (
		!isAllowedUnauthentic &&
		isAuthentic !== AuthenticState.Authentic &&
		isAuthentic !== AuthenticState.Unknown &&
		active
	) {
		navigate(targetWhenUnauthentic);
	}

	const renderedComponents = useMemo(
		() =>
			flexConfig.map(({ type, label, required, inputStyle, extra, size, ...props }) =>
				mapNameToGridComponent(type, {
					label,
					required,
					extra,
					size,
					inputStyle: inputStyle || 'outlined', // Default all flex reg to outlined components unless otherwise specified
					...props,
				})
			),
		[flexConfig]
	);

	const renderAdditionalComponents = useMemo(() => {
		if (!R.isNil(additionalTextConfig)) {
			return additionalTextConfig.map(
				({ type, label, required, inputStyle, extra, size, ...props }) =>
					mapNameToGridComponent(type, {
						label,
						required,
						extra,
						size,
						inputStyle: inputStyle || 'outlined',
						...props,
					})
			);
		}
		return [];
	}, [additionalTextConfig]);

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMedia}
			rightMedia={rightMedia}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			customLogoUrl={customLogoUrl}
		>
			<Grid container direction="column" spacing={3}>
				{renderedComponents.map((C, idx) => (
					// Just render the thing
					// eslint-disable-next-line react/no-array-index-key
					<C key={idx} active={active} formState={formState} />
				))}
			</Grid>
			<RegisterButton
				md={12}
				submitDisabled={submitDisabled}
				onClick={onClick}
				size="large"
				smallGutter={!!legalTextConfig}
			>
				{buttonConfig?.text ?? 'Next'}
				{buttonConfig?.icon && (
					<PaddedBox>
						<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
					</PaddedBox>
				)}
			</RegisterButton>
			{(legalTextConfig || showAlreadyHaveAccountLink) && (
				<SpacingDiv>
					{legalTextConfig && <LegalTextAndLinks configs={legalTextConfig} />}
					{showAlreadyHaveAccountLink && (
						<MarginDiv>
							<AnchorButton text="Already have an account?" href="/login" />
						</MarginDiv>
					)}
				</SpacingDiv>
			)}
			<Grid container direction="column" spacing={3}>
				{renderAdditionalComponents.map((C, idx) => (
					// Just render the thing
					// eslint-disable-next-line react/no-array-index-key
					<C key={idx} active={active} formState={formState} />
				))}
			</Grid>
		</DataFlowContainer>
	);
};

export default FlexStep;
