import React, { FC } from 'react';
import OfferBaseFormCheckbox from '@src/features/offer/components/inputs/OfferBaseFormCheckbox';
import { ReferenceData } from '@src/common/util/hooks/useCloudReferenceData';
import * as R from 'ramda';
import { findRequiredValidation } from '../util/offerUtil';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';

const isNotNil = R.complement(R.isNil);

const OfferCardCheckboxInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const contentId = `${content.id}`;
	const required = findRequiredValidation(content.campaignOfferContentValidations);
	const value = (content.campaignOfferContentValue || [])
		.filter(it => isNotNil(it.value)) // Find everything that has a value
		.map(it => ({
			id: it.value,
			value: it.text,
		}))
		.shift();
	// Find the content value without a value
	const label = (content.campaignOfferContentValue || [])
		.filter(it => R.isNil(it.value)) // Remove anything that has a value
		.map(it => it.text)
		.shift();

	return (
		<OfferBaseFormCheckbox
			id={contentId}
			name={contentId}
			required={required}
			automationName={automationName}
			label={label}
			value={value as ReferenceData}
		/>
	);
};

export default OfferCardCheckboxInput;
