import academicBadgeIcon from '@common/assets/incentivization/academics-badge.svg';
import academicBadgeGreyIcon from '@common/assets/incentivization/academics-badge-grey.svg';
import individualistBadgeIcon from '@common/assets/incentivization/individualist-badge.svg';
import individualistBadgeGreyIcon from '@common/assets/incentivization/individualist-badge-grey.svg';
import resumeBadgeIcon from '@common/assets/incentivization/resume-badge.svg';
import resumeBadgeGreyIcon from '@common/assets/incentivization/resume-badge-grey.svg';
import visionaryBadgeIcon from '@common/assets/incentivization/visionary-badge.svg';
import visionaryBadgeGreyIcon from '@common/assets/incentivization/visionary-badge-grey.svg';
import explorerBadgeIcon from '@common/assets/incentivization/explorer-badge.svg';
import explorerBadgeGreyIcon from '@common/assets/incentivization/explorer-badge-grey.svg';
import wandererBadgeIcon from '@common/assets/incentivization/wanderer-badge.svg';
import wandererBadgeGreyIcon from '@common/assets/incentivization/wanderer-badge-grey.svg';
import seekerBadgeIcon from '@common/assets/incentivization/seeker-badge.svg';
import seekerBadgeGreyIcon from '@common/assets/incentivization/seeker-badge-grey.svg';
import matchmakerBadgeIcon from '@common/assets/incentivization/matchmaker-badge.svg';
import matchmakerBadgeGreyIcon from '@common/assets/incentivization/matchmaker-badge-grey.svg';
import pioneerBadgeIcon from '@common/assets/incentivization/pioneer-badge.svg';
import pioneerBadgeGreyIcon from '@common/assets/incentivization/pioneer-badge-grey.svg';
import foundationBadgeIcon from '@common/assets/incentivization/foundation-badge.svg';
import foundationBadgeGreyIcon from '@common/assets/incentivization/foundation-badge-grey.svg';
import trifectaBadgeIcon from '@common/assets/incentivization/trifecta-badge.svg';
import trifectaBadgeGreyIcon from '@common/assets/incentivization/trifecta-badge-grey.svg';
import prospectorBadgeIcon from '@common/assets/incentivization/prospector-badge.svg';
import prospectorBadgeGreyIcon from '@common/assets/incentivization/prospector-badge-grey.svg';
import investorBadgeIcon from '@common/assets/incentivization/investor-badge.svg';
import investorBadgeGreyIcon from '@common/assets/incentivization/investor-badge-grey.svg';

export type AchievementCategory = {
	title: string;
	priority: number;
	achievementList: Achievement[];
};

export type Achievement = {
	title: string;
	unearnedText: string;
	earnedText: string;
	unearnedAssetKey: string;
	earnedAssetKey: string;
	earnedNotificationText: string;
	active: boolean;
	priority: number;
};

export const testAchievementConfig: AchievementCategory[] = [
	{
		title: 'All About Me',
		priority: 1,
		achievementList: [
			{
				title: 'Academics',
				unearnedText:
					'Unlock your chances of getting in. Add your high school GPA and at least one test score.',

				earnedText:
					'You unlocked your chances of getting in by adding your high school GPA and at least one test score.',
				unearnedAssetKey: academicBadgeGreyIcon,
				earnedAssetKey: academicBadgeIcon,
				earnedNotificationText:
					'You earned the Academic badge and unlocked your chances of getting in!',
				active: true,
				priority: 1,
			},
			{
				title: 'Resume',
				unearnedText:
					'Upgrade your admission chances by adding your class rank, course rigor and high school',
				earnedText:
					'You upgraded your admission chances by adding your class rank, course rigor, and high school.',
				unearnedAssetKey: resumeBadgeGreyIcon,
				earnedAssetKey: resumeBadgeIcon,
				earnedNotificationText: 'You earned the Resume badge and upgraded your admission chances!',
				active: true,
				priority: 2,
			},
			{
				title: 'Individualist',
				unearnedText:
					'Boost your personalized matches by adding family income, phone, religion, and ethnicity to your student profile.',
				earnedText:
					'Personalized matches boosted! You added family income, phone, religion, and ethnicity to your student profile.',
				unearnedAssetKey: individualistBadgeGreyIcon,
				earnedAssetKey: individualistBadgeIcon,
				earnedNotificationText:
					'You earned the Individualist badge! Your personalized matches have been boosted.',
				active: false,
				priority: 3,
			},
			{
				title: 'Visionary',
				unearnedText:
					'See into your future college vision! Add at least three college preferences to shape what you want in a school.',
				earnedText:
					'Your college vision is taking shape! You added at least three college preferences.',
				unearnedAssetKey: visionaryBadgeGreyIcon,
				earnedAssetKey: visionaryBadgeIcon,
				earnedNotificationText:
					'You earned the Visionary badge! You‘re beginning to see your future colllege vision.',
				active: false,
				priority: 4,
			},
		],
	},
	{
		title: 'Research and Discovery',
		priority: 2,
		achievementList: [
			{
				title: 'Exlporer',
				unearnedText: 'Kick off your research deep-dive by exploring a college’s details.',
				earnedText: 'Your research deep-dive begins! You explored a college’s details.',
				unearnedAssetKey: explorerBadgeGreyIcon,
				earnedAssetKey: explorerBadgeIcon,
				earnedNotificationText: 'You earned the Explorer badge! Your research deep-dive begins.',
				active: true,
				priority: 1,
			},
			{
				title: 'Wanderer',
				unearnedText: 'Go for a wander! Research in-state and out-of-state schools.',
				earnedText: 'You’re out for a wander! You researched in-state and out-of-state schools.',
				unearnedAssetKey: wandererBadgeGreyIcon,
				earnedAssetKey: wandererBadgeIcon,
				earnedNotificationText:
					'You earned the Wanderer badge by researching in-state and out-of-state schools.',
				active: true,
				priority: 2,
			},
			{
				title: 'Seaker',
				unearnedText:
					'Boost your personalized matches by adding family income, phone, religion, and ethnicity to your student profile.',
				earnedText:
					'Personalized matches boosted! You added family income, phone, religion, and ethnicity to your student profile.',
				unearnedAssetKey: seekerBadgeGreyIcon,
				earnedAssetKey: seekerBadgeIcon,
				earnedNotificationText:
					'You earned the Seeker badge! You’re seeking out ways to plan and pay.',
				active: false,
				priority: 3,
			},
			{
				title: 'Matchmaker',
				unearnedText: 'Hone in on the perfect school. Like or dismiss Today’s Matches.',
				earnedText: 'You completed Today’s Matches. You’re honing in on the perfect school!',
				unearnedAssetKey: matchmakerBadgeGreyIcon,
				earnedAssetKey: matchmakerBadgeIcon,
				earnedNotificationText:
					'You earned the Matchmaker badge! You’re honing in on the perfect school.',
				active: false,
				priority: 4,
			},
		],
	},
	{
		title: 'College List Building',
		priority: 3,
		achievementList: [
			{
				title: 'Pioneer',
				unearnedText: 'Start blazing a trail by adding your first school to your list.',
				earnedText: 'You’re blazing a trail! You saved your first school to your list.',
				unearnedAssetKey: pioneerBadgeGreyIcon,
				earnedAssetKey: pioneerBadgeIcon,
				earnedNotificationText:
					'You earned the Pioneer badge! You’re blazing a trail on your college list.',
				active: true,
				priority: 1,
			},
			{
				title: 'Foundation',
				unearnedText: 'Build a foundation by adding four or more schools to your list.',
				earnedText: 'You’re building a foundation. You have four or more schools on your list.',
				unearnedAssetKey: foundationBadgeGreyIcon,
				earnedAssetKey: foundationBadgeIcon,
				earnedNotificationText:
					'You earned the Foundation badge! You’re beginning to build your college list.',
				active: true,
				priority: 2,
			},
			{
				title: 'Trifecta',
				unearnedText:
					'Achieve the trifecta! Craft a well-rounded list by having at two or more reach, target, and likely schools.',
				earnedText:
					'You achieved the trifecta! You have a well-rounded list — two or more target, reach, and likely schools.',
				unearnedAssetKey: trifectaBadgeGreyIcon,
				earnedAssetKey: trifectaBadgeIcon,
				earnedNotificationText:
					'You achieved the Trifecta badge! Congrats to having a well-rounded list!',
				active: false,
				priority: 3,
			},
		],
	},
	{
		title: 'Ways to Pay',
		priority: 4,
		achievementList: [
			{
				title: 'Prospector',
				unearnedText: 'Find the money! Explore scholarships and start thinking about ways to pay.',
				earnedText:
					'You’re finding the money! You explored scholarships and are thinking about ways to pay.',
				unearnedAssetKey: prospectorBadgeGreyIcon,
				earnedAssetKey: prospectorBadgeIcon,
				earnedNotificationText: 'You earned the Prospector badge! You’re exploring scholarships.',
				active: true,
				priority: 1,
			},
			{
				title: 'Investor',
				unearnedText:
					'Put on your finance hat! Find student loan options that are available for the schools you like.',
				earnedText:
					'Your finance hat is on! You’re thinking about student loan options for schools you like.',
				unearnedAssetKey: investorBadgeGreyIcon,
				earnedAssetKey: investorBadgeIcon,
				earnedNotificationText:
					'You earned the Investor badge! You’re thinking about your finance options.',
				active: false,
				priority: 2,
			},
		],
	},
];

export const testAchievementCardList: Achievement[] = [
	{
		title: 'Academics',
		unearnedText:
			'Unlock your chances of getting in. Add your high school GPA and at least one test score.',

		earnedText:
			'You unlocked your chances of getting in by adding your high school GPA and at least one test score.',
		unearnedAssetKey: academicBadgeGreyIcon,
		earnedAssetKey: academicBadgeIcon,
		earnedNotificationText:
			'You earned the Academic badge and unlocked your chances of getting in!',
		active: true,
		priority: 1,
	},
	{
		title: 'Investor',
		unearnedText:
			'Put on your finance hat! Find student loan options that are available for the schools you like.',
		earnedText:
			'Your finance hat is on! You’re thinking about student loan options for schools you like.',
		unearnedAssetKey: investorBadgeGreyIcon,
		earnedAssetKey: investorBadgeIcon,
		earnedNotificationText:
			'You earned the Investor badge! You’re thinking about your finance options.',
		active: true,
		priority: 2,
	},
	{
		title: 'Trifecta',
		unearnedText:
			'Achieve the trifecta! Craft a well-rounded list by having at two or more reach, target, and likely schools.',
		earnedText:
			'You achieved the trifecta! You have a well-rounded list — two or more target, reach, and likely schools.',
		unearnedAssetKey: trifectaBadgeGreyIcon,
		earnedAssetKey: trifectaBadgeIcon,
		earnedNotificationText:
			'You achieved the Trifecta badge! Congrats to having a well-rounded list!',
		active: true,
		priority: 3,
	},
	{
		title: 'Exlporer',
		unearnedText: 'Kick off your research deep-dive by exploring a college’s details.',
		earnedText: 'Your research deep-dive begins! You explored a college’s details.',
		unearnedAssetKey: explorerBadgeGreyIcon,
		earnedAssetKey: explorerBadgeIcon,
		earnedNotificationText: 'You earned the Explorer badge! Your research deep-dive begins.',
		active: true,
		priority: 1,
	},
];
