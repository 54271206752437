import * as R from 'ramda';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSackDollar } from '@fortawesome/pro-light-svg-icons/faSackDollar';
import { faSliders } from '@fortawesome/pro-light-svg-icons/faSliders';
import { faGraduationCap } from '@fortawesome/pro-light-svg-icons/faGraduationCap';
import { faBackpack } from '@fortawesome/pro-light-svg-icons/faBackpack';
import { faHomeAlt } from '@fortawesome/pro-light-svg-icons/faHomeAlt';
import { faUserEdit } from '@fortawesome/pro-light-svg-icons/faUserEdit';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons/faMagnifyingGlass';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';

export type NavigationLink = {
	icon: IconDefinition;
	name: string;
	link: string;
	divider?: boolean;
	openInNewTab?: boolean;
};

export type NavigationSection = Exclude<NavigationLink, 'link'> & {
	key: string;
	icon?: IconDefinition;
	link?: string;
	navigationLinks: NavigationLink[];
};

export const collegePreferencesNavigationLink = {
	icon: faSliders,
	name: 'My Preferences',
	link: '/college-preferences',
} as NavigationLink;

export const exploreCollegesNavigationLink = {
	icon: faMagnifyingGlass,
	name: 'Explore Colleges',
	link: '/college-search',
} as NavigationLink;

export const collegeListNavigationLink = {
	icon: faHeart,
	name: 'My College List',
	link: '/college-list',
} as NavigationLink;

export const collegePlannerNavigationLink = {
	icon: faCheck,
	name: 'College Planner',
	link: '/college-planner',
	divider: true,
} as NavigationLink;

export const highSchoolAcademicInfoNavigationLink = {
	icon: faBackpack,
	name: 'High School Academics',
	link: '/profile/academic-info',
} as NavigationLink;

export const collegeAcademicInfoNavigationLink = {
	icon: faGraduationCap,
	name: 'College Academics',
	link: '/profile/college-academics',
} as NavigationLink;

export const achievementListNavigationLink = {
	name: 'My Achievements',
	link: '/achievements',
} as NavigationLink;

export const myAdmissionOffersNavigationLink = {
	icon: faEnvelopeOpenText,
	name: 'Direct Admissions',
	link: '/match-direct',
} as NavigationLink;

export const addCollegeAcademicInfoNavigationLinkToProfileSection: SectionsModifier = R.map(
	(section: NavigationSection) =>
		section.name === 'My student profile'
			? R.evolve({
					navigationLinks: (R.curry(
						R.concat(R.__, [collegeAcademicInfoNavigationLink])
					) as unknown) as (n: NavigationLink[]) => NavigationLink[],
			  })(section)
			: section
);

export const findScholarshipsNavigationLink = {
	icon: faSackDollar,
	name: 'Scholarships',
	link: '/scholarship-search',
	divider: true,
} as NavigationLink;

export const homeSection = {
	key: 'home',
	name: 'Home',
	icon: faHomeAlt,
	link: '/dashboard',
	navigationLinks: [],
	divider: true,
} as NavigationSection;

const collegeSection = {
	key: 'college',
	navigationLinks: [
		exploreCollegesNavigationLink,
		collegeListNavigationLink,
		myAdmissionOffersNavigationLink,
		collegePlannerNavigationLink,
	],
} as NavigationSection;

const scholarshipSection = {
	key: 'scholarship',
	navigationLinks: [findScholarshipsNavigationLink],
} as NavigationSection;

const profileSection = {
	key: 'profile',
	navigationLinks: [
		{
			icon: faUserEdit,
			name: 'Profile',
			link: '/profile/personal-info',
		},
		collegePreferencesNavigationLink,
	],
} as NavigationSection;

const navigationSections: NavigationSection[] = [
	homeSection,
	collegeSection,
	scholarshipSection,
	profileSection,
];

export type SectionsModifier = (sections: NavigationSection[]) => NavigationSection[];

export const sectionModifiersHighSchoolStudent: SectionsModifier[] = [];
export const sectionModifiersCollegeStudent: SectionsModifier[] = [
	addCollegeAcademicInfoNavigationLinkToProfileSection,
];

export default navigationSections;
