import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import YesNoSelectorInput, { YesNoSelection } from '@common/components/YesNoSelectorInput';

type OpenToTransferProps = Omit<Partial<BaseFormSelectProps>, 'references'>;

const OpenToTransfer: FC<OpenToTransferProps> = ({
	label = 'Are you considering transferring colleges?',
	initialValue = YesNoSelection.NO_ANSWER,
	required,
}) => (
	<YesNoSelectorInput
		title={label}
		name={FormNames.openToTransfer}
		defaultValue={initialValue}
		required={required}
	/>
);

export default OpenToTransfer;
