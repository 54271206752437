import * as R from 'ramda';
import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import {
	AutocompleteProvided,
	Hit,
	Configure,
	connectAutoComplete,
} from 'react-instantsearch-core';
import {
	BaseSearch,
	ReactSelect,
	defaultComponents,
	OptionItem,
	WarningBoundary,
} from '@cappex/search';
import SEARCH_INDEX_NAMES from '@util/search/constants';
import { searchIndexPrefix } from '@src/features/environment';
import { Cbo } from '../util/cbo/constants';
import CboSearchItem from './CboSearchItem';
import ErrorBoundary from './ErrorBoundary';
import BaseSearchFallback from './BaseSearchFallback';

export interface AlgoliaCboHit {
	objectID: string;
	name: string;
	nickName: string;
	address: string;
	city: string;
	stateAbbreviation: string;
	zipCode: string;
	_geoloc: {
		lat: string;
		lng: string;
	};
}
interface CboSearchInputProps {
	studentCboIds: string[];
	cbos: Cbo[];
	// eslint-disable-next-line react/no-unused-prop-types -- eslint claims it is not used when it really is
	studentLat: string;
	// eslint-disable-next-line react/no-unused-prop-types -- eslint claims it is not used when it really is
	studentLng: string;
	onSelect: (option: OptionItem) => void;
	onRemove: (option: OptionItem) => void;
}

interface CboOptionItem extends OptionItem {
	city?: string;
	stateAbbreviation?: string;
}
type CboInputProps = CboSearchInputProps & AutocompleteProvided<AlgoliaCboHit>;

export const MAX_SELECTED_CBOS = 3;

const mapHitsToOptions = (hits: Hit<AlgoliaCboHit>[]): CboOptionItem[] =>
	hits.map(hit => ({
		value: hit.objectID,
		label: hit.name,
		city: hit.city,
		stateAbbreviation: hit.stateAbbreviation,
	}));

const getSelectedOptions = (studentCbos: string[] = [], cbos: Cbo[] = []): CboOptionItem[] => {
	const options: CboOptionItem[] = [];

	cbos.some(data => {
		const matchedCbo = studentCbos.find(studentCbo => data.cboId === studentCbo);

		if (matchedCbo) {
			options.push({ value: data.cboId, label: data.data.organizationName });
		}

		return options.length === studentCbos.length;
	});

	return options;
};

const CboInput: FC<CboInputProps> = ({ studentCboIds, cbos, hits, refine, onSelect, onRemove }) => {
	const [selectedCbos, setSelectedCbos] = useState<Cbo[]>();

	const optionsToShow = useMemo(() => mapHitsToOptions(hits), [hits]);
	const selectedItems = useMemo(() => getSelectedOptions(studentCboIds, selectedCbos), [
		studentCboIds,
		selectedCbos,
	]);

	const onSelectOption = useCallback(
		(option: OptionItem) => {
			const selectedCbo: Cbo = {
				cboId: option.value,
				data: {
					enabled: true,
					organizationName: option.label,
				},
			};

			setSelectedCbos([...selectedCbos, selectedCbo]);

			onSelect(option);
		},
		[selectedCbos, setSelectedCbos, onSelect]
	);

	const onRemoveOption = useCallback(
		(option: OptionItem) => {
			setSelectedCbos(R.reject(cbo => cbo.cboId === option.value, selectedCbos));

			onRemove(option);
		},
		[selectedCbos, setSelectedCbos, onRemove]
	);

	useEffect(() => {
		setSelectedCbos(cbos);
	}, [cbos]);

	return (
		<ReactSelect
			id="cbo-search"
			name="cbo-search"
			placeholderText="Search Programs"
			value={selectedItems}
			isMultiSelect
			closeMenuOnSelect
			components={{
				...defaultComponents,
				Option: CboSearchItem,
			}}
			options={optionsToShow}
			onItemSelect={onSelectOption}
			onItemRemove={onRemoveOption}
			onInputChange={refine}
			disabled={selectedItems.length >= MAX_SELECTED_CBOS}
		/>
	);
};

const ConnectedCboSearchInput = connectAutoComplete<AlgoliaCboHit>(CboInput);

const CboSearchInput: FC<CboSearchInputProps> = ({ studentLat, studentLng, ...rest }) => {
	const aroundLatLng: string = useMemo(
		() =>
			!R.isNil(studentLat) && !R.isNil(studentLng) ? `${studentLat}, ${studentLng}` : undefined,
		[studentLat, studentLng]
	);

	return (
		<ErrorBoundary Fallback={BaseSearchFallback}>
			<BaseSearch indexName={SEARCH_INDEX_NAMES.CBOS} environment={searchIndexPrefix}>
				{!R.isNil(aroundLatLng) && <Configure aroundLatLng={aroundLatLng} />}
				<WarningBoundary>
					<ConnectedCboSearchInput {...rest} />
				</WarningBoundary>
			</BaseSearch>
		</ErrorBoundary>
	);
};

export default CboSearchInput;
