import React, { useState, useContext, useRef, useMemo, FC, useEffect } from 'react';
import * as R from 'ramda';
import useCloudReferenceData from '@src/common/util/hooks/useCloudReferenceData';
import { ReferenceDataTypes, FormNames } from '@cappex/constants';
import useFormValidation from '@src/common/util/hooks/useFormValidation';
import { SubFormContext } from '@src/common/components/BaseValidationForm';
import { ConvertedLocationData } from '@src/common/util/preferences/constants';
import { AutomationNameDefault } from '@src/common/util/automation';
import { Typography, Grid } from '@material-ui/core';
import Distance from './LocationDistance';
import { OpenToAnyButton } from './CollegePreferencesCard';
import { convertNumbersToStrings } from '@src/common/util/general';

type LocationDistanceFormComponentProps = {
	label: string;
	subText?: string;
	name: string;
	defaultValue?: string;
};

const DEFAULT_VALUE = null;

const LocationDistanceFormComponent: FC<LocationDistanceFormComponentProps> = ({
	label,
	subText,
	name,
	defaultValue = DEFAULT_VALUE,
}) => {
	const automationNameClearPreference = `${label}-${AutomationNameDefault.clearPreferencesButton}`;
	const { path } = useContext(SubFormContext);
	const [preferences, setPreferences] = useState<ConvertedLocationData[]>([]);

	const headerRef = useRef(null);
	const initialValueObj = useMemo(
		() => ({
			[FormNames.locationCollegePreference]: defaultValue,
		}),
		[defaultValue]
	);
	const { value, setValue } = useFormValidation({
		path,
		name,
		initialValue: initialValueObj,
		fieldRef: headerRef,
	});

	const convertLocationData = (data: Record<string, any>) =>
		data &&
		Object.entries(convertNumbersToStrings(data)).map(([key, val]) => ({
			type: key,
			value: val,
		}));

	useEffect(() => {
		// keep the selected items in sync with the form value when the form value changes from outside
		const locationData = value[FormNames.locationCollegePreference];
		if (locationData && Object.keys(locationData).length !== preferences.length) {
			setPreferences(
				convertLocationData(
					(locationData as unknown) as Record<string, any>
				) as ConvertedLocationData[]
			);
		}
	}, [value, preferences]);

	const onSelect = (options: ConvertedLocationData[]) => {
		setPreferences(options);
		setValue({
			...value,
			[FormNames.locationCollegePreference]: ({
				distanceId: options[0].value,
				stateIds: [],
				regionIds: [],
			} as unknown) as string,
		});
	};
	const clear = () => {
		setPreferences([]);
		setValue({
			...value,
			[FormNames.locationCollegePreference]: ({
				distanceId: null,
				stateIds: [],
				regionIds: [],
			} as unknown) as string,
		});
	};

	const [distanceData] = useCloudReferenceData({
		dataType: ReferenceDataTypes.distanceFromHome,
	});

	return (
		<Grid container>
			{label && (
				<Grid item>
					<Typography variant="h6">{label}</Typography>
				</Grid>
			)}
			{subText && (
				<Grid item>
					<Typography variant="body2" color="textSecondary">
						{subText}
					</Typography>
				</Grid>
			)}
			<Grid item>
				<Distance
					suppressDivider
					setStudentPreferences={onSelect}
					studentPreferences={preferences}
					distanceData={distanceData}
					isSelected
				/>
			</Grid>
			<Grid item xs>
				<OpenToAnyButton
					type="button"
					$boldFontWeight
					$noneTextTransform
					$primaryText
					fullWidth
					variant="text"
					color="primary"
					onClick={clear}
					isOpenToAny={R.isEmpty(preferences)}
					data-qa={automationNameClearPreference}
				>
					Open to any location
				</OpenToAnyButton>
			</Grid>
		</Grid>
	);
};

export default LocationDistanceFormComponent;
