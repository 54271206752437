import React from 'react';
import ReactDOM from 'react-dom';
import WrappedApp from './features/app/main';
import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

ReactDOM.render(<WrappedApp />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
	window.addEventListener('load', () => {
		navigator.serviceWorker.register('/service-worker.js');
	});
}
