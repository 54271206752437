import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

let previousDataLayerValue;

const EnrolledDataLayer = (eventType: DataLayerEventTypes, enrolled: string) => {
	const enrolledValue = enrolled == null ? null : enrolled === 'true';
	if (previousDataLayerValue !== enrolledValue) {
		previousDataLayerValue = enrolledValue;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: enrolledValue },
		});
	}
};

export default EnrolledDataLayer;
