import * as R from 'ramda';
import { FormNames } from '@cappex/constants';
import { FormFields } from '../form';

const incorrectDateFormatMessage = 'Must provide Birth Date';
const pastMaximumDateMessage = 'Birth Date must be in the past';
const pastMinimumDateMessage = 'Birth Date must be no earlier than 1900';
const invalidDateMessage = 'Invalid date';

const minDate = '1900-01-01T00:00:00';
const validDateExp = /^(\d{4,4})-(\d{2,2})-(\d{2,2})T\d{2,2}:\d{2,2}:\d{2,2}.\d{3,3}Z$/;

const isDate: (s: string) => boolean = RegExp.prototype.test.bind(validDateExp);

const requiredCriteria = (fields: Pick<FormFields, FormNames.birthDate>): string =>
	isDate(R.prop(FormNames.birthDate, fields)) ? '' : incorrectDateFormatMessage;

const maxDateCriteria = (fields: Pick<FormFields, FormNames.birthDate>): string =>
	R.gte(new Date(R.prop(FormNames.birthDate, fields)).valueOf(), new Date().valueOf())
		? pastMaximumDateMessage
		: '';

const minDateCriteria = (fields: Pick<FormFields, FormNames.birthDate>): string =>
	R.lte(new Date(R.prop(FormNames.birthDate, fields)).valueOf(), new Date(minDate).valueOf())
		? pastMinimumDateMessage
		: '';

const noLeadingZeroRegex = /^[1-9][0-9]*$/;
const noLeadingZeroYearCriteria = (fields: Pick<FormFields, FormNames.birthYear>): string =>
	R.isNil(R.prop(FormNames.birthYear, fields)) ||
	noLeadingZeroRegex.test(R.prop(FormNames.birthYear, fields))
		? ''
		: invalidDateMessage;

export {
	// validation failure message = exported primarily for testing
	incorrectDateFormatMessage,
	pastMaximumDateMessage,
	pastMinimumDateMessage,
	invalidDateMessage,
	// validation criteria - to be used in components.
	requiredCriteria,
	maxDateCriteria,
	minDateCriteria,
	noLeadingZeroYearCriteria,
};
