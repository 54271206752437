import React, { FC } from 'react';
import { styled } from '@cappex/theme';

const BlurredDiv = styled.div`
	filter: blur(3px);
`;

type OwnProps = {
	blur?: boolean;
};

const BlurredContent: FC<OwnProps> = ({ blur = true, children }) =>
	blur ? <BlurredDiv>{children}</BlurredDiv> : <>{children}</>;

export default BlurredContent;
