import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

const IdDegreeMapping = {
	'1': 'HS Diploma',
	'2': "Associate's Degree",
	'3': "Bachelor's Degree",
	'4': "Master's Degree",
	'5': 'Doctoral Degree',
	'6': 'No Degree or Diploma',
};

let previousDataLayerValue;

const MostRecentDegreeDataLayer = (eventType: DataLayerEventTypes, degreeId: string) => {
	if (previousDataLayerValue !== degreeId) {
		previousDataLayerValue = degreeId;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: IdDegreeMapping[degreeId] },
		});
	}
};

export default MostRecentDegreeDataLayer;
