import { styled } from '@cappex/theme';
import { Divider, Grid } from '@material-ui/core';
import React, { FC } from 'react';
import AchievementCategoryContainer from './components/AchievementCategoryContainer';
import { testAchievementConfig } from './util/achievementUtil';

const MainContentPadding = styled.div`
	padding: 2rem 1rem;
	@media (min-width: ${props => props.theme.breakpoints.values.md}px) {
		padding: 3rem;
	}
`;

const AchievementsPage: FC = () => {
	// Replace once back end is finished and can map user achievements
	const achievementData = testAchievementConfig;

	return (
		<MainContentPadding>
			<Grid container direction="column" spacing={4}>
				{achievementData.map((category, index) => (
					<>
						<Grid item>
							<AchievementCategoryContainer
								categoryName={category.title}
								achievementList={category.achievementList}
							/>
						</Grid>
						{index !== achievementData.length - 1 && (
							<Grid item>
								<Divider />
							</Grid>
						)}
					</>
				))}
			</Grid>
		</MainContentPadding>
	);
};

export default AchievementsPage;
