import * as R from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import { styled, ThemeContext } from '@cappex/theme';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Grid, useMediaQuery } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Skeleton } from '@material-ui/lab';
import { AutomationNameDefault } from '../util/automation';

interface SliderButtonProps extends ButtonProps {
	side: 'left' | 'right';
}

const SliderButton = styled(Fab)<SliderButtonProps>`
	&&& {
		font-size: 14px;
		z-index: 10;
		height: 2rem;
		width: 2rem;
		${props => (props.side === 'left' ? 'left: -2.125rem;' : 'right: -2.125rem;')}
		box-shadow: none;
	}

	&&&:before {
		content: '';
	}
`;

const CARD_PADDING = '1rem';

// This is to "remove" the padding from the cards on the ends of the carousel so they line up with other elements on the page
const SliderWrapper = styled.div`
	margin: 0 -${CARD_PADDING};
`;

const CarouselCardWrapper = styled.div`
	margin: 0.5rem ${CARD_PADDING};
`;

const StyledCardSkeleton = styled(Skeleton)`
	border-radius: 0.5rem;
`;

const SkeletonScrollButton = styled(({ slideCount, currentSlide, ...props }) => (
	<Skeleton {...props} />
))`
	&&& {
		font-size: 0.875rem;
		z-index: 10;
		height: 2rem;
		width: 2rem;
		${props => (props.side === 'left' ? 'left: -2.125rem;' : 'right: -.8125rem;')}
		box-shadow: none;
	}

	&&&:before {
		content: '';
	}
`;

const skeletonObjArray = [
	{ id: 'abc' },
	{ id: 'def' },
	{ id: 'ghi' },
	{ id: 'jkl' },
	{ id: 'mno' },
];

type ScrollButtonProps = {
	onClick?: React.MouseEventHandler<HTMLElement>;
	className?: string;
	icon: IconDefinition;
	side: 'left' | 'right';
	dataQa: string;
};

const ScrollButton: FC<ScrollButtonProps> = ({ className, icon, onClick, dataQa = '', side }) => {
	const theme = useContext(ThemeContext);
	return (
		<SliderButton onClick={onClick} className={className} data-qa={dataQa} side={side}>
			<FontAwesomeIcon size="lg" icon={icon} color={theme.palette.common.black} />
		</SliderButton>
	);
};

type GenericCardCarouselSettings = {
	loading: boolean;
	isSmScreen: boolean;
	isMdScreen: boolean;
	isLgScreen: boolean;
	maxCardsVisible: number;
	numCardsInCarousel: number;
	centerMode: boolean;
	centerModePadding: string;
};

export const buildCardSettings = ({
	loading,
	isSmScreen,
	isMdScreen,
	isLgScreen,
	maxCardsVisible,
	numCardsInCarousel,
	centerMode,
	centerModePadding,
}: GenericCardCarouselSettings) => {
	const settings: Settings = {
		dots: false,
		className: '',
		speed: 500,
		arrows: true,
		slidesToShow: maxCardsVisible,
		slidesToScroll: 1,
		prevArrow: loading ? (
			<SkeletonScrollButton
				variant="circle"
				data-qa={AutomationNameDefault.outreachCarouselLeftArrowSkeleton}
			/>
		) : (
			<ScrollButton
				icon={faChevronLeft}
				side="left"
				dataQa={AutomationNameDefault.outreachCarouselLeftArrow}
			/>
		),
		nextArrow: loading ? (
			<SkeletonScrollButton
				variant="circle"
				data-qa={AutomationNameDefault.outreachCarouselRightArrowSkeleton}
			/>
		) : (
			<ScrollButton
				icon={faChevronRight}
				side="right"
				dataQa={AutomationNameDefault.outreachCarouselRightArrow}
			/>
		),
		centerMode: false,
		centerPadding: '',
	};

	// Set per screen size override options
	if (isSmScreen) {
		settings.slidesToShow = 1;
		settings.centerMode = centerMode;
		settings.className = 'center';
		settings.centerPadding = centerModePadding;
		settings.arrows = false;
	} else if (isMdScreen) {
		settings.slidesToShow = R.max(maxCardsVisible - 2, 1);
	} else if (isLgScreen) {
		settings.slidesToShow = R.max(maxCardsVisible - 1, 1);
	}

	// react-slick will duplicate slides (and screw up the carousel) in infinte mode if the amount to show is greater than the number of cards
	settings.infinite = settings.slidesToShow <= numCardsInCarousel;

	return settings;
};

export type CardSkeletonSettings = {
	variant: 'text' | 'rect' | 'circle';
	width: number | string;
	height: number | string;
};

type GenericCardCarouselProps = {
	cardComponenet?: React.ComponentType;
	cardData?: object[];
	maxCardsVisible?: number;
	maxCardsInCarousel?: number;
	cardSkeletonSettings: CardSkeletonSettings;
	centerMode?: boolean;
	centerModePadding?: string;
};

const GenericCardCarousel: FC<GenericCardCarouselProps> = ({
	cardComponenet: Card,
	cardData,
	maxCardsVisible = 4,
	maxCardsInCarousel = 8,
	cardSkeletonSettings,
	centerMode = true,
	centerModePadding = '20%',
}) => {
	const theme = useContext(ThemeContext);

	const cardDataToDisplay = useMemo(() => cardData && cardData.slice(0, maxCardsInCarousel), [
		cardData,
		maxCardsInCarousel,
	]);

	const loading = !cardDataToDisplay || R.isEmpty(cardData);

	const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

	const settings = buildCardSettings({
		loading,
		isSmScreen,
		isMdScreen,
		isLgScreen,
		maxCardsVisible,
		numCardsInCarousel: cardDataToDisplay ? cardDataToDisplay.length : 0,
		centerMode,
		centerModePadding,
	});

	return (
		<SliderWrapper>
			<Slider {...settings}>
				{loading
					? skeletonObjArray.map(data => (
							<Grid
								container
								spacing={3}
								alignContent="flex-start"
								direction="column"
								key={data.id}
								data-qa={AutomationNameDefault.genericCardCarouselSkeleton}
							>
								<Grid item xs>
									<StyledCardSkeleton
										variant={cardSkeletonSettings.variant}
										width={cardSkeletonSettings.width}
										height={cardSkeletonSettings.height}
										data-qa={AutomationNameDefault.genericCardCarouselSkeleton}
									/>
								</Grid>
							</Grid>
					  ))
					: cardDataToDisplay.map((data, i) => (
							// Do not remove the wrapping <div>. The carousel will apply styles to the element that conflict with the desired design
							// eslint-disable-next-line react/no-array-index-key
							<div key={i}>
								<CarouselCardWrapper>
									<Card {...data} />
								</CarouselCardWrapper>
							</div>
					  ))}
			</Slider>
		</SliderWrapper>
	);
};

export default GenericCardCarousel;
