import transformCostLabel from '@src/common/util/search/transformCostLabel';

export const STATE_LABEL = 'state';
export const INTL_LABEL = 'intl';

export const initialDisabledBasicsState = {
	[STATE_LABEL]: false,
	[INTL_LABEL]: false,
};

export interface DisabledBasics {
	state: boolean;
	intl: boolean;
}

export enum CollegeIndexFacets {
	STATE = 'state',
	IS_INTERNATIONAL = 'isCollegeInternational',
	LEVEL_OF_INSTITUTION = 'levelOfInstitution',
	INSTITUTION_TYPE = 'institutionType',
	NEED_BLIND = 'needBlind',
	NO_APP_FEE = 'noAppFee',
	TEST_OPTIONAL = 'testOptional',
	HISTORICALLY_BLACK_UNIVERSITY = 'hbcu',
	LGBT_STUDENT_GROUPS = 'lgbtStudentResourceGroups',
	CULTURAL_STUDENT_GROUPS = 'culturalStudentResourceGroups',
	DISABILITY_HOUSING_AVAILABLE = 'disabilityHousingAvailable',
	CAMPUS_WOMENS_CENTER = 'campusWomensCenter',
	HISPANIC_SERVING_INSTITUTION = 'hispanicServingInstitution',
	TRIBAL = 'tribalCollegesAndUniversities',
	GENDERED_COLLEGE = 'coedStatus',
	CAMPUS_SETTING = 'campusSetting',
	STUDENT_BODY_SIZE = 'studentBodySize',
	SELECTIVITY = 'selectivity',
	ACT_GROUPING = 'actGrouping',
	SAT_GROUPING = 'satGrouping',
	MAJOR_FAMILIES = 'majorFamilies',
	NET_PRICE = 'averageNetPrice',
	STICKER_PRICE = 'sticker',
	COST_PER_CREDIT_HOUR = 'pricePerCreditHour',
	FINANCIAL_NEED_MET = 'averageFinancialNeedMet',
	EVENING_WEEKEND = 'eveningWeekend',
	ONLINE_OPTIONS = 'onlineClasses',
	DEDICATED_VETERAN_STAFF = 'dedicatedVeteranStaffMember',
	CREDIT_FOR_MILITARY_TRAINING = 'offersCreditForMilitaryTraining',
	YELLOW_RIBBON_PROGRAM_MEMBER = 'yellowRibbonProgramMember',
	STUDENT_VETERAN_ORGANIZATION = 'studentVeteranOrganization',
	SERVICE_MEMBER_OPPORTUNITY = 'serviceMemberOpportunity',
	CREDIT_FOR_LIFE_EXPERIENCE = 'offersCreditForLifeExperience',
	CAREER_SERVICES = 'offersCareerServices',
	REMEDIAL_SERVICES = 'offersRemedialServices',
	ON_CAMPUS_DAYCARE = 'offersOnCampusDaycare',
	YOU_VISIT_VIRTUAL_TOUR = 'hasYouVisitVirtualTour',
}

export const collegeFilterBasicsFacets = [
	CollegeIndexFacets.LEVEL_OF_INSTITUTION,
	CollegeIndexFacets.INSTITUTION_TYPE,
	CollegeIndexFacets.STATE,
	CollegeIndexFacets.IS_INTERNATIONAL,
];

export const collegeFilterMoreFacets = [
	CollegeIndexFacets.NEED_BLIND,
	CollegeIndexFacets.NO_APP_FEE,
	CollegeIndexFacets.TEST_OPTIONAL,
	CollegeIndexFacets.HISTORICALLY_BLACK_UNIVERSITY,
	CollegeIndexFacets.GENDERED_COLLEGE,
	CollegeIndexFacets.FINANCIAL_NEED_MET,
	CollegeIndexFacets.CREDIT_FOR_LIFE_EXPERIENCE,
	CollegeIndexFacets.CAREER_SERVICES,
	CollegeIndexFacets.REMEDIAL_SERVICES,
	CollegeIndexFacets.ON_CAMPUS_DAYCARE,
];

export const collegeFilterCampusFacets = [
	CollegeIndexFacets.CAMPUS_SETTING,
	CollegeIndexFacets.STUDENT_BODY_SIZE,
];

export const collegeFilterAcademicsFacets = [
	CollegeIndexFacets.SELECTIVITY,
	CollegeIndexFacets.ACT_GROUPING,
	CollegeIndexFacets.SAT_GROUPING,
];

export const collegeFilterMajorFacets = [CollegeIndexFacets.MAJOR_FAMILIES];

export const collegeFilterCostFacets = [
	CollegeIndexFacets.NET_PRICE,
	CollegeIndexFacets.STICKER_PRICE,
	CollegeIndexFacets.COST_PER_CREDIT_HOUR,
];

export const collegeFilterModeOfStudyFacets = [
	CollegeIndexFacets.ONLINE_OPTIONS,
	CollegeIndexFacets.EVENING_WEEKEND,
];

export const collegeFilterVeteranFacets = [
	CollegeIndexFacets.DEDICATED_VETERAN_STAFF,
	CollegeIndexFacets.CREDIT_FOR_MILITARY_TRAINING,
	CollegeIndexFacets.YELLOW_RIBBON_PROGRAM_MEMBER,
	CollegeIndexFacets.STUDENT_VETERAN_ORGANIZATION,
	CollegeIndexFacets.SERVICE_MEMBER_OPPORTUNITY,
];

export const transformFilterLabelMap = {
	[CollegeIndexFacets.NET_PRICE]: transformCostLabel,
	[CollegeIndexFacets.STICKER_PRICE]: transformCostLabel,
	[CollegeIndexFacets.COST_PER_CREDIT_HOUR]: transformCostLabel,
	[CollegeIndexFacets.IS_INTERNATIONAL]: () => 'Colleges outside the U.S.',
	[CollegeIndexFacets.FINANCIAL_NEED_MET]: () => '100% of Financial Need Met',
	[CollegeIndexFacets.ACT_GROUPING]: text => `ACT: ${text}`,
	[CollegeIndexFacets.SAT_GROUPING]: text => `SAT: ${text}`,
};

type CollegeIndexFacetsType = typeof CollegeIndexFacets[keyof typeof CollegeIndexFacets];

export const PriceFilterInputMax: Partial<Record<CollegeIndexFacetsType, number>> = {
	[CollegeIndexFacets.NET_PRICE]: 1000000,
	[CollegeIndexFacets.STICKER_PRICE]: 1000000,
	[CollegeIndexFacets.COST_PER_CREDIT_HOUR]: 1000000,
};
