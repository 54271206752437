import React, { FC } from 'react';
import { EllipsisTypography } from '@cappex/components';
import { AutomationNameDefault } from '@src/common/util/automation';
import { CampaignOfferContent } from '../constants/constants';
import { findContentValueText } from '../util/offerUtil';

type OfferCardSubHeaderProps = {
	content: CampaignOfferContent;
	automationName?: string;
};

const OfferCardSubHeader: FC<OfferCardSubHeaderProps> = ({
	content,
	automationName = AutomationNameDefault.paOfferSubHeader,
}) => {
	const text = findContentValueText(content);
	return (
		text && (
			<EllipsisTypography
				paddingRight={false}
				variant="h6"
				lines={1}
				automationName={automationName}
				gutterBottom
			>
				{text}
			</EllipsisTypography>
		)
	);
};

export default OfferCardSubHeader;
