import { validNumericRangeCriteria } from '@util/validation/numericrange';
import { validIsMultipleCriteria } from '../isMultiple';
import { isEmpty } from 'ramda';

const INCREMENT = 10;
const SAT_MIN = 400;
const SAT_MAX = 1600;
const SAT_INDIVIDUAL_MIN = 200;
const SAT_INDIVIDUAL_MAX = 800;
const PSAT_NMSQT_MIN = 320;
const PSAT_NMSQT_MAX = 1520;
const SAT_ERROR_MESSAGE = `Must be an increment of ${INCREMENT} between ${SAT_MIN} and ${SAT_MAX}`;
const SAT_INDIVIDUAL_ERROR_MESSAGE = `Must be an increment of ${INCREMENT} between ${SAT_INDIVIDUAL_MIN} and ${SAT_INDIVIDUAL_MAX}`;
const PSAT_NMSQT_ERROR_MESSAGE = `Must be an increment of ${INCREMENT} between ${PSAT_NMSQT_MIN} and ${PSAT_NMSQT_MAX}`;

const validSatCriteria = (value: string): string => {
	return isEmpty(validNumericRangeCriteria(value, SAT_MIN, SAT_MAX)) &&
		isEmpty(validIsMultipleCriteria(value, INCREMENT))
		? ''
		: SAT_ERROR_MESSAGE;
};

const validIndividualSatCriteria = (value: string): string => {
	return isEmpty(validNumericRangeCriteria(value, SAT_INDIVIDUAL_MIN, SAT_INDIVIDUAL_MAX)) &&
		isEmpty(validIsMultipleCriteria(value, INCREMENT))
		? ''
		: SAT_INDIVIDUAL_ERROR_MESSAGE;
};

const validPsatNmsqtCriteria = (value: string): string => {
	return isEmpty(validNumericRangeCriteria(value, PSAT_NMSQT_MIN, PSAT_NMSQT_MAX)) &&
		isEmpty(validIsMultipleCriteria(value, INCREMENT))
		? ''
		: PSAT_NMSQT_ERROR_MESSAGE;
};

export {
	SAT_MIN,
	SAT_MAX,
	SAT_INDIVIDUAL_MIN,
	SAT_INDIVIDUAL_MAX,
	PSAT_NMSQT_MIN,
	PSAT_NMSQT_MAX,
	// validation criteria - to be used in components.
	validSatCriteria,
	validIndividualSatCriteria,
	validPsatNmsqtCriteria,
};
