import { StudentType, LevelOfDegreeId } from '@util/student/studentDataUtil';

import { StudentView } from '..';
import * as R from 'ramda';

type DecideStudentTypeProps = {
	studentTypeId: StudentType;
	levelOfDegreeSeekingId: LevelOfDegreeId;
};
const decideStudentType = (
	{ studentTypeId, levelOfDegreeSeekingId }: DecideStudentTypeProps,
	allowedViews = [StudentView.GRADUATE, StudentView.ADULT_COMPLETER, StudentView.UNDERGRADUATE]
): StudentView => {
	if (allowedViews.length === 1) {
		return allowedViews[0];
	}

	if (
		R.includes(levelOfDegreeSeekingId, [LevelOfDegreeId.DOCTORATE, LevelOfDegreeId.MASTERS]) &&
		R.includes(studentTypeId, [StudentType.NOT_IN_SCHOOL, StudentType.COLLEGE]) &&
		R.includes(StudentView.GRADUATE, allowedViews)
	) {
		return StudentView.GRADUATE;
	}
	if (
		studentTypeId === StudentType.NOT_IN_SCHOOL &&
		R.includes(StudentView.ADULT_COMPLETER, allowedViews)
	) {
		return StudentView.ADULT_COMPLETER;
	}
	return StudentView.UNDERGRADUATE;
};

export default decideStudentType;
