import React, { FC, useMemo } from 'react';
import { CampaignOfferContent } from '@src/features/offer/constants/constants';
import OfferBaseFormInput from '@src/features/offer/components/inputs/OfferBaseFormInput';
import {
	findContentValueText,
	findRequiredValidation,
	getValidationCriteriaForCampaignOfferContentValidation,
} from '@src/features/offer/util/offerUtil';

export type OfferCardBaseInputType = {
	content: CampaignOfferContent;
	automationName: string;
};

const OfferCardTextInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const contentId = `${content.id}`;
	const label = findContentValueText(content);
	const { campaignOfferContentValidations } = content;
	const required = findRequiredValidation(campaignOfferContentValidations);
	const validCriteria = useMemo(
		() => getValidationCriteriaForCampaignOfferContentValidation(campaignOfferContentValidations),
		[campaignOfferContentValidations]
	);
	return (
		<OfferBaseFormInput
			id={contentId}
			name={contentId}
			automationName={automationName}
			label={label}
			required={required}
			validCriteria={validCriteria}
		/>
	);
};

export default OfferCardTextInput;
