import illustration from '@common/assets/Chances.svg';
import { includedInStepCount, name } from '@src/common/util/steps/constants';
import { DataFlowConfig } from '../../dataflow/constants/types';
import { TextLinkEnum } from '@src/common/components/LegalTextAndLinks';
import { AutomationNameGeneric } from '@src/common/util/automation';
import { DataFlowStepName } from '@cappex/constants';

export const createChancesConfig = (collegeName?: string): DataFlowConfig => ({
	key: 'registration__chances',
	canStartMidway: false,
	steps: [
		{
			key: DataFlowStepName.ChancesLandingName,
			sortOrder: 0,
			data: {
				[includedInStepCount]: false,
				[name]: 'landing',
				leftMedia: {
					media: illustration,
				},
				rightMedia: {
					headerVariant: 'h5',
					header: 'Calculate your chances of getting into any college',
					textVariant: 'body1',
					text:
						'Find out how your scores stack up and if the college is a reach, target or likely school.',
				},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.ChancesCollegeInfoName,
			sortOrder: 1,
			data: {
				[includedInStepCount]: true,
				[name]: 'college-info',
				leftMedia: {
					media: illustration,
					header: `Can you get in to ${collegeName}?`,
					headerVariant: 'h6',
				},
				rightMedia: {
					text: 'Tell us a bit about your academics.',
					textVariant: 'body1',
				},
				showLeftTextMediaMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.ChancesAdditionalCollegesName,
			sortOrder: 2,
			data: {
				[includedInStepCount]: true,
				[name]: 'additional-colleges',
				leftMedia: {
					media: illustration,
					header: `Can you get in to ${collegeName}?`,
					headerVariant: 'h6',
				},
				rightMedia: {
					text:
						"Let us know what other colleges you're interested in. These will be added to your list where you can see your chances.",
					textVariant: 'body1',
				},
				showLeftTextMediaMobile: true,
				hideLeftMediaImageMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.ChancesPersonalInfoName,
			sortOrder: 3,
			data: {
				[includedInStepCount]: true,
				[name]: 'personal-info',
				leftMedia: {
					media: illustration,
					header: `Can you get in to ${collegeName}?`,
					headerVariant: 'h6',
				},
				rightMedia: {
					text: 'Last step to see your acceptance chances!',
					textVariant: 'body1',
				},
				showLeftTextMediaMobile: true,
				hideLeftMediaImageMobile: true,
				showRightTextMedia: true,
				isAllowedUnauthentic: true,
			},
		},
		{
			key: DataFlowStepName.ScholarshipParentData,
			sortOrder: 4,
			data: {
				[includedInStepCount]: false,
				[name]: 'parent',
				leftMedia: {
					media: illustration,
				},
				rightMedia: {
					headerVariant: 'h5',
					header: 'Increase your chances of getting into your dream school!',
					text: `Appily will provide your parents with opportunities to help increase your chances of being accepted into your dream school.`,
				},
				isAllowedUnauthentic: false,
				showRightTextMedia: true,
				targetWhenUnauthentic: '/login',
				legalTextConfig: [
					{
						type: TextLinkEnum.TEXT,
						text:
							'No purchase necessary to enter or win. US residents enrolling in qualified post secondary institutions only. See ',
					},
					{
						type: TextLinkEnum.LINK,
						text: 'Official Scholarship Rules.',
						href: 'https://www.appily.com/official-scholarship-rules',
						qa: `${AutomationNameGeneric.link}-scholarship-rules`,
					},
				],
			},
		},
		{
			key: DataFlowStepName.Finalize,
			sortOrder: 5,
			data: {
				[includedInStepCount]: false,
				[name]: 'finalize',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Consent,
			sortOrder: 6,
			data: {
				[includedInStepCount]: false,
				[name]: 'consent',
				pseudo: true,
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
		{
			key: DataFlowStepName.Redirect,
			sortOrder: 7,
			data: {
				[includedInStepCount]: false,
				[name]: 'redirect',
				pseudo: true,
				// @ts-ignore
				to: { pathname: '/dashboard', search: '?path=chances' },
				isAllowedUnauthentic: false,
				targetWhenUnauthentic: '/login',
			},
		},
	],
});

export default createChancesConfig();
