import React, { FC, useMemo } from 'react';
import {
	findContentValueText,
	findRequiredValidation,
	getValidationCriteriaForCampaignOfferContentValidation,
} from '@src/features/offer/util/offerUtil';
import OfferBaseFormNameInput from '@src/features/offer/components/inputs/OfferBaseFormNameInput';
import { OfferCardBaseInputType } from '@src/features/offer/components/OfferCardTextInput';

const OfferCardNameInput: FC<OfferCardBaseInputType> = ({ content, automationName }) => {
	const contentId = `${content.id}`;
	const label = findContentValueText(content);
	const { campaignOfferContentValidations } = content;
	const required = findRequiredValidation(campaignOfferContentValidations);
	const validCriteria = useMemo(
		() => getValidationCriteriaForCampaignOfferContentValidation(campaignOfferContentValidations),
		[campaignOfferContentValidations]
	);
	return (
		<OfferBaseFormNameInput
			id={contentId}
			name={contentId}
			automationName={automationName}
			label={label}
			required={required}
			validCriteria={validCriteria}
		/>
	);
};

export default OfferCardNameInput;
