import * as R from 'ramda';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
	StudentCollegeConnection,
	StudentCollegeListTrackingData,
	StudentCollegeListTrackingType,
} from '../studentcollege/constants';
import StudentCollegeListContext from '@util/studentcollege/StudentCollegeListContext';

const createSave = ({ collegeId, trackingData, addListItem, additionalTracking }) => (): void => {
	let updatedTrackingData = { ...trackingData };
	const specificCollegeTracking = additionalTracking?.find(info => info.collegeId === collegeId);

	if (!R.isNil(additionalTracking) && !R.isNil(specificCollegeTracking)) {
		updatedTrackingData = {
			[StudentCollegeListTrackingType.RECOMMENDATION_VERSION]: specificCollegeTracking.recVersion,
			...updatedTrackingData,
		};
	}

	addListItem({ collegeId, trackingData: updatedTrackingData });
};

const createUnsave = ({ id, removeListItem }) => (): void => {
	removeListItem(id);
};

type SelectedCollegeType = {
	collegeId: number;
	selected: boolean;
	save: () => void;
	unsave: () => void;
};

type PickStudentCollegeConnection = Partial<Pick<StudentCollegeConnection, 'id'>> &
	Pick<StudentCollegeConnection, 'collegeId'>;

/**
 * Get status of one or more colleges (active or not), and functions to save or unsave that college.
 * Depends on Student College List Context and Snackbar Context.
 * @param ids The Selected college Ids to check if saved or archived, and to create save/unsave actions for
 * @param trackingData associated tracking data for inquiry
 */

type AdditionalTracking = {
	collegeId: number;
	recVersion?: string;
};

const useSelectedColleges = (
	ids: number[],
	trackingData: StudentCollegeListTrackingData,
	additionalTracking?: AdditionalTracking[]
): {
	[key: string]: SelectedCollegeType;
} => {
	const { list, addListItem, removeListItem } = useContext(StudentCollegeListContext);
	const [selectedColleges, setSelectedColleges] = useState<PickStudentCollegeConnection[]>([]);

	useEffect(() => {
		setSelectedColleges(
			R.map((collegeId: number): PickStudentCollegeConnection => {
				const college = R.find<Record<'collegeId' | 'id', number>>(
					R.propEq('collegeId', collegeId),
					list
				);

				return {
					id: R.prop('id', college),
					collegeId,
				};
			}, ids)
		);
	}, [list, ids]);
	// List of collegeIds to List of College Connections

	const connections: {
		[key: string]: SelectedCollegeType;
	} = useMemo(
		() =>
			R.pipe(
				R.map(
					({ id, collegeId }): SelectedCollegeType => ({
						collegeId,
						selected: !R.isNil(id),
						save: createSave({
							collegeId,
							addListItem,
							trackingData,
							additionalTracking,
						}),
						unsave: createUnsave({
							id,
							removeListItem,
						}),
					})
				),
				R.indexBy(R.pipe(R.prop('collegeId'), R.toString))
			)(selectedColleges),
		[selectedColleges, addListItem, trackingData, removeListItem, additionalTracking]
	);
	return connections;
};
export default useSelectedColleges;
