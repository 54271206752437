import {
	CampaignOfferContent,
	CampaignOfferContentIdToValues,
	CampaignOfferContentToContent,
	CampaignOfferContentType,
	CampaignOfferContentValidation,
	CampaignOfferContentValueAction,
	CampaignOfferContentValueActionType,
	CampaignOfferValidationType,
	offerCountryCodeNamePrefix,
	offerPhoneNamePrefix,
	Placement,
	regPathToRegInterstitialPlacement,
} from '@src/features/offer/constants/constants';
import * as R from 'ramda';
import { FormFields, FormValue } from '@util/validation/form';
import { KeyedData } from '@cappex/request';
import { nameValidCriteria } from '@common/components/NameInput';
import { Criteria } from '@util/validation/constants';
import { validEmailCriteria } from '@util/validation/email';

// taken from https://github.com/ramda/ramda/wiki/Cookbook#rename-key-of-an-object
export const renameKey = R.curry((oldKey, newKey, obj) =>
	R.assoc(newKey, R.prop(oldKey, obj), R.dissoc(oldKey, obj))
);
export const sortByPosition = R.sortBy(R.prop('position'));
export const groupByContentDisplayType = R.groupBy(
	(campaignOfferContent: CampaignOfferContent) =>
		campaignOfferContent.campaignOfferContentDisplayType
);

export const mapOfferContentTypeToOfferContent = (
	campaignOfferContent: CampaignOfferContent[]
): CampaignOfferContentToContent => {
	if (R.isNil(campaignOfferContent) || R.isEmpty(campaignOfferContent)) {
		return {} as CampaignOfferContentToContent;
	}

	return campaignOfferContent.reduce((acc, content) => {
		acc[content.campaignOfferContentType] = content;
		return acc;
	}, {} as CampaignOfferContentToContent);
};

export const findContentByType = (
	contentList: CampaignOfferContent[],
	contentType: CampaignOfferContentType
): CampaignOfferContent =>
	R.isEmpty(contentList)
		? undefined
		: contentList.find(content => content.campaignOfferContentType === contentType);

export const findContentValueText = (campaignOfferContent: CampaignOfferContent): string =>
	campaignOfferContent &&
	campaignOfferContent.campaignOfferContentValue &&
	campaignOfferContent.campaignOfferContentValue[0]
		? campaignOfferContent.campaignOfferContentValue[0].text
		: '';

export const findContentValueTextByType = (
	contentList: CampaignOfferContent[],
	contentType: CampaignOfferContentType
): string => findContentValueText(findContentByType(contentList, contentType));

export const findContentValueValue = (campaignOfferContent: CampaignOfferContent): string =>
	campaignOfferContent &&
	campaignOfferContent.campaignOfferContentValue &&
	campaignOfferContent.campaignOfferContentValue[0]
		? campaignOfferContent.campaignOfferContentValue[0].value
		: '';

export const findButtonAction = (
	buttonCampaignOfferContent: CampaignOfferContent
): CampaignOfferContentValueAction => {
	if (
		R.isNil(buttonCampaignOfferContent) ||
		R.isEmpty(buttonCampaignOfferContent.campaignOfferContentValue) ||
		R.isNil(buttonCampaignOfferContent.campaignOfferContentValue[0]) ||
		R.isEmpty(buttonCampaignOfferContent.campaignOfferContentValue[0].offerContentValueActions)
	) {
		return undefined;
	}

	return buttonCampaignOfferContent.campaignOfferContentValue[0].offerContentValueActions[0];
};

export const findButtonActionType = (
	buttonCampaignOfferContent: CampaignOfferContent
): CampaignOfferContentValueActionType => {
	const campaignOfferContentValueAction = findButtonAction(buttonCampaignOfferContent);

	return R.isNil(campaignOfferContentValueAction)
		? undefined
		: campaignOfferContentValueAction.campaignOfferContentValueActionType;
};

export const findValidationType = (
	validationType: CampaignOfferValidationType,
	validations?: CampaignOfferContentValidation[]
): boolean =>
	!!(validations || []).find(it => validationType === it.campaignOfferContentValidationType);

export const findRequiredValidation = (validations?: CampaignOfferContentValidation[]): boolean =>
	findValidationType(CampaignOfferValidationType.REQUIRED, validations);

export const getValidationCriteriaForCampaignOfferContentValidation = (
	validations?: CampaignOfferContentValidation[]
): Criteria[] => {
	if (R.isNil(validations) || R.isEmpty(validations)) {
		return [];
	}

	const validCriteria = [];
	validations.forEach(validation => {
		switch (validation.campaignOfferContentValidationType) {
			case CampaignOfferValidationType.NO_NUMERIC:
				validCriteria.push(nameValidCriteria);
				break;
			case CampaignOfferValidationType.EMAIL:
				validCriteria.push(validEmailCriteria);
				break;
			case CampaignOfferValidationType.PHONE_NUMBER: // phone number validation is applied automatically on the Phone Num field
			case CampaignOfferValidationType.REQUIRED: // required is applied as a separate prop
				break;
			default:
				break;
		}
	});

	return validCriteria;
};

const handleAddingToPhoneContentIdToValuesReference = (
	phoneContentIdToValues: Record<string, any>,
	phoneContentId: string,
	phonePrefixKey: string,
	value: FormValue
) => {
	Object.assign(phoneContentIdToValues, {
		...phoneContentIdToValues,
		[phoneContentId]: {
			...phoneContentIdToValues[phoneContentId],
			[phonePrefixKey]: value,
		},
	});
};

export const buildCampaignOfferContentIdToValuesFromFormValues = (
	formValues: FormFields
): CampaignOfferContentIdToValues => {
	if (R.isNil(formValues)) {
		return formValues;
	}

	// hold ref to each phoneContentId that contains
	// code: value, phone: value - using the field prefixes as keys
	const phoneContentIdToValues = {};
	const returnObject = {} as CampaignOfferContentIdToValues;

	R.forEachObjIndexed((value, field) => {
		if (field.startsWith(offerCountryCodeNamePrefix)) {
			// assign phoneCode to value as {phoneContentId: {"countryCode-": 'valueOfCode'}}
			handleAddingToPhoneContentIdToValuesReference(
				phoneContentIdToValues,
				field.replace(offerCountryCodeNamePrefix, ''),
				offerCountryCodeNamePrefix,
				value
			);
		} else if (field.startsWith(offerPhoneNamePrefix)) {
			// assign phone to value as {phoneContentId: {"phone--": 'valueOfPhone'}}
			handleAddingToPhoneContentIdToValuesReference(
				phoneContentIdToValues,
				field.replace(offerPhoneNamePrefix, ''),
				offerPhoneNamePrefix,
				value
			);
		} else {
			const valueArray: string[] = R.is(Array, value) ? (value as string[]) : ([value] as string[]);
			returnObject[field] = valueArray.filter(val => !R.isNil(val));
		}
	}, formValues);

	if (!R.isEmpty(phoneContentIdToValues)) {
		R.forEachObjIndexed((value, contentId) => {
			// check if we have phone num value
			const fullPhoneValueArray = [];
			if (!R.isNil(value[offerPhoneNamePrefix]) && !R.isEmpty(value[offerPhoneNamePrefix])) {
				// space between country and phone
				fullPhoneValueArray.push(
					R.concat(value[offerCountryCodeNamePrefix] || '', ` ${value[offerPhoneNamePrefix]}`)
				);
			}
			returnObject[contentId] = fullPhoneValueArray;
		}, phoneContentIdToValues);
	}

	return returnObject;
};

export const buildValidationErrorFieldNames = (
	validationErrors: KeyedData<string>,
	currentFormValues: FormFields
): KeyedData<string> => {
	if (R.isNil(validationErrors) || R.isNil(currentFormValues)) {
		return validationErrors;
	}

	// Need to handle phone input 'field'
	let mappedValidationErrors = validationErrors;
	const phoneContentIds = [];

	// go over currentFormValues to find phone contentId
	R.forEachObjIndexed((_, field) => {
		if (field.startsWith(offerPhoneNamePrefix)) {
			phoneContentIds.push(field.replace(offerPhoneNamePrefix, '')); // find phoneName content Id
		}
	}, currentFormValues);

	if (!R.isEmpty(phoneContentIds)) {
		phoneContentIds
			.filter(id => !R.isNil(id))
			.forEach(phoneContentId => {
				// map to correct phone field
				mappedValidationErrors = renameKey(
					phoneContentId,
					`${offerPhoneNamePrefix}${phoneContentId}`,
					mappedValidationErrors
				);
			});
	}

	return mappedValidationErrors;
};

export const getRegistrationInterstitialPlacementByPathname = (pathname: string): Placement => {
	const defaultPlacement = Placement.REGISTRATION_INTERSTITIAL__MAIN;
	if (R.isNil(pathname)) {
		return defaultPlacement;
	}

	const regPath = R.keys(regPathToRegInterstitialPlacement).find(path => pathname.includes(path));

	if (R.isNil(regPath)) {
		return defaultPlacement;
	}

	return regPathToRegInterstitialPlacement[regPath];
};
