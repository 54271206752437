import CssHideWhen from '@src/common/components/CssHideWhen';
import React, { useContext, useState, useCallback, useMemo } from 'react';
import {
	Step,
	stepToComponentMapping,
	StepConfiguration,
	FlowComponent,
	RoutingFlowComponent,
	ReconfigureArgs,
} from '..';
import useDerivedStateFromPropsWithMap from '../../hooks/useDerivedStateFromPropsWithMap';
import { FormContext } from '../../validation/form';
import { includedInStepCount, name } from '../constants';
import * as R from 'ramda';
import evaluatePredicate from '../../form/evaluatePredicate';
import HiddenInput from '@src/common/components/HiddenInput';
import { DataFlowType, FormNames, ListFormNames } from '@cappex/constants';
import usePreFillForm from '../../jit/usePreFillForm';
import CustomMapping from '@common/components/CustomMapping';

const StateBasedOneStepAtATimeFactory = <
	T extends Step<any, any & { [name]: string; [includedInStepCount]: boolean; pseudo?: boolean }>
>(
	stepToComponent: stepToComponentMapping<T>,
	onLastStep?: () => void
): FlowComponent<T> => {
	const StateBasedOneStepAtATimeFlow: RoutingFlowComponent<T> = ({
		initialId,
		initialConfig,
		initialDataFlowType,
	}) => {
		const initialConsentsValue = useMemo(() => [], []);
		const { formState } = useContext(FormContext);
		const [config, setConfig] = useDerivedStateFromPropsWithMap(initialConfig);
		const [currentStep, setCurrentStep] = useState(0);
		const [id, setId] = useState(initialId);
		const [dataFlowCode, dataFlowVersion] = useMemo(() => id.split('.'), [id]);
		const [dataFlowType, setDataFlowType] = useState<DataFlowType>(initialDataFlowType);

		usePreFillForm();

		const limitStep = useMemo(() => R.compose(R.max(0), R.min(config.steps.length - 1)), [
			config.steps,
		]);

		const getStepPredicate = useCallback(
			(step: number) => R.path([step, 'data', 'predicate'], config.steps),
			[config.steps]
		);

		const changeStepWithReconfigure = useCallback(
			(by: number) => (props: ReconfigureArgs<StepConfiguration<T>>) => {
				let newStep = limitStep(currentStep + by);
				const lastStep = config.steps.length - 1;
				if (currentStep === lastStep) {
					onLastStep?.();
					newStep = 0;
				}
				// skip steps where predicate exists and evaluates to false
				while (
					getStepPredicate(newStep) &&
					!evaluatePredicate(getStepPredicate(newStep))(formState)
				) {
					newStep += by;
				}

				if (props?.newId) {
					setId(props.newId);
				}

				if (props?.dataFlowType) {
					setDataFlowType(props.dataFlowType);
				}

				if (props && props.newConfig) {
					setConfig(props.newConfig);
					setCurrentStep(props.newStep || newStep);
				} else {
					setCurrentStep(newStep);
				}
			},
			[currentStep, setConfig, formState, limitStep, getStepPredicate, config.steps.length]
		);

		const complete = useMemo(() => changeStepWithReconfigure(1), [changeStepWithReconfigure]);
		const reverse = useMemo(() => changeStepWithReconfigure(-1), [changeStepWithReconfigure]);

		const BoundComponents = useMemo(
			() =>
				config.steps.map((step: T, idx: number) => {
					const Component = stepToComponent(step);
					return (
						(!step.data.pseudo || idx === currentStep) && (
							<CssHideWhen key={`${step.key}-${step.sortOrder}-hide`} when={idx !== currentStep}>
								<Component
									key={`${step.key}-${step.sortOrder}`}
									data={step.data}
									active={idx === currentStep}
									complete={complete}
									reverse={reverse}
									customLogoUrl={config.customLogoUrl}
									dataFlowType={dataFlowType}
									saveResults={config.saveResults}
								/>
							</CssHideWhen>
						)
					);
				}),
			[
				config.steps,
				config.customLogoUrl,
				config.saveResults,
				currentStep,
				complete,
				reverse,
				dataFlowType,
			]
		);

		return (
			<div>
				<HiddenInput
					name={FormNames.dataFlowCode}
					id="dataFlowCode"
					initialValue={dataFlowCode}
					automationName="hidden-input"
				/>
				<HiddenInput
					name={FormNames.dataFlowVersion}
					id="dataFlowVersion"
					initialValue={dataFlowVersion}
					automationName="hidden-input"
				/>
				<HiddenInput
					name={ListFormNames.consents}
					id="consents"
					initialValue={initialConsentsValue}
					automationName="hidden-input"
				/>
				<CustomMapping config={config}/>
				{BoundComponents}
			</div>
		);
	};
	return StateBasedOneStepAtATimeFlow;
};
export default StateBasedOneStepAtATimeFactory;
