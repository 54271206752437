import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import YesNoSelectorInput, { YesNoSelection } from '@src/common/components/YesNoSelectorInput';

type EnrolledProps = Omit<Partial<BaseFormSelectProps>, 'references'>;

const Enrolled: FC<EnrolledProps> = ({ required }) => (
	<YesNoSelectorInput
		title=""
		name={FormNames.enrolled}
		defaultValue={YesNoSelection.NO_ANSWER}
		required={required}
	/>
);

export default Enrolled;
