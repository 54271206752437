import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@cappex/theme';

interface ValidationListItemProps {
	text: string;
	iconColor: string;
	icon: IconDefinition;
	disableGutters?: boolean;
}

const ListItemIconStyled = styled(ListItemIcon)`
	font-size: 0.75rem;
	min-width: 0;
`;

const ListItemTextStyled = styled(ListItemText)`
	margin: 0 0.5rem;
`;

const ListItemStyled = (styled(ListItem)`
	padding-top: 0;
	padding-bottom: 0;
` as unknown) as typeof ListItem;

const ValidationListItem: FC<ValidationListItemProps> = ({
	text,
	iconColor,
	icon,
	disableGutters = false,
}) => (
	<ListItemStyled disableGutters={disableGutters}>
		<ListItemIconStyled>
			<FontAwesomeIcon icon={icon} color={iconColor} />
		</ListItemIconStyled>
		<ListItemTextStyled primary={text} primaryTypographyProps={{ variant: 'caption' }} />
	</ListItemStyled>
);

export default ValidationListItem;
