export enum RoleId {
	STUDENT = '1',
	PARENT = '2',
}

export enum RoleName {
	STUDENT = 'student',
	PARENT = 'parent',
}

export const roleIdToShortRoleName = (id: RoleId): RoleName => {
	switch (id) {
		case RoleId.STUDENT:
			return RoleName.STUDENT;
		case RoleId.PARENT:
			return RoleName.PARENT;
		default:
			return undefined;
	}
};
