import React, { useRef } from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@cappex/theme';
import useClampLines from '@src/common/util/hooks/useClampLines';

export type OfferCardBaseTextProps = {
	text: string;
};

const StyledParagraphTypography = styled(Typography)`
	line-height: 1.5;

	ul {
		margin-top: 0.25rem;
	}
`;

const OfferCardParagraphWithHtml: React.FC<{ text: string }> = ({ text }) => {
	const paragraphRef = useRef<HTMLDivElement>(null);
	// Using a JS solution because safari wasn't properly clamping with <ul> tags present
	const clampedText = useClampLines(text, paragraphRef, 5);

	return (
		<StyledParagraphTypography
			variant="body2"
			color="textSecondary"
			ref={paragraphRef}
			dangerouslySetInnerHTML={{ __html: clampedText }}
		/>
	);
};

export default OfferCardParagraphWithHtml;
