import React, { FC } from 'react';
import { StudentType } from '@util/student/studentDataUtil';
import StudentTypeSelect, { StudentTypeSelectProps } from '@common/components/StudentTypeSelect';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';

type RegStudentTypeSelectProp = Omit<Partial<BaseFormSelectProps>, 'references'> & {
	hideStudentTypes?: StudentType[];
};

const STUDENT_TYPE_OPTIONS = [
	{ displayValue: 'High school student', value: StudentType.HIGH_SCHOOL },
	{ displayValue: 'College student', value: StudentType.COLLEGE },
	{ displayValue: 'Not enrolled', value: StudentType.NOT_IN_SCHOOL },
];

const MATCHES_STUDENT_TYPE_CHIP_COLUMNS: StudentTypeSelectProps['chipColumns'] = {};

const RegStudentSelectType: FC<RegStudentTypeSelectProp> = ({
	label = 'Join as a',
	hideStudentTypes,
}) => {
	const filteredOptions = STUDENT_TYPE_OPTIONS.filter(
		item => !hideStudentTypes?.includes(item.value)
	);

	return (
		<StudentTypeSelect
			title={label}
			defaultStudentTypeId={filteredOptions[0]?.value}
			options={filteredOptions}
			chipColumns={MATCHES_STUDENT_TYPE_CHIP_COLUMNS}
		/>
	);
};

export default RegStudentSelectType;
