import React, { FC, useMemo } from 'react';
import { AutomationNameDefault } from '@common/util/automation';
import BaseFormInput, { BaseInputProps } from '@common/components/BaseFormInput';
import { GPA_MAX, GPA_MIN, validGpaCriteria } from '@util/validation/gpa';
import { toFixedDecimal } from '@src/common/util/string/stringUtil';
import { FormNames } from '@cappex/constants';

type UnweightedGpaInputProps = Pick<
	BaseInputProps,
	Exclude<keyof BaseInputProps, 'inputType' | 'validCriteria'>
>;

const UNWEIGHTED_GPA_CRITERIA = [validGpaCriteria];

const UnweightedGpaInput: FC<UnweightedGpaInputProps> = ({
	id = AutomationNameDefault.gpaInput,
	name = FormNames.gpaUnweighted,
	label = 'Unweighted GPA',
	disabled = false,
	onChange,
	onBlur,
	initialValue,
	fullWidth = false,
	helperText = 'Out of 4.00',
	LeadingIcon,
	TrailingIcon,
	required,
	verifiedBy,
	verifies,
	automationName = AutomationNameDefault.gpaInput,
	margin,
	variant,
}) => {
	const truncateTo2Decimals = useMemo(() => toFixedDecimal(2), []);

	return (
		<BaseFormInput
			margin={margin}
			inputType="number"
			automationName={automationName}
			id={id}
			label={label}
			inputMin={GPA_MIN}
			inputMax={GPA_MAX}
			inputStep={0.01}
			fullWidth={fullWidth}
			initialValue={initialValue}
			validCriteria={UNWEIGHTED_GPA_CRITERIA}
			name={name}
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			beforeChange={truncateTo2Decimals}
			helperText={helperText}
			LeadingIcon={LeadingIcon}
			TrailingIcon={TrailingIcon}
			required={required}
			verifiedBy={verifiedBy}
			verifies={verifies}
			variant={variant}
		/>
	);
};

export default UnweightedGpaInput;
