import DataLayerTracking from './dataLayerTracking';
import DataLayerEventTypes from './dataLayerEventTypes';

let previousDataLayerValue;

const EmailAddressDataLayer = (eventType: DataLayerEventTypes, emailAddress: string) => {
	if (previousDataLayerValue !== emailAddress) {
		previousDataLayerValue = emailAddress;
		DataLayerTracking({
			eventType,
			eventObj: { [eventType]: emailAddress },
		});
	}
};

export default EmailAddressDataLayer;
