import React, { createContext, FC, useMemo, useEffect } from 'react';
import defaultClient from 'search-insights';
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from '@cappex/search/lib/algolia/constants';
import { v4 } from 'uuid';
import * as R from 'ramda';

const EMPTY_FUNCTION = () => {};

type AlgoliaInsightsContextType = {
	client: typeof defaultClient;
};

export const AlgoliaInsightsContext = createContext<AlgoliaInsightsContextType>({
	client: EMPTY_FUNCTION,
});

const AlgoliaInsightsProvider: FC<{ client?: typeof defaultClient }> = ({
	client = defaultClient,
	children,
}) => {
	useEffect(() => {
		client('init', {
			appId: ALGOLIA_APP_ID,
			apiKey: ALGOLIA_API_KEY,
		});
		const token = client('getUserToken');
		if (R.isNil(token)) {
			client('setUserToken', v4());
		}
	}, [client]);

	const value = useMemo(() => ({ client }), [client]);

	return (
		<AlgoliaInsightsContext.Provider value={value}>{children}</AlgoliaInsightsContext.Provider>
	);
};

export default AlgoliaInsightsProvider;
