import React, { FC, ReactNode } from 'react';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { AutomationNameDefault } from '@src/common/util/automation';
import { styled } from '@cappex/theme';
import { BoldTypography } from '@src/common/components/TextCaptionItem';
import { Achievement } from '../util/achievementUtil';

type AchievementContainerProps = {
	achievement: Achievement;
};

const AchievementStyledIcon = styled.img`
	height: 100%;
	width: 100%;
`;

const AchievementIconBox = styled(Box)`
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	box-shadow: ${({ theme: { shadows } }) => shadows[8]};
`;

const ColorDiv = styled.div`
	padding: 1rem;
	color: ${props => props.theme.palette.common.white};
`;

const TextCenterTypography = styled(Typography)`
	text-align: center;
`;

// styled components needs to be updated to remove "as any"
const TextCenterBoldTypography = styled(BoldTypography as any)`
	text-align: center;
`;

const buildTooltip = (headerText: string, bodyText: string): ReactNode => (
	<ColorDiv>
		<Grid container justifyContent="center" alignItems="center">
			<Grid item>
				<TextCenterBoldTypography>{headerText}</TextCenterBoldTypography>
			</Grid>
			<Grid item>
				<TextCenterTypography variant="subtitle2">{bodyText}</TextCenterTypography>
			</Grid>
		</Grid>
	</ColorDiv>
);

const AchievementContainer: FC<AchievementContainerProps> = ({ achievement }) => (
	<Grid
		container
		direction="column"
		justifyContent="center"
		alignItems="center"
		spacing={1}
		data-qa={`${AutomationNameDefault.achievementContainer}-${achievement.title}`}
	>
		<Grid item>
			<Tooltip
				title={buildTooltip(
					achievement.title,
					achievement.active ? achievement.earnedText : achievement.unearnedText
				)}
				enterTouchDelay={0}
			>
				<AchievementIconBox>
					<AchievementStyledIcon
						src={achievement.active ? achievement.earnedAssetKey : achievement.unearnedAssetKey}
						alt=""
						data-qa={`${AutomationNameDefault.achievementIcon}-${
							achievement.active ? 'active' : 'inactive'
						}`}
					/>
				</AchievementIconBox>
			</Tooltip>
		</Grid>
		<Grid item>
			<Typography variant="subtitle2" data-qa={AutomationNameDefault.achievementTitle}>
				{achievement.title}
			</Typography>
		</Grid>
	</Grid>
);

export default AchievementContainer;
