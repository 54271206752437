import { Button, Dialog, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import useRequest from '../../../common/util/hooks/useRequest';
import { RequestMethod } from '@cappex/request';
import { ConsentResponse, ConsentType } from '@src/features/consent/consentUtil';
import styled from 'styled-components';
import { FloatingCloseButton } from '@cappex/components';
import inquiryConsentSvg from '@common/assets/inquiry-consent.svg';

const StyledDialog = styled(Dialog)({
	'& .MuiPaper-root': {
		margin: '8px',
		width: '100%',
		maxWidth: '34rem',
	},
});

const Padding = styled.div`
	padding: 3rem 1.5rem;
`;

const MaxWidthTypography = styled(Typography)`
	max-width: 28rem;
`;

const StyledButton = styled(Button)`
	padding-left: 5rem;
	padding-right: 5rem;
	border-radius: 0.75rem;
`;

interface Props {
	complete: () => void;
	consentTypeId: ConsentType;
	consentTextId: string;
	consentText: string;
}

const InquiryConsentModal: React.FC<Props> = ({
	complete,
	consentTypeId,
	consentTextId,
	consentText,
}) => {
	const body = useMemo(
		() => ({
			consents: [
				{
					consentTypeId,
					consentTextId,
					responseTypeId: ConsentResponse.YES,
				},
			],
		}),
		[consentTypeId, consentTextId]
	);

	const [saveConsent] = useRequest({
		endpoint: '/governance/consent/save',
		method: RequestMethod.POST,
		body,
	});

	useEffect(() => {
		saveConsent();
	}, [saveConsent]);

	return (
		<StyledDialog open maxWidth="sm" onClose={complete}>
			<FloatingCloseButton onClick={complete} />
			<Padding>
				<Grid container spacing={3} alignItems="center" direction="column">
					<Grid item>
						<Typography variant="h6">👋 Quick heads up!</Typography>
					</Grid>

					<Grid item>
						<MaxWidthTypography color="textSecondary" variant="body2">
							{consentText}
						</MaxWidthTypography>
					</Grid>

					<Grid item>
						<img src={inquiryConsentSvg} alt="Inquiry Consent" />
					</Grid>

					<Grid item>
						<StyledButton variant="contained" color="primary" onClick={complete}>
							Got it
						</StyledButton>
					</Grid>
				</Grid>
			</Padding>
		</StyledDialog>
	);
};

export default InquiryConsentModal;
