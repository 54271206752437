import request, { JsonContentTypeHeader, RequestMethod } from '@cappex/request';
import getEndpoint from '@util/request';
import { convertArrayNumbersToStrings } from '@src/common/util/general';
import { getApiDateString } from '@src/common/components/BirthDateInput';
import { dataOptionParams } from '../hooks/useStudentCollegePreferences';
import * as R from 'ramda';
import { useCallback, useContext, useEffect } from 'react';
import { FormContext } from '../validation/form';
import StudentContext from '../studentContext';

const removeNullValues = R.pipe(
	R.toPairs,
	R.filter(([, value]) => value !== null),
	R.fromPairs
);

const isObjectOrArray = val => R.is(Object, val) || R.is(Array, val);

const isArray = R.is(Array);

const deepRejectNull = val =>
	R.when(
		isObjectOrArray,
		R.pipe(
			R.ifElse(
				isArray,
				R.reject(R.equals(null)),
				R.pipe(R.toPairs, R.reject(R.pipe(R.last, R.equals(null))), R.fromPairs)
			),
			R.map(deepRejectNull)
		)
	)(val);

const getStudentPreferences = (studentId: string) =>
	request({
		url: getEndpoint('/student-college-preferences/v1/retrieve', [
			...dataOptionParams,
			{ name: 'studentId', value: studentId },
		]),
		method: RequestMethod.GET,
		withCredentials: true,
		headers: [JsonContentTypeHeader],
	}).then(res => res.data.response);

const replaceBoolean = (value: boolean) => (value ? 'true' : 'false');
const replaceNumber = (value?: number) => value && String(value);

const usePreFillForm = () => {
	const { setValues } = useContext(FormContext);
	const { student } = useContext(StudentContext);

	const preFillForm = useCallback(async () => {
		const { ...studentPreferences } = await getStudentPreferences(student.studentId);
		const newValues = {
			...removeNullValues(student as any),
			...convertArrayNumbersToStrings(deepRejectNull(studentPreferences as any)),
			stateId: replaceNumber(student.stateId),
			startTimeframeId: replaceNumber(student.startTimeframeId),
			levelOfDegreeSeekingId: replaceNumber(student.levelOfDegreeSeekingId),
			desiredCompletionTimeframeId: replaceNumber(student.desiredCompletionTimeframeId),
			openToTransfer: replaceBoolean(student.openToTransfer),
			firstGenerationStudent: replaceBoolean(student.firstGenerationStudent),
			interestedInFinancialAid: replaceBoolean(student.interestedInFinancialAid),
			enrolled: replaceBoolean(student.enrolled),
			seekingMba: replaceBoolean(student.seekingMba),
			seekingTestOptionalAdmissions: replaceBoolean(student.seekingTestOptionalAdmissions),
			birthDate: getApiDateString(student.birthYear, student.birthMonth, student.birthDay),
		};
		setValues(newValues);
	}, [setValues, student]);

	useEffect(() => {
		preFillForm();
	}, [preFillForm]);
};

export default usePreFillForm;
