import React, { FC } from 'react';
import BaseFormSelect, { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import useCloudReferenceData from '@util/hooks/useCloudReferenceData';
import { ReferenceDataTypes } from '@cappex/constants';
import { AutomationNameDefault } from '@util/automation';

type ClassRankSelectProps = Omit<BaseFormSelectProps, 'references'>;

const ClassRankSelect: FC<ClassRankSelectProps> = ({
	id = AutomationNameDefault.classRank,
	name,
	label = 'Class Rank',
	initialValue,
	automationName = AutomationNameDefault.classRank,
	onChange,
	required,
	addEmptyValue,
	selectMargin,
	variant,
}) => {
	const [references] = useCloudReferenceData({
		dataType: ReferenceDataTypes.classRank,
	});

	return (
		<BaseFormSelect
			id={id}
			name={name}
			label={label}
			initialValue={initialValue}
			references={references}
			automationName={automationName}
			onChange={onChange}
			required={required}
			addEmptyValue={addEmptyValue}
			selectMargin={selectMargin}
			variant={variant}
		/>
	);
};

export default ClassRankSelect;
