import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as R from 'ramda';
import React, { FC, useContext, useMemo } from 'react';
import Slider, { Settings } from 'react-slick';
import { styled, ThemeContext } from '@cappex/theme';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fab, Grid, useMediaQuery } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { Skeleton } from '@material-ui/lab';
import useSelectedColleges from '@src/common/util/hooks/useSelectedColleges';
import { AutomationNameDefault } from '@util/automation';
import {
	StudentCollegeListLocationTrackingValue,
	StudentCollegeListTrackingData,
	StudentCollegeListTrackingType,
	StudentCollegeListTypeTrackingValue,
} from '@util/studentcollege/constants';
import { CollegeRecommendationResult } from '@util/collegerecommendation/constants';
import CollegeCardComponent from './CollegeCard';

interface SliderButtonProps extends ButtonProps {
	side: 'left' | 'right';
}

const WhiteFab = styled(Fab)<SliderButtonProps>`
	&&& {
		font-size: 14px;
		height: 3rem;
		width: 3rem;
		top: 3.25rem;
		z-index: 10;
		${props => (props.side === 'left' ? 'left: -2.125rem;' : 'right: -2.25rem;')}
		box-shadow: none;
	}

	&&&:before {
		content: '';
	}
`;

const WhiteFabSkeleton = styled(({ slideCount, currentSlide, ...props }) => (
	<Skeleton {...props} />
))`
	margin: 1.5rem 1.5rem 4rem 1.5rem;
	height: 3rem;
	width: 3rem;
`;

const CARD_PADDING = '0.5rem';

// This is to "remove" the padding from the cards on the ends of the carousel so they line up with other elements on the page
const SliderWrapper = styled.div`
	margin: 0 -${CARD_PADDING};
`;

export const CarouselCardWrapper = styled.div`
	margin: 0 ${CARD_PADDING};
`;

const skeletonObjArray = [
	{ id: 'abc' },
	{ id: 'def' },
	{ id: 'ghi' },
	{ id: 'jkl' },
	{ id: 'mno' },
];

type ScrollButtonProps = {
	onClick?: React.MouseEventHandler<HTMLElement>;
	className?: string;
	icon: IconDefinition;
	side: 'left' | 'right';
	dataQa: string;
};

const ScrollButton: FC<ScrollButtonProps> = ({ className, icon, onClick, dataQa = '', side }) => {
	const theme = useContext(ThemeContext);
	return (
		<WhiteFab onClick={onClick} className={className} data-qa={dataQa} side={side}>
			<FontAwesomeIcon size="lg" icon={icon} color={theme.palette.common.black} />
		</WhiteFab>
	);
};

export type CollegeCardCarouselProps = {
	collegeData: CollegeRecommendationResult[];
	displayDismiss?: boolean;
	onSelectClick?: (id: number) => void;
	onDismissClick?: (id: number) => void;
	locationTrackingValue: StudentCollegeListLocationTrackingValue;
	maxCardsVisible: number;
	maxCardsInCarousel: number;
	className?: string;
};

type BuildSettingProps = {
	loading: boolean;
	isSmScreen: boolean;
	isMdScreen: boolean;
	isLgScreen: boolean;
	maxCardsVisible: number;
	numCardsInCarousel: number;
};

const buildSettings = ({
	loading,
	isSmScreen,
	isMdScreen,
	isLgScreen,
	maxCardsVisible,
	numCardsInCarousel,
}: BuildSettingProps) => {
	const settings: Settings = {
		dots: false,
		className: '',
		speed: 500,
		arrows: true,
		slidesToShow: maxCardsVisible,
		slidesToScroll: 1,
		prevArrow: loading ? (
			<WhiteFabSkeleton
				variant="circle"
				data-qa={AutomationNameDefault.collegeCarouselLeftArrowSkeleton}
			/>
		) : (
			<ScrollButton
				icon={faChevronLeft}
				side="left"
				dataQa={AutomationNameDefault.collegeCarouselLeftArrow}
			/>
		),
		nextArrow: loading ? (
			<WhiteFabSkeleton
				variant="circle"
				data-qa={AutomationNameDefault.collegeCarouselRightArrowSkeleton}
			/>
		) : (
			<ScrollButton
				icon={faChevronRight}
				side="right"
				dataQa={AutomationNameDefault.collegeCarouselRightArrow}
			/>
		),
		centerMode: false,
		centerPadding: '',
	};

	// Set per screen size override options
	if (isSmScreen) {
		settings.slidesToShow = 1;
		settings.centerMode = true;
		settings.className = 'center';
		settings.centerPadding = '20%';
		settings.arrows = false;
	} else if (isMdScreen) {
		settings.slidesToShow = R.max(maxCardsVisible - 2, 1);
	} else if (isLgScreen) {
		settings.slidesToShow = R.max(maxCardsVisible - 1, 1);
	}

	// react-slick will duplicate slides (and screw up the carousel) in infinte mode if the amount to show is greater than the number of cards
	settings.infinite = settings.slidesToShow <= numCardsInCarousel;

	return settings;
};

const emptyArray = [];

const getTrackingData = (
	locationTrackingValue: StudentCollegeListLocationTrackingValue
): StudentCollegeListTrackingData => ({
	[StudentCollegeListTrackingType.LOCATION]: locationTrackingValue,
	[StudentCollegeListTrackingType.TYPE]: StudentCollegeListTypeTrackingValue.RECOMMENDATION,
});

const CollegeCardCarousel: FC<CollegeCardCarouselProps> = ({
	collegeData,
	locationTrackingValue,
	maxCardsVisible,
	maxCardsInCarousel,
	className,
	displayDismiss = false,
	onDismissClick = () => {},
}) => {
	const collegeDataToDisplay = useMemo(
		() => collegeData && collegeData.slice(0, maxCardsInCarousel),
		[collegeData, maxCardsInCarousel]
	);

	const collegeIds = useMemo(
		() =>
			collegeDataToDisplay ? collegeDataToDisplay.map(data => Number(data.collegeId)) : emptyArray,
		[collegeDataToDisplay]
	);

	const theme = useContext(ThemeContext);

	const isSmScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const isMdScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isLgScreen = useMediaQuery(theme.breakpoints.down('lg'));

	const initialTrackingData = useMemo(() => getTrackingData(locationTrackingValue), [
		locationTrackingValue,
	]);

	let additionalTracking;

	if (!R.isNil(collegeData)) {
		additionalTracking = collegeData.map(data => ({
			collegeId: data.collegeId,
			recVersion: data.recVersion,
		}));
	}
	const collegeSaveSelect = useSelectedColleges(
		collegeIds,
		initialTrackingData,
		additionalTracking
	);

	const loading = !collegeDataToDisplay || R.isEmpty(collegeSaveSelect);

	const settings = buildSettings({
		loading,
		isSmScreen,
		isMdScreen,
		isLgScreen,
		maxCardsVisible,
		numCardsInCarousel: collegeDataToDisplay ? collegeDataToDisplay.length : 0,
	});

	const createOnDismissClick = (id: number) => () => {
		onDismissClick(id);
	};

	return (
		<SliderWrapper className={className}>
			<Slider {...settings}>
				{loading
					? skeletonObjArray.map(data => (
							<Grid
								container
								spacing={2}
								direction="column"
								key={data.id}
								data-qa={AutomationNameDefault.collegeCarouselCardSkeleton}
							>
								<Grid item xs>
									{/* card skeleton */}
									<Skeleton
										height="6.5rem"
										width="13rem"
										data-qa={AutomationNameDefault.collegeCarouselCardHeroSkeleton}
									/>
								</Grid>
								<Grid item xs>
									{/* caption skeleton */}
									<Skeleton
										height="1.5rem"
										width="11rem"
										data-qa={AutomationNameDefault.collegeCarouselCardTitleSkeleton}
									/>
								</Grid>
							</Grid>
					  ))
					: collegeDataToDisplay.map(data => {
							const { selected, save, unsave } = R.defaultTo(
								{ selected: false, save: () => {}, unsave: () => {} },
								R.prop(R.toString(data.collegeId), collegeSaveSelect)
							);

							// Do not remove the wrapping <div>. The carousel will apply styles to the element that conflict with the desired design
							return (
								<div key={data.collegeId}>
									<CarouselCardWrapper>
										<CollegeCardComponent
											collegeId={data.collegeId}
											collegeName={data.collegeName}
											collegeHeroImage={data.heroImage}
											selected={selected}
											saveCollege={save}
											unsaveCollege={unsave}
											dismissCollege={createOnDismissClick(data.collegeId)}
											displayDismiss={displayDismiss}
										/>
									</CarouselCardWrapper>
								</div>
							);
					  })}
			</Slider>
		</SliderWrapper>
	);
};

export default CollegeCardCarousel;
