import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { StepContainerProps } from '@src/common/util/steps';
import { DataFlowStepComponent } from '../../constants/types';
import RegisterButton from '@src/common/components/RegisterButton';
import {
	checkForFormError,
	FORM_NAME,
	getFormErrors,
	RequestSourceIdentifier,
} from '@cappex/request';
import DataFlowContext from '../../util/DataFlowContext';
import { FormContext } from '@src/common/util/validation/form';
import checkLockout from '@src/common/util/lockout';
import { SnackbarContext } from '@src/common/components/SnackbarManager';
import DataFlowContainer from '../DataFlowContainer';
import {
	Box,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	Grid,
	makeStyles,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LegalTextAndLinks from '@src/common/components/LegalTextAndLinks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { styled, theme } from '@cappex/theme';
import AnchorButton from '@src/common/components/AnchorButton';
import EmailInput from '@common/components/EmailInput';
import { SubForm } from '@common/components/BaseValidationForm';
import PasswordInputValidation from '@common/components/PasswordInputValidation';
import SignInWithGoogleButton from '@src/common/components/SignInWithGoogleButton';
import * as R from 'ramda';
import { FormNames, ListFormNames } from '@cappex/constants';
import HiddenInput from '@src/common/components/HiddenInput';
import {
	GOOGLE_BUTTON_FIXED_WIDTH,
	GOOGLE_BUTTON_FIXED_WIDTH_PX,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL,
	GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX,
	GOOGLE_BUTTON_SMALL_BREAKPOINT,
} from '@src/common/constants/signInWithGoogle';
import getGoogleEmail from '@src/common/util/google/getGoogleEmail';
import getGoogleConsentText, {
	GoogleSSOConsentResponse,
} from '@src/common/util/google/getGoogleConsentText';
import FeatureFlag from '@src/features/environment/components/FeatureFlag';
import AuthContext, { AuthenticState } from '@src/common/util/auth';
import { QuizContext } from '@src/common/util/quiz';
import redirectToQuizResult from '../../util/redirectToQuizResult';
import { ConsentForm, ConsentResponse, ConsentType } from '@src/features/consent/consentUtil';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	fixedWidth: {
		maxWidth: GOOGLE_BUTTON_FIXED_WIDTH,
	},
}));

const widthStyles = `
width: ${GOOGLE_BUTTON_FIXED_WIDTH};
margin: 0 auto;
@media (max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT}) {
	width: ${GOOGLE_BUTTON_FIXED_WIDTH_SMALL};
}
`;

const WidthGrid = styled(Grid)`
	${widthStyles}
`;

library.add(fal);
const PaddedBox = styled(Box)`
	padding-left: 1rem;
	padding-right: 1rem;
`;
const SpacingDiv = styled.div`
	padding-bottom: 2rem;
	text-align: center;
`;
const MarginDiv = styled.div`
	margin-top: 1rem;
`;

const EdgeTopCheckbox = styled(Checkbox)`
	margin-top: -0.5rem;
`;

const TopAlignFormControlLabel = styled(FormControlLabel)`
	align-items: flex-start;
`;

const DEFAULT_FLAGS = {
	showEmailInput: true,
	showPasswordInput: false,
	showSignInWithGoogleButton: false,
};

const emailInUseError = 'This email address is already in use';

const FlexStepAccountCreation: FC<DataFlowStepComponent<any, any> & StepContainerProps> = ({
	complete,
	active,
	data: {
		topMedia,
		backgroundMedia,
		variant,
		leftMedia,
		rightMedia,
		hideLeftMediaImageMobile,
		showLeftTextMediaMobile,
		showRightTextMedia,
		currentStep,
		totalSteps,
		buttonConfig,
		legalTextConfig,
		showAlreadyHaveAccountLink,
		flags: { showEmailInput, showPasswordInput, showSignInWithGoogleButton } = DEFAULT_FLAGS,
	},
	customLogoUrl,
	redirectIfAccountExists,
}) => {
	const [submitDisabled, setSubmitDisabled] = useState(false);
	const [consent, setConsent] = useState<GoogleSSOConsentResponse>();
	const [email, setEmail] = useState('');
	const [consentChecked, setConsentChecked] = useState(false);
	const [consentLoading, setConsentLoading] = useState(false);
	const [signInWithGoogleError, setSignInWithGoogleError] = useState('');
	const { setPreHook, setPostHook, setErrorHook } = useContext(DataFlowContext);
	const { setFormErrors, getValue, setFormValue } = useContext(FormContext);
	const { getCurrentResult } = useContext(QuizContext);
	const { openSnackbar } = useContext(SnackbarContext);
	const xxsDown = useMediaQuery(`(max-width: ${GOOGLE_BUTTON_SMALL_BREAKPOINT})`);
	const smDown = useMediaQuery(theme.breakpoints.down('sm'));
	const buttonWidth = xxsDown ? GOOGLE_BUTTON_FIXED_WIDTH_SMALL_PX : GOOGLE_BUTTON_FIXED_WIDTH_PX;
	const classes = useStyles();
	const navigate = useNavigate();
	const navigationType = useNavigationType();
	const location = useLocation();

	const { isAuthentic } = useContext(AuthContext);

	const leftMediaProp = R.set(
		R.lensPath(['textProps', 'className']),
		classes.fixedWidth,
		leftMedia
	);

	const onSignInWithGoogle = useCallback(
		async (credentials: string) => {
			setSignInWithGoogleError('');
			setSubmitDisabled(true);
			setConsentLoading(true);

			const googleEmail = await getGoogleEmail(credentials, err => {
				setSubmitDisabled(false);
				setConsentLoading(false);
				setSignInWithGoogleError(err);
			});
			if (!googleEmail) return;
			setEmail(googleEmail);

			const googleConsentText = await getGoogleConsentText(err => {
				setSubmitDisabled(false);
				setConsentLoading(false);
				setSignInWithGoogleError(err);
			});
			if (!googleConsentText) return;
			setConsent(googleConsentText);
			navigate(location.pathname);

			setSubmitDisabled(false);
			setConsentLoading(false);
		},
		[navigate, location.pathname]
	);

	useEffect(() => {
		if (redirectIfAccountExists && signInWithGoogleError === emailInUseError) {
			redirectToQuizResult(getCurrentResult);
		}
	}, [getCurrentResult, redirectIfAccountExists, signInWithGoogleError]);

	const handleConsentChecked = (val: boolean) => {
		setConsentChecked(val);
		const currentConsents = (getValue(FormNames.email) as { consents: ConsentForm[] }).consents;
		const newConsent = {
			consentTypeId: ConsentType.GOOGLE_SSO,
			consentTextId: consent.id,
			responseTypeId: val ? ConsentResponse.YES : ConsentResponse.NO_RESPONSE,
		};
		if (currentConsents !== undefined && currentConsents.length > 0) {
			const oldConsent = R.find(R.propEq('consentTextId', consent.id), currentConsents);

			if (oldConsent !== undefined) {
				const index = R.indexOf(oldConsent, currentConsents);
				const newConsents = [...currentConsents];
				newConsents[index] = newConsent;
				setFormValue(ListFormNames.consents, newConsents);
			} else {
				const newConsents = [...currentConsents, newConsent];
				setFormValue(ListFormNames.consents, newConsents);
			}
		} else {
			setFormValue(ListFormNames.consents, [newConsent]);
		}
	};

	const onClick = () => {
		setPreHook(() => () => {
			setSubmitDisabled(true);
		});

		setPostHook(() => data => {
			if (data.meta.success) {
				complete();
				setSubmitDisabled(false);
			} else {
				throw data;
			}
		});

		setErrorHook(() => err => {
			setSubmitDisabled(false);
			let data;
			if (err.response && err.response.source === RequestSourceIdentifier) {
				// If this is coming from the request util
				data = { meta: { success: false } };
			} else {
				data = err;
			}

			const lockedOut = checkLockout(data);
			if (!lockedOut) {
				setSubmitDisabled(false);
				const errors = getFormErrors(data);

				const emailAlreadyInUse = errors?.email?.[0] === emailInUseError;
				if (redirectIfAccountExists && emailAlreadyInUse) {
					redirectToQuizResult(getCurrentResult);
					return;
				}

				setFormErrors(errors);

				if (checkForFormError(errors)) {
					openSnackbar({
						message: errors[FORM_NAME],
					});
				}
			}
		});
		return true;
	};

	useEffect(() => {
		if (
			(isAuthentic === AuthenticState.Authentic || isAuthentic === AuthenticState.Unknown) &&
			active
		) {
			complete();
			return;
		}

		if (navigationType === 'POP' && consent) {
			setConsent(undefined);
			setEmail('');
		}
	}, [location, navigationType, consent, isAuthentic, active, complete]);

	return (
		<DataFlowContainer
			topMedia={topMedia}
			backgroundMedia={backgroundMedia}
			variant={variant}
			leftMedia={leftMediaProp}
			rightMedia={rightMedia}
			hideLeftMediaImageMobile={hideLeftMediaImageMobile}
			showLeftTextMediaMobile={showLeftTextMediaMobile}
			showRightTextMedia={showRightTextMedia}
			currentStep={currentStep}
			totalSteps={totalSteps}
			centered={smDown}
			customLogoUrl={customLogoUrl}
		>
			{consent && (
				<>
					{/* hidden input sets the email obtained from Google, can't use setFormValue because it makes the Google button rerender every formState change */}
					<SubForm name="accountInfo">
						<HiddenInput name={FormNames.email} initialValue={email} />
					</SubForm>
					<WidthGrid>
						<TopAlignFormControlLabel
							control={
								<EdgeTopCheckbox
									checked={consentChecked}
									onChange={(_, val) => handleConsentChecked(val)}
								/>
							}
							label={consent.text}
						/>
					</WidthGrid>
				</>
			)}
			{!consent && !consentLoading && (
				<WidthGrid container direction="column" spacing={3}>
					<SubForm name="accountInfo">
						{showEmailInput && <EmailInput disabled={!active} required={active} />}
						{showPasswordInput && <PasswordInputValidation disabled={!active} required={active} />}
					</SubForm>
				</WidthGrid>
			)}
			{consentLoading && (
				<WidthGrid container justifyContent="center">
					<SpacingDiv>
						<CircularProgress size={55} />
					</SpacingDiv>
				</WidthGrid>
			)}
			<WidthGrid>
				<RegisterButton
					md={12}
					submitDisabled={submitDisabled || (consent && !consentChecked)}
					onClick={onClick}
					size="large"
					smallGutter
				>
					{buttonConfig?.text ?? 'Next'}
					{buttonConfig?.icon && (
						<PaddedBox>
							<FontAwesomeIcon icon={['fal', buttonConfig.icon]} />
						</PaddedBox>
					)}
				</RegisterButton>
			</WidthGrid>

			{showSignInWithGoogleButton && !consent && (
				<FeatureFlag flag="enableGoogleSSO">
					<Grid container direction="column" alignItems="center">
						<Typography variant="body2" color="textSecondary" align="center">
							OR
						</Typography>
						<MarginDiv>
							<SignInWithGoogleButton
								text="signup_with"
								width={buttonWidth}
								onSignIn={onSignInWithGoogle}
							/>
							{signInWithGoogleError &&
								(!redirectIfAccountExists || signInWithGoogleError !== emailInUseError) && (
									<Typography variant="caption" color="error">
										{signInWithGoogleError}
									</Typography>
								)}
						</MarginDiv>
					</Grid>
				</FeatureFlag>
			)}
			{(legalTextConfig || showAlreadyHaveAccountLink) && (
				<WidthGrid>
					<SpacingDiv>
						{legalTextConfig && (
							<MarginDiv>
								<LegalTextAndLinks configs={legalTextConfig} />
							</MarginDiv>
						)}
						{showAlreadyHaveAccountLink && (
							<MarginDiv>
								<AnchorButton text="Already have an account?" href="/login" />
							</MarginDiv>
						)}
					</SpacingDiv>
				</WidthGrid>
			)}
		</DataFlowContainer>
	);
};

export default FlexStepAccountCreation;
