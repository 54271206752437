import * as R from 'ramda';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, PropTypes, TextFieldProps, Typography } from '@material-ui/core';
import { FormNames } from '@cappex/constants';
import { FormContext } from '../util/validation/form';
import ActInput from './ActInput';
import SatInput from './SatInput';
import requiredFieldMessage from '../util/validation/constants';
import PreTestScoresInput from './PreTestScoresInput';

type CompositeTestScoresProps = {
	centered?: boolean;
	singleColumnStyle?: boolean;
	inputMargin?: PropTypes.Margin;
	actLabel?: string;
	satLabel?: string;
	label?: string;
	subText?: string;
	required?: boolean;
	variant?: TextFieldProps['variant'];
	showPreTestScoresOption?: boolean;
};

const CompositeTestScoresInput: FC<CompositeTestScoresProps> = ({
	centered = false,
	singleColumnStyle,
	inputMargin,
	actLabel,
	satLabel,
	label = 'Test Scores',
	subText,
	required = false,
	variant,
	showPreTestScoresOption,
}) => {
	const [hasTakenTests, setHasTakenTests] = useState(true);
	const { getValue, getError, setError } = useContext(FormContext);

	const actIsEmpty = R.isEmpty(getValue(FormNames.actComposite)[FormNames.actComposite]);
	const satIsEmpty = R.isEmpty(getValue(FormNames.satComposite)[FormNames.satComposite]);

	useEffect(() => {
		if (!satIsEmpty && getError(FormNames.actComposite) === requiredFieldMessage) {
			setError(FormNames.actComposite, ['']);
		}
	}, [actIsEmpty, satIsEmpty, getError, setError]);

	useEffect(() => {
		if (!actIsEmpty && getError(FormNames.satComposite) === requiredFieldMessage) {
			setError(FormNames.satComposite, ['']);
		}
	}, [actIsEmpty, satIsEmpty, getError, setError]);

	return (
		<Grid container>
			{hasTakenTests ? (
				<Grid container spacing={4} justifyContent={centered ? 'center' : 'flex-start'}>
					<Grid container item xs={12} spacing={1}>
						<Grid item xs={12}>
							<Typography variant="h6">{label}</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body2" color="textSecondary">
								{subText}
							</Typography>
						</Grid>

						<Grid item xs={singleColumnStyle ? 12 : 6}>
							<ActInput
								label={actLabel}
								margin={inputMargin}
								name={FormNames.actComposite}
								fullWidth
								required={required && satIsEmpty}
								variant={variant}
							/>
						</Grid>
						<Grid item xs={singleColumnStyle ? 12 : 6}>
							<SatInput
								label={satLabel}
								margin={inputMargin}
								name={FormNames.satComposite}
								fullWidth
								required={required && actIsEmpty}
								variant={variant}
							/>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<PreTestScoresInput
					label={label}
					subText={subText}
					centered={centered}
					required={required}
					singleColumnStyle={singleColumnStyle}
					variant={variant}
				/>
			)}
			{showPreTestScoresOption && (
				<Grid item>
					<Button color="primary" onClick={() => setHasTakenTests(prev => !prev)}>
						{hasTakenTests ? "I haven't taken the ACT or SAT" : "I've taken the ACT or SAT"}
					</Button>
				</Grid>
			)}
		</Grid>
	);
};

export default CompositeTestScoresInput;
