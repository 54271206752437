import React, { FC } from 'react';
import { FormNames } from '@cappex/constants';
import { BaseFormSelectProps } from '@common/components/BaseFormSelect';
import YesNoSelectorInput, { YesNoSelection } from '@common/components/YesNoSelectorInput';

type FirstGenerationStudentProps = Omit<Partial<BaseFormSelectProps>, 'references'>;

const FirstGenerationStudent: FC<FirstGenerationStudentProps> = ({
	label = 'Will you (or your siblings) be the first in your family to go to college?',
	initialValue = YesNoSelection.NO_ANSWER,
	required,
}) => (
	<YesNoSelectorInput
		title={label}
		name={FormNames.firstGenerationStudent}
		defaultValue={initialValue}
		required={required}
	/>
);

export default FirstGenerationStudent;
