import React, { FC, useCallback, useContext, useState } from 'react';
import { styled } from '@cappex/theme';
import { Card, CardContent } from '@material-ui/core';
import { AutomationNameDefault } from '@util/automation';
import OfferCardMedia from './OfferCardMedia';
import {
	AccountCampaignOfferResponse,
	CampaignOffer,
	CampaignOfferContent,
	CampaignOfferContentDisplayType,
	CampaignOfferContentType,
	CampaignOfferContentValueActionType,
	Placement,
	ResponseType,
} from '@src/features/offer/constants/constants';
import * as R from 'ramda';
import OfferCardHeader from '@src/features/offer/components/OfferCardHeader';
import OfferCardParagraph from '@src/features/offer/components/OfferCardParagraph';
import OfferCardSubmitButton from '@src/features/offer/components/OfferCardSubmitButton';
import { SnackbarContext, SnackbarType } from '@common/components/SnackbarManager';
import { ErrorMessages } from '@cappex/request';
import {
	findButtonAction,
	findButtonActionType,
	findContentValueText,
	groupByContentDisplayType,
	mapOfferContentTypeToOfferContent,
	sortByPosition,
} from '@src/features/offer/util/offerUtil';
import Grid from '@material-ui/core/Grid';
import { saveAccountCampaignOfferResponse } from '@util/hooks/useCampaignOffers';
import OfferCardParagraphWithHtml from '@src/features/offer/components/OfferCardParagraphWithHtml';

export interface OfferCardBaseProps {
	onOfferAccepted?: (campaignOfferId: number) => void;
	placement?: Placement;
}

interface OfferCardProps extends OfferCardBaseProps {
	campaignOffer: CampaignOffer;
	onOpenModalClick: (
		campaignOfferId: number,
		modalCampaignOfferContent: CampaignOfferContent[]
	) => void;
}

const CardStyled = styled(Card)`
	position: relative;
`;

const StyledCardContent = styled(CardContent)`
	height: 17rem;
	padding-top: 2rem;
	&& {
		padding-bottom: 0.5rem;
	}
`;

const FullHeightGrid = styled(Grid)`
	height: 100%;
`;

const OfferCard: FC<OfferCardProps> = ({
	onOpenModalClick,
	onOfferAccepted,
	campaignOffer,
	placement,
}) => {
	const { openSnackbar } = useContext(SnackbarContext);
	const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false);
	const { campaignOfferContent, id } = campaignOffer;

	const groupedByContentDisplayType = groupByContentDisplayType(campaignOfferContent);
	const mainContent = sortByPosition(
		groupedByContentDisplayType[CampaignOfferContentDisplayType.MAIN] || []
	) as CampaignOfferContent[];

	const mainContentTypeToContent = mapOfferContentTypeToOfferContent(mainContent);

	const onError = useCallback(
		(message = ErrorMessages.unknown) => {
			setSubmitButtonDisabled(false);
			openSnackbar({
				snackbarType: SnackbarType.Error,
				message,
			});
		},
		[openSnackbar, setSubmitButtonDisabled]
	);

	const onOfferSubmitSuccess = () => {
		setSubmitButtonDisabled(false);
		onOfferAccepted?.(id);
	};

	const onSubmit = () => {
		setSubmitButtonDisabled(true);

		const form = {
			campaignOfferId: id,
			responseType: ResponseType.ACCEPT,
			placementName: placement,
			campaignOfferContentIdToValues: {},
		} as AccountCampaignOfferResponse;

		// no custom question validation
		saveAccountCampaignOfferResponse(form, onOfferSubmitSuccess, onError, onError);
	};

	const onButtonClick = () => {
		const actionType = findButtonActionType(
			mainContentTypeToContent[CampaignOfferContentType.BUTTON]
		);

		const contentValueAction = findButtonAction(
			mainContentTypeToContent[CampaignOfferContentType.BUTTON]
		);

		if (R.isNil(actionType)) {
			onError('Unable to submit offer!');
		}

		if (actionType === CampaignOfferContentValueActionType.SHOW_MODAL) {
			onOpenModalClick(
				id,
				groupedByContentDisplayType[CampaignOfferContentDisplayType.MODAL] || []
			);
		} else if (actionType === CampaignOfferContentValueActionType.LINK_OUT) {
			window.open(contentValueAction.value, '_blank');
		} else {
			onSubmit();
		}
	};

	return (
		mainContent.length > 0 && (
			<div>
				<CardStyled raised data-qa={`${AutomationNameDefault.paOfferCard}-id-${id}`}>
					<OfferCardMedia
						heroImageContent={mainContentTypeToContent[CampaignOfferContentType.HERO]}
						logoImageContent={mainContentTypeToContent[CampaignOfferContentType.LOGO]}
					/>
					<StyledCardContent>
						<FullHeightGrid container spacing={2} alignContent="space-between">
							<Grid item xs={12}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<OfferCardHeader
											text={findContentValueText(
												mainContentTypeToContent[CampaignOfferContentType.HEADER]
											)}
											automationName={AutomationNameDefault.paOfferCardHeaderText}
										/>
									</Grid>
									<Grid item xs={12}>
										<OfferCardParagraph
											text={findContentValueText(
												mainContentTypeToContent[CampaignOfferContentType.PARAGRAPH]
											)}
											automationName={AutomationNameDefault.paOfferCardBodyText}
										/>
										<OfferCardParagraphWithHtml
											text={findContentValueText(
												mainContentTypeToContent[CampaignOfferContentType.PARAGRAPH_WITH_HTML]
											)}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<OfferCardSubmitButton
									disabled={submitButtonDisabled}
									automationName={AutomationNameDefault.paOfferCardSignUpButton}
									onClick={onButtonClick}
									buttonText={findContentValueText(
										mainContentTypeToContent[CampaignOfferContentType.BUTTON]
									)}
								/>
							</Grid>
						</FullHeightGrid>
					</StyledCardContent>
				</CardStyled>
			</div>
		)
	);
};

export default OfferCard;
